<template>
  <!-- Component: molecules/toast--glossary -->
  <p class="glossary-link" @click="toast"><slot>Open Glossary</slot></p>
  <!-- End Component: molecules/toast--glossary -->
</template>

<script>
  import { defineComponent } from "vue"
  import { createToast, withProps } from 'mosha-vue-toastify';
  import ToastContent from "@/alivue/components/toast--content.vue";

  export default defineComponent({
    name: 'ToastGlossary',
    setup () {
      const toast = () => {
        createToast(withProps(ToastContent, {
              title: 'Agency',
              definition: `<div>
                            <p>An agency Includes any official, officer, commission, board, authority, council, committee, or department of the executive branch of state government. For purposes of this website the judicial and legislative branches are also included as agencies.</hp>
                          </div>`,
              plainLang: `<div>
                          <p>Agencies are the department-level organizations underneath the state executive branch of governement.</p>
                          </div>`
              }
            ),
          {
            swipeClose: false,
            position: 'top-right',
            timeout: -1,
            showCloseButton: true
          }
        );
      }
      return { toast }
    }
  })
</script>

<style lang='scss'>
    @import "../../assets/scss/plugins/_mosha-vue-toastify.scss";
    @import "../../assets/scss/_variables.scss";

    .glossary-link {
      cursor: pointer;
      border-bottom: 1px dotted $blue--med;
      display: inline-block;
    }
    .mosha__toast__close-icon {
      position: relative;
      left: -25px;
    }
</style>
