<template>
    <!-- Component: organisms/search-result-card -->
    <info-card search="true" :type="type" :contact="contact" :agency="agency" :position="position" :program="program" :programcategory="programcategory" :vendor="vendor" :cardtoolsize="cardtoolsize">
        <div class="card-content">
            <slot></slot>
        </div>
    </info-card>
    <!-- End Component: organisms/search-result-card -->
</template>

<script>
    import InfoCard from "./info-card";

    export default {
        name: 'SearchResultCard',
        props: [
            'type',
            'contact',
            'agency',
            'position',
            'program',
            'programcategory',
            'vendor',
        ],
        components: {InfoCard},
        data() {
            return {
                search: true,
                cardtoolsize: 'small'
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/mixins/_bemify.scss";

    @include block('card') {
        .media {
            display: contents;
        }
        .gov-tag {
            display: inline-block;
        }
    }

    .contact-is-leader {
        display: flow-root;

        h1 {
            display: inline-block;
            float: left;
            margin-right: 5px;
        }
    }

    .contact-block {
        .card__info__label {
            margin-bottom: 15px !important;
        }

        .columns {
            margin-bottom: 0px !important;

            .column {
                padding-bottom: 0px;
                padding-top: 0px;
                padding-left: 0px;
            }
        }
    }
</style>

