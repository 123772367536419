<template>
    <!-- Component: organisms/documents-table -->
     <div id="snackbar-document">
        <div @click="hideFlyout()" class="close-item"></div>
        <div class="snackbar-container">
            <document-flyout
                v-if="documentContentId"
                v-bind="documentProps"
                :agency="documentProps.agency && documentProps.agency.AgencyRef ? documentProps.agency.AgencyRef : null"
            ></document-flyout>
        </div>
    </div>

    <general-container
        :customClass="'column documents-table-column'"
        :titleClass="titleClass"
        :isLocked="false"
        :title="'Budget Document Search'"
        v-if="showBlock"
    >

        <div class="filter-container container" id="documentsTableWrapper">

            <div id="reset-filters" @click="resetAllFilters"></div>

            <div class="columns" >
                <div class="column" v-if="notOnAgencyPage">
                    <label for="tbl-agency" class="label">Search by Agency</label>
                    <div class="control">
                        <custom-select
                            id="tbl-agency"
                            :items="[{value: 'agency', name: 'All Agencies'}].concat(agencies.map(item => ({value: item.locationId, name: item.name})).filter(x => x.name !== 'Legislative Branch'
                            && x.name !== 'Administered Funds'
                            && x.name !== 'CareerSource Florida'
                            && x.name !== 'Citizen\'s Property Insurance'
                            && x.name !== 'Commission on Offender Review'
                            && x.name !== 'Office of Financial Regulation'
                            && x.name !== 'State Board of Administration'
                            && x.name !== 'Office of Insurance Regulation'
                            && x.name !== 'Division of Emergency Management'
                            && x.name !== 'Florida Housing Finance'
                            && x.name !== 'Volunteer Florida'))"
                            name="agencyId"
                            placeholder="Select an Agency..."
                            :dropdown-hide="hideDropdown"
                            v-model:value="agencyId"
                            @update:value="updateFilters"
                        ></custom-select>
                    </div>
                </div>

                <div class="column">
                    <label for="tbl-year" class="label">Fiscal Year</label>
                    <div class="control">
                        <custom-select
                            :uniqueId="uniqueId"
                            :items="years"
                            name="year"
                            placeholder="Select a year..."
                            :dropdown-hide="hideDropdown"
                            v-model:value="year"
                            @update:value="updateFilters"
                        ></custom-select>
                    </div>
                </div>

                <div class="column" v-if="notOnAgencyPage">
                    <label for="tbl-domain" class="label">Search by Domain</label>
                     <div class="control">
                        <custom-select
                            :uniqueId="uniqueId"
                            :items="domains"
                            name="domain"
                            placeholder="Select a Domain..."
                            :dropdown-hide="hideDropdown"
                            v-model:value="domain"
                            @update:value="updateFilters"
                        ></custom-select>
                    </div>
                </div>

                <div class="column">
                    <label for="tbl-types" class="label">Search by Type</label>
                     <div class="control">
                        <custom-select
                            :uniqueId="uniqueId"
                            :items="types"
                            name="type"
                            placeholder="Select a Type..."
                            :dropdown-hide="hideDropdown"
                            v-model:value="type"
                            @update:value="updateFilters"
                        ></custom-select>
                    </div>
                </div>
            </div>

            <div class="columns">
                <div class="column">
                    <div :id="'applied-filters-' + uniqueId"></div>
                </div>
            </div>

            <div class="filter-input field">
                <label :for="'tbl-keyword-' + uniqueId" class="label">Filter</label>
                <div class="control">
                    <input type="search" :id="'tbl-keyword-' + uniqueId" v-model="keyword" class="input"
                        placeholder="Filter by keyword"
                    />
<!-- this overlaps the 'x' to clear the search field
                    <span class="search-icon">
                        <font-awesome-icon :icon="faSearch" aria-label="Search icon"></font-awesome-icon>
                    </span>
-->
                    <div class="exact-match">
                        <input :id="'exact-match'"
                                class="switch is-rounded"
                                v-on:click="clickExactMatch($event)"
                                type="checkbox"
                                :value="'Exact match'"
                        >
                        <label :for="'exact-match'" class="status-filter-label">Exact match</label>
                    </div>
                </div>
            </div>

            <div class="columns">
                <div class="column">
                    <div id="docCount" class="filter-info">{{ documentsTotalCount || '0' }} items found</div>
                </div>
            </div>
        </div>

        <div class="documentsResults">
            <template v-if="!documentsLoading" v-for="document in documentsSorted">
                <document-info-card
                    :title="document.title"
                    :year="document.fiscalYearStart"
                    :agency="document.agency && document.agency.AgencyRef ? document.agency.AgencyRef : null"
                    :category="document.category"
                    :fileUrl="document.fileUrl"
                    :contentId="document.contentId"
                    :locationId="document.locationId"
                    :highlight="document.highlight"
                    :searchTerm="keyword"
                    :exact="exactMatch"
                />
            </template>
        </div>

        <div class="preloader-container">
            <preloader v-if="documentsLoading"/>
        </div>

        <pagination v-if="documentsSorted && documentsPageCount > 1" v-on:paginated="paginateDocuments($event)" :current="documentsPage" :pageCount="documentsPageCount"/>
    </general-container>
    <!-- End Component: organisms/documents-table -->
</template>

<script>
    import RequestHandler from "@/handler/RequestHandler";
    import GeneralContainer from "./general-container";
    import { nextTick,computed, onMounted, ref, watch } from "vue";
    import { useStore } from "vuex";
    import Preloader from "./preloader";
    import Pagination from "./pagination.vue";
    import CustomSelect from "./custom-select";
    import Datepicker from "vue3-datepicker";
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
    import { faCalendarDay } from '@fortawesome/pro-duotone-svg-icons';
    import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
    import Multiselect from '@vueform/multiselect';
    import ProcurementFlyout from "./procurement-flyout";
    import BookmarkNoteIcons from "./bookmark-note-icons"
    import DocumentInfoCard from "./document-info-card";
    import { v4 as uuidv4 } from "uuid";
    import { getElementByPartialId } from "@/helpers/utils";

    export default {
        components: {
            Preloader,
            GeneralContainer,
            Pagination,
            CustomSelect,
            Datepicker,
            FontAwesomeIcon,
            Multiselect,
            ProcurementFlyout,
            BookmarkNoteIcons,
            DocumentInfoCard,
        },
        props: {
            title: String,
            titleClass: String,
            customContainerClass: String,
            scrollable: Boolean,
            hideAgencyName: Boolean,
            procurementFiscalYear: String,
            notOnAgencyPage: {
                type: Boolean,
                default: true,
            },
            agencyId: String,
        },
        data() {
            return {
                faCalendarDay: faCalendarDay,
                faSearch: faMagnifyingGlass,
                documentContentId: null,
                documentProps: null,
            }
        },
        methods: {
            updateQueryParameters() {
                const currentQuery = { ...this.$route.query };

                currentQuery.agency = this.agencyId;
                currentQuery.year = this.year;
                currentQuery.domain = this.domain;

                if (this.typesValues.length > 0) {
                    currentQuery.type = this.typesValues.join(',');
                } else {
                    delete currentQuery.type;
                }

                currentQuery.keyword = this.keyword;
                this.$router.push({ query: currentQuery });
            },
            openFlyout(locationId) {
                RequestHandler.loadDocument(locationId).then((data) => {
                    if (data && data.DocumentRef) {
                        this.documentContentId = data.DocumentRef.contentId;
                        this.documentProps = data.DocumentRef;
                        var x = document.getElementById("snackbar-document");
                        x.classList.add('show');
                        x.classList.add('fadein');
                        document.body.classList.add('position-fixed');
                    }
                }).catch(e => {
                    console.log(e);
                })
            },
            hideFlyout: function() {
                var x = document.getElementById("snackbar-document");
                x.classList.remove('fadein');
                x.classList.add('fadeout');
                document.body.classList.remove('position-fixed');
                setTimeout(function(){
                    x.classList.remove('show'); x.classList.remove('fadeout');
                }, 500);
            },
            resetDropDown: function () {
                console.log(this.uniqueId);
                this.agencyIds = [];
                let valDiv =  document.getElementById('dmagencyId-' + this.uniqueId);
                valDiv.firstChild.innerHTML = "Select an Agency...";

                this.domainValues = [];
                valDiv = document.getElementById('dmdomain-' + this.uniqueId);
                valDiv.firstChild.innerHTML = "Select a Domain...";

                this.typesValues = [];
                valDiv = document.getElementById('dmtype-' + this.uniqueId);
                valDiv.firstChild.innerHTML = "Select a Type...";

                this.appliedFilters = [];
                valDiv = document.getElementById('applied-filters-' + this.uniqueId);
                valDiv.innerHTML = "";
            },
            removeFilter: function (elem) {
                let filterValue = ''; let filterName = '';
                if (elem.target.nodeName === 'SPAN') {
                    filterValue = elem.target.parentNode.getAttribute('data-value');
                    filterName = elem.target.parentNode.getAttribute('data-name');

                    elem.target.parentNode.remove();
                } else {
                    filterValue = elem.target.dataset.value;
                    filterName = elem.target.dataset.name;

                    elem.target.remove();
                }

                let valType = false;
                let index = this.appliedFilters.indexOf(filterValue);
                if (index !== -1) {
                    this.appliedFilters.splice(index, 1);
                }

                if (filterName === "types") {
                    this.typesValues.splice(this.typesValues.indexOf(filterValue),1);
                    if (!this.typesValues.length) {
                        valType = 'type';
                    }
                } else if (filterName === "domain") {
                    this.domainValues.splice(this.domainValues.indexOf(filterValue),1);
                    if (!this.domainValues.length) {
                        valType = 'domain';
                    }
                } else if (filterName === "year") {
                    this.yearValues.splice(this.yearValues.indexOf(filterValue),1);
                    if (!this.yearValues.length) {
                        valType = 'year';
                    }
                } else {
                    this.agencyIds.splice(this.agencyIds.indexOf(parseInt(filterValue)),1);
                    if (!this.agencyIds.length) {
                        valType = 'agencyId';
                    }
                }

                if (valType) {
                    this.hideDropdown = true;
                    let valSelect = document.getElementById('dm' + valType + '-' + this.uniqueId);
                    let valOption = (valType === 'agencyId') ? 'agencies' : valType + 's';
                    if (valSelect == null) {
                        this.hideDropdown = false;
                        this.resetAllFilters()
                    }
                    valSelect.click();

                    setTimeout(() => {
                        let itemSelect = document.getElementById(valType + '-all-' + valOption);
                        itemSelect.click();
                        this.hideDropdown = false;
                    }, 400)
                } else {
                    this.loadDocuments(true);
                }
            },
            resetAllFilters: function () {
                this.resetDropDown();

                this.keyword = '';
                this.timer = null;
                getElementByPartialId('tbl-keyword').value = '';

                this.resetDate = true;
                this.dateStartStart = 0;
                this.dateStartEnd = 0;
                this.dateEndStart = 0;
                this.dateEndEnd = 0;
            },
            updateFilters: function (val) {
                if (this.appliedFilters.indexOf(val) !== -1) {
                    return;
                }

                if (val === 'year' || val === 'types' || val === 'domain' || val === 'agency') {
                    let allRemove = document.querySelectorAll("[data-name='" + val + "']");
                    for (let i = 0; i < allRemove.length; i++) {
                        allRemove[i].parentNode.removeChild(allRemove[i]);
                    }

                    if (val === "types") {
                        this.typesValues = [];
                    } else if (val === "domain") {
                        this.domainValues = [];
                    } else if (val === "year") {
                        this.appliedFilters = this.appliedFilters.filter((y) => !this.yearValues.includes(y));
                        this.yearValues = [];
                    } else {
                        this.agencyIds = [];
                    }

                    this.loadDocuments(true);
                    return;
                }

                let filter = val; let filterType = '';
                this.appliedFilters.push(filter.toString());


                if (this.types.find(o => o.value === val)) {
                    filterType = 'types';
                    this.typesValues.push(filter)
                } else if (this.domains.find(o => o.value === val)) {
                    filterType = 'domain';
                    this.domainValues.push(filter);
                } else if (this.years.find(o => o.value === val)) {
                    filterType = 'year';
                    this.yearValues.push(filter);
                    let yearValue = this.years.find(o => o.value === val);
                    filter = yearValue.name;
                } else {
                    filterType = 'agency';
                    this.agencyIds.push(filter);

                    let agency = this.agencies.find(o => o.locationId === val);
                    filter = agency.name;
                }

                let buttonEl = document.createElement('button');
                buttonEl.setAttribute("class", "button-filter button btn");
                buttonEl.setAttribute("data-value", val);
                buttonEl.setAttribute("data-name", filterType);
                buttonEl.addEventListener("click", this.removeFilter);

                let filterClose = document.createElement('span');
                filterClose.setAttribute("style", "padding-right: 10px; font-weight: bold; position: relative; top: -1px; font-size: 21px;");
                filterClose.innerHTML = "× ";
                buttonEl.appendChild(filterClose);
                buttonEl.innerHTML += filter;

                let filterDiv = document.getElementById('applied-filters-' + this.uniqueId);
                filterDiv.appendChild(buttonEl);

                this.loadDocuments(true);
            }
        },
        mounted() {
            if ( this.notOnAgencyPage) {
                let currentFiscalYear = 2025;
                if (new Date().getMonth() < 6) {
                    currentFiscalYear = currentFiscalYear - 1;
                }

                let filter = currentFiscalYear.toString() + " - " + (currentFiscalYear + 1).toString();

                let buttonEl = document.createElement('button');
                buttonEl.setAttribute("class", "button-filter button btn");
                buttonEl.setAttribute("data-value", currentFiscalYear);
                buttonEl.setAttribute("data-name", "year");
                buttonEl.addEventListener("click", this.removeFilter);

                let filterClose = document.createElement('span');
                filterClose.setAttribute("style", "padding-right: 10px; font-weight: bold; position: relative; top: -2px; font-size: 21px;");
                filterClose.innerHTML = "× ";
                buttonEl.appendChild(filterClose);
                buttonEl.innerHTML += filter;

                let filterDiv = document.getElementById('applied-filters-' + this.uniqueId);
                // filterDiv.appendChild(buttonEl);
            }
        },
        setup(props) {
            let currentFiscalYear = new Date().getFullYear();
            if (new Date().getMonth() < 6) {
                currentFiscalYear = currentFiscalYear - 1;
            }

            const notOnAgencyPage = ref(props.notOnAgencyPage);
            const store = useStore();
            const documents = ref([]);
            const sortField = ref('title');
            const documentsLoading = ref(true);
            const documentsSorted = ref([]);
            const documentsPageCount = ref(null);
            const sortIsAsc = ref(false);
            const documentsPage = ref(1);
            const documentsLimit = ref(10);
            const documentsTotalCount = ref(0);
            const appliedFilters = ref([]);
            const appliedFilterType = ref('');
            const agencies = computed(() => store.getters.agencies || []);
            const agencyId = ref('');
            const agencyIds = ref(props.notOnAgencyPage ? [] : props.agencyId);
            const hideDropdown = ref(false);
            const resetDate = ref(false);
            const typesValues = ref([]);
            const year = ref('');
            const yearValues = ref([]);
            let yrs = [];
            let currentYear = 2025;
            for (let i = 2009; i <= currentYear; i++) {
                yrs.push({
                    value: i.toString(),
                    name: i.toString() + " - " + (i + 1).toString(),
                });
            }
            yrs = yrs.reverse();
            yrs = [{value: 'year', name: 'All Years'}].concat(yrs.map(item => ({value: item.value, name: item.name})))
            const years = ref(yrs);
            const domain = ref('');
            const domainValues = ref([]);
            const domains = ref([
                {
                    value: 'domain',
                    name: 'All Domains',
                },
                {
                    value: 'Health and Human Services',
                    name: 'Health and Human Services',
                },
                {
                    value: 'Justice and Public Safety',
                    name: 'Justice and Public Safety',
                },
                {
                    value: 'Environment, Agriculture, and Natural Resources',
                    name: 'Environment, Agriculture, and Natural Resources',
                },
                {
                    value: 'Infrastructure, Tourism, and Economic Development',
                    name: 'Infrastructure, Tourism, and Economic Development',
                },
                {
                    value: 'Education',
                    name: 'Education',
                },
                {
                    value: 'General Government, State Administration, and Technology',
                    name: 'General Government, State Administration, and Technology',
                },
                {
                    value: 'Legislature - House and Senate',
                    name: 'Legislature - House and Senate',
                },
            ]);
            const types = ref([
                {
                    value: 'types',
                    name: 'All Types',
                },
                {
                    value: 'General Appropriations Act',
                    name: 'General Appropriations Act',
                },
                {
                    value: 'House Appropriations Bill 5001',
                    name: 'House Appropriations Bill 5001',
                },
                {
                    value: 'Senate Appropriations Bill 2500',
                    name: 'Senate Appropriations Bill 2500',
                },
                {
                    value: 'Adjustments to Agency Legislative Budget Request',
                    name: 'Adjustments to Agency Legislative Budget Request',
                },
                {
                    value: 'Agency Amended Legislative Budget Request',
                    name: 'Agency Amended Legislative Budget Request',
                },
                {
                    value: 'Agency Capital Improvements Plans',
                    name: 'Agency Capital Improvements Plans',
                },
                {
                    value: 'Agency Legislative Budget Request',
                    name: 'Agency Legislative Budget Request',
                },
                {
                    value: 'Agency Long Range Program Plan',
                    name: 'Agency Long Range Program Plan',
                },
                {
                    value: 'Citizen Support and Direct-Support Organization Report',
                    name: 'Citizen Support and Direct-Support Organization Report',
                },
                {
                    value: 'Contracted Services',
                    name: 'Contracted Services',
                },
                {
                    value: 'Implementing Bill',
                    name: 'Implementing Bill',
                },
                {
                    value: 'Information Technology',
                    name: 'Information Technology',
                },
                {
                    value: 'IT Related Budget Request',
                    name: 'IT Related Budget Request',
                },
                {
                    value: 'Manual Exhibits',
                    name: 'Manual Exhibits',
                },
                {
                    value: 'Schedule IV-B - Information Technology Projects Documents IVB',
                    name: 'Schedule IV-B - Information Technology Projects Documents',
                },
                {
                    value: 'Schedule VIII',
                    name: 'Schedule VIII',
                },
                {
                    value: 'Schedule XIV: Variance from Long Range Financial Outlook',
                    name: 'Schedule XIV: Variance from Long Range Financial Outlook',
                },
                {
                    value: 'Second Submission',
                    name: 'Second Submission',
                },
                {
                    value: 'Third Submission',
                    name: 'Third Submission',
                },
            ]);
            let showBlock = ref(true)
            // RequestHandler.loadDocumentTypes().then((response) => {
            //     let data = response;
            //     data.forEach((item) => {
            //         types.value.push({
            //             value: item.name,
            //             name: item.name,
            //         });
            //     });
            //     console.log(types);
            // });

            // Generate a unique ID for each instance
            const uniqueId = computed(() => {
                return uuidv4();
            });

            const paginateDocuments = (pageTo) => {
                documentsPage.value = pageTo;
                loadDocuments();
                nextTick(() => {
                    scrollToTop();
                });
            };

            const scrollToTop = () => {
                let el = document.getElementById('docCount');
                let navigation = document.getElementById('primary-navigation__container');
                if (!navigation) {
                    navigation = document.getElementById('mobile-navigation');
                }

                window.scrollBy(0, -navigation.scrollHeight);
                el.scrollIntoView({behavior: 'smooth'});
            };

            const loadDocuments = (reset) => {
                if (reset) {
                    documents.value = [];
                    documentsPage.value = 1;
                    documentsPageCount.value = null;
                    documentsTotalCount.value = null;
                }
                let originalSearchQuery = keyword.value;
                let searchQuery = keyword.value;
                if (exactMatch.value) {
                    searchQuery = '"' + searchQuery + '"';
                }
                documentsLoading.value = true;
                RequestHandler.loadDocuments(
                    searchQuery,
                    (documentsPage.value - 1) * documentsLimit.value,
                    documentsLimit.value,
                    sortField.value,
                    sortIsAsc.value,
                    agencyIds.value.toString(),
                    yearValues.value.toString(),
                    domainValues.value.toString(),
                    typesValues.value.toString(),
                ).then(response => {
                    if (originalSearchQuery !== keyword.value) {
                        return;
                    }
                    resetDate.value = false;
                    documentsLoading.value = false;
                    documents.value = response.ContentList.list;
                    documentsPageCount.value = response.ContentList.pageCount;
                    documentsTotalCount.value = response.ContentList.totalCount;
                    if (documentsTotalCount.value > 0) {
                        showBlock.value = true
                    } else {
                        if (!notOnAgencyPage) showBlock.value = false
                    }
                    setDocuments();
                });
            };
            const setDocuments = () => {
                let d = documents.value;

                function compare(a, b) {
                    if (a[sortField.value] < b[sortField.value]) {
                        return sortIsAsc.value ? -1 : 1;
                    }
                    if (a[sortField.value] > b[sortField.value]) {
                        return sortIsAsc.value ? 1 : -1;
                    }
                    return 0;
                }

                documentsSorted.value = d.sort(compare);
            };

            onMounted(() => {
              if (!props.notOnAgencyPage ) {
                  agencyIds.value = props.agencyId
                documentsLimit.value = 5
              }
              loadDocuments()
            });

            const keyword = ref('');
            let timer = null;
            watch(keyword, (newVal) => {
                if (timer) {
                    clearTimeout(timer);
                }
                timer = setTimeout(() => loadDocuments(true), 600);
            });

            const exactMatch = ref(0);
            const clickExactMatch = (event) => {
                exactMatch.value = event.target.checked ? 1 : 0;
                nextTick(() => {
                  loadDocuments(true);
                });
            }

            return {
                keyword,
                documents,
                documentsLoading,
                documentsPageCount,
                documentsLimit,
                documentsTotalCount,
                sortIsAsc,
                sortField,
                documentsSorted,
                agencies,
                agencyId,
                agencyIds,
                appliedFilters,
                appliedFilterType,
                loadDocuments,
                paginateDocuments,
                documentsPage,
                hideDropdown,
                domains,
                domainValues,
                domain,
                year,
                yearValues,
                years,
                exactMatch,
                clickExactMatch,
                types,
                typesValues,
                showBlock,
                notOnAgencyPage,
                uniqueId,
            };
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";

    .tbl thead th {
        color: #fff;
        position: -webkit-sticky;
        position: sticky;
    }
    .documents-table-column {
        padding-left: 0px;
        padding-right: 0px;
    }

    .filter-container {
        //display: flex;
        //justify-content: space-between;
        height: 100%;
        .card-content {
            height: 100%;
        }
    }

    .filter-input {
        .label {
            display: none;
        }
    }
    #tbl-keyword {
        height: 75px;
    }

    .filter-info {
        text-align: center;
        font-size: 21px;
        font-weight: 600;
    }

    .custom-select {
        width: 100%;
    }

    .filter-checkboxes {
        list-style-type: none;
        margin-left: 0px;
        column-count: 2;
    }

    .dark-mode {
        background-color: $blue--dark;
        color: $white;
    }

    .search-filter-name {
        border-bottom: 1px solid $blue--med;
        color: $white !important;
        font-weight: 600;
        font-family: Fira Sans,sans-serif;
        font-size: 1rem;
        margin-bottom: 1em;
        padding-bottom: 10px;
    }

    .date-range-card {
        .control {
            margin-bottom: 10px;
            display: flex;
            .control-icon {
                float: left;
                padding-right: 10px;
                color: $blue--dark;
                font-size: 28px;
            }
            .control-input {
                float: left;
                display: inline-block;
                position: relative;
                top: 7px;
            }
        }
        .control-title {
            display: block;
            width: 100%;
        }
    }

    h3 {
        label {
            font-size: 1rem;
            line-height: 1.1rem;
            margin-bottom: 1em;
            padding-bottom: 10px;
            border-bottom: 1px solid $blue--med;
            &.sub-label {
                border-bottom: none;
                margin-bottom: 0px;
                padding-bottom: 0px;
            }
        }
    }

    .inactive-sort {
        opacity: 0.45;
    }
    .active-sort {
        opacity: 1.0;
    }

    .mw-110 {
        min-width: 110px;
    }

    .search-icon {
        position: absolute;
        top: 20px;
        right: 15px;
        font-size: 24px;
        color: $grey--lighter;
    }

    .date-set {
        padding: 10px;
        .columns {
            margin-bottom: 0px;
            .column {
                padding-bottom: 0px;
            }
        }
        &:nth-of-type(even) {
            background-color: $grey--light;
        }
        h3 {
            line-height: 0em;
            margin-bottom: 10px;
        }
    }

    // based on https://www.w3schools.com/howto/howto_js_snackbar.asp
    /* The snackbar - position it at the bottom and in the middle of the screen */
    #snackbar-document {
        visibility: hidden; /* Hidden by default. Visible on click */
        min-width: 250px; /* Set a default minimum width */
        max-width: 95vw; /* for mobile */
        margin-left: -125px; /* Divide value of min-width by 2 */
        padding: 16px; /* Padding */
        position: fixed; /* Sit on top of the screen */
        z-index: 9; /* Add a z-index if needed */
        right: 15px;
        bottom: 50px;
        max-height: 90vh;
        overflow-y: scroll;
        background-color: $blue--lighter !important;
        color: $blue--dark !important;
        box-shadow: $box-shadow !important;
        border-radius: 8px;
    }

    /* Show the snackbar when clicking on a button (class added with JavaScript) */
    #snackbar-document {
        visibility: hidden;
        opacity: 0;
        &.show {
            visibility: visible;
            opacity: 1;
            -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
            animation: fadein 0.5s, fadeout 0.5s 2.5s;
            &.fadein {
                -webkit-animation: fadein 0.5s;
                animation: fadein 0.5s;
            }
            &.fadeout {
                -webkit-animation: fadeout 0.5s;
                animation: fadeout 0.5s;
            }
        }
    }

    /* Animations to fade the snackbar in and out */
    @-webkit-keyframes fadein {
        from {bottom: 0; opacity: 0;}
        to {bottom: 30px; opacity: 1;}
    }

    @keyframes fadein {
        from {bottom: 0; opacity: 0;}
        to {bottom: 30px; opacity: 1;}
    }

    @-webkit-keyframes fadeout {
        from {bottom: 30px; opacity: 1;}
        to {bottom: 0; opacity: 0;}
    }

    @keyframes fadeout {
        from {bottom: 30px; opacity: 1;}
        to {bottom: 0; opacity: 0;}
    }

    .close-item:before {
        font-size: 30px;
        cursor: pointer;
        content: "×";
        color: $blue--dark;
        transition: color .3s;
        font-weight: 600;
        margin-left: 10px;
        position: relative;
        float: right;
        top: -5px;
        left: -5px;
    }

    .sort-status {
        span:last-of-type {
            position: relative;
            top: -1px;
        }
    }

    .active-only {
        background-color: rgba(51, 116, 170, 0.3);
        text-align: center;
        margin-bottom: 15px;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    #reset-filters {
        display: none;
    }

    .title__bookmark {
        width: 25px;
    }
    .title__content {
        display: inherit;
    }

    .button-filter.button.btn {
        &:focus, &:hover {
        color: $white;
        }
    }
</style>
