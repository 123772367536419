<template>
    <!-- Component: organisms/vendor-info-card -->
    <info-card :vendor="$props">
        <div class="card-content">
            <div class="section">
                <div class="content">

                    <p class="card__info__label top">
                        <Tooltip :tooltipText="definitions && definitions['Vendor'] && definitions['Vendor'].definition ? definitions['Vendor'].definition : 'Loading...'">
                            <span class='has-tooltip'>
                                Vendor
                            </span>
                        </Tooltip>
                    </p>
                    <p class="card__info__title">{{ name }}</p>

                    <template v-if="showSecondName">
                        <p class="card__info__label">Secondary Name</p>
                        <p class="card__info__data">{{ factsSecondaryName }}</p>
                    </template>
                </div>
            </div>
        </div>
    </info-card>
    <!-- End Component: organisms/vendor-info-card -->
</template>

<script>
    import { reactive, computed, ref } from "vue";
    import { useStore } from "vuex";
    import InfoCard from "./info-card"
    import ReadMoreLink from "./read-more-link"
    import Tooltip from "./tooltip";

export default {
        name: 'VendorInformationCard',
        components: {
            InfoCard,
            ReadMoreLink,
            Tooltip
        },
        props: {
            name: String,
            type: String,
            locationId: Number,
            contentId: Number,
            factsSecondaryName: String,
            factsName: String,
            transparencyName: Object,
        },
        setup(props) {
            /*
            let readMoreLink = props.readMoreLink

            if (!readMoreLink) {
                readMoreLink = {};
            }
            if (!readMoreLink.name) {
                readMoreLink.name = "Read More About The " + props.name
            }

            return reactive({readMoreLink})
            */
            const store = useStore();
            const definitions = computed(() => store.getters.definitions);
            const showSecondName  = ref( false)

            function isNumeric(val) {
                return /[^\d\/\.]/g.test(val);
            }

             isNumeric(props.factsSecondaryName) ? showSecondName.value = true : showSecondName.value = false

            return {
                definitions,
                showSecondName,
            };
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";

    .content .card__info__title {
        margin-top: 0.75em !important;
        font-family: $header;
        font-weight: 600;
        font-size: 2em;
    }

    @include block('card') {
        .media {
            display: contents;
        }
        @include element('info') {
            @include element('label') {
                float: left;
                margin-right: 10px;
                display: block;
                width: 100%;
                margin-bottom: 5px !important;
                &.top {
                    margin-bottom: 0px !important;
                }
            }
            @include element('description') {
                line-height: 1.7em;
            }
        }
    }
</style>

