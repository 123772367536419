<template>
    <!-- Component: organisms/navigation -->
    <div id="mobile-nav__container" class="mobile-nav__container">
        <div class="mobile-navigation" id="mobile-navigation">

            <!-- Desktop Navigation -->
            <nav class="nav">
                <div class="hamburger-container" id="hamburger-trigger">
                    <ul class="hamburger">
                        <li id="topBar"></li>
                        <li id="middleBar"></li>
                        <li id="bottomBar"></li>
                    </ul>
                </div>

                <!-- left icons -->
                <div class="desktop-menu-item" data-dropdown="agencies" @click="toggleDropdown('agencies')">
                    <span class="hover">
                        <font-awesome-icon :icon="iconAgency" aria-label="Browse Agencies icon"></font-awesome-icon>
                        <p>Agencies</p>
                    </span>
                </div>
                <div class="dropdown" :style="computedPosition('agencies', 'left')" v-show="dropdown === 'agencies'">
                    <a href="/browse-agencies" class="dropdown-item" @click="[isOpened = false, closeDropdowns()]">
                        <font-awesome-icon :icon="iconOrg" aria-label="Browse Org Charts icon"></font-awesome-icon>
                        <p>Org Charts</p>
                    </a>
                    <a href="/browse-agencies#AgencyList" class="dropdown-item" @click="[isOpened = false, closeDropdowns()]">
                        <font-awesome-icon :icon="iconAgency" aria-label="Browse Agencies icon"></font-awesome-icon>
                        <p>Agencies List</p>
                    </a>
                    <a href="/agency-contacts" class="dropdown-item" @click="[isOpened = false, closeDropdowns()]">
                        <font-awesome-icon :icon="iconContact" aria-label="Browse Contacts icon"></font-awesome-icon>
                        <p>Contacts</p>
                    </a>
                </div>

                <div class="desktop-menu-item" data-dropdown="budgets" @click="toggleDropdown('budgets')">
                    <span class="hover">
                        <font-awesome-icon :icon="iconBudget" aria-label="Browse Budgets icon"></font-awesome-icon>
                        <p>Budgets</p>
                    </span>
                </div>
                <div class="dropdown" :style="computedPosition('budgets', 'left')" v-show="dropdown === 'budgets'">
                    <a href="/budget-dashboard" class="dropdown-item" @click="[isOpened = false, closeDropdowns()]">
                        <font-awesome-icon :icon="iconDocument" aria-label="Budget Dashboard icon"></font-awesome-icon>
                        <p>Budget Dashboard</p>
                    </a>
                    <a href="/browse-documents" class="dropdown-item" @click="[isOpened = false, closeDropdowns()]">
                        <font-awesome-icon :icon="iconDocument" aria-label="Browse Budget Documents icon"></font-awesome-icon>
                        <p>Budget Documents</p>
                    </a>
                    <a href="/browse-budgets" class="dropdown-item" @click="[isOpened = false, closeDropdowns()]">
                        <font-awesome-icon :icon="iconBudget" aria-label="Browse Budgets icon"></font-awesome-icon>
                        <p>Agency Budgets</p>
                    </a>
                </div>
                <!-- end left icons -->

                <!-- desktop / logo -->
                <a href="/dashboard" id="logo" class="logo">
                    <img src="@/assets/svg/MGG-logo-horiz-white.png" alt="MyGovGuide" class="logo-image"/>
                </a>

                <!-- right icons -->
                <div class="desktop-menu-item" data-dropdown="bids" @click="toggleDropdown('bids')">
                    <span class="hover">
                        <font-awesome-icon :icon="iconProcurement" aria-label="Browse Bids icon"></font-awesome-icon>
                        <p>Bids</p>
                    </span>
                </div>
                <div class="dropdown" :style="computedPosition('bids', 'right')" v-show="dropdown === 'bids'">
                    <a href="/browse-procurements" class="dropdown-item" @click="[isOpened = false, closeDropdowns()]">
                        <font-awesome-icon :icon="iconSearchBid" aria-label="Search Bids icon"></font-awesome-icon>
                        <p>Bid Search</p>
                    </a>
                    <a href="/browse-contracts" class="dropdown-item" @click="[isOpened = false, closeDropdowns()]">
                        <font-awesome-icon :icon="iconContract" aria-label="Browse Contracts icon"></font-awesome-icon>
                        <p>Contracts</p>
                    </a>
                    <!-- not ready for production yet
                    <a href="/browse-vendors" class="dropdown-item" @click="[isOpened = false, closeDropdowns()]">
                        <font-awesome-icon :icon="iconVendor" aria-label="Browse Vendors icon"></font-awesome-icon>
                        <p>Vendors</p>
                    </a>
                    -->
                </div>

                <a href="/gov-guidance" class="desktop-menu-item" @click="toggleDropdown('govGuidance')">
                    <span class="hover">
                        <font-awesome-icon :icon="iconGuidance" aria-label="GovGuidance icon"></font-awesome-icon>
                        <p>GovGuidance</p>
                    </span>
                </a>
                <!-- end right icons -->

                <div class="search-trigger__container" id="search-trigger__container" v-if="user">
                    <search-trigger @click="clickSearch"></search-trigger>
                </div>

                <div id="search-container" class="search-container">
                    <div class="filter-wrapper">
                        <search-block
                            title="What can we help you find?"
                            placeholder="Search agencies, job titles, people and more"
                            type="autocomplete"
                            ref="autofocusinputmob"
                        />
                    </div>
                </div>
            </nav>
            <!-- End Desktop Menu -->

            <!-- Main Mobile Menu -->
            <nav>
                <ul id="menu" style="height: 0px;">
                    <li v-for="navItem in navItems">
                        <!-- nav items with children -->
                        <div v-if="navItem.children && navItem.children.length > 0" class="item-container">
                            <p :class="[{ 'is-active': isActive(navItem) }, 'mobile-menu-item mobile-menu-item-with-children']" @click="[isOpened = false, closeDropdowns(), toggleSubmenu(navItem.title)]">
                                <span class="hover">
                                    <font-awesome-icon :icon="whichIcon(navItem.title)" :aria-label="whichLabel(navItem.title) + ' icon'"></font-awesome-icon>
                                    <p>{{ navItem.title.replace('Browse', '') }}</p>
                                </span>
                            </p>
                        </div>
                        <!-- nav items with no children -->
                        <div v-else @click="clickNavItem" class="item-container">
                            <a :href="navItem.url" :class="[{ 'is-active': isActive(navItem) }, 'mobile-menu-item']" @click="[isOpened = false, closeDropdowns(), closeSubmenus()]">
                                <span class="hover">
                                    <font-awesome-icon :icon="whichIcon(navItem.title)" :aria-label="whichLabel(navItem.title) + ' icon'"></font-awesome-icon>
                                    <p>{{ navItem.title.replace('Browse', '') }}</p>
                                </span>
                            </a>
                        </div>
                    </li>
                </ul>
                <ul class="submenu" :data-submenu="navItem.title" v-for="navItem in navItems">
                    <li @click="closeSubmenus()">
                        <span class="hover cursor-pointer mobile-menu-item back-button">
                            <font-awesome-icon :icon="iconBack" aria-label="Back icon"></font-awesome-icon>
                            <p>Back</p>
                        </span>
                    </li>
                    <li v-for="childItem in navItem.children" @click="clickNavItem">
                        <a :href="childItem.url" :class="[{ 'is-active': isActive(childItem) }, 'mobile-menu-item']" @click="[isOpened = false, closeDropdowns(), closeSubmenus()]">
                            <span class="hover">
                                <font-awesome-icon :icon="whichIcon(childItem.title)" :aria-label="whichLabel(childItem.title) + ' icon'"></font-awesome-icon>
                                <p>{{ childItem.title.replace('Browse', '') }}</p>
                            </span>
                        </a>
                    </li>
                </ul>
            </nav>
            <!-- End Main Mobile Menu -->

        </div>
    </div>
    <!-- End Component: organisms/navigation -->
</template>

<script>
    import { ref } from "vue";
    import { useRouter } from "vue-router";
    import useUser from "@/helpers/useUser";
    import SearchTrigger from "./search-trigger.vue";
    import SearchBlock from "./search-block";
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { faTachometerAltFast, faBuilding, faChartLine,  faFileInvoice, faWallet, faFileContract, faMagnifyingGlassDollar, faBriefcase, faChartNetwork, faArrowLeft } from '@fortawesome/pro-regular-svg-icons'
    import { faUser, faMapSigns } from '@fortawesome/pro-solid-svg-icons'
    import AppButton from "./app-button.vue";
    import UserTools from "./user-tools.vue";

    export default {
        name: 'Mobile Navigation',
        data() {
            return {
                iconAgency: faBuilding,
                iconBudget: faChartLine,
                iconContact: faUser,
                iconProcurement: faWallet,
                iconDocument: faFileInvoice,
                iconContract: faFileContract,
                iconSearchBid: faMagnifyingGlassDollar,
                iconVendor: faBriefcase,
                iconOrg: faChartNetwork,
                iconGuidance: faMapSigns,
                iconBack: faArrowLeft,
                dropdown: null,
                activeSubmenu: null
            }
        },
        components: {
            UserTools,
            AppButton,
            SearchTrigger,
            SearchBlock,
            FontAwesomeIcon
        },
        props: {
            agencies: Array,
            searchStatus: String
        },
        computed: {
            computedPosition() {
                return (element, position) => {
                    const prevMenuItem = document.querySelector(`.desktop-menu-item[data-dropdown="${element}"]`);
                    if (prevMenuItem) {
                        const prevMenuItemRect = prevMenuItem.getBoundingClientRect();
                        if (position === 'left') {
                            return `left: ${prevMenuItemRect.left}px`;
                        } else if (position === 'right') {
                            const menuItemFromRight = prevMenuItemRect.right - 300;
                            return `left: ${menuItemFromRight}px`;
                        }
                    }
                    return '0px';
                };
            }
        },
        methods: {
            whichIcon(title) {
                const icons = {
                    'Dashboard': faTachometerAltFast,
                    'Browse Agencies': faBuilding,
                    'Agencies List': faBuilding,
                    'Agency Budgets': faChartLine,
                    'Org Charts': faChartNetwork,
                    'Browse Budgets': faChartLine,
                    'Contacts': faUser,
                    'Browse Contacts': faUser,
                    'Browse Contracts': faFileContract,
                    'Browse Bids': faWallet,
                    'Budget Documents': faFileInvoice,
                    'Bid Search': faMagnifyingGlassDollar,
                    'Browse Vendors': faBriefcase,
                    'GovGuidance': faMapSigns
                };
                return icons[title] || null;
            },
            whichLabel(title) {
                const labels = {
                    'Dashboard': 'Dashboard',
                    'Browse Agencies': 'Browse Agencies',
                    'Agencies List': 'Agencies List',
                    'Agency Budgets': 'Agency Budgets',
                    'Org Charts': 'Org Charts',
                    'Browse Budgets': 'Browse Budgets',
                    'Contacts': 'Contacts',
                    'Browse Contacts': 'Browse Contacts',
                    'Browse Contracts': 'Browse Contracts',
                    'Browse Bids': 'Browse Bids',
                    'Bid Search': 'Bid Search',
                    'Budget Documents': 'Budget Documents',
                    'Browse Vendors': 'Browse Vendors',
                    'GovGuidance': 'GovGuidance'
                };
                return labels[title] || null;
            },
            clickSearch() {
                let element = document.getElementById('search-container');
                let searchTrigger = document.getElementById('search-trigger__container');
                this.closeTabs();
                this.dropdown = null;
                this.closeHamburger();

                if (element.classList.contains('is-active')) {
                    this.closeSearch();
                } else {
                    element.classList.add('is-active');
                    searchTrigger.classList.add('is-active');
                }
                this.$refs.autofocusinputmob.focusInput()
            },
            closeSearch() {
                const search = document.getElementById('search-container');
                const searchTrigger = document.getElementById('search-trigger__container');
                if (search.classList.contains('is-active')) {
                    search.classList.remove('is-active');
                    searchTrigger.classList.remove('is-active');
                }
            },
            clickNavItem() {
                this.closeTabs();
                this.dropdown = null;
                this.closeHamburger();
                this.closeSearch();
            },
            toggleDropdown(dropdown) {
                this.closeHamburger();
                this.closeSearch();

                if (this.dropdown === dropdown) {
                    this.dropdown = null;
                    this.closeTabs();
                } else {
                    this.closeTabs();
                    this.dropdown = dropdown;
                    // Add 'active' class to the clicked dropdown's parent element
                    const dropdownItem = document.querySelector(`.desktop-menu-item[data-dropdown="${dropdown}"]`);
                    if (dropdownItem) {
                        dropdownItem.classList.toggle('active');
                    }
                }
            },
            closeDropdowns() {
                this.dropdown = null;
                this.closeTabs();
            },
            openHamburger() {
                this.closeDropdowns();
                this.closeSearch();

                const topBar = document.getElementById('topBar'),
                      middleBar = document.getElementById('middleBar'),
                      bottomBar = document.getElementById('bottomBar'),
                      containerMobile = document.getElementById('menu');

                    bottomBar.classList.add("hidden");
                    topBar.classList.add("rot45deg");
                    middleBar.classList.add("rot-45deg");

                    containerMobile.classList.add('active');
                    containerMobile.style.height = 'auto';

                    let height = containerMobile.clientHeight + "px";

                    setTimeout(function () {
                        containerMobile.style.height = height;
                    }, 0);
            },
            closeHamburger() {
                const topBar = document.getElementById('topBar'),
                      middleBar = document.getElementById('middleBar'),
                      bottomBar = document.getElementById('bottomBar'),
                      containerMobile = document.getElementById('menu');

                topBar.classList.remove("rot45deg");
                middleBar.classList.remove("rot-45deg");
                bottomBar.classList.remove("hidden");

                containerMobile.style.height = '0px';
                containerMobile.addEventListener('transitionend', function () {
                    containerMobile.classList.remove('active');
                }, {
                    once: true
                });
                this.closeSubmenus();
                this.closeDropdowns();
            },
            toggleSubmenu(elm) {
                this.closeSubmenus();
                const submenu = document.querySelector(`[data-submenu="${elm}"]`);
                submenu.classList.add('active');
                // make sure the hamburger menu still thinks it is active
                const container = document.getElementById('menu');
                container.classList.add('active');

                const submenuItems = document.querySelectorAll('#menu > li');
                submenuItems.forEach(submenuItem => {
                    submenuItem.classList.add('menu-hidden');
                });
                submenu.parentElement.parentElement.classList.add('submenu-opened');
            },
            closeSubmenus() {
                const submenus = document.querySelectorAll('.submenu');
                submenus.forEach(submenu => {
                    submenu.classList.remove('active');
                });

                const submenuItems = document.querySelectorAll('#menu li');
                submenuItems.forEach(submenuItem => {
                    submenuItem.classList.remove('menu-hidden');
                    submenuItem.classList.remove('submenu-opened');
                });
            },
            closeTabs() {
                // Remove 'active' class from all dropdowns
                const dropdownItems = document.querySelectorAll('.desktop-menu-item');
                dropdownItems.forEach(item => item.classList.remove('active'));
            },
            isActive(navItem) {
                if (this.$route.fullPath.includes('/budget/') && navItem.url === '/browse-budgets' ) {
                    return true;
                } else {
                    return this.$route.fullPath.startsWith(navItem.url) && !this.$route.fullPath.includes('/budget/') ;
                }
            },
            handleClickOutside(event) {
                const container = document.getElementById("mobile-nav__container");
                if (!container.contains(event.target)) {
                    this.closeDropdowns();
                    this.closeHamburger();
                    this.closeSearch();
                }
            },
            handleScroll (event) {
                this.scrollPosition = window.scrollY;
                var navElm = document.getElementById('mobile-nav__container');
                if (this.scrollPosition > 58) {
                    navElm.classList.add("sticky");
                } else {
                    navElm.classList.remove("sticky");
                }
            }
        },
        mounted() {
            window.addEventListener('scroll', this.handleScroll);
            document.addEventListener("click", this.handleClickOutside);

            window.addEventListener('resize', () => {
                if (window.innerWidth >= 750) {
                    this.closeHamburger();
                    this.closeSearch();
                }
            });

            document.getElementById("hamburger-trigger").addEventListener('click', (e) => {
                this.closeTabs();
                this.dropdown = null;
                let container = document.getElementById('menu');
                if (!container.classList.contains('active')) {
                    this.openHamburger();
                } else {
                    this.closeHamburger();
                }
            });
        },
        beforeUnmount() {
            window.removeEventListener('scroll', this.handleScroll);
            document.removeEventListener("click", this.handleClickOutside);
            window.removeEventListener('resize');
        },
        watch: {
            $route: function (toRoute) {
                this.closeSearch();
            }
        },
        setup() {
            const { user } = useUser();
            const router = useRouter();
            const isOpened = ref(false);
            const toggleNav = () => isOpened.value = !isOpened.value;
            const navItems = [
                {
                    url: '/dashboard',
                    title: 'Dashboard',
                },
                {
                    url: '/browse-agencies',
                    title: 'Browse Agencies',
                    children: [
                        { url: '/browse-agencies', title: 'Agencies List' },
                        { url: '/browse-agencies#AgencyList', title: 'Org Charts' },
                        { url: '/agency-contacts', title: 'Contacts' },
                    ],
                },
                {
                    url: '/browse-budgets',
                    title: 'Browse Budgets',
                    children: [
                        { url: '/browse-documents', title: 'Budget Documents' },
                        { url: '/browse-budgets', title: 'Agency Budgets' },
                    ],
                },
                {
                    url: '/browse-procurements',
                    title: 'Browse Bids',
                    children: [
                        { url: '/browse-procurements', title: 'Bid Search'},
                        { url: '/browse-contracts', title: 'Browse Contracts'},
                        { url: '/browse-vendors', title: 'Browse Vendors'},
                    ]
                },
                {
                    url: '/gov-guidance',
                    title: 'GovGuidance',
                }
            ];

            return {isOpened, toggleNav, navItems, user};
        },
    };
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";

    .logo {
        text-decoration: none;
        &:focus {
            text-decoration: none;
            border: 1px dotted $blue--med !important;
        }
        &.is-active .logo-image {
            position: initial;
            top: 0px;
            left: 0px;
        }
    }

    .logo-image {
        width: 220px;
        @media screen and (max-width: 550px) {
            top: 0px;
            left: 0px;
        }
    }

    .logo-container {
        @media screen and (max-width: 550px) {
            text-align: center;
        }
    }

    @include block('mobile-navigation') {

        @include element('container') {
            &.sticky {
                position: sticky;
                top: 0px;
                z-index: 9999;
            }
        }

        .search-container {
            display: none;
            width: 100%;
            position: absolute;
            z-index: 9999999;
            background-color: $blue--med;

            &.is-active {
                display: flow-root;
            }
        }
    }

    .search-trigger__container {
        display: block;
        position: relative;
        top: -30px;

        @media screen and (max-width: 991px) {
            top: -25px;
        }
    }

    #logo {
        display: none;

        &.is-active {
            display: block;
        }
    }

    @media screen and (max-width: 1100px) {
        #logo {
            position: absolute;
            top: 20px;
            text-align: center;
        }
    }

    @media screen and (max-width: 550px) {
        #logo {
            top: 15px;
        }
    }

    // hamburger menu adapted for vue from: https://codepen.io/crushoftheyear/pen/redJdy
    #mobile-nav__container {
        display: list-item;

        ul li {
            list-style-type: none;
        }

        &.sticky {
            display: block;
            position: sticky;
            top: 0px;
            z-index: 9999;

            .nav {
                top: 0;
            }
        }
    }

    .nav {
        background-color: $blue--med;
        width: 100%;
        z-index: 10;
        display: flex;
        justify-content: space-between;

        #logo {
            display: inline-block;
            position: initial;
        }

        .search-trigger__container {
            float: right;
            position: relative;
            top: 6px;
            right: 10px;
        }
    }

    @media screen and (min-width: 751px) {
        .hamburger-container {
            opacity: 0;
            pointer-events: none;
        }
    }

    //horizontal desktop menu
    @media screen and (min-width: 2000px) {
        .nav {
            ul#menu {
                li {
                    display: inline-block;
                    margin-right: -5px;
                }
            }
        }
    }

    // icon animaition
    %burger {
        height: 5px;
        background: $white;
        content: '';
        position: relative;
        transition: .25s ease-in-out;
    }

    .hidden {
        opacity:0;
    }

    .rot45deg {
        transform:rotate(45deg);
        top:10px !important;
    }

    .rot-45deg {
        transform:rotate(-45deg);
    }

    ul.hamburger {
        display:block;

        li {
            @extend %burger;

            &:nth-child(1) {
                top:0;
            }

            &:nth-child(2) {
                top:5px;
            }

            &:nth-child(3) {
                top:10px;
            }
        }
    }

    .hamburger-container {
        width:38px;
        margin:1.2em 1.0em;
        height:30px;
        cursor: pointer;
        display: inline-block;
        position: relative;
        z-index: 40;
    }

    #menu {
        transition: height .25s ease;
        overflow: hidden;

        li a, li > div > p {
            text-align: center;

            span {

                svg, p {
                    color: $white;
                }

                svg {
                    margin-right: 10px;
                    font-size: 24px;
                }

                p {
                    display: initial;
                    font-size: 21px;
                    font-weight: bold;
                }
            }
        }
    }

    .desktop-menu-item {
        display: inline-block;
        color: $white;
        text-decoration: none;
        text-align: center;
        padding: 0 10px;
        cursor: pointer;

        &:hover, &:focus, &:active {
            color: $white;
        }

        @media screen and (max-width: 750px) {
            display: none;
        }

        &.active {
            background-color: $primary;
        }
    }

    .desktop-menu-item span {
        position: relative;
        top: 9px;

        p {
            font-weight: bold;
            color: $white;
            text-decoration: none;
            margin-bottom: 0px;
            &:hover, &:focus, &:active {
                color: $white;
            }
        }

        svg {
            font-size: 28px;
        }
    }

    .mobile-navigation .search-container.is-active {
        position: absolute;
        z-index: 9999999;
    }

    .search-trigger__container.is-active .search-trigger {
        position: relative;
        z-index: 999999999;
    }

    .filter-wrapper {
        max-width: 50%;
        margin: auto;
        margin-top: 20px;

        @media screen and (max-width: 768px) {
            width: 90%;
            max-width: 90%;
        }
    }

    .dropdown {
        display: block;
        position: absolute;
        top: 124px;
        width: 300px;
        background-color: $primary;
        text-align: center;
        z-index: 2;

        .dropdown-item {
            padding: 1em;
        }

        .dropdown-item, .dropdown-item p {
            text-decoration: none !important;
            font-weight: bold;
            font-size: 1em;
            color: $white;

            &:hover, &:focus {
                background-color: #275983;
            }
        }

        .dropdown-item {

            p, svg {
                font-size: 21px;
                display: inline;
            }

            p:hover, p:focus {
                background-color: transparent;
            }

            svg {
                font-size: 24px;
                margin-right: 10px;
            }
        }
    }

    .mobile-nav__container.sticky .dropdown {
        top: 68px;
    }

    .submenu {
        display: none;
        width: 300px;
        text-align: center;
        &.active {
            display: block;
            position: absolute;
            top: 124px;
            z-index: 9;
        }
        li a span, li > span {
            svg {
                margin-right: 10px;
                font-size: 24px;
            }
            p {
                display: inline;
                color: #fff;
                font-size: 21px;
                font-weight: 700;
            }
        }
    }
    .mobile-nav__container.sticky .submenu {
        &.active {
            top: 68px;
        }
    }
    .mobile-menu-item-with-children {
        margin-bottom: 0px;
    }

    ul#menu {
        position: absolute;
        width: 300px;
        z-index: 9;

        li {
            width: 100%;
            display: inline-block;

            &:first-of-type {
                width: 100%;
            }

            @media screen and (max-width: 500px) {
                width: 100%;
            }
            &.menu-hidden {
                display: none !important;
            }
        }

        a:hover {
            background-color: darken($blue--med, 10%);
        }
    }
    .mobile-menu-item {
        width: 100%;
        display: block;
        background-color: $blue--med;
        color: $white;
        font-size: 1.0em;
        padding: 1em;
        transition: background 0.2s;
        text-decoration: none;
        &:hover {
            background-color: darken($blue--med, 10%);
        }
    }
    .submenu li a, .submenu li > span {
        background-color: $blue--dark;
        &:hover {
            background-color: #275983;
        }
    }
    .submenu li > div > p {
        background-color: transparent;
        &:hover {
            background-color: #275983;
        }
    }

    .item-container {
        cursor: pointer;
    }

    .cursor-pointer {
        cursor: pointer;
    }

    .back-button {
        text-align: left;
    }
</style>
