<template>
  <span :tooltip="tooltipText" :position="position" data-html="true" :class="cClass + ' tooltip-container'"><slot /></span>
</template>

<script>
export default {
  name: "Tooltip",
  props: {
    tooltipText: {
      type: String,
      default: "Tooltip text",
    },
    position: {
      default: "top",
      type: String,
    },
    cClass: {
      type: String,
      default: "",
    }
  },
};
</script>
