<template>
    <!-- Component: organisms/recent-news-all-block -->
    <general-container :customClass="customClass" :title="title ? title: ''" >

        <preloader v-if="loading"/>

        <info-block-news
           v-if="!loading && listStory && listStory.length"
            v-for="item in listStory"
            :title="item.title"
            :desc="item.content"
            :url="item.link"
            :date="formatDate(item.lastModified)"
        />

        <div v-if="!listStory || listStory.length === 0" class="no-data">
            <p class="no-data__icon">🧐</p>
            <p class="no-data__title">Hmmmm...</p>
            <p>It looks like there is no recent news. We'll keep checking and put the news here when it drops!</p>
        </div>

    </general-container>
    <!-- End Component: organisms/recent-news-all-block -->
</template>

<script>
import GeneralContainer from "./general-container"
import InfoBlockNews from "./info-block--news"
import ReadMoreLink from "./read-more-link"
import { onMounted,  ref, } from "vue";
import TagAgency from "./tag--agency.vue";
import InfoBlock from "./info-block.vue";
import Preloader from "@/alivue/components/preloader";
import RequestHandler from "@/handler/RequestHandler";

export default {
    name: 'RecentNewsRss',
    props: [ 'title', 'num', 'list' ],
    components: {
      InfoBlock, TagAgency,
        GeneralContainer,
        InfoBlockNews,
        ReadMoreLink,
        Preloader,
    },
    data() {
        return {
            customClass: "recent-news-block",
            readMore: "See All Executive News",
        }
    },

   setup(){
       const loading = ref(true);
       const listStory = ref( [])
       function formatDate(item) {
                let dateFetch = new Date(item)
                let day = dateFetch.getDate()
                let month = dateFetch.getMonth() +1
                let year = dateFetch.getFullYear()
                let date = month +`/`+ day + `/`+ year
           return date
       }

       onMounted(() => {
           RequestHandler.loadFeed().then((list) => {
            listStory.value = list
               loading.value = false;
           }).catch(error => {
               console.error(error);
           });
       });

     return {
         listStory,
         loading,
         formatDate,
     }
   }
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/_variables.scss";
@import "../../assets/scss/mixins/_bemify.scss";
/*
    .media + .media {
        border-top: 1px solid $blue--med;
    }
*/
@include block('no-data') {
    font-weight: 600;
    font-family: $header;
    font-size: 1.25rem;
    text-align: center;
    max-width: 800px;
    margin: 0 auto;
    @include element('title') {
        font-size: 1.5rem;
        margin-bottom: 5px;
    }
    @include element('icon') {
        font-size: 3.5rem;
        margin-bottom: -15px;
    }
}
</style>

