<template>
    <!-- Start Component: organisms/position-budget-type-chart -->
    <div class="pie-chart-wrapper">
        <h3 class="pie-chart-title has-text-centered">Funding Source</h3>
        <div ref="chartEl" class="pie-chart-container"></div>
        <div class="chart-legend chart-legend-with-tooltips">
            <div class="chart-legend__i">
                <span class="chart-legend-1 swatch" :style="{'background-color': CHART_COLORS[0] }"></span>
                <Tooltip :tooltipText="definitions && definitions['General Revenue'] && definitions['General Revenue'].definition ? definitions['General Revenue'].definition : 'Loading...'">
                    <span class='has-tooltip chart-legend__item__title mb-15'>
                        General Revenue
                    </span>
                </Tooltip>
            </div>
            <div class="chart-legend__i">
                <span class="chart-legend-2 swatch" :style="{'background-color': CHART_COLORS[1] }"></span>
                <span class="chart-legend__item__title">
                    <Tooltip :tooltipText="definitions && definitions['Trust Fund'] && definitions['Trust Fund'].definition ? definitions['Trust Fund'].definition : 'Loading...'">
                        <span class='has-tooltip chart-legend__item__title mb-15'>
                            Trust Fund
                        </span>
                    </Tooltip>
                </span>
            </div>
        </div>
    </div>
    <!-- End Component: organisms/position-budget-type-chart -->
</template>

<script>
    import { onMounted, ref, computed } from "vue";
    import { useStore } from "vuex";
    import { renderBudgetTypeChart } from "@/helpers/charts";
    import { CHART_COLORS } from "@/constants";
    import Tooltip from "./tooltip";

    export default {
        name: 'PositionBudgetTypeChart',
        props: ['budgets'],
        components: {
            Tooltip
        },
        setup(props) {
            const chartEl = ref(null);
            const budgetsList = ref(props.budgets || []);
            const store = useStore();
            const definitions = computed(() => store.getters.definitions);

            budgetsList.value.forEach((item, i) => {
                item.color = CHART_COLORS[i] || CHART_COLORS[0];
            });

            onMounted(() => {
                renderBudgetTypeChart(chartEl.value, budgetsList.value);
            });

            return {
                chartEl,
                CHART_COLORS,
                budgetsList,
                definitions
            };
        },
        methods: {
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";

    .pie-chart-title {
        font-weight: 600;
        color: $blue--dark;
        font-size: 18px;
    }

    @include block('chart-legend') {
        text-align: center;
        @include element('item') {
            @include element('title') {
                font-size: 16px !important;
                font-weight: 400;
                position: relative;
                top: -5px;
                padding-bottom: 5px;
            }
        }
    }

    .pie-chart-wrapper {
        background-color: $white;
        border-radius: $border-radius;
        padding: 15px;
        box-shadow: $box-shadow--light;
    }

    .pie-chart-container svg {
        text {
            fill: $white;
        }

        path {
            stroke: $white;
            stroke-width: 5px;

            &:hover, &:active, &:focus {
                stroke-width: 0!important;
            }
        }
    }

    .pie-chart-container {
        position: relative;
        align-items: center;
        display: flex;
        justify-content: center;
    }

    .pie-chart-tooltip {
        position: absolute;
        background-color: $white;
        border: 2px solid $blue--dark;
        border-radius: $border-radius;
        padding: 5px;

        &:hover, &:active, &:focus {
            display: block !important;
        }
    }

    .chart-legend {
        text-align: left;
        a {
            text-decoration: none;
            margin-right: 10px;
            background-color: $white;
            padding: 5px 10px;
            border-radius: $border-radius;
            display: block;
            line-height: 1.4em;
            &.chart-highlighted {
                font-weight: bold;
            }
        }

        span {
            font-size: 2.4em;
            padding-right: 10px;
            line-height: 10px;
        }
    }
    .swatch {
        width: 20px;
        height: 20px;
        display: inline-block;
        margin-right: 10px;
    }

    .pie-chart-wrapper .chart-legend-with-tooltips {
        max-height: auto;
        overflow-y: auto;
        .chart-legend__item__title {
            top: 0px;
        }
    }
</style>
