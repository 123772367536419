<template>
    <!-- Component: organisms/social-media-block--locked -->
    <voter-data-block :isLocked="true" :isMemberOnly="true" :customClass="customClass"></voter-data-block>
    <!-- End Component: organisms/social-media-block--locked -->
</template>

<script>
    import VoterDataBlock from "./voter-data-block.vue"


    export default {
        name: 'VoterDataBlockLocked',

        components: {
            VoterDataBlock
        },
        props: [
            "customClass"
        ],
        data() {
            return {}
        }

    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";

    @include block('voter-data-block') {
        @include element('item') {
            display: block;
            @include element('label') {
                display: inline-block;
                font-weight: 600;
                color: $blue--dark;
                padding-right: 5px;
                margin-bottom: 0.5em !important;
            }
            @include element('response') {
                display: inline-block;
                margin-bottom: 0.5em !important;
            }
        }
    }

    .content.is-medium {
        .voter-data-block__item__label,
        .voter-data-block__item__response {
            font-size: 18px;
        }
    }

    hr {
        background-color: $blue--dark;
    }

    .voter-data-block__party-icon {
        display: inline-block;
        margin-left: 3px;
        position: relative;
        top: 4px;
    }
</style>

