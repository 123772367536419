<template>
    <!-- Component: organisms/agency-budget-group -->
    <div v-if="budgetGroup.budgets.length > 1" class="budget-group">
        <h2 v-if="view != 'program_category'" class="budget-program" @click="budgetGroup.expanded = !budgetGroup.expanded">
            <span class="expandable-sign">{{ budgetGroup.expanded ? '+' : '-' }}</span>
            {{ budgetGroup.program.name === 'Budget' ? budgetGroup.name : budgetGroup.program.name }}
            <span class="budget-program--price">
                ${{ priceFormat(budgetGroup.disbursementAmount) }}
                / ${{ priceFormat(budgetGroup.appropriationAmount) }}
            </span>
        </h2>

        <info-block
                v-if="!budgetGroup.expanded || view == 'program_category'"
                :hasIcon="false" :hasClose="false" :isTweet="false" color="blue--med"
                v-for="budget in budgetGroup.budgets"
                :key="budget"
        >
            <h3><a :href="budget.url">{{ titleize(budget.name) }}</a></h3>
            <p>Amount Spent: <strong>${{ priceFormat(budget.disbursementAmount) }}</strong></p>
            <p>Total Budget: <strong>${{ priceFormat(budget.appropriationAmount) }}</strong></p>
        </info-block>

        <!--agency-budgets-history-chart
                v-if="budgetGroup.expanded || view == 'program_category'"
                :agency-id="agencyId"
                :program-id="budgetGroup.program.id"
                :key="budgetGroup.program.id"
        /-->
    </div>
    <div v-else class= "budget-group">
    </div>
    
    <!-- Component: organisms/agency-budget-group -->
</template>

<script>
    import InfoBlock from "./info-block";
    import { priceFormat } from "@/helpers/filter";
    import AgencyBudgetsHistoryChart from "./agency-budgets-history-chart";
    import { useRouter, useRoute } from 'vue-router';
    import { onMounted, ref } from "vue";

    export default {
        name: 'AgencyBudgetGroup',
        props: ['budgetGroup', 'agencyId', 'view'],
        components: {
            AgencyBudgetsHistoryChart,
            InfoBlock,
        },
        setup(props) {
            const router = useRouter();
            if (props.budgetGroup.budgets.length == 1) {
                router.replace({ path: props.budgetGroup.url })
            }
            return {
                priceFormat,
            };
        },
        methods: {
            titleize: function(item) {
                return item.toLowerCase().replace(/(?:^|\s|-)\S/g, x => x.toUpperCase());
            },
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";

    .budget-group {
        padding-bottom: 15px;
        border-bottom: 1px solid $blue--dark;
        margin-bottom: 30px;
        &:last-of-type {
            border-bottom: none;
            margin-bottom: none;
        }
    }
    .budget-program {
        cursor: pointer;
        font-size: 1.25rem;
        .expandable-sign {
            color: silver;
            padding-right: 10px;
        }
    }

    .budget-program--price {
        float: right;
        font-size: 80%;
    }
</style>
