<template>
    <template v-if="isTableauEmbed">
        <div class="full-page-preloader supe" v-if="pageLoading">
            <preloader />
        </div>
        <component :is="'style'">
            .marker-app {
                display: none;
            }
        </component>
        <div id="main-content" class="container" role="main">
            <router-view></router-view>
            <tableau-embed
                :view-type="'MyGovGuideSpendDashboard'"
                :view-names-breakpoints="{
                    0: 'FloridaSpending',
                }"
                :public="true"
                height="1600px"
            />
        </div>
    </template>
    <template v-else>
        <div class="full-page-preloader supe" v-if="pageLoading">
            <preloader />
        </div>
        <div class="full-page-preloader__background" v-if="pageLoading"></div>

        <SkipToMainContent />

        <masthead @click="catchLinkToRoute" v-bind="siteConfig"/>
    <!--    <navigation v-if="isMobile() == false" @click="catchLinkToRoute" v-bind="{agencies}" :searchStatus="getSearchStatus(currentPageComponentName)"/>
        <mobile-navigation v-if="isMobile()" @click="catchLinkToRoute" v-bind="{agencies}" :searchStatus="getSearchStatus(currentPageComponentName)"/>-->
        <mobile-navigation @click="catchLinkToRoute" v-bind="{agencies}" :searchStatus="getSearchStatus(currentPageComponentName)" v-if="user"/>

        <div id="main-content" class="container" @click="catchLinkToRoute" role="main">
            <router-view></router-view>
            <app-dynamic-page></app-dynamic-page>
        </div>

        <add-to-home-screen
            title="Add to Homescreen"
            titleColor="#022c43"
            buttonColor="#022c43"
            content="For a better user experience, add myGovGuide to your device's homescreen."
            iconPath="/favicons/apple-touch-icon-precomposed.png"
        />

        <app-footer @click="catchLinkToRoute" v-bind="siteConfig"/>
    </template>
</template>

<script>
    import PageHandler from "./handler/PageHandler";
    import AppDynamicPage from "./DynamicPage";
    import Masthead from "@/alivue/components/masthead";
    import Navigation from "@/alivue/components/navigation";
    import { useRouter } from "vue-router";
    import AppFooter from "@/alivue/components/app-footer";
    import { computed, watchEffect } from "vue";
    import { useStore } from "vuex";
    import Preloader from "@/alivue/components/preloader";
    import useUser from "@/helpers/useUser";
    import addToHomeScreen from "@/alivue/components/add-to-home-screen";
    import MobileNavigation from "@/alivue/components/mobile-navigation";
    import SkipToMainContent from "@/alivue/components/skip-to-content";
    import TableauEmbed from "@/alivue/components/tableau-embed";

    export default {
        name: 'app',
        components: {
            Preloader,
            AppFooter,
            Navigation,
            Masthead,
            AppDynamicPage,
            addToHomeScreen,
            MobileNavigation,
            SkipToMainContent,
            TableauEmbed,
        },
        methods: {
            getSearchStatus(pageComponentName) {
                let hiddenSearchPages = [
                    null,
                    'PagePosition',
                    'PageContact',
                    'PageAgency',
                    'PageProgram',
                    'PageProgramCategory',
                    'PageVendor',
                    'PageContract',
                ];
                let fullSearchURLs = [
                    '/',
                    '/dashboard',
                    '/search',
                    '/login',
                ];
                if (
                    hiddenSearchPages.indexOf(pageComponentName) > -1 &&
                    !(fullSearchURLs.indexOf(this.$route.path) > -1)
                ) {
                    return 'hidden';
                }
                return '';
            },
            isMobile() {
                if ((/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) || (window.innerWidth < 992)) {
                    return true
                } else {
                    return false
                }
            }
        },
        setup() {
            const router = useRouter();
            const { user } = useUser();

            let isTableauEmbed = false;
            // if path is /standalone/tableau, set isTableauEmbed to true and return early
            if (router.currentRoute.value.path === '/standalone/tableau') {
                isTableauEmbed = true;
            }
            if (isTableauEmbed) {
                return {
                    isTableauEmbed,
                    pageLoading: false,
                };
            }

            function catchLinkToRoute(event) {
                PageHandler.catchLinkToRoute(event, router);
            }

            const store = useStore();
            const siteConfig = computed(() => store.getters.currentSiteConfig);
            const pageLoading = computed(() => store.getters.pageLoading);
            if (!siteConfig.value) {
                store.dispatch('fetchSiteConfig');
            }
            const agencies = computed(() => store.getters.agencies);
            if (!agencies.value) {
                // update components that rely on this call before removing here
                store.dispatch('fetchAgencies');
            }

            const definitions = computed(() => store.getters.definitions);
            store.dispatch('loadAllDefinitions');

            const currentPageComponentName = computed(() => store.getters.currentPageComponentName);

            const {redirectIfNotVerified, userLoaded} = useUser();
            watchEffect(() => {
                redirectIfNotVerified(router);
            });

            return {
                catchLinkToRoute,
                siteConfig,
                agencies,
                currentPageComponentName,
                pageLoading,
                definitions,
                user,
                isTableauEmbed,
            };
        },
        serverPrefetch() {
            return this.$store.dispatch('fetchSiteConfig');
        }
    }
</script>


<style lang="scss">
    @import './src/assets/scss/_variables.scss';
    @import './src/assets/scss/_required.scss';
    @import './src/assets/scss/helpers/_spacing-helpers.scss';
    @import './src/assets/scss/mixins/_bemify.scss';

    @include block('full-page-preloader') {
        position: absolute;
        left: calc(50% - 100px);
        z-index: 15;
        @include element('background') {
            background-color: $blue--lighter;
            opacity: 0.7;
            width: 100vw;
            height: 100vh;
            position: absolute;
            z-index: 10;
        }
    }
</style>
