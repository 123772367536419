<template>
    <!-- Component: molecules/info-block--stacked-budget-update -->
    <info-block :hasIcon="false" :hasClose="false" :isTweet="false" :color="color">

        <i class="fas fa-dollar-sign icon-inline" ></i>
        <p class="info-block__title">Budget Update {{ updatedAt }}</p>

        <div class="group">
            <p class="label">Agency:</p>
            <tag-agency
                v-if="budget && budget.ProgramRef && budget.ProgramRef.agency && budget.ProgramRef.agency.AgencyRef"
                :tagText="budget.ProgramRef.agency.AgencyRef.name"
                :tagUrl="budget.ProgramRef.agency.AgencyRef.url"
            />
        </div>

        <div class="group">
            <p class="label">Program:</p>
            <tag-program
                v-if="budget && budget.ProgramRef"
                :tagText="budget.ProgramRef.name"
                :type="disbursementPercentage > 0 ? inType : outType"
                :tagUrl="budget.ProgramRef.url"
            />

            <div class="mw-160">
                <span class="info-block__spacer" v-if="disbursementPercentage > 0"
                >Available budget increase <span class="green is-size-7">▲</span></span>
                <span class="info-block__spacer" v-else
                >Available budget decrease <span class="red is-size-7">▼</span></span>
                <span class="info-block__amount is-size-5" v-if="disbursementPercentage">
                    {{ Math.abs(disbursementPercentage).toFixed(4) }}% (${{ priceFormat(difference) }})
                </span>
            </div>
        </div>

    </info-block>
    <!-- End Component: molecules/info-block--stacked-budget-update -->
</template>

<script>
    import InfoBlock from "./info-block"
    import Tag from "./tag";
    import TagAgency from "./tag--agency";
    import TagRole from "./tag--role";
    import TagContact from "./tag--contact";
    import TagProgram from "./tag--program";
    import TagBudget from "./tag--budget";
    import { capitalize, priceFormat } from '@/helpers/filter';

    export default {
        name: 'InfoBlockStackedBudgetUpdate',
        components: {
            InfoBlock, TagAgency, TagRole, TagContact, Tag, TagProgram, TagBudget,
        },
        props: [
            'class',
            'contentId',
            'budget',
            'contentId',
            'disbursementPercentage',
            'locationId',
            'name',
            'updatedAt',
            'difference',
        ],
        data() {
            return {
                requestedIcon: "faDollarSign",
                color: "blue--dark",
                outType: "outgoing",
                inType: "incoming"
            }
        },
        setup(props) {
            return { priceFormat, };
        },
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";

    @include block('info-block') {
        @include element('title') {
            margin-bottom: 10px;
            font-weight: 600;
            font-family: $header;
            font-size: 1.2rem;
        }
    }
    .mw-160 {
        min-width: 160px;
        display: inline-block;
    }
    .red {
        color: $red;
    }
    .green {
        color: $green--light;
    }
    .group {
        margin-bottom: 5px;
    }
    .label {
        margin-bottom: 0px !important;
    }
    .icon-inline {
        display: block;
        float: left;
        margin-right: 7px;
        position: relative;
        top: 5px;
        color: $blue--med;
    }
</style>

