<template>
	<!-- Component: organisms/voter-data-block -->
	<general-container :customClass="customClass" :title="title" :isLocked="isLocked">

		<div class="content is-medium">
			<span class="voter-data-block__item" v-if="partyAffiliation">
				<p class="voter-data-block__item__label">Party Affiliation:</p>
				<img class="voter-data-block__party-icon" src="@/assets/svg/democrat.svg" alt="Democrat" v-if="partyAffiliation === 'Democrat'"/>
				<img class="voter-data-block__party-icon" src="@/assets/svg/republican.svg" alt="Republican" v-if="partyAffiliation  === 'Republican'"/>
				<img class="voter-data-block__party-icon" src="@/assets/svg/flag-usa.svg" alt="Independent" v-if="partyAffiliation  === 'Independent'"/>
				<p class="voter-data-block__item__response">{{ partyAffiliation }}</p>
			</span>

			<span class="voter-data-block__item" v-if="voterLeavesIn">
				<p class="voter-data-block__item__label">Registered to vote in:</p>
				<p class="voter-data-block__item__response">{{ voterLeavesIn }}</p>
			</span>

			<span class="voter-data-block__item" v-if="voteRegistrationDate">
				<p class="voter-data-block__item__label">Registration Date:</p>
				<p class="voter-data-block__item__response">{{ voteRegistrationDate }}</p>
			</span>

			<span class="voter-data-block__item">
				<p class="voter-data-block__item__label">Voter Status:</p>
				<p class="voter-data-block__item__response">{{ voteActive ? 'Active' : 'Inactive' }}</p>
			</span>

		</div>

		<hr v-if="voterPrecinct || voterPrecinctGroup || voterPrecinctSplit || voterCongressionalDistrict || voterHouseDistrict || voterSenateDistrict || voterCountyDistrict || voterSchoolBoardDistrict"/>

		<div class="content is-small" v-if="voterPrecinct || voterPrecinctGroup || voterPrecinctSplit || voterCongressionalDistrict || voterHouseDistrict || voterSenateDistrict || voterCountyDistrict || voterSchoolBoardDistrict">

			<div class="columns voter-data-block__small">

				<div class="column">

					<span class="voter-data-block__item" v-if="voterPrecinct">
						<p class="voter-data-block__item__label">Precinct:</p>
						<p class="voter-data-block__item__response">{{ voterPrecinct   }}</p>
					</span>

					<span class="voter-data-block__item" v-if="voterPrecinctGroup">
						<p class="voter-data-block__item__label">Precinct Group:</p>
						<p class="voter-data-block__item__response">{{ voterPrecinctGroup }}</p>
					</span>

					<span class="voter-data-block__item" v-if="voterPrecinctSplit">
						<p class="voter-data-block__item__label">Precinct Split:</p>
						<p class="voter-data-block__item__response">{{ voterPrecinctSplit }}</p>
					</span>

					<span class="voter-data-block__item" v-if="voterCongressionalDistrict">
						<p class="voter-data-block__item__label">Congressional District:</p>
						<p class="voter-data-block__item__response">{{ voterCongressionalDistrict }}</p>
					</span>

				</div>

				<div class="column">

					<span class="voter-data-block__item" v-if="voterHouseDistrict">
						<p class="voter-data-block__item__label">House District:</p>
						<p class="voter-data-block__item__response">{{ voterHouseDistrict }}</p>
					</span>

					<span class="voter-data-block__item" v-if="voterSenateDistrict">
						<p class="voter-data-block__item__label">Senate District:</p>
						<p class="voter-data-block__item__response">{{ voterSenateDistrict }}</p>
					</span>

					<span class="voter-data-block__item" v-if="voterCountyDistrict"> 
						<p class="voter-data-block__item__label">County District:</p>
						<p class="voter-data-block__item__response">{{ voterCountyDistrict }}</p>
					</span>

					<span class="voter-data-block__item" v-if="voterSchoolBoardDistrict">
						<p class="voter-data-block__item__label">School Board District:</p>
						<p class="voter-data-block__item__response">{{ voterSchoolBoardDistrict }}</p>
					</span>

				</div>

			</div>

		</div>

	</general-container>
	<!-- End Component: organisms/voter-data-block -->
</template>

<script>
	import GeneralContainer from "./general-container"

	export default {
		name: 'VoterDataBlock',
		props: [
			'race', 'partyAffiliation', 'residentialAddress', 'voteRegistrationDate', 'voteActive',
			'isLocked', 'voterCongressionalDistrict', 'voterCountyDistrict',
			'voterSenateDistrict', 'voterSchoolBoardDistrict', 'voterHouseDistrict',
			'voterLeavesIn', 'voterPhone', 'voterPrecinct', 'voterPrecinctGroup',
			'voterPrecinctSplit',
		],
		components: {
			GeneralContainer
		},
		data() {
			return {
				title: "Voter Data",
				customClass: "voter-data-block"
			}
		}
	}
</script>

<style lang="scss" scoped>
	@import "../../assets/scss/_variables.scss";
	@import "../../assets/scss/mixins/_bemify.scss";

	@include block('voter-data-block') {
		@include element('item') {
			display: block;
			@include element('label') {
				display: inline-block;
				font-weight: 600;
				color: $blue--dark;
				padding-right: 5px;
				margin-bottom: 0.5em !important;
			}
			@include element('response') {
				display: inline-block;
				margin-bottom: 0.5em !important;
			}
		}
	}

	.content.is-medium {
		.voter-data-block__item__label,
		.voter-data-block__item__response {
			font-size: 18px;
		}
	}

	hr {
		background-color: $blue--dark;
	}

	.voter-data-block__party-icon {
		display: inline-block;
		margin-right: 7px;
		margin-left: 3px;
		position: relative;
		top: 6px;
	}
</style>