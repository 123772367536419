<template>
    <!-- Component: molecules/prespinner -->
    <div class="loader-container">
        <!-- By Sam Herbert (@sherb), for everyone. More @ http://goo.gl/7AJzbL -->
        <svg width="44" height="44" viewBox="0 0 44 44" xmlns="http://www.w3.org/2000/svg" stroke="#3374AA">
            <g fill="none" fill-rule="evenodd" stroke-width="2">
                <circle cx="22" cy="22" r="1">
                    <animate attributeName="r"
                        begin="0s" dur="1.8s"
                        values="1; 20"
                        calcMode="spline"
                        keyTimes="0; 1"
                        keySplines="0.165, 0.84, 0.44, 1"
                        repeatCount="indefinite" />
                    <animate attributeName="stroke-opacity"
                        begin="0s" dur="1.8s"
                        values="1; 0"
                        calcMode="spline"
                        keyTimes="0; 1"
                        keySplines="0.3, 0.61, 0.355, 1"
                        repeatCount="indefinite" />
                </circle>
                <circle cx="22" cy="22" r="1">
                    <animate attributeName="r"
                        begin="-0.9s" dur="1.8s"
                        values="1; 20"
                        calcMode="spline"
                        keyTimes="0; 1"
                        keySplines="0.165, 0.84, 0.44, 1"
                        repeatCount="indefinite" />
                    <animate attributeName="stroke-opacity"
                        begin="-0.9s" dur="1.8s"
                        values="1; 0"
                        calcMode="spline"
                        keyTimes="0; 1"
                        keySplines="0.3, 0.61, 0.355, 1"
                        repeatCount="indefinite" />
                </circle>
            </g>
        </svg>

        <p class="loader-text">WORKING...</p>

    </div>
    <!-- End Component: molecules/prespinner -->
</template>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";

    .loader-container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 100px;
        margin-top: 100px;
        height: 100px;
    }

    svg{
        width: 200px;
        height: 200px;
        margin: 20px;
        display:inline-block;
    }

    .loader-text {
        position: absolute;
        font-size: 21px;
        font-weight: 600;
        margin-bottom: 0px;
    }
</style>

<script>export default {name:"Preloader",props:[]}</script>