<template>
    <!-- Component: molecules/info-block--budget -->
    <infoBlock requestedIcon="faChartPie" :hasIcon="true" :hasClose="false" :isTweet="false" color="green--light">
        <p class="info-block__title">Budget Update <span>{{ updatedAt }}</span></p>
        <tag-agency
            v-if="budget?.ProgramRef?.agency?.AgencyRef"
            :tag-text="budget.ProgramRef.agency?.AgencyRef.name"
            :tag-url="budget.ProgramRef.agency?.AgencyRef.url"
        />
        <tag-role
            v-if="budget?.ProgramRef"
            :tag-text="budget.ProgramRef.name"
            :tag-url="budget.ProgramRef.url
                ? budget.ProgramRef.url
                : null"
        />
        <span class="info-block__spacer" v-if="disbursementPercentage > 0"
        >increase <span class="green is-size-7">▲</span></span>
        <span class="info-block__spacer" v-else
        >decrease <span class="red is-size-7">▼</span></span>
        <span class="info-block__amount is-size-5" v-if="disbursementPercentage">
            {{ disbursementPercentage.toFixed(4) }}% (${{ priceFormat(difference) }})
        </span>
    </infoBlock>
    <!-- End Component: molecules/info-block--budget -->
</template>

<script>
    import InfoBlock from "./info-block"
    import TagAgency from "./tag--agency";
    import TagRole from "./tag--role";
    import { capitalize, priceFormat } from '@/helpers/filter';

    export default {
        name: 'InfoBlockBudget',
        components: {
            InfoBlock, TagAgency, TagRole
        },
        props: ['disbursementPercentage', 'budget', 'updatedAt', 'difference', ],
        setup(props) {
            return { priceFormat, };
        },
        methods: {
            typeOfChange() {
                if (this.disbursementPercentage > 0) {
                    return 'Increase in Budget';
                } else {
                    return 'Decrease in Budget';
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";

    .red {
        color: $red;
    }

    .green {
        color: $green--light;
    }

    @include block('info-block') {
        @include element('amount') {

        }
        @include element('spacer') {

        }
        @include element('title') {
            font-weight: 600;
            span {
                font-weight: 400;
            }
        }
    }
</style>
