import { ref } from "vue";
const bus = ref(new Map());

const useEventsBus = function(){

    function emit(event, ...args) {
        bus.value.set(event, args);
    }

    return {
        emit,
        bus
    }
}

export {useEventsBus}