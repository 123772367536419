<template>
    <!-- Component: organisms/note-edit -->
    <form action="#note-create" v-on:submit.prevent="editNote">
        <p class="note-create__title">Edit Note</p>

        <div class="field">
            <label class="label" for="note-title">Title</label>
            <div class="control">
                <input class="input" type="text" placeholder="Note Title" id="note-title" v-model="editParams.title" required>
            </div>
        </div>

        <div class="field">
            <label class="label" for="note-text">Text</label>
            <div class="control">
                <textarea class="textarea" placeholder="Note Contents" id="note-text" v-model="editParams.text" required></textarea>
            </div>
        </div>

        <div class="mb-15">
            <tag-input @tag-selected="addTag($event)"></tag-input>
            <ul v-if="selectedTagsList.length">
                <li :class="'tag-' + i" v-for="(tag, i) in selectedTagsList">
                    {{ tag.name }}
                    <button @click.prevent="removeTag(tag.contentId)" v-if="!relatedContentId || relatedContentId != tag.contentId">X</button>
                </li>
            </ul>
        </div>

        <div class="field">
            <label class="label" for="note-file">Files</label>
            <div class="control">
                <input id="note-file" type="file" ref="filesRef" class="button is-primary" multiple>
            </div>
            <ul v-if="files && files.length">
                <li v-for="(file) in files">
                    {{ file.name }}
                    <button @click.prevent="toggleFileRemove(file.locationId)">
                        <span v-if="deletedFiles && deletedFiles.includes(file.locationId)">deleted</span>
                        <span v-else>X</span>
                    </button>
                </li>
            </ul>
        </div>

        <div class="field">
            <button class="button is-primary" type="submit" :disabled="editPending ? 'disabled' : undefined">Save Note</button>
            <preloader v-if="editPending"/>
            <div class="note-line__cancel__container">
                <a class="note-line__cancel" @click="$emit('cancel-edit-note')">Cancel</a>
            </div>
        </div>
        <div v-if="editError">{{ editError }}</div>
    </form>
    <!-- End Component: organisms/note-edit -->
</template>

<script>
    import { reactive, ref } from 'vue';
    import RequestHandler from "@/handler/RequestHandler";
    import { useStore } from "vuex";
    import { contactFullName } from "@/helpers/objects";
    import TagInput from "./tag-input";
    import Preloader from "./preloader";

    export default {
        name: 'note-edit',
        components: {Preloader, TagInput},
        props: [
            'date', 'title', 'text', 'isOdd', 'tags', 'locationId', 'editing', 'relatedContentId', 'files'
        ],
        emits: ['edited', 'cancel-edit-note',],
        setup(props, {emit}) {
            const store = useStore();
            const filesRef = ref(null);
            const selectedTagsList = ref(props.tags ? props.tags : []);
            const editPending = ref(false);
            const editError = ref(null);
            const deletedFiles = ref([]);

            const editParams = reactive({
                title: props.title,
                text: props.text,
            });

            const editNote = () => {
                editPending.value = true;
                RequestHandler.editNote({
                    title: editParams.title,
                    text: editParams.text,
                    relatedIds: selectedTagsList.value.map(item => item.contentId),
                    files: filesRef.value.files,
                    deletedFiles: deletedFiles.value,
                    locationId: props.locationId,
                }, store.getters.csrf).then((response) => {
                    //editParams.title = '';
                    //editParams.text = '';
                    //selectedTagsList.value = objectTag ? [objectTag] : [];

                    emit('edited', response);
                    editPending.value = false;
                    deletedFiles.value = [];
                }).catch((error) => {
                    console.error(error);
                    editError.value = error.message || error;
                    editPending.value = false;
                    deletedFiles.value = [];
                });
            };

            const toggleFileRemove = (locationId) => {
                const index = deletedFiles.value.indexOf(locationId);
                if (index !== -1) {
                    deletedFiles.value.splice(index, 1);
                } else {
                    deletedFiles.value.push(locationId);
                }
            };

            const addTag = (tag) => {
                for (let i = 0; i < selectedTagsList.value.length; i++) {
                    if (selectedTagsList.value[i].contentId === tag.contentId) {
                        return; // already listed
                    }
                }
                selectedTagsList.value.push({
                    name: tag.class === 'ContactRef'
                        ? (contactFullName(tag) + (tag.agency ? ' (' + tag.agency.name + ')' : ''))
                        : tag.name,
                    contentId: tag.contentId
                });
            };

            const removeTag = (contentId) => {
                selectedTagsList.value = selectedTagsList.value.filter(item => item.contentId !== contentId);
            }

            return {
                editNote, editParams, editError, filesRef, addTag, removeTag,
                selectedTagsList, editPending,
                deletedFiles, toggleFileRemove,
            };
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";

    @include block('note-create') {
        @include element('title') {
            font-size: 1.5rem;
            font-weight: 600;
            font-family: $header;
            margin-top: 15px
        }
    }
    button.is-primary {
        font-weight: 600;
        font-family: $header;
        margin-top: 15px;
    }

    @include block('note-line') {
        @include element('cancel') {
            color: black;
            text-decoration: underline;
            background-color: transparent;
            border: none;
            padding-top: 10px;
            cursor: pointer;
            @include element('container') {
                width: 100%;
                margin-top: 15px;
                text-align: right;
            }
        }
    }
</style>
