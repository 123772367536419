<template>
    <!-- Component: organisms/search-result-card--contract -->
    <searchResultCard type="contract" :contract="$props">

        <div class="section">
            <div class="content">

                <p class="card__info__label">Contract</p><br>

                <h1 class="card__info__title"><a :href="url">{{ titleize(name) }}</a></h1>

                <template v-if="vendor">
                    <p class="card__info__label card__info__label__with-tag">
                        <Tooltip :tooltipText="definitions && definitions['Vendor'] && definitions['Vendor'].definition ? definitions['Vendor'].definition : 'Loading...'">
                            <span class='has-tooltip'>
                                Vendor
                            </span>
                        </Tooltip>
                    </p>
                    <tag-vendor :tag-text="vendor.name" :tag-url="vendor.url"/>
                </template>

                <div class="columns">
                    <div class="column" v-if="agency">
                        <p class="card__info__label card__info__label__with-tag">Agency</p>
                        <tag-agency :tag-text="agency.name" :tag-url="agency.url"/>
                    </div>
                </div>

                <!--div class="columns">
                    <div class="column" v-if="contractType">
                        <p class="card__info__label">Contract Type</p>
                        <p class="card__info__data">{{ contractType }}</p>
                    </div>

                    <div class="column" v-if="serviceType">
                        <p class="card__info__label">Service Type</p>
                        <p class="card__info__data">{{ serviceType }}</p>
                    </div>
                </div>

                <div class="columns">
                    <div class="column" v-if="methodOfProcurement">
                        <p class="card__info__label">Method of Procurement</p>
                        <p class="card__info__data">{{ methodOfProcurement }}</p>
                    </div>
                </div>

                <div class="columns">
                    <div class="column" v-if="totalAmount">
                        <p class="card__info__label">Total Amount</p>
                        <p class="card__info__data">${{ priceFormat(totalAmount) }}</p>
                    </div>

                    <div class="column" v-if="orderDate">
                        <p class="card__info__label">Order Date</p>
                        <p class="card__info__data">{{ orderDate }}</p>
                    </div>
                </div>

                <div class="columns">
                    <div class="column" v-if="beginDate">
                        <p class="card__info__label">Begin Date</p>
                        <p class="card__info__data">{{ beginDate }}</p>
                    </div>

                    <div class="column" v-if="endDate">
                        <p class="card__info__label">End Date</p>
                        <p class="card__info__data">{{ endDate }}</p>
                    </div>

                    <div class="column" v-if="newEndDate">
                        <p class="card__info__label">New End Date</p>
                        <p class="card__info__data">{{ newEndDate }}</p>
                    </div>
                </div-->

            </div>

        </div>
    </searchResultCard>
    <!-- End Component: organisms/search-result-card--conotract -->
</template>

<script>
    import SearchResultCard from "./search-result-card";
    import { priceFormat } from "@/helpers/filter";
    import TagVendor from "./tag--vendor";
    import TagAgency from "./tag--agency";
    import { computed, ref } from "vue";
    import { useStore } from "vuex";
    import Tooltip from "./tooltip";

    export default {
        name: 'SearchResultCardContract',
        components: {
            SearchResultCard,
            TagVendor,
            TagAgency,
            Tooltip
        },
        props: {
            contentId: Number,
            locationId: Number,
            beginDate: String,
            contractType: String,
            endDate: String,
            factsId: String,
            flairContractId: String,
            grantId: String,
            methodOfProcurement: String,
            name: String,
            newEndDate: String,
            orderDate: String,
            poNumber: String,
            serviceType: String,
            totalAmount: Number,
            vendor: Object,
            agency: Object,
            url: String,
        },
        data() {
            return {
            }
        },
        setup(props) {
            const store = useStore();
            const definitions = computed(() => store.getters.definitions);

            return {
                priceFormat,
                defintions
            };
        },
        methods: {
            titleize: function(item) {
                return item.toLowerCase().replace(/(?:^|\s|-)\S/g, x => x.toUpperCase());
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/mixins/_bemify.scss";

    @include block('card') {
        .media {
            display: contents;
        }
        @include element('info') {
            @include element('label') {
                float: left;
                margin-right: 10px;
            }
        }
    }
</style>

