<template>
    <!-- Component: organisms/glossary -->
    <div class="glossary">

        <h2>GovSpeak</h2>

        <div class="search">
            <search-block
                :type="'filter'"
                :darkMode='true'
                :title="'Filter GovSpeak'"
                :placeholder="'Filter GovSpeak Terms'"
                v-on:search-keyup="keyup"
                customId="glossarySearch"
                :button="false"
            />
        </div>

        <preloader v-if="!definitions || definitions.length === 0"/>

        <definition
            v-for="(item, k) in filteredDefinitions"
            :key="'definition-' + k"
            :name="item[1].name"
            :definition="item[1].fullDefinition"
            :secondDefinition="item[1].secondDefinition"
            :darkMode='true'
            :rand="k"
            :class="item[1].class"
        />

    </div>
    <!-- End Component: organisms/glossary -->
</template>

<script>
    import RequestHandler from "@/handler/RequestHandler";
    import { useStore } from "vuex";
    import { computed } from "vue";
    import Preloader from "./preloader";
    import Definition from "./definition";
    import SearchBlock from "./search-block";

    export default {
        name: 'Glossary',
        props: [

        ],
        components: {
            Preloader,
            Definition,
            SearchBlock
        },
        data() {
            return {
                search: '',
            };
        },
        methods: {
            keyup(s) {
                this.search = s;
            },
        },
        computed: {
            filteredDefinitions() {
                if (!this.definitions) {
                    return [];
                }

                let defArray = Object.entries(this.definitions).filter(item => {
                    return (item[0].toLowerCase().includes(this.search.toLowerCase())) || (item[1].fullDefinition.toLowerCase().includes(this.search.toLowerCase()) || (item[1].secondDefinition.toLowerCase().includes(this.search.toLowerCase())));
                });

                // sort alphabetically
                if (defArray.length <= 0) {
                    let defArray = [
                        ["No results found for '" + this.search + "'", {
                            name: "No results found for '" + this.search + "'",
                            fullDefinition: "",
                            secondDefinition: "",
                            class: "no-results"
                        }]
                    ];
                    return defArray;
                } else {
                    return defArray.sort((a, b) => {
                        return a[0].localeCompare(b[0]);
                    });
                }
            },
            definitions: function () {
                return this.$store.getters.definitions;
            }
        },
        setup(props) {
            const store = useStore();
            const showSearch = computed(() => store.getters.showSearch);
            const definitions = computed(() => store.getters.definitions);

            return {
                showSearch,
                definitions
            };
        }
    }
</script>

<style lang='scss' scoped>
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";

    h2 {
        font-family: Fira Sans,sans-serif;
        font-weight: 600;
        font-size: 2rem;
        line-height: 1.2em;
        padding-bottom: 15px;
        margin-bottom: 30px;
        border-bottom: 1px solid $white;
        width: 100%;
    }

    @include block('glossary') {
        padding: 15px;
        background-color: $blue--dark;
        min-width: 450px;
        min-height: 80vh;
        max-width: 450px;
        width: 450px;
        overflow: scroll;
        -webkit-overflow-scrolling: touch;
        @media screen and (max-width: 500px) {
            min-width: 100%;
            min-height: 80vh;
            max-width: 100%;
            height: 100vh;
            position: fixed;
            top: 0px;
            left: 0px;
        }
        h2, p, #searchTitle, .glossary__item__definition, .glossary__item__title {
            color: $white !important;
        }
    }

    .browse-agencies-filter-wrapper {
        max-width: 50%;
        margin: auto;
        @media screen and (max-width: 768px) {
            width: 90%;
            max-width: 90%;
        }
        .search-block {
            margin-bottom: 30px;
        }
    }

    .search {
        margin-bottom: 30px;
    }
</style>
