<template>
    <!-- Component: page/program -->
    <div class="page-container">

        <br/>

        <breadcrumbs :items="path"/>

        <div class="container">
            <alert/>
        </div>

        <hr/>

        <div class="container" style="display: flow-root;" v-if="user">
            <userTools/>
        </div>

        <br/>

        <h1>{{ name }}</h1>

        <br>

        <div class="container">
            <div class="columns">
                <general-container
                    :customClass="'column'"
                    :isLocked="false"
                >
                    <preloader v-if="loading"></preloader>
                    <agency-budget-group
                        v-if="!loading"
                        v-for="budgetGroup in budgetsGrouped"
                        :budget-group="budgetGroup"
                        :agency-id="agency.contentId"
                        :view="'program_category'"
                    ></agency-budget-group>
                </general-container>
            </div>
        </div>

    </div>
    <!-- Component: page/program -->
</template>

<script>
    import Alert from "@/alivue/components/alert";
    import Breadcrumbs from "./breadcrumbs";
    import UserTools from "@/alivue/components/user-tools";
    import GeneralContainer from "./general-container"
    import InfoBlock from "./info-block"
    import SearchBlock from "./search-block";
    import { onMounted, ref, computed } from "vue";
    import { priceFormat } from "@/helpers/filter";
    import RequestHandler from "@/handler/RequestHandler";
    import Preloader from "@/alivue/components/preloader";
    import { useStore } from "vuex";
    import { loadAgencyBudgets } from "@/helpers/budgets";
    import AgencyBudgetGroup from "./agency-budget-group";

    export default {
        name: 'Program',
        props: [
            'contentId',
            'locationId',
            'name',
            'transparencyId',
            'agency',
        ],
        components: {
            Preloader,
            InfoBlock,
            GeneralContainer,
            Alert,
            Breadcrumbs,
            SearchBlock,
            UserTools,
            AgencyBudgetGroup,
        },
        methods: {
        },
        setup(props) {
            const store = useStore();
            const loading = ref(false);
            const budgetsGrouped = ref([]);

            loadAgencyBudgets(props.contentId, budgetsGrouped, loading);

            const showSearch = computed(() => store.getters.showSearch);

            return {
                loading,
                budgetsGrouped,
                path: [{
                    url: props.agency.url,
                    title: props.agency.name,
                    isActive: false,
                },
                {
                    url: '/browse-budgets?agencyId=' + props.agency.contentId,
                    title: 'Budget',
                    isActive: false,
                },
                {
                    url: '#',
                    title: props.name,
                    isActive: true,
                }],
                showSearch,
            };
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";


</style>

