<template>
    <!-- Page: pages/guidance -->

    <div class="container">
        <alert/>
    </div>

    <hr/>

    <div class="container">


        <!-- <gantt /> -->

        <h1 class="has-text-centered">How can we help?</h1>
        <p class="has-text-centered">Click a section below to find guidance on specific topics or scroll down to find tours of MyGovGuide features.</p>

        <div class="columns">

            <div class="column is-full-mobile is-one-third-tablet" @click="openModal('Executive')">
                <div class="big-button">
                    <img class="big-button__icon" src="@/assets/img/icons/executive.png" alt="Executive" />
                    <p class="big-button__text has-text-centered">Executive</p>
                </div>
            </div>
            <div class="column is-full-mobile is-one-third-tablet" @click="openModal('Budget')">
                <div class="big-button">
                    <img class="big-button__icon" src="@/assets/img/icons/budget.png" alt="Budget" />
                    <p class="big-button__text has-text-centered">Budgets</p>
                </div>
            </div>
            <div class="column is-full-mobile is-one-third-tablet" @click="openModal('FAQs')">
                <div class="big-button">
                    <img class="big-button__icon" src="@/assets/img/icons/chat.png" alt="FAQs" />
                    <p class="big-button__text has-text-centered">FAQs</p>
                </div>
            </div>

        </div>
    </div>

    <div class="container tour-container">
        <h2 class="has-text-centered">Available Tours</h2>
        <div class="columns jc-center">

            <div class="column is-full-mobile is-one-third-tablet">
                <a href="/dashboard/state-government/department-of-management-services?showProductTour=18205" class="media tour-trigger">
                    <div class="media-left">
                        <figure class="image is-48x48">
                            <font-awesome-layers>
                                <font-awesome-icon :icon="iconCircle" />
                            <font-awesome-icon :icon="iconBudget" aria-label="Budget Maximization"></font-awesome-icon>
                            </font-awesome-layers>
                        </figure>
                    </div>
                    <div class="media-content">
                        <p class="title is-4">Budget Maximization</p>
                        <p class="subtitle is-6">Now that you have all the money knowledge, let's use it!</p>
                    </div>
                </a>
            </div>

            <div class="column is-full-mobile is-one-third-tablet">
                <a href="/agency-contacts?showProductTour=17884" class="media tour-trigger">
                    <div class="media-left">
                        <figure class="image is-48x48">
                            <font-awesome-layers>
                                <font-awesome-icon :icon="iconCircle" />
                                <font-awesome-icon :icon="iconContacts" aria-label="Browse Contacts"></font-awesome-icon>
                            </font-awesome-layers>
                        </figure>
                    </div>
                    <div class="media-content">
                        <p class="title is-4">Browse Contacts</p>
                        <p class="subtitle is-6">Learn how to navigate the many names and faces of Florida Government.</p>
                    </div>
                </a>
            </div>

            <div class="column is-full-mobile is-one-third-tablet">
                <a href="/browse-agencies?showProductTour=17678" class="media tour-trigger">
                    <div class="media-left">
                        <figure class="image is-48x48">
                            <font-awesome-layers>
                                <font-awesome-icon :icon="iconCircle" />
                                <font-awesome-icon :icon="iconGuidance" aria-label="Browse Agencies"></font-awesome-icon>
                            </font-awesome-layers>
                        </figure>
                    </div>
                    <div class="media-content">
                        <p class="title is-4">Browse Agencies</p>
                        <p class="subtitle is-6">Find out at a glance what our Agency pages have to offer using predictive searching.</p>
                    </div>
                </a>
            </div>

            <div class="column is-full-mobile is-one-third-tablet">
                <a href="/dashboard/state-government/agency-for-health-care-administration?showProductTour=17800" class="media tour-trigger">
                    <div class="media-left">
                        <figure class="image is-48x48">
                            <font-awesome-layers>
                                <font-awesome-icon :icon="iconCircle" />
                                <font-awesome-icon :icon="iconTour" aria-label="Budget Maximization"></font-awesome-icon>
                            </font-awesome-layers>
                        </figure>
                    </div>
                    <div class="media-content">
                        <p class="title is-4">Browse Budgets</p>
                        <p class="subtitle is-6">View detailed breakdowns of budget and spending for the current fiscal year.</p>
                    </div>
                </a>
            </div>

            <div class="column is-full-mobile is-one-third-tablet">
                <a href="/dashboard?showProductTour=17600" class="media tour-trigger">
                    <div class="media-left">
                        <figure class="image is-48x48">
                            <font-awesome-layers>
                                <font-awesome-icon :icon="iconCircle" />
                                <font-awesome-icon :icon="iconCalculator" aria-label="Welcome"></font-awesome-icon>
                            </font-awesome-layers>
                        </figure>
                    </div>
                    <div class="media-content">
                        <p class="title is-4">Welcome</p>
                        <p class="subtitle is-6">Get an overview of MyGovGuide and what it can do for you.</p>
                    </div>
                </a>
            </div>

            <div class="column is-full-mobile is-one-third-tablet">
                <a href="/gov-guidance?showProductTour=17894" class="media tour-trigger">
                    <div class="media-left">
                        <figure class="image is-48x48">
                            <font-awesome-layers>
                                <font-awesome-icon :icon="iconCircle" />
                                <font-awesome-icon :icon="iconCompass" aria-label="GovGuidance"></font-awesome-icon>
                            </font-awesome-layers>
                        </figure>
                    </div>
                    <div class="media-content">
                        <p class="title is-4">GovGuidance</p>
                        <p class="subtitle is-6">Utilize all the information on this page and see how far it can take you.</p>
                    </div>
                </a>
            </div>

            <div class="column is-full-mobile is-one-third-tablet">
                <a href="/browse-procurements?showProductTour=25610" class="media tour-trigger">
                    <div class="media-left">
                        <figure class="image is-48x48">
                            <font-awesome-layers>
                                <font-awesome-icon :icon="iconCircle" />
                                <font-awesome-icon :icon="iconContract" aria-label="GovGuidance"></font-awesome-icon>
                            </font-awesome-layers>
                        </figure>
                    </div>
                    <div class="media-content">
                        <p class="title is-4">Browse Bids</p>
                        <p class="subtitle is-6">Search through state procurement contracts.</p>
                    </div>
                </a>
            </div>

            <div class="column is-full-mobile is-one-third-tablet">
                <a href="/browse-documents?showProductTour=32798" class="media tour-trigger">
                    <div class="media-left">
                        <figure class="image is-48x48">
                            <font-awesome-layers>
                                <font-awesome-icon :icon="iconCircle" />
                                <font-awesome-icon :icon="iconDocuments" aria-label="GovGuidance"></font-awesome-icon>
                            </font-awesome-layers>
                        </figure>
                    </div>
                    <div class="media-content">
                        <p class="title is-4">Browse Documents</p>
                        <p class="subtitle is-6">Search through all budget documents using key words or phrases.</p>
                    </div>
                </a>
            </div>
        </div>
    </div>

    <faqs
        v-if="!loadingGuidance"
        :items="guidanceTabs"
        @close="closeModals()"
    ></faqs>
    <!-- End Page: pages/guidance -->
</template>

<script>
    import Alert from "./alert";
    import Faqs from "./faqs";
    import RequestHandler from "@/handler/RequestHandler";
    import { useRouter } from "vue-router";
    import Preloader from "./preloader";
    import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome'
    import { faChartLine, faSearch, faUsers, faLocation, faCalculator, faBadge, faCompass, faFileContract, faFileInvoice } from '@fortawesome/pro-light-svg-icons'
    import { faCircle } from '@fortawesome/pro-solid-svg-icons'

    import Gantt from "./gantt";

    export default {
        name: 'Guidance',
        components: {
            Alert,
            Preloader,
            FontAwesomeIcon,
            FontAwesomeLayers,
            Faqs,
            Gantt
        },
        setup(props) {

            return {

            }
        },
        props: ['name', 'description'],
        data() {
            return {
                guidanceTabs: [],
                loadingGuidance: true,
                iconBudget: faChartLine,
                iconContacts: faUsers,
                iconGuidance: faSearch,
                iconTour: faLocation,
                iconCalculator: faCalculator,
                iconBadge: faBadge,
                iconCircle: faCircle,
                iconCompass: faCompass,
                iconContract: faFileContract,
                iconDocuments: faFileInvoice
            };
        },
        methods: {
            openModal: function(id) {
                document.getElementById(id + "-modal").classList.add('is-active');
                document.body.classList.add('position-fixed');
            },
            closeModals: function() {
                let modals = document.getElementsByClassName('modal');
                for (var i = 0; i < modals.length; i++) {
                    modals[i].classList.remove('is-active');
                }
                document.body.classList.remove('position-fixed');
            }
        },
        mounted() {
            const router = useRouter();
            RequestHandler.loadPageGuidance(router.currentRoute.value.fullPath).then(response => {
                this.guidanceTabs = response;
                this.loadingGuidance = false;
            });
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";
    @import "../../assets/scss/partials/_accordion-checkbox.scss";

    @include block('big-button') {
        border: 3px solid $blue--med;
        border-radius: 6px;
        max-height: 250px;
        padding-top: 25px;
        padding-bottom: 25px;
        background-color: $white;
        transition: all 0.3s ease-in-out;
        &:hover {
            cursor: pointer;
        }
        &:hover, &:focus, &:active {
            .big-button__icon {
                transform: scale(1.1);
            }
            .big-button__text {
                text-decoration: underline;
            }
        }
        @include element('icon') {
            max-width: 100px;
            margin: auto !important;
            display: block;
            text-align: center;
            padding-bottom: 10px;
            transition: all 0.3s ease-in-out;
        }
        @include element('text') {
            font-size: 28px;
            margin-bottom: 0px;
            transition: all 0.3s ease-in-out;
        }
    }

    .tour-container {
        margin-top:30px;
        padding: 15px;
        border: 3px solid $blue--med;
        border-radius: 6px;
        h2 {
            margin-bottom: 15px;
        }
    }

    figure.image {
        text-align: center;
        svg {
            color: $white;
            font-size: 28px;
            &.fa-circle {
                color: $blue--med;
                font-size: 50px;
                top: 12px;
                transition: all 0.3 ease-in-out;
            }
            &:not(.fa-circle) {
                position: relative;
                top: 17px;
            }
        }
    }

    .fa-layers {
        width: 50px;
        height: 50px;
    }

    .tour-trigger {
        text-decoration: none !important;
        .title, .subtitle {
            text-decoration: none !important;
            transition: all 0.3s ease-in-out;
        }
        &:hover, &:focus, &:active {
            .title {
                text-decoration: underline !important;
                color: $blue--med;
            }
            &.fa-circle {
                color: $blue--dark;
            }
        }
    }

    .jc-center {
        justify-content: center;
    }

    .media-content .title {
        color: $blue-ncs;
        text-decoration: underline;
        transition: all ease-in-out 0.5s;
        &:hover {
            color: $prussian-blue;
        }
    }
</style>