<template>
    <!-- Component: organisms/container-search -->
    <div class="container-search">
        <div class="search-trigger__container">
            <search-trigger @click="openSearchModal"></search-trigger>
        </div>
        <div id="container-search__container" class="search-container" v-if="showSearchModal">
            <div id="container-search-modal" class="modal container-search-notes-modal is-active">
                <div class="modal-background" @click="closeSearchModal"></div>

                <div class="modal-card">
                    <header class="modal-card-head">
                        <span class="modal-card-title">Search {{ name }}</span>
                        <button class="delete" aria-label="close" @click="closeSearchModal"></button>
                    </header>

                    <section class="modal-card-body">
                        <div class="modal-card-content">
                            <p v-if="message">
                                {{ message }}
                            </p>
                            <div class="field has-addons">
                                <div class="control container-search-input-wrapper">
                                    <input
                                        id="container-search-input"
                                        v-model="searchModelValue"
                                        v-on:focus="searchFocus"
                                        v-on:keyup="searchKeyup"
                                        v-on:keydown="searchKeydown"
                                        class="input search-block__search-input"
                                        type="text"
                                        :placeholder="'Search...'"
                                        aria-labelledby="searchTitle"
                                        autocomplete="off"
                                    >
                                </div>
                            </div>
                            <div
                                :key="searchAutocompleteRand"
                                id="container-search-autocomplete-results"
                                class="container-search-autocomplete-results"
                                ref="autocomplete-results"
                                v-if="showNoResultsSearchAutocomplete || (autocompleteResults && autocompleteResults.length)"
                            >
                                <div v-if="showNoResultsSearchAutocomplete && searchModelValue && searchModelValue.length && (!autocompleteResults || autocompleteResults.length === 0)">
                                    No results
                                </div>
                                <div class="search-label" v-for="(searchItem, i) in autocompleteResults" :key="'autocomplete-result-' + i">

                                    <span @click="itemSelectedCustom(searchItem)" :data-name="searchItem.name.replace(/[^A-Z0-9]/ig, '_')">
                                            {{ searchItem.name }}
                                    </span>

                                    <div @click="itemSelectedDefault(searchItem)" :data-name="searchItem.name.replace(/[^A-Z0-9]/ig, '_')" class="search-label-muted" v-if="searchItem.appropriationCategoryName && searchItem.appropriationCategoryName != searchItem.name">
                                        ({{ searchItem.appropriationCategoryName }})
                                    </div>

                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </div>
    <!-- Component: organisms/container-search -->
</template>

<script>

import SearchTrigger from "./search-trigger.vue";
import { scrollToTile, clickToTile } from "@/helpers/budgetTreeMap";
import { useEventsBus } from "@/helpers/eventBus";

export default {
    name: 'ContainerSearch',
    props: ['searchData', 'name', 'message', 'treeMap', 'treeData'],
    components: {
        SearchTrigger,
    },
    data() {
        return {
            searchModelValue: '',
            showSearchModal: false,
            searchAutocompleteRand: Math.random(),
            autocompleteResults: [],
            showNoResultsSearchAutocomplete: false,
        };
    },
    methods: {
        itemSelectedDefault(item) {
            this.closeSearchModal();
        },
        itemSelectedCustom(item) {
            this.closeSearchModal();
            this.emit('call-link-to-treemap', [item, this.treeData]);
        },
        searchFocus(event) {
        },
        searchKeydown() {
        },
        searchKeyup() {
            this.autoCompleteSearch();
        },
        openSearchModal() {
            this.searchAutocompleteRand = Math.random();
            this.showSearchModal = true;
            this.$nextTick(() => {
                if (document.getElementById('container-search-input')) {
                    document.getElementById('container-search-input').focus();
                }
            });
        },
        closeSearchModal() {
            this.showSearchModal = false;
        },
        clickSearch() {
            let element = document.getElementById('container-search__container');
            if (element.classList.contains('is-active')) {
                element.classList.remove('is-active');
            } else {
                element.classList.add('is-active');
            }
        },
        autoCompleteSearch() {
            this.$nextTick(() => {
                if (this.searchModelValue === this.lastAutocompleteSearchQ) {
                    //return;
                }

                this.lastAutocompleteSearchQ = this.searchModelValue;


                if (this.searchModelValue.length < 1) {
                    this.autocompleteResults = [];
                    this.searchAutocompleteRand = Math.random();
                    this.showNoResultsSearchAutocomplete = false;
                    return;
                }

                this.showNoResultsSearchAutocomplete = false;

                let newAutocompleteResults = [];

                for (let i = 0; i < this.searchData.length; i++) {
                    for (let key in this.searchData[i]) {

                        if (!this.searchData[i].hasOwnProperty(key)) {
                            continue;
                        }
                        let obj=  this.searchData[i]

                        if (obj['name'] && !this.message.includes(obj['name']) && obj['name'].toLowerCase().includes(this.searchModelValue.toLowerCase()) &&
                            obj['name'] !== 'Legislative Branch' && obj['name'] !== 'Administered Funds' ||
                            (obj['acronym'] && obj['acronym'].toLowerCase().includes(this.searchModelValue.toLowerCase()))) {
                            if (newAutocompleteResults.indexOf(obj) === -1){
                                newAutocompleteResults.push(obj);
                            }
                        }
                    }
                }
                if (newAutocompleteResults.length === 0) {
                    this.showNoResultsSearchAutocomplete = true;
                }

                this.autocompleteResults = newAutocompleteResults;
                this.searchAutocompleteRand = Math.random();

            });
        },
    },
    setup(props) {

        const {emit}=useEventsBus();

        return {
            emit,
        }
    },
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/_variables.scss";

.container-search-input-wrapper {
    width: 100%;
}

.search-trigger {
    background-color: $blue--dark;
    border-radius: 30px;
    margin-right: 30px;
    position: absolute;
    right: 0;
    color: $white;
    font-size: 21px;
}

#container-search-autocomplete-results {
    max-height: 200px;
    overflow-y: scroll;
    border: 1px solid gray;
    border-radius: 10px;
    padding: 10px;
    > div {
        margin-bottom: 10px;
    }
}

.modal-card-head {
    background-color: $blue--dark;
    color: $white;
}
.modal-card-title {
    color: $white;
}
.modal-card-body {
    background-color: $blue--lighter;
}
.modal-background {
    background-color: rgba(0,0,0,.7);
}

.container-search-autocomplete-results {
    background-color: $white;
}

.search-label > span {
    color: #3374aa;
    text-decoration: underline;
    transition: all .3s ease-in-out;
    cursor: pointer;
}

.search-label-muted {
    color: $grey--dark;
    display: inline;
}

button.delete {
    background-color: $white;
    &:before, &:after {
        background-color: $blue--dark;
    }
}
</style>

