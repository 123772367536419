<template>
    <!-- Page: pages/not-found -->
    <div class="container has-text-centered error-text__container">
        <h1>😧<br />Oh no! 404 Error!</h1>
        <p class="error-text">We're not sure that page even exists!</p>
        <p class="error-text">Perhaps you could try <a href="/search">searching</a> for what you need.<br />Or maybe you'd rather go back to your <a href="/dashboard">dashboard</a>.</p>
    </div>
    <!-- End Page: pages/not-found -->
</template>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";

    .glitch {
        font-size: 100px;
        position: relative;
        width: 670px;
        margin: 0 auto;
        min-height: 40vh;
    }

    @keyframes noise-anim {
        $steps: 20;
        @for $i from 0 through $steps {
            #{percentage($i*(math.div(1, $steps)))} {
                clip: rect(random(100)+px, 9999px, random(100)+px, 0);
            }
        }
    }

    .glitch:after {
        content: attr(data-text);
        position: absolute;
        left: 2px;
        text-shadow: -1px 0 red;
        top: 0;
        overflow: hidden;
        clip: rect(0, 900px, 0, 0);
        animation: noise-anim 2s infinite linear alternate-reverse;
    }

    @keyframes noise-anim-2 {
        $steps: 20;
        @for $i from 0 through $steps {
            #{percentage($i*(math.div(1, $steps)))} {
                clip: rect(random(100)+px, 9999px, random(100)+px, 0);
            }
        }
    }

    .glitch:before {
        content: attr(data-text);
        position: absolute;
        left: -2px;
        text-shadow: 1px 0 blue;
        top: 0;
        overflow: hidden;
        clip: rect(0, 900px, 0, 0);
        animation: noise-anim-2 3s infinite linear alternate-reverse;
    }

    .error-text {
        margin-bottom: 10px;
        font-size: 1.5em;
        line-height: 1.6em;
    }
    .error-text__container {
        margin-bottom: 100px;
        max-width: 640px !important;
        margin-top: 60px;
    }
</style>

<script>export default {name:"NotFound",props:[]}</script>