<template>
    <!-- Component: molecules/info-block--news -->
    <infoBlock :hasIcon="false" :hasClose="false" :isTweet="false" :color="color">
        <a :href="url" target="_blank" class="info-block__title" v-html="title"></a>
        <br v-if="agency">
        <tagAgency
            v-if="agency"
            :tagText="agency ? agency.name : ''"
            :tagUrl="agency ? agency.url : ''"
        />
        <p class="info-block__date" v-if="date">{{ formatDate(date) }}</p>
        <p class="info-block__publication" v-if="publication">{{ publication }}</p>
        <p class="info-block__desc" v-if="desc" v-html="desc"></p>
    </infoBlock>
    <!-- End Component: molecules/info-block--news -->
</template>

<script>
    import InfoBlock from "./info-block"
    import TagAgency from "./tag--agency";

    export default {
        name: 'InfoBlockTravel',
        components: {
            InfoBlock,
            TagAgency,
        },
        props: ['title', 'publication', 'desc', 'url', 'date', 'agency',],
        methods: {
            formatDate(date) {
                // don't try to convert already converted dates;
                if (typeof date === 'string' && date.indexOf('/') !== -1) {
                    return date;
                }
                let d = date.date;
                let dParts = d.split(' ');
                d = dParts[0];
                let dateParts = d.split('-'); 
                let month = dateParts[1];
                let day = dateParts[2];
                let year = dateParts[0];
                return month + '/' + day + '/' + year;
            },
        },
        data() {
            return {
                color: "blue--med"
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";

    .content p:not(:last-child), .content ul:not(:last-child) {
        margin-bottom: 0px !important;
    }

    .tag__agency.gov-tag {
        margin-top: 10px;
    }

    @include block('info-block') {
        @include element('title') {
            color: $blue--dark;
            font-size: 18px;
            font-weight: 600;
            margin-bottom: 0px;
        }
        @include element('publication') {
            color: #777171;
            font-weight: bold;
            margin-bottom: 0px !important;

        }
        @include element('desc') {
            margin-bottom: 0px !important;
        }
        @include element('date') {
            color: $grey--dark;
            font-weight: 400;
        }

    }
</style>
