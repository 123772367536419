<template>
    <div v-if="isTreemapDetailVisible" class="columns no-flex__container">

        <container-search :search-data="getNames([categoriesTree])"
                          :tree-data="categoriesTree"
                          :name="'Spending Details'"
                          :message="'NOTE: This search returns budget details within ' + name + '.'"
        >
        </container-search>

        <div class="column">

    <general-container
        :customClass="'column'"
        :title="'Spending Details'"
        :titleClass="'has-text-centered'"
        :isLocked="false"
        :titleTooltip="true"
        :ttText="'How the money was spent within the budget details/categories above in the current fiscal year.'"
    >
      <div class="view-type-container">
        <div class="toggle">

          <span class="toggle__title">Switch View:</span>

          <span id="view-toggle--table--three"
                v-on:click="toggleViewSpending()" type="checkbox"
                :checked="budgetDetailsGraphView"
                class="view-toggle"
          >
                        <img src="@/assets/svg/table--inactive.svg"
                             alt="Show Graph View"
                             v-if="!budgetDetailsGraphView"
                             class="mr-10"
                        />
                        <img src="@/assets/svg/table--active.svg"
                             alt="Show Table View"
                             v-else
                             class="mr-10"
                        />
                    </span>

          <span id="view-toggle--card--three"
                v-on:click="toggleViewSpending()" type="checkbox"
                :checked="budgetDetailsGraphView"
                class="view-toggle"
          >
                        <img src="@/assets/svg/card--active.svg"
                             alt="Show Graph View"
                             v-if="!budgetDetailsGraphView"
                        />
                        <img src="@/assets/svg/card--inactive.svg"
                             alt="Show Table View"
                             v-else
                        />
                    </span>

        </div>
      </div>

        <div class="treemap-wrapper-container columns container">
            <div class="column is-full"  v-if="categoriesTreeLoading">
                <preloader/>
            </div>

            <template v-if="spendingGraphview">
                <div class="treemap-wrapper-container columns container">
                    <div class="column is-one-quarter">
                        <div class="legend" ref="budgetCategoriesLegendEl">
                            <ul id="treemap-legend-items" class="steps is-vertical is-dashed legend-items">
                                <li class="legend-item-template steps-segment" style="display: none;">
                                    <span href="#" class="steps-marker"></span>
                                    <div class="steps-content">
                                        <div class="legend-item-type">
                                            Type
                                        </div>
                                        <div class="legend-item-title">
                                            Title
                                        </div>
                                        <div class="legend-item-amount">
                                            Amount
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="treemap-wrapper column">
                        <div ref="budgetCategoriesChartEl" class="treemap-container"></div>
                    </div>
                </div>
            </template>
            <template v-if="!spendingGraphview">
                <div>
                    <h3>
                        Table
                    </h3>
                </div>
            </template>
        </div>
    </general-container>
        </div>
    </div>
</template>

<script>

import {onMounted, ref, watch} from "vue";
import GeneralContainer from "./general-container.vue";
import RequestHandler from "@/handler/RequestHandler";
import {  renderBudgetCategoriesDetailsTreemap } from "@/helpers/charts";
import Preloader from "./preloader.vue";
import ContainerSearch from "./container-search.vue";
import { scrollToTile, clickToTile } from "@/helpers/budgetTreeMap";

export default{
    name: 'SpendingDetailsBlock',
    props: [
        'locationId', 'name', 'dontShowTreemapSearch'
    ],
    data() {
        return {
            treeMap: null,
        };
    },
    components: {ContainerSearch, Preloader, GeneralContainer},
    methods: {
        getTreemap() {
            return this.treeMap;
        },
        getNames(obj, savedAppropriationCategoryName) {
            if (!obj) return [];

            return obj.map(el => {
                if (!el) {
                    return;
                }

                if (
                    typeof savedAppropriationCategoryName === "undefined" &&
                    el.contentType === "Appropriation Category"
                ) {
                    savedAppropriationCategoryName = el.name;
                }

                const names = [{
                    'name' : el.name,
                    'locationId' : el.locationId,
                    'appropriationCategoryName' : savedAppropriationCategoryName,
                }];

                if (el.children) {
                    names.push(this.getNames(el.children, savedAppropriationCategoryName));
                }

                return names;
            }).flat(Infinity);

        },
    },
    setup(props) {
        const isTreemapDetailVisible = ref(true);
        const spendingGraphview = ref(true)
        const budgetCategoriesChartEl = ref(null);
        const categoriesTreeLoading = ref(true);
        const categoriesTree = ref(null);
        const budgetCategoriesLegendEl = ref(null);
        const selectedVendor = ref(null);
        const treeMap = ref(null);
        const dontShowTreemapSearch = ref();

        function treemapCallback(callbackResult) {
            props.dontShowTreemapSearch();
        }

        function toggleViewSpending  () {
            spendingGraphview.value = !spendingGraphview.value
        }

        function loadCategoriesTree() {
            categoriesTreeLoading.value = true;

            // Load the categories tree data
            RequestHandler.loadCategoriesTree(props.locationId)
                .then(response => {
                    categoriesTree.value = response;
                    categoriesTreeLoading.value = false;

                    renderBudgetCategoriesDetailsTreemap(
                        budgetCategoriesChartEl.value,
                        categoriesTree.value,
                        budgetCategoriesLegendEl.value,
                        selectedVendor,
                        treemapCallback
                    ).then((resolvedValue) => {
                        // Use the ref value directly to set treemap
                        treeMap.value = resolvedValue.controller;
                    });
                })
                .catch(e => {
                    console.log(e);
                    categoriesTreeLoading.value = false;
                });
        }

        watch(spendingGraphview, () => loadCategoriesTree() )

        onMounted(() => {
            loadCategoriesTree()
        });

        return {
            isTreemapDetailVisible,
            spendingGraphview,
            toggleViewSpending,
            budgetCategoriesChartEl,
            categoriesTree,
            categoriesTreeLoading,
            budgetCategoriesLegendEl,
            selectedVendor,
            clickToTile,
            treeMap,
            dontShowTreemapSearch,
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/_variables.scss";
@import "../../assets/scss/mixins/_bemify.scss";
@import "../../assets/scss/helpers/responsive-tables";

.view-type-container-spending {
    margin-bottom: 30px;
    text-align: right;
    .view-toggle img {
        max-width: 24px;
    }
    .toggle__title {
        margin-right: 10px;
    }
}


@media screen and (max-width: 768px) {
    .treemap-wrapper-container > .column{
        width: 100% !important;
        .treemap-wrapper {
            padding: 15px;
        }
    }
}
.treemap-icon, .treemap-icon--stop {
    transform: scale(.05) translate(200px, 200px);
}
.treemap-icon--stop {
    display: none;
}
.treemap-container svg g g:last-of-type .treemap-icon,
.hide-text ~ .treemap-icon {
    display: none;
}
.treemap-container svg g g:not([cursor="pointer"]) {
    .treemap-icon {
        display: none;
    }
    .treemap-icon--stop {
        display: block;
    }
}
.treemap-container svg g g:last-of-type .treemap-icon--stop {
    display: none !important;
}
// Bulma Steps overrides
.steps .legend-item {
    cursor: pointer;
    &:hover {
        opacity: 0.6;
    }
}
.steps .steps-marker {
    height: 1.5rem !important;
    width: 1.5rem !important;
    top: -10px !important;
    border: 3px solid $blue--lighter !important;
}
.steps.is-vertical .steps-segment:not(:last-child):after {
    left: calc(1rem - 0.35em) !important;
    top: 0.76rem !important;
}
.steps:not(.is-hollow) .steps-marker:not(.is-hollow),
.steps:not(.is-hollow) .steps-segment.is-active .steps-marker:not(.is-hollow),
.steps-segment:after {
    background-color: $blue--med !important;
}
.steps.is-vertical .steps-segment:not(:last-child):after {
    width: 0.2em !important;
}
.steps.is-vertical .steps-content {
    margin-left: 1rem !important;
    margin-bottom: 1.5em !important;
}
.steps.is-vertical.is-dashed .steps-segment:after, .steps.is-vertical .steps-segment.is-dashed:after {
    background: repeating-linear-gradient(0deg, $blue--light, $blue--light 5px, transparent 0, transparent 10px) !important;
}
.toggle {
  margin-left: 15px;
}
.view-type-container {
  margin-bottom: 30px;
  text-align: right;
}
.view-toggle img {
  max-width: 24px;
}
.toggle__title {
  margin-right: 10px;
}
</style>
