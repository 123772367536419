import { createRouter } from 'vue-router'
import FratomicRoutes from "../alivue/routes"
import { scrollBehavior } from "@/helpers/scroll";

let routes = FratomicRoutes;

// push your own routes here (if developed outside of alivue)
// routes.push(...[
//     {
//         path: '/about',
//         name: 'About',
//         // route level code-splitting
//         // this generates a separate chunk (about.[hash].js) for this route
//         // which is lazy-loaded when the route is visited.
//         component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
//     }
// ])

export function createConfiguredRouter(history) {
    return createRouter({
        history: history,
        routes,
        scrollBehavior
    })
}
