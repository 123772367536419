<template>
    <!-- Component: atoms/contact-link -->

    <button class="contact-link">
        <a  target="_blank" class="phone-link" v-if="icon === 'phone'" @click="copy(url)">
            <img src="@/assets/svg/social-media/phone.svg" :alt="icon + ' icon'" :class="icon + '-icon icon'"/>
            <div class="slot">
                <slot></slot>
            </div>
        </a>

        <a :href="url" target="_blank" class="icon-link" v-else>
            <img src="@/assets/svg/social-media/facebook.svg" :alt="icon + ' icon'" v-if="icon === 'facebook'" :class="icon + '-icon icon'"/>
            <img src="@/assets/svg/social-media/twitter.svg" :alt="icon + ' icon'" v-else-if="icon === 'twitter'" :class="icon + '-icon icon'"/>
            <img src="@/assets/svg/social-media/linkedin.svg" :alt="icon + ' icon'" v-if="icon === 'linkedin'" :class="icon + '-icon icon'"/>
            <img src="@/assets/svg/social-media/email.svg" :alt="icon + ' icon'" v-else-if="icon === 'email'" :class="icon + '-icon icon'"/>
            <img src="@/assets/svg/social-media/youtube.svg" :alt="icon + ' icon'" v-else-if="icon === 'youtube'" :class="icon + '-icon icon'"/>

            <div class="slot">
                <slot></slot>
            </div>
        </a>

    </button>
    <!-- End Component: atoms/contact-link -->
</template>

<script>
    export default {
        name: 'ContactLink',
        props: ['icon', 'url'],
        methods: {
            callNumber: function(number) {
                window.open(number, '_blank');
            },
            async copy(s) {
                await navigator.clipboard.writeText(s);
                alert('Phone number copied!');
            }
        },
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";

    .contact-link {
        border: none;
        background-color: transparent;

        img {
            margin-right: 5px;
        }
        a {
            display: block;
            img, .slot {
                display: inline-block;
                float: left;
            }
            .slot {
                position: relative;
                top: 4px;
                font-size: 14px;
                text-decoration: underline;
                font-family: $body;
                @media screen and (max-width: 768px) {
                    word-break: break-all; // emails in mobile
                    text-align: left;
                }
            }
            .icon {
                @media screen and (max-width: 768px) {
                    display: none;
                }
            }
        }
    }
</style>
