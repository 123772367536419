<template>
    <!-- contract-flyout -->
    <div id="snackbar-vendor-contract">
        <div class="snackbar-container">
            <div @click="hideFlyout()" class="close-item"></div>
            <div class="page-container vendor-page">
                <div class="container">
                    <contract-info-card v-bind="$props"/>
                </div>
            </div>
        </div>
    </div>
    <!-- End contract-flyout -->
</template>

<script>
    import ContractInfoCard from "./contract-info-card";
    import useUser from "@/helpers/useUser";
    import { computed } from "vue";
    import { useStore } from "vuex";
    import RequestHandler from "@/handler/RequestHandler";

    export default {
        components: {
            ContractInfoCard,
        },
        props: {
            contentId: Number,
            locationId: Number,
            beginDate: String,
            contractType: String,
            endDate: String,
            factsId: String,
            flairContractId: String,
            grantId: String,
            methodOfProcurement: String,
            name: String,
            newEndDate: String,
            orderDate: String,
            poNumber: String,
            serviceType: String,
            totalAmount: Number,
            vendor: Object,
            agency: Object,
            organizationAllotments: Array,
            programs: Array,
            budgetEntity: String,
            factsDetailsUrl: String,
        },
        methods: {
            openFlyout(contractLocationId) {
                return new Promise((resolve, reject) => {
                RequestHandler.loadContract(contractLocationId)
                    .then((data) => {
                        if (data && data.ContractRef) {
                            resolve(data.ContractRef); // Resolve the Promise with the data
                        } else {
                            reject("Contract data not found");
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error);
                    });
                });
            },
            hideFlyout() {
                var x = document.getElementById("snackbar-vendor-contract");
                x.classList.remove('fadein');
                x.classList.add('fadeout');
                setTimeout(function(){
                    x.classList.remove('show');
                    x.classList.remove('fadeout');
                }, 500);
            },
        },
        setup(props) {
            const { user } = useUser();

            const store = useStore();
            const showSearch = computed(() => store.getters.showSearch);

            return {
                user,
                path: [{ title: props.name }],
                showSearch,
            }
        }
    }
</script>

<style lang="scss" scoped>

    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";

    // based on https://www.w3schools.com/howto/howto_js_snackbar.asp
    /* The snackbar - position it at the bottom and in the middle of the screen */
    #snackbar-vendor-contract {
        visibility: hidden; /* Hidden by default. Visible on click */
        min-width: 250px; /* Set a default minimum width */
        margin-left: -125px; /* Divide value of min-width by 2 */
        padding: 0px;
        position: fixed; /* Sit on top of the screen */
        z-index: 99999; /* Add a z-index if needed */
        right: 15px;
        bottom: 50px;
        max-height: 90vh;
        overflow-y: scroll;
        background-color: $blue--lighter !important;
        color: $blue--dark !important;
        box-shadow: $box-shadow !important;
        border-radius: 8px;
        max-width: 95vw !important;
    }

    /* Show the snackbar when clicking on a button (class added with JavaScript) */

    #snackbar-vendor-contract {
        visibility: hidden;
        opacity: 0;

        &.show {
            visibility: visible;
            opacity: 1;
            -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
            animation: fadein 0.5s, fadeout 0.5s 2.5s;

            &.fadein {
                -webkit-animation: fadein 0.5s;
                animation: fadein 0.5s;
            }

            &.fadeout {
                -webkit-animation: fadeout 0.5s;
                animation: fadeout 0.5s;
            }
        }

        /* Animations to fade the snackbar in and out */
        @-webkit-keyframes fadein {
            from {
                bottom: 0;
                opacity: 0;
            }
            to {
                bottom: 30px;
                opacity: 1;
            }
        }
        @keyframes fadein {
            from {
                bottom: 0;
                opacity: 0;
            }
            to {
                bottom: 30px;
                opacity: 1;
            }
        }
        @-webkit-keyframes fadeout {
            from {
                bottom: 30px;
                opacity: 1;
            }
            to {
                bottom: 0;
                opacity: 0;
            }
        }
        @keyframes fadeout {
            from {
                bottom: 30px;
                opacity: 1;
            }
            to {
                bottom: 0;
                opacity: 0;
            }
        }

        .columns {
            margin-top: 0rem;
        }

        .column {
            padding: 0em 0.75rem;
        }

        .close-item {
            position: absolute;
            right: 10px;

            &:before {
                font-size: 30px;
                cursor: pointer;
                content: "×";
                color: $blue--dark;
                transition: color .3s;
                font-weight: 600;
                margin-left: 10px;
                position: relative;
                float: right;
                top: -5px;
                left: -5px;
            }
        }

        .filter-container--tags {
            padding: 0 15px;
        }

        .filter-container--main {
            display: inline-block;
            width: 100%;

            .filter-input {
                display: inline-block;

                input {
                    height: 60px;
                    margin-left: 15px;
                }
            }

            .filter-info {
                display: inline-block;
                float: right;
                margin-top: 15px;
            }
        }

        .button-filter.button.btn {
            &:focus, &:hover {
                color: $white;
            }
        }

        .filter-container--tags {
            padding: 0 15px;
        }
    }

</style>
