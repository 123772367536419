function toTitleCase(str) {
    let exclusions = [];
    if (arguments.length > 1) {
        exclusions = Array.from(arguments).slice(1);
    }
    let s = str.replace(
        /\w\S*/g,
        function (txt) {
            // if we also passed an exclusion to the function, ignore those strings
            if (exclusions.includes(txt.toLowerCase())) {
                return txt;
            }
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    );

    let replacements = {
        'eog': 'EOG',
        'Eog': 'EOG',
        'dbpr': 'DBPR',
        'Dbpr': 'DBPR',
        'ses': 'SES',
        'Ses': 'SES',
        'ii': 'II',
        'Ii': 'II',
        'dms': 'DMS',
        'Dms': 'DMS',
        'Of': 'of',
        'Dep': 'DEP',
        'fwc': 'FWC',
        'Fwc': 'FWC',
        'And': 'and',
        'The': 'the',
        'ii': 'II',
        'Ii': 'II',
        'iii': 'III',
        'Iii': 'III',
        'dla': 'DLA',
        'Dla': 'DLA',
        'djj': 'DJJ',
        'Djj': 'DJJ',
        'ahca': 'AHCA',
        'Ahca': 'AHCA',
        'it': 'IT',
        'It': 'IT',
        'dxc': 'DXC',
        'Dxc': 'DXC',
        'llc': 'LLC',
        'Llc': 'LLC',
        'supv': 'Supervisor',
        'Supv': 'Supervisor',
        'mgmt': 'Management',
        'Mgmt': 'Management',
        'investgator': 'Investigator',
        'Investgator': 'Investigator',
        'enforce': 'Enforcement',
        'Enforce': 'Enforcement',
        'mrg': 'Manager',
        'Mrg': 'Manager',
        'supt': 'Support',
        'Supt': 'Support',
        'highwy': 'Highway',
        'Highwy': 'Highway',
        'prog': 'Program',
        'Prog': 'Program',
        'dir': 'Director',
        'Dir': 'Director',
        'asst': 'Assistant',
        'Asst': 'Assistant',
        'pgm': 'Program',
        'Pgm': 'Program',
        'Deo': 'DEO',
        'deo': 'DEO',
        'Hr': 'HR',
        'hr': 'HR',
        'Iv': 'IV',
        'iv': 'IV',
        'Hlth': 'Health',
        'hlth': 'Health',
        'Departmnt': 'Department',
        'departmnt': 'Department',
        'Desantis': 'DeSantis',
        'desantis': 'DeSantis',
        'Mygovguide': 'MyGovGuide',
        'mygovguide': 'MyGovGuide',
        'Govguidance': 'GovGuidance',
        'Comm/flwac': 'Comm/FLWAC',
        'Ofr/oir': 'OFR/OIR',
        'Dc': 'DC',
        'Dacs': 'DACS',
        "Apd": "APD",
        "Fpc": "FPC",
        "Dcf": "DCF",
        "Dc": "DC",
        "Doe": "DOE",
        "Doea": "DOEA",
        "Dfs": "DFS",
        "Hsmv": "HSMV",
        "Fdle": "FDLE",
        "Dma": "DMA",
        "Dos": "DOS",
        "Dot": "DOT",
        "Counsel-Dot": "Counsel - DOT",
        "COUNSEL-DOT": "Counsel - DOT",
        "Admin-Dot": "Admin - DOT",
        "ADMIN-DOT": "Admin - DOT",
        "General-Dot": "General - DOT",
        "GENERAL-DOT": "General - DOT",
        "Engineer-Dot" : "Engineer - DOT",
        "ENGINEER-DOT": "Engineer - DOT",
        "Dva": "DVA",
        "Em": "EM",
        "Osca": "OSCA",
        "Anderson-Mclean": "Anderson-McLean",
        "Arnwine-Mcgowan": "Arnwine-McGowan",
        "Castellina-Mcclain": "Castellina-McClain",
        "Collins-O'kane": "Collins-O'Kane",
        "Cruz-Mcnair": "Cruz-McNair",
        "Drake-Mckinney": "Drake-McKinney",
        "Macdonald": "MacDonald",
        "Macarthur": "MacArthur",
        "Mackinnon": "MacKinnon",
        "Mackay": "MacKay",
        "Maclafferty": "MacLafferty",
        "Macphee": "MacPhee",
        "Mcalister": "McAlister",
        "Mcallister": "McAllister",
        "Mcalpin": "McAlpin",
        "Mcanally": "McAnally",
        "Mcarthur": "McArthur",
        "Mcauliffe": "McAuliffe",
        "Mcavoy": "McAvoy",
        "Mcbeath": "McBeath",
        "Mcbeth": "McBeth",
        "Mcbrayer": "McBrayer",
        "Mcbride": "McBride",
        "Mcburrow": "McBurrow",
        "Mccabe": "McCabe",
        "Mccaffrey": "McCaffrey",
        "Mccall": "McCall",
        "Mccall": "McCall",
        "Mccallum": "McCallum",
        "Mccammon": "McCammon",
        "Mccane": "McCane",
        "Mccann": "McCann",
        "Mccants": "McCants",
        "Mccardle": "McCardle",
        "Mccardle": "McCardle",
        "Mccarroll": "McCarroll",
        "Mccarron": "McCarron",
        "Mccarter": "McCarter",
        "Mccarthy": "McCarthy",
        "Mccartney": "McCartney",
        "Mccarty": "McCarty",
        "Mccarville": "McCarville",
        "Mccaskill": "McCaskill",
        "Mccasland": "McCasland",
        "Mccaulley": "McCaulley",
        "Mccawley": "McClawley",
        "Mcclain": "McClain",
        "Mcclain-Hatcher": "McClain-Hatcher",
        "Mcclam": "McClam",
        "Mcclellan": "McClellan",
        "Mcclelland": "McClelland",
        "Mcclelland": "McClelland",
        "Mcclendon": "McClendon",
        "Mccleod": "McLeod",
        "Mcclintic": "McClintic",
        "Mcclintock": "McClintock",
        "Mcclinton": "McClinton",
        "Mccloud": "McCloud",
        "Mcclougheny": "McClougheny",
        "Mccluney": "McCluney",
        "Mcclung": "McClung",
        "Mcclure": "McClure",
        "Mcclurg": "McClurg",
        "Mcclurkin": "McClurkin",
        "Mccluskey": "McCluskey",
        "Mccluster": "McCluster",
        "Mccolgin": "McColgin",
        "Mccollin": "McCollin",
        "Mccollister": "McCollister",
        "Mccollough": "McCollough",
        "Mccollum": "McCollum",
        "Mccomas": "McComas",
        "Mccomas": "McComas",
        "Mcconaghey": "McConaghey",
        "Mcconaghy": "McConaghy",
        "Mcconn": "McConn",
        "Mcconnell": "McConnell",
        "Mcconnell-Bailey": "McConnell-Bailey",
        "Mcconner": "McConner",
        "Mcconville": "McConville",
        "Mccool": "McCool",
        "Mccord": "McCord",
        "Mccorkle": "McCorkle",
        "Mccormack": "McCormack",
        "Mccormick": "McCormick",
        "Mccorquodale": "McCorquodale",
        "Mccorvey": "McCorvey",
        "Mccorvey-Reddick": "McCorvey-Reddick",
        "Mccourt": "McCourt",
        "Mccoy": "McCoy",
        "Mccracken": "McCracken",
        "Mccranie": "McCranie",
        "Mccranie": "McCranie",
        "Mccrary": "McCrary",
        "Mccravy": "McCravy",
        "Mccraw": "McCraw",
        "Mccray": "McCray",
        "Mccrea": "McCrea",
        "Mccree": "McCree",
        "Mccroskey": "McCroskey",
        "Mccrudden": "McCrudden",
        "Mccuddy": "McCuddy",
        "Mccue": "McCue",
        "Mccullers": "McCullers",
        "Mccullough": "McCullough",
        "Mccune": "McCune",
        "Mccurdy": "McCurdy",
        "Mccurry": "McCurry",
        "Mcdade": "McDade",
        "Mcdaniel": "McDaniel",
        "Mcdaris": "McDaris",
        "Mcdermott": "McDermott",
        "Mcdevitt": "McDevitt",
        "Mcdole": "McDole",
        "Mcdonald": "McDonald",
        "Mcdonough": "McDonough",
        "Mcdougald": "McDougald",
        "Mcdougall": "McDougall",
        "Mcdougle": "McDougle",
        "Mcdow": "McDow",
        "Mcdowall": "McDowall",
        "Mcdowell": "McDowell",
        "Mcdowell": "McDowell",
        "Mcduffie": "McDuffie",
        "Mcduffie": "McDuffie",
        "Mceachron": "McEachron",
        "Mceachron": "McEachron",
        "Mcelheney": "McElheney",
        "Mcelroy": "McElroy",
        "Mcelvin": "McElvin",
        "Mcentyre": "McEntyre",
        "Mcfadden": "McFadden",
        "Mcfall": "McFall",
        "Mcfall": "McFall",
        "Mcfarland": "McFarland",
        "Mcfarlane": "McFarlane",
        "Mcfee": "McFee",
        "Mcgaha": "McGaha",
        "Mcgee": "McGee",
        "Mcgee-Aviano": "McGee-Aviano",
        "Mcgehee": "McGehee",
        "Mcghee": "McGhee",
        "Mcghie": "McGhie",
        "Mcghin": "McGhin",
        "Mcgighan": "McGighan",
        "Mcgill": "McGill",
        "Mcgillen": "McGillen",
        "Mcgillin": "McGillin",
        "Mcgillivray": "McGillivray",
        "Mcgilvray": "McGilvray",
        "Mcginley": "McGinley",
        "Mcginn": "McGinn",
        "Mcginnis": "McGinnis",
        "Mcgivern": "McGivern",
        "Mcglamory": "McGlamory",
        "Mcglamory-Evans": "McGlamory-Evans",
        "Mcglashan": "McGlashan",
        "Mcglohon": "McGlohon",
        "Mcglohon": "McGlohon",
        "Mcglone": "McGlone",
        "Mcglynn": "McGlynn",
        "Mcglynn": "McGlynn",
        "Mcgoogan": "McGoogan",
        "Mcgough": "McGough",
        "Mcgovern": "McGovern",
        "Mcgowan": "McGowan",
        "Mcgowen": "McGowen",
        "Mcgrady": "McGrady",
        "Mcgrane": "McGrane",
        "Mcgrapth": "McGrapth",
        "Mcgrath": "McGrath",
        "Mcgraw": "McGraw",
        "Mcgrede": "McGrede",
        "Mcgregor": "McGregor",
        "Mcgriff": "McGriff",
        "Mcgruder": "McGruder",
        "Mcguckin": "McGuckin",
        "Mcguffey": "McGuffey",
        "Mcguigan": "McGuigan",
        "Mcguigan": "McGuigan",
        "Mcguire": "McGuire",
        "Mchargue": "McHargue",
        "Mchenry": "McHenry",
        "Mchugh": "McHugh",
        "Mchugh": "McHugh",
        "Mcilroy": "McIlroy",
        "Mcinerney": "McInerney",
        "Mcinnis": "McInnis",
        "Mcintosh": "McIntosh",
        "Mcintyre": "McIntyre",
        "Mcivor": "McIvor",
        "Mckay": "McKay",
        "Mckayla": "McKayla",
        "Mckean": "McKean",
        "Mckee": "McKee",
        "Mckeeman": "McKeeman",
        "Mckellips": "McKellips",
        "Mckenna": "McKenna",
        "Mckenny": "Mckenny",
        "Mckenzie": "McKenzie",
        "Mckenzy": "McKenzy",
        "Mckeough": "McKeough",
        "Mckeown": "McKeown",
        "Mckibben": "McKibben",
        "Mckinley": "McKinley",
        "Mckinney": "McKinney",
        "Mckinnie": "McKinnie",
        "Mckinnon": "McKinnon",
        "Mckinson": "McKinison",
        "Mckinstry": "McKinstry",
        "Mckirdy": "McKirdy",
        "Mckissock": "McKissock",
        "Mcknight": "McKnight",
        "Mckoy": "McKoy",
        "Mckyton": "McKyton",
        "Mclain": "McLain",
        "Mclane": "McLane",
        "Mclaren": "McLaren",
        "Mclaughlin": "McLaughlin",
        "Mclean": "McLean",
        "Mclean": "McLean",
        "Mclellan": "McLellan",
        "Mclellan": "McLellan",
        "Mclemore": "McLemore",
        "Mclendon": "McLendon",
        "Mclin": "McLin",
        "Mclin": "McLin",
        "Mclochlin": "Mclochlin",
        "Mcmahan": "McMahan",
        "Mcmahon": "McMahon",
        "Mcmahon": "McMahon",
        "Mcmains": "McMains",
        "Mcmanus": "McManus",
        "Mcmeekan": "McMeekan",
        "Mcmenemy": "McMenemy",
        "Mcmillan-Rollins": "McMillan-Rollins",
        "Mcmillen": "McMillen",
        "Mcmillian": "McMillian",
        "Mcmillion": "McMillion",
        "Mcmillon": "McMillon",
        "Mcminn": "McMinn",
        "Mcmullen": "McMullen",
        "Mcnabb": "McNabb",
        "Mcnair": "McNair",
        "Mcnally": "McNally",
        "Mcnamara": "McNamara",
        "Mcneal": "McNeal",
        "Mcnease": "McNease",
        "Mcneil": "McNeil",
        "Mcneill": "McNeill",
        "Mcneilly": "McNeilly",
        "Mcnelis": "McNelis",
        "Mcnemar": "McNemar",
        "Mcnichol": "McNichol",
        "Mcnicholas": "McNicholas",
        "Mcnickles": "McNickles",
        "Mcnish": "McNish",
        "Mcnulty": "McNulty",
        "Mcpartlan": "McPartlan",
        "Mcphail": "McPhail",
        "Mcphee": "McPhee",
        "Mcpherson": "McPherson",
        "Mcpherson": "McPherson",
        "Mcpherson": "McPherson",
        "Mcpheters": "McPheters",
        "Mcquagge": "McQuagge",
        "Mcquaide": "McQuaide",
        "Mcquaig": "McQuaig",
        "Mcquarrie": "McQuarrie",
        "Mcqueen": "McQueen",
        "Mcrae": "McRae",
        "Mcrory": "McRory",
        "Mcspadden": "McSpadden",
        "Mcswain": "McSwain",
        "Mctaggart": "McTaggart",
        "Mcteer": "McTeer",
        "Mcvaney": "McVaney",
        "Mcvay": "McVay",
        "Mcveigh": "McVeigh",
        "Mcwade": "McWade",
        "Mcwaters": "McWaters",
        "Mcwhorter": "McWhorter",
        "Mcwilliams": "McWilliams",
        "Morris-Mcmillan": "Morris-McMillan",
        "O'bar": "O'Bar",
        "O'berry": "O'Berry",
        "O'boyle": "O'Boyle",
        "O'brady": "O'Brady",
        "O'brian": "O'Brian",
        "O'brien": "O'Brien",
        "O'bryan": "O'Bryan",
        "O'cain": "O'Cain",
        "O'connell": "O'Connell",
        "O'connor": "O'Connor",
        "O'dell": "O'Dell",
        "O'donnell": "O'Donnell",
        "O'halloran": "O'Halloran",
        "O'hara": "O'Hara",
        "O'hearn": "O'Hearn",
        "O'horo": "O'Horo",
        "o'kane": "O'Kane",
        "O'keefe": "O'Keefe",
        "O'keeffe": "O'Keeffe",
        "O'leary": "O'Leary",
        "O'malley": "O'Malley",
        "O'mara": "O'Mara",
        "O'neal": "O'Neal",
        "O'neil": "O'Neil",
        "O'reilly": "O'Reilly",
        "O'risky": "O'Risky",
        "O'toole": "O'Toole",
        "O'rourke": "O'Rourke",
        "O'shea": "O'Shea",
        "O'steen": "O'Steen",
        "O'sullivan-Velez": "O'Sullivan-Velez",
        "Park-O'hara": "Park-O'Hara",
        "Pierre-Mcnealy": "Pierre-McNealy",
        "Seiler-Mcclellan": "Seiler-McClellan",
        "Surratt-Mcintosh": "Surratt-McIntosh",
        "Wood-Mcgrath": "Wood-McGrath",
    };

    for (const k in replacements) {
        // ignore the item if it is passed as an exclusion
        if (exclusions.includes(k)) {
            continue;
        }
        if (!replacements.hasOwnProperty(k)) {
            continue;
        }
        let pattern = '\\b' + k + '\\b';
        let regex = new RegExp(pattern, 'g');
        s = s.replace(
            regex,
            replacements[k]
        );
    }

    s = s.replace(/([\s-])\S/g, function (match) {
        return match.toUpperCase();
    });

    s = s.replace(/-Dacs/g, " - DACS");
    s = s.replace(/-Dos/g, " - DOS");
    s = s.replace(/-Dms/g, " - DMS");
    s = s.replace(/-Dot/g, " - DOT");

    return s;
}

function capitalize(text) {
    if (!text) {
        return text;
    }

    let s = text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();

    if (s.indexOf('Dr. ') === 0) {
        let parts = s.split('Dr. ');
        s = 'Dr. ' + capitalize(parts[1]);
    }

    if (s.indexOf(' jr') === s.length - 3) {
        let parts = s.split(' jr');
        s = parts[0] + ' Jr';
    }

    s = s.replace('Desantis', 'DeSantis');

    return s;
}

function humanFileSize(size) {
    if (!size) {
        return '0B';
    }

    const i = Math.floor(Math.log(size) / Math.log(1024));

    return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
}

function priceFormat(amount, noCents = true) {
    if (!amount) {
        return '0';
    }

    return amount.toFixed(noCents ? 0 : 2)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
        .replace('.00', '');
}

function reformatDateString(dateString) {
    if (!dateString) {
        return dateString;
    }
    const dateParts = dateString.split('-');

    return dateParts[1] + '-' + dateParts[2] + '-' + dateParts[0];
}

function idFormat(str) {
    str.trim();
    return str.replace(/\s+/g, '-').toLowerCase();
}


export { capitalize, priceFormat, humanFileSize, toTitleCase, reformatDateString, idFormat };
