<template>
    <!-- Component: organisms/org-chart -->
    <div>
        <div class="search-trigger__container">
            <search-trigger @click="openSearchModal"></search-trigger>
        </div>
        <div id="org-chart-search-container" class="search-container" v-if="showSearchModal">
            <div id="org-chart-search-modal" class="modal org-chart-search-notes-modal is-active">
                <div class="modal-background" @click="closeSearchModal"></div>

                <div class="modal-card">
                    <header class="modal-card-head">
                        <span class="modal-card-title">Search Organizational Chart</span>
                        <button class="delete" aria-label="close" @click="closeSearchModal"></button>
                    </header>

                    <section class="modal-card-body">
                        <div class="modal-card-content">
                            <p>
                                NOTE: This search returns agencies, contacts, and positions within this State of Florida agency org chart, and not all of state government.
                            </p>
                            <div class="field has-addons">
                                <div class="control org-chart-search-input-wrapper">
                                    <input
                                        id="org-chart-search-input"
                                        v-model="searchModelValue"
                                        v-on:focus="searchFocus"
                                        v-on:keyup="searchKeyup"
                                        v-on:keydown="searchKeydown"
                                        class="input search-block__search-input"
                                        type="text"
                                        :placeholder="'Search...'"
                                        aria-labelledby="searchTitle"
                                        autocomplete="off"
                                    >
                                </div>
                            </div>

                            <div
                                :key="searchAutocompleteRand"
                                id="org-chart-search-autocomplete-results"
                                class="org-chart-search-autocomplete-results"
                                ref="autocomplete-results"
                                v-if="showNoResultsSearchAutocomplete || (autocompleteResults && autocompleteResults.length)"
                            >
                                <div v-if="showNoResultsSearchAutocomplete && searchModelValue && searchModelValue.length && (!autocompleteResults || autocompleteResults.length === 0)">
                                    No results
                                </div>
                                <div v-for="(searchItem, i) in autocompleteResults" :key="'autocomplete-result-' + i">
                                    <p class="card__info__label" v-if="searchItem.positionName">{{ searchItem.positionName }}</p>
                                    <a href="#" @click="(e) => { selectSearchItem(e, searchItem); }">
                                        <span>
                                            {{ searchItem.name ? toTitleCase(searchItem.name) : '' }}
                                        </span>
                                    </a>
                                </div>
                            </div>

                            <p class="org-chart-search-helper" v-if="agencyLocationId">
                                Can't find who you're looking for?
                                <a :href="'/agency-contacts?agencyId=' + agencyLocationId">
                                    Search the entire agency
                                </a>
                            </p>
                            <p class="org-chart-search-helper" v-else>
                                Can't find who you're looking for?
                                <a :href="'/search?q=' + searchModelValue">
                                    Search the state contact list
                                </a>
                            </p>
                        </div>
                    </section>
                </div>

            </div>
        </div>
        <div id="org-chart-head"></div>
        <template v-if="flattenedTree">
            <div v-for="(level, levelI) in flattenedTree" :data-level="levelI" :key="'level-i' + levelI" :style="{'display': levelI <= highestVisibleLevelIndex ? 'block' : 'none'}" class="chart-level-container">
                <div v-for="(group, groupI) in level" :data-group="groupI" :key="'group-' + levelI + '-' + groupI" :style="{'display': (levelI == 0 || (groupI == visibleItemIndices[levelI])) ? 'block' : 'none'}">
                    <div v-for="(chunkedSubitems, chunkI) in chunk(group, chunkSize)" :key="'chunk-' + levelI + '-' + groupI + '-' + chunkI">
                        <div class="columns">
                            <div :data-locationid="subItem.locationId" v-for="(subItem, subItemI) in chunkedSubitems" :key="'item-' + levelI + '-' + groupI + '-' + chunkI + '-' + subItemI" :data-item="subItemI" :style="{'margin': 'auto', 'display': (levelI < highestVisibleLevelIndex || (levelI == highestVisibleLevelIndex && groupI == visibleItemIndices[levelI])) ? 'block' : 'none'}">
                                <div :id="'org-chart-item-wrapper-' + subItem.locationId" :class="'column ' + (highestVisibleLevelIndex > levelI ? (visibleGroupIndices[levelI+1] == subItem.locationId) ? 'highlighted' : 'unhighlighted' : '')">
                                    <org-chart-item
                                        :name="subItem.name"
                                        :nameObject="subItem.nameObject"
                                        :title="subItem.positionName"
                                        :displayTitle="subItem.positionTitle"
                                        :downlineNumber="subItem.downlineNumber"
                                        @clickdownline="updateData"
                                        :level="levelI"
                                        :group="groupI"
                                        :index="subItemI + (chunkSize * chunkI)"
                                        :nameHref="subItem.href"
                                        :imageUrl="subItem.imageUrl"
                                        :imageWidth="subItem.imageWidth"
                                        :imageHeight="subItem.imageHeight"
                                        :boundingBox="subItem.boundingBox"
                                        :vacant="subItem.vacant"
                                        :highlightItem="subItem.locationId == contactLocationId"
                                        :isStateOrgChart="isStateOrgChart"
                                        :type="subItem.type"
                                        :locationId="subItem.locationId"
                                        :subtitleHref="subItem.subtitleHref"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-for="(chunkedSubitems, chunkI) in chunk(group, chunkSize)" :key="'chunk-divider-' + levelI + '-' + groupI + '-' + chunkI">
                        <div v-for="(subItem, subItemI) in chunkedSubitems" :key="'item-divider-' + levelI + '-' + groupI + '-' + chunkI + '-' + subItemI" :style="{'display': (visibleItemIndices[levelI+1] == (subItem.locationId) && highestVisibleLevelIndex > levelI) ? 'block' : 'none'}">
                            <div class="divider__container" :id="'divider-' + subItem.locationId">
                                <span class="divider">
                                    <img src="@/assets/svg/bracket-center.svg" alt="decorative image" class="divider__bracket">
                                </span>
                                <span class="divider__name">{{ subItem.name }}</span>
                                <span class="divider__collapse" v-on:click="collapse(levelI)">Collapse <img src="@/assets/svg/caret--grey.svg" alt="Show More" class="divider__collapse__caret"></span>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </template>
    </div>
    <!-- Component: organisms/org-chart -->
</template>

<script>
    import OrgChartItem from "./org-chart-item";
    import SearchTrigger from "./search-trigger.vue";
    import { toTitleCase } from "@/helpers/filter";

    export default {
        name: 'OrganizationChart',
        components: {
            OrgChartItem,
            SearchTrigger,
        },
        props: ['tree', 'contactLocationId', 'isStateOrgChart', 'agencyId', 'agencyLocationId',],
        data() {
            return {
                chunkSize: 4,
                flattenedTree: [],
                highestVisibleLevelIndex: 0,

                visibleGroupIndices: [0],
                visibleItemIndices: [0],

                searchModelValue: '',
                showSearchModal: false,
                typingTimer: null,
                doneTypingInterval: 200,
                lastAutocompleteSearchQ: '',
                searchAutocompleteRand: Math.random(),
                autocompleteResults: [],
                showNoResultsSearchAutocomplete: false,
            };
        },
        mounted() {
            // this is where all the magic happens, we transform a recursive
            // object into a flattened, grouped array that lets us
            // render the html in levels, how we want it
            this.initFlattenedTree();

            if (this.$props.contactLocationId) {
                // find the contact whose profile we're on now,
                // and open up their parent levels
                this.findAndOpenByLocationId(this.$props.contactLocationId);
            }
            else if (this.$props.tree && this.$props.tree && this.$props.tree.children && this.$props.tree.children.length) {
                // we're on an agency, so show 2 levels by default
                this.findAndOpenByLocationId(this.$props.tree.children[0].locationId);
            }
        },
        setup(props) {
            return {
                toTitleCase,
            };
        },
        methods: {
            selectSearchItem(e, searchItem) {
                e.preventDefault();
                e.stopPropagation();
                if (searchItem && searchItem.locationId) {
                    this.collapse(0);
                    this.closeSearchModal();
                    this.findAndOpenByLocationId(searchItem.locationId, true);
                }
            },
            searchFocus(event) {
            },
            searchKeydown() {
            },
            searchKeyup() {
                this.autocompleteSearch();
            },
            openSearchModal() {
                this.searchAutocompleteRand = Math.random();
                this.showSearchModal = true;
                this.$nextTick(() => {
                    if (document.getElementById('org-chart-search-input')) {
                        document.getElementById('org-chart-search-input').focus();
                    }
                });
            },
            closeSearchModal() {
                this.showSearchModal = false;
            },
            clickSearch() {
                let element = document.getElementById('org-chart-search-container');
                if (element.classList.contains('is-active')) {
                    element.classList.remove('is-active');
                } else {
                    element.classList.add('is-active');
                }
            },
            autocompleteSearch() {
                this.$nextTick(() => {
                    if (this.searchModelValue === this.lastAutocompleteSearchQ) {
                        //return;
                    }

                    this.lastAutocompleteSearchQ = this.searchModelValue;

                    if (this.searchModelValue.length < 1) {
                        this.autocompleteResults = [];
                        this.searchAutocompleteRand = Math.random();
                        this.showNoResultsSearchAutocomplete = false;
                        return;
                    }

                    this.showNoResultsSearchAutocomplete = false;

                    let newAutocompleteResults = [];
                    for (let i = 0; i < this.flattenedTree.length; i++) {
                        for (let key in this.flattenedTree[i]) {
                            if (!this.flattenedTree[i].hasOwnProperty(key)) {
                                continue;
                            }
                            for (let j = 0; j < this.flattenedTree[i][key].length; j++) {
                                let obj = this.flattenedTree[i][key][j];
                                if (
                                    (obj.name && obj.name.toLowerCase().includes(this.searchModelValue.toLowerCase())) ||
                                    (obj.positionName && obj.positionName.toLowerCase().includes(this.searchModelValue.toLowerCase())) ||
                                    (obj.acronym && obj.acronym.toLowerCase().includes(this.searchModelValue.toLowerCase()))
                                ) {
                                    newAutocompleteResults.push(obj);
                                }
                            }
                        }
                    }

                    if (newAutocompleteResults.length === 0) {
                        this.showNoResultsSearchAutocomplete = true;
                    }

                    this.autocompleteResults = newAutocompleteResults;
                    this.searchAutocompleteRand = Math.random();

                });
            },
            initFlattenedTree() {
                this.initFlattenedTreeInner([this.$props.tree], 0, 0);
            },
            initFlattenedTreeInner(tree, level, group) {
                if (!(level in this.flattenedTree)) {
                    this.flattenedTree[level] = {};
                }

                if (!(group in this.flattenedTree[level])) {
                    this.flattenedTree[level][group] = [];
                }

                for (let i = 0; i < tree.length; i++) {
                    this.flattenedTree[level][group].push({
                        name: tree[i].name,
                        positionName: tree[i].positionName ? tree[i].positionName : tree[i].subtitle ? tree[i].subtitle : tree[i].subtitleRelationName ? tree[i].subtitleRelationName : '',
                        positionTitle: tree[i].positionTitle,
                        subtitleHref: tree[i].subtitleHref ? tree[i].subtitleHref : '',
                        href: tree[i].href,
                        downlineNumber: tree[i].children ? tree[i].children.length : 0,
                        vacant: tree[i].vacant,
                        imageUrl: tree[i].imageUrl,
                        imageWidth: tree[i].imageWidth,
                        imageHeight: tree[i].imageHeight,
                        boundingBox: tree[i].boundingBox,
                        nameObject: tree[i].nameObject,
                        locationId: tree[i].locationId,
                        type: tree[i].type,
                        acronym: tree[i].acronym,
                    });
                }

                for (let i = 0; i < tree.length; i++) {
                    if (tree[i].children && tree[i].children.length) {
                        this.initFlattenedTreeInner(tree[i].children, level + 1, tree[i].locationId);
                    }
                }
            },
            collapse(level) {
                for (let i = this.highestVisibleLevelIndex; i > level; i--) {
                    this.visibleGroupIndices.pop();
                    this.visibleItemIndices.pop();
                }

                this.highestVisibleLevelIndex = level;

                let id = 'org-chart-head';
                if (level - 1 >= 0) {
                    id = 'divider-' + this.visibleItemIndices[level];
                }
                this.$nextTick(() => {
                    if (document.getElementById(id)) {
                        this.doScrolling(
                            this.findPos(document.getElementById(id)) - 100,
                            300
                        );
                    }
                });
            },
            doScrolling(elementY, duration) {
                var startingY = window.pageYOffset;
                var diff = elementY - startingY;
                var start;

                // Bootstrap our animation - it will get called right before next frame shall be rendered.
                window.requestAnimationFrame(function step(timestamp) {
                    if (!start) start = timestamp;
                    // Elapsed milliseconds since start of scrolling.
                    var time = timestamp - start;
                    // Get percent of completion in range [0, 1].
                    var percent = Math.min(time / duration, 1);

                    window.scrollTo(0, startingY + diff * percent);

                    // Proceed with animation as long as we wanted it to.
                    if (time < duration) {
                        window.requestAnimationFrame(step);
                    }
                });
            },
            findPos(obj) {
                var curtop = 0;
                if (obj.offsetParent) {
                    do {
                        curtop += obj.offsetTop;
                    } while (obj = obj.offsetParent);
                    return [curtop];
                }
            },
            updateData(level, group, locationId, index) {

                for (let i = this.highestVisibleLevelIndex; i > level; i--) {
                    this.visibleGroupIndices.pop();
                    this.visibleItemIndices.pop();
                }
                this.highestVisibleLevelIndex = level + 1;

                this.visibleGroupIndices.push(locationId);
                this.visibleItemIndices.push(locationId);

                this.$nextTick(() => {
                   this.doScrolling(
                       this.findPos(document.getElementById('divider-' + locationId)) - 100,
                       300
                    );
                });
                /* havent gotten this right yet
                setTimeout(function(){
                    // get the labels in the dividers
                    let dividerNames = document.querySelectorAll('.divider__name');
                    // get the names of all the orch chart entries
                    let itemNames = document.querySelectorAll('.org-chart__item__name__link');

                    // for each divider name
                    for (var i = 0; i < dividerNames.length; i++) {
                        // get the name and make lowercase
                        let text = dividerNames[i].innerText.toLowerCase();
                        // for each org chart entry name
                        for (var j = 0; j < itemNames.length; j++) {
                            // get the name and make lowercase
                            let nameOf = itemNames[j].innerText.toLowerCase();
                            // if the name of the org chart entry matches the name of the divider
                            if (text === nameOf) {
                                // go remove the class
                                itemNames[j].parentNode.parentNode.parentNode.classList.remove('unhighlighted');
                            }
                        }
                    }
                }, 300);
                */
            },
            chunk: (array, chunkLength) => {
                let chunks = [];
                var i,j, temporary, chunk = chunkLength;
                if (!Array.isArray(array) || !array) {
                    return chunks;
                }
                for (i = 0, j = array.length; i < j; i += chunk) {
                    temporary = array.slice(i, i + chunk);
                    chunks.push(temporary);
                }
                return chunks;
            },
            findAndOpenByLocationId(locationId, tempHighlightAndScroll) {
                // this is an obj so we can pass by reference easier (?)
                let pathObj = { path: [] };
                this.findByLocationIdInner([this.$props.tree], locationId, pathObj);
                for (let i = 0; i < pathObj.path.length - 1; i++) {
                    this.visibleGroupIndices.push(pathObj.path[i]);
                    this.visibleItemIndices.push(pathObj.path[i]);
                    this.highestVisibleLevelIndex = this.highestVisibleLevelIndex + 1;
                }

                if (tempHighlightAndScroll) {
                    this.$nextTick(() => {
                        let id = 'org-chart-item-wrapper-' + locationId;
                        if (document.getElementById(id)) {
                            this.doScrolling(
                                this.findPos(document.getElementById(id)) - 100,
                                300
                            );
                            document.getElementById(id).querySelector('.org-chart__item__container').classList.add('item-highlighted');
                            setTimeout(() => {
                                if (document.getElementById(id)) {
                                    document.getElementById(id).querySelector('.org-chart__item__container').classList.remove('item-highlighted');
                                }
                            }, 3000);
                        }
                    });
                }
            },
            findByLocationIdInner(tree, locationId, pathObj) {
                for (let i = 0; i < tree.length; i++) {
                    if (tree[i].locationId && tree[i].locationId === locationId) {
                        pathObj.path.unshift(tree[i].locationId);
                        return i;
                    }

                    if (tree[i].children && tree[i].children.length) {
                        if (this.findByLocationIdInner(tree[i].children, locationId, pathObj) !== false) {
                            pathObj.path.unshift(tree[i].locationId);
                            return i;
                        }
                    }
                }
                return false;
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";

    .org-chart-search-helper {
        margin-top: 10px;
    }

    .org-chart-search-input-wrapper {
        width: 100%;
    }

    .search-trigger {
        background-color: $blue--dark;
        border-radius: 30px;
        margin-right: 30px;
        position: absolute;
        right: 0;
        color: $white;
        font-size: 21px;
    }

    #org-chart-search-autocomplete-results {
        max-height: 200px;
        overflow-y: scroll;
        border: 1px solid gray;
        border-radius: 10px;
        padding: 10px;
        > div {
            margin-bottom: 10px;
        }
    }

    .chart-level-container {
        margin-top: 10px;
        margin-left: 15px;
        margin-right: 15px;
        .column {
            padding-top: 0px;
        }
    }

    @include block('divider') {
        height: 10px;
        width: 100%;
        border-radius: 10px 10px 0 0;
        border: 2px solid $blue--dark;
        border-bottom: none;
        display: block;
        margin-top: 30px;
        @include element('container') {
            display: flow-root;
        }
        @include element('bracket') {
            position: relative;
            left: calc(50% - 20px);
            top: -17px;
        }
        @include element('name') {
            font-size: 14px;
            color: $grey--dark;
            float: left;
            margin-left: 15px;
            margin-top: 5px;
            text-transform: uppercase;
        }
        @include element('collapse') {
            font-size: 14px;
            float: right;
            margin-right: 5px;
            color: $grey--dark;
            margin-top: 5px;
            cursor: pointer;
            @include element('caret') {
                transition: rotate(180deg);
                margin-right: 15px;
            }
        }
    }

    .highlighted {
        opacity: 1.0;
    }
    .unhighlighted {
        opacity: 0.5;
    }

    .modal-card-head {
        background-color: $blue--dark;
        color: $white;
    }
    .modal-card-title {
        color: $white;
    }
    .modal-card-body {
        background-color: $blue--lighter;
    }
    .modal-background {
        background-color: rgba(0,0,0,.7);
    }
    .org-chart-search-autocomplete-results {
        background-color: $white;
    }
    button.delete {
        background-color: $white;
        &:before, &:after {
            background-color: $blue--dark;
        }
    }
</style>
