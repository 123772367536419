<template>
    <!-- Component: organisms/search-result-card==category -->
    <searchResultCard type="program-category" :programcategory="$props">

        <div class="section">
            <div class="content">

                <p class="card__info__label">Program Category</p><br>

                <h1 class="card__info__title" ><a :href="url">{{ titleize(name) }}</a></h1>

                <div class="columns" v-if="commonName">
                    <div class="column is-half">
                        <p class="card__info__label">Common Name</p>
                        <p>
                            {{ commonName }}
                        </p>
                    </div>
                </div>

                <div class="columns" v-if="agency">
                    <div class="column mb20">
                        <p class="card__info__label">Agency</p>
                        <tagAgency :tagText="agency.name" :tagUrl="agency.url"/>
                    </div>
                </div>

            </div>

        </div>
    </searchResultCard>
    <!-- End Component: organisms/search-result-card==category -->
</template>

<script>
    import SearchResultCard from "./search-result-card";
    import TagAgency from "./tag--agency";
    import TagContact from "./tag--contact";
    import LeadershipMarker from "./leadership-marker";

    export default {
        name: 'SearchResultCardProgramCategory',
        components: {SearchResultCard, TagAgency, TagContact, LeadershipMarker},
        props: [
            'url',
            'name',
            'contentId',
            'locationId',
            'commonName',
            'agency',
        ],
        data() {
            return {
            }
        },
        methods: {
            titleize: function(item) {
                return item.toLowerCase().replace(/(?:^|\s|-)\S/g, x => x.toUpperCase());
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/mixins/_bemify.scss";

    @include block('card') {
        .media {
            display: contents;
        }
    }
</style>

