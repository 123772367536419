<template>
    <!-- Component: organisms/interested-in-block -->

    <!-- if there are position changes render that box -->
    <div class="columns pb-30" id="interested-in-block-container" v-if="withContainer && updates.length > 0">
        <div class="column">

            <general-container :customClass="customClass" :title="title">

                <template
                    v-if="updates && updates.length"
                    v-for="item in updates"
                    :key="item.contentId"
                >
                    <component v-bind="item"
                               v-bind:is="getComponent(item)"/>
                </template>

                <preloader v-if="loading"></preloader>
                <p v-if="!loading && updates.length == 0" id="no-entries">Check back later for more relevant updates.</p>

                <read-more-link :contents="readMoreOverride ? readMoreOverride : readMore"
                                :url="'/relevant-updates?' + (type ? 'type=' + type : '') + (subtree ? '&subtree=' + subtree : '')"
                ></read-more-link>

            </general-container>

        </div>
    </div>

    <!-- Otherwise just use this block by itself (these are otherwise identical) -->
    <general-container :customClass="customClass" :title="title" v-else-if="updates.length > 0">

        <template
            v-if="updates && updates.length"
            v-for="item in updates"
            :key="item.contentId"
        >
            <component v-bind="item" v-bind:is="getComponent(item)"/>
        </template>

        <preloader v-if="loading"></preloader>
        <p v-if="!loading && updates.length == 0" id="no-entries">Check back later for more relevant updates.</p>

        <read-more-link :contents="readMoreOverride ? readMoreOverride : readMore"
                        :url="'/relevant-updates?' + (type ? 'type=' + type : '') + (subtree ? '&subtree=' + subtree : '')"
        ></read-more-link>

    </general-container>

    <!-- in the scenario there are no position changes on file -->
    <div class="general-container" v-else>
        <p class="general-container__title">{{ title }}</p>
        <div class="columns" id="interested-in-block-container">
            <div class="column">
                <general-container :customClass="'general-container--is-locked'">
                    <div v-if="type === 'budget_update'" class="no-data">
                        <img src="@/assets/svg/icon-bar-chart.svg" alt="No data icon" class="no-data__icon"/>
                        <p class=" no-data__title">This budget has not increased or decreased more than 5% recently.</p>
                    </div>
                    <div v-else class="no-data">
                        <p class=" no-data__icon no-data__icon--interested">🤷‍♀️</p>
                        <p class=" no-data__title">Hmmm...</p>
                        <p>Couldn't find any recent position changes.</p>
                    </div>
                </general-container>
            </div>
        </div>
    </div>
    <!-- End Component: organisms/interested-in-block -->
</template>

<script>
    import GeneralContainer from "./general-container";
    import InfoBlock from "./info-block";
    import InfoBlockStacked from "./info-block--stacked";
    import InfoBlockStackedBudgetUpdate from "./info-block--stacked-budget-update";
    import TagAgency from "./tag--agency";
    import TagRole from "./tag--role";
    import ReadMoreLink from "./read-more-link";
    import RequestHandler from "@/handler/RequestHandler";
    import Preloader from "./preloader";
    import InfoBlockBudget from "./info-block--budget";
    import InfoBlockTwitter from "./info-block--twitter";
    import InfoBlockEmployment from "./info-block--employment";
    import {onMounted, onUnmounted, ref, watch} from "vue";

    export default {
        name: 'InterestedInBlock',
        props: [
            'agency',
            'role',
            'changeType',
            'changeAmount',
            'title',
            'type',
            'num',
            'subtree',
            'readMoreOverride',
            'view',
            'withContainer'
        ],
        components: {
            InfoBlock,
            InfoBlockStacked,
            InfoBlockStackedBudgetUpdate,
            TagAgency,
            TagRole,
            GeneralContainer,
            ReadMoreLink,
            Preloader,
            InfoBlockBudget,
            InfoBlockEmployment,
            InfoBlockTwitter,
        },
        data() {
            return {
                customClass: "interested-in",
                readMore: "See More Position Changes",
            }
        },
        methods: {
            getComponent(item) {
                if (item.class === 'EmploymentUpdateRef') {
                    if (this.$props.view === 'full') {
                        return 'info-block-employment';
                    }
                    return 'info-block-stacked';
                }
                if (item.class === 'BudgetUpdateRef') {
                    if (this.$props.view === 'full') {
                        return 'info-block-budget';
                    }
                    return 'info-block-stacked-budget-update';
                }
                // should never get here
                return '';
            }
        },
        setup(props) {
                const loading = ref(true)
                const updates = ref([])

            const loadAllRecentUpd = (reset) =>{
                loading.value = true;
                if (reset) {
                    updates.value = [];
                }
                RequestHandler.loadAllRecentUpdates(
                    props.num,
                    0,
                    props.type,
                    props.subtree,
                )
                    .then(response => {
                    updates.value = response.ContentList.list;
                    for (let i = 0; i < updates.value.length; i ++){
                        if (updates.value[i]['contact'] != undefined) {
                            let contact =  updates[i]['contact']['ContactRef']
                            let contentID = contact.contentId
                            if (updates[i].toPositionName === "" ){
                                // if to position name is "" but person is not currently under
                                // 'no-longer-with-an-agency' remove update from list
                                RequestHandler.loadPositionByContentId(contentID).then(response => {
                                    let currPos = response['PositionRef']['url']
                                    if (!currPos.includes('no-longer-with-an-agency')){
                                        updates.value.splice(updates.value.indexOf(updates[i]),1)
                                    }
                                })
                            }
                        }
                    }
                    loading.value = false;
                }).catch((error) => {
                    console.error(error);
                });
            }
            onMounted(() => {
                loadAllRecentUpd(true)
                loading.value = false
            })
            return{
                loading,
                updates,
                loadAllRecentUpd,
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";

    .red {
        color: $red;
    }

    .green {
        color: $green--light;
    }

/*
    .media + .media {
        border-top: 1px solid $blue--med;
        &.info-block__green--light {
            border-top: 1px solid $green--light !important;
        }
        &.info-block__twitter {
            border-top: 1px solid $twitter !important;
        }
    }
*/

    @include block('no-data') {
        font-weight: 600;
        font-family: $header;
        font-size: 1.25rem;
        text-align: center;
        margin: 0 auto;
        @include element('title') {
            font-size: 1.5rem;
            margin-bottom: 5px;
        }
        @include element('icon') {
            width: 70px;
        }
    }
    .no-data__icon--interested {
        font-size: 64px;
        margin: 0 auto -15px auto;
    }
</style>

