import { computed } from 'vue'
import { useStore } from 'vuex'
import { ACTION_TYPES, MUTATION_TYPES } from "@/constants";

export default function () {
    const store = useStore();
    const user = computed(() => store.getters.user);
    const userLoaded = computed(() => store.getters.isUserLoaded);
    const userLoading = computed(() => store.getters.isUserLoading);
    const redirectPath = computed(() => store.getters.redirectPath);

    const redirectIfNotLoggedIn = (router) => {
        if (!userLoading.value && userLoaded.value && !user.value) {
            router.push({name: 'login'});
        }
    }
    const redirectIfNotVerified = (router) => {
        if (!userLoading.value && userLoaded.value && user.value && !user.value.verified) {
            router.push({name: 'verify'});
        }
    }
    const logout = () => store.dispatch(ACTION_TYPES.logout);

    const redirectIfLoggedIn = (router) => {
        if (userLoaded.value && user.value) {
            const redirect = redirectPath.value && redirectPath.value !== '/standalone/tableau' && redirectPath.value !== '/login' && redirectPath.value !== '/logout' && redirectPath.value !== '/teaser'
                ? {path: redirectPath.value}
                : {name: !user.value.verified ? 'verify' : user.value.subscriptionIsValid && user.value.agreedTos ? 'dashboard' : 'teaser'}; // used to be 'dashboard' : 'profile'
            store.commit(MUTATION_TYPES.setRedirectPath, null);
            router.push(redirect);
        }
    };

    const original_user = computed(() => user.value);

    return {
        user,
        original_user,
        userLoaded,
        userLoading,
        redirectIfNotLoggedIn,
        redirectIfNotVerified,
        redirectIfLoggedIn,
        logout
    }
}
