<template>
    <!-- Component: molecules/info-block--stacked -->
    <info-block :hasIcon="false" :hasClose="false" :isTweet="false" :color="color">

        <i class="fas fa-user icon-inline" :aria-labelledby="id"></i>
        <p class="info-block__title" :id="id">Employment Update {{ updatedAt }}</p>

        <div class="group">
            <p class="label">Contact:</p>
            <tag-contact v-if="contact" :tagText="getFullNameText(contact.ContactRef)" :tagUrl="contact.ContactRef.url" />
            <br v-if="toAgency && fromAgency && fromAgencyName == toAgencyName" />
            <tag-agency v-if="toAgency && fromAgency && fromAgencyName == toAgencyName" :tagText="fromAgencyName" :tagUrl="fromAgency.AgencyRef.url" />
        </div>

        <div class="columns">
            <div class="column is-two-fifths">
                <div class="group">
                    <p class="label">Former Position:</p>
                    <tag-agency v-if="(toAgency && fromAgency && fromAgencyName != toAgencyName) || !toAgency" :tagText="fromAgencyName" :tagUrl="fromAgency.AgencyRef.url" :type="outType" :customClass="'full-width'" :ttip="true" :ttipText="'Former Agency'"/>
                    <br v-if="toAgency && fromAgency && fromAgencyName != toAgencyName" />
                    <tag-role v-if="fromPosition" :tagText="fromPositionName" :tagUrl="fromPosition.PositionRef.url" :type="outType" :customClass="'full-width'" :ttip="true" :ttipText="'Former Role'"/>
                    <tag v-else :tagText="'No information on this contact\'s previous position.'" :customClass="'disabled-cursor ml0 full-width'" :type="'disabled'"/>
                </div>
            </div>

            <div class="column no-mobile has-text-centered">
                <span class="icon"><i class="fas fa-arrow-right" aria-hidden="true" role="presentation"></i></span>
            </div>

            <div class="column is-two-fifths">
                <div class="group">
                    <p class="label">Updated Position:</p>
                    <tag-agency v-if="(toAgency && fromAgency && fromAgencyName != toAgencyName) || !fromAgency" :tagText="toAgencyName" :tagUrl="toAgency.AgencyRef.url" :type="inType" :customClass="'full-width'" :ttip="true" :ttipText="'Current Agency'"/>
                    <br v-if="toAgency && fromAgency && fromAgencyName != toAgencyName" />
                    <tag-role v-if="toPosition" :tagText="toPositionName" :tagUrl="toPosition.PositionRef.url" :type="inType" :customClass="'full-width'" :ttip="true" :ttipText="'Current Role'"/>
                    <tag v-else :tagText="'This contact has left this state government entity.'" :customClass="'disabled-cursor full-width'" :type="'disabled'"/>
                </div>
            </div>
        </div>

    </info-block>
    <!-- End Component: molecules/info-block--stacked -->
</template>

<script>
    import InfoBlock from "./info-block"
    import Tag from "./tag";
    import TagAgency from "./tag--agency";
    import TagRole from "./tag--role";
    import TagContact from "./tag--contact";
    import { capitalize } from '@/helpers/filter';
    import { v4 as uuidv4 } from "uuid";

    export default {
        name: 'InfoBlockStacked',
        components: {
            InfoBlock, TagAgency, TagRole, TagContact, Tag
        },
        props: [
            'class',
            'contentId',
            'fromAgency',
            'fromAgencyName',
            'fromClassCode',
            'fromPosition',
            'fromPositionName',
            'fromPositionNumber',
            'fromSalary',
            'locationId',
            'name',
            'objectNameString',
            'toAgency',
            'toAgencyName',
            'toClassCode',
            'toPosition',
            'toPositionName',
            'toPositionNumber',
            'toSalary',
            'updatedAt',
            'url',
            'contact',
        ],
        data() {
            return {
                requestedIcon: "faUser",
                color: "blue--dark",
                outType: "outgoing",
                inType: "incoming",
                id: null
            }
        },
        methods: {
            getFullNameText(contact) {
                return capitalize(contact.firstName)
                    + ' ' + capitalize(contact.lastName);
            }
        },
        mounted() {
            this.id = uuidv4();
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";

    @include block('info-block') {
        @include element('title') {
            margin-bottom: 10px;
            font-weight: 600;
            font-family: $header;
            font-size: 1.2rem;
        }
    }
    .group {
        margin-bottom: 5px;
    }
    .label {
        margin-bottom: 0px !important;
    }
    .icon-inline {
        display: inline-block;
        float: left;
        margin-right: 7px;
        position: relative;
        top: 5px;
        color: $blue--med;
    }
    .ml0 {
        margin-left: 0px !important;
    }
    @media screen and (max-width:768px) {
        .no-mobile {
            display: none;
        }
    }
    .arrow {
        max-width: 70px;
        position: relative;
        top: 15px;
    }
    .icon {
        padding-top: 25px;
        display: inherit;
        margin: 0 auto;
        .fa-arrow-right {
            font-size: 28px;
        }
    }
    .is-two-fifths {
        @media screen and (min-width: 769px) {
            width: 45%; // want to make these a little wider than usual
        }
    }
</style>

