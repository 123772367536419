<template>
    <!-- Page: pages/folder -->

    <template v-if="isGovGuidance()">

        <div class="container">
            <alert/>
        </div>

        <hr/>

        <div class="container">
            <div class="columns">

                <div class="column is-two-thirds">
                    <h1>{{ name }}</h1>
                    <div v-html="description"></div>
                    <PDFViewer />
                </div>

                <div class="column is-one-third">
                    <glossary></glossary>
                </div>

            </div>
        </div>

    </template>

    <template v-else>
        <div class="container">
            <alert/>
        </div>

        <hr/>

        <div class="container">
            <h1>{{ name }}</h1>
            <div v-html="description"></div>
            <PDFViewer />
        </div>
    </template>

    <!-- End Page: pages/folder -->
</template>

<script>
    import Alert from "./alert";
    import Glossary from "./glossary";
    import PDFViewer from "./pdf-viewer";

    export default {
        name: 'Folder',
        components: {
            Alert,
            Glossary,
            PDFViewer
        },
        props: ['name', 'description'],
        methods: {
            isGovGuidance() {
                if (this.$props.name === "GovGuidance") {
                    return true;
                } else {
                    return false;
                }
            }
        }
    }
</script>
