<template>
    <!-- Page: pages/recent-updates -->

    <br/>

    <div class="container">
        <alert/>
    </div>

    <hr/>

    <breadcrumbs :items="path"/>

    <div class="container" style="display: flow-root;">
        <h1 style="float:left;">Recent Updates</h1>
        <user-tools/>
    </div>

    <hr/>

    <div class="container">

        <general-container :customClass="customClass" :title="title" :isLocked="false">

            <preloader v-if="loading"></preloader>
            <p v-if="!loading && results && results.list && results.list.length == 0">Please bookmark more Contacts, Positions, Agencies, etc. to see recent updates.</p>

            <template
                v-if="!loading && results && results.list && results.list.length"
                v-for="item in results.list"
                :key="item.contentId"
            >
                <component v-bind="item" v-bind:is="getComponent(item)"/>
            </template>

            <pagination v-if="results && results.pageCount" v-on:paginated="paginate($event)" :current="page" :pageCount="results.pageCount" />

        </general-container>

    </div>

    <!-- End Page: pages/recent-updates -->
</template>

<script>
    import Alert from "@/alivue/components/alert";
    import Breadcrumbs from "@/alivue/components/breadcrumbs";
    import GeneralContainer from "./general-container"
    import InfoBlockLeadership from "./info-block--leadership"
    import InfoBlockBudget from "./info-block--budget"
    import InfoBlockTwitter from "./info-block--twitter"
    import InfoBlockEmployment from "./info-block--employment"
    import InfoBlockEmpty from "./info-block--empty"
    import ReadMoreLink from "./read-more-link"
    import RequestHandler from "@/handler/RequestHandler"
    import UserTools from "./user-tools";
    import SearchBlock from "./search-block";
    import Pagination from "./pagination.vue";
    import { computed } from "vue";
    import { useStore } from "vuex";
    import Preloader from "./preloader";

    export default {
        name: 'RecentNewsBlock',
        props: [],
        components: {
            Alert,
            Breadcrumbs,
            GeneralContainer,
            InfoBlockLeadership,
            InfoBlockBudget,
            InfoBlockTwitter,
            InfoBlockEmployment,
            InfoBlockEmpty,
            ReadMoreLink,
            SearchBlock,
            UserTools,
            Pagination,
            Preloader,
        },
        mounted() {
            this.loadResults();
        },
        setup(props) {
            const store = useStore();
            const showSearch = computed(() => store.getters.showSearch);

            return {
                path: [{title: 'Recent Updates'}],
                showSearch,
            };
        },
        methods: {
            loadResults() {
                this.loading = true;
                RequestHandler.loadRecentUpdates(this.pageLimit, true, (this.page-1)*this.pageLimit).then(response => {
                    this.results = response.ContentList;
                    this.loading = false;
                }).catch((error) => {
                    console.error(error);
                });
            },
            paginate(pageTo, force) {
                window.scrollTo(0, 0);
                this.page = pageTo;
                this.loadResults();
            },
            getComponent(item) {
                if (item.class === 'EmploymentUpdateRef') {
                    return 'info-block-employment';
                }
                if (item.class === 'TweetRef') {
                    return 'info-block-twitter';
                }
                if (item.class === 'BudgetUpdateRef') {
                    return 'info-block-budget';
                }
                if (item.class === 'EmptyRef') {
                    return 'info-block-empty';
                }
                // should never get here
                return '';
            }
        },
        data() {
            return {
                title: "",
                customClass: "since-you-last-block",

                loading: true,
                page: 1,
                pageLimit: 50,
                results: null,
            }
        }

    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";
/*
    .media + .media {
        border-top: 1px solid $blue--med;
    }
*/
</style>

