export const MUTATION_TYPES = {
    setBookmarkIds: 'setBookmarkIds',
    addBookmarkId: 'addBookmarkId',
    removeBookmarkId: 'removeBookmarkId',
	setUser: 'setUser',
	setUserLoading: 'setUserLoading',
	setUserLoaded: 'setUserLoaded',
	setCsrfToken: 'setCsrfToken',
	setSessionId: 'setSessionId',
	setEZSessIdKey: 'setEZSessIdKey',
	setSignInError: 'setSignInError',
	setUserReference: 'setUserReference',
    setBookmarkIdsLoaded: 'setBookmarkIdsLoaded',
	setRedirectPath: 'setRedirectPath',
	setNewlyRegistered: 'setNewlyRegistered',
    setUserVerified: 'setUserVerified',
    setVerificationCode: 'setVerificationCode',
    setSavedPasswordFromData: 'savedPasswordFromData',
};

export const ACTION_TYPES = {
    loadBookmarkIds: 'loadBookmarkIds',
    addBookmarkId: 'addBookmarkId',
    removeBookmarkId: 'removeBookmarkId',
	loadUser: 'loadUser',
	signIn: 'signIn',
	logout: 'logout',
	syncWithStorage: 'syncWithStorage',
};

export const CHART_COLORS = [
//    '#EBF8FF', // blue
    '#BEE3F8',
    '#90CDF4',
    '#63B3ED',
    '#4299E1',
    '#3182CE',
    '#2B6CB0',
//    '#2C5282',
//    '#2A4365',
//    '#FAF5FF', // purple
    '#E9D8FD',
    '#D6BCFA',
    '#B794F4',
    '#9F7AEA',
    '#805AD5',
    '#6B46C1',
//    '#553C9A',
//    '#44337A',
//    '#E6FFFA', // teal
    '#B2F5EA',
    '#81E6D9',
    '#4FD1C5',
    '#38B2AC',
    '#319795',
    '#2C7A7B',
//    '#285E61',
//    '#234E52',
//    '#F0FFF4', // green
    '#C6F6D5',
    '#9AE6B4',
    '#68D391',
    '#48BB78',
    '#38A169',
    '#2F855A',
//    '#276749',
//    '#22543D',
//    '#FFFFF0', // yellow
    '#FEFCBF',
    '#FAF089',
    '#F6E05E',
    '#ECC94B',
    '#D69E2E',
    '#B7791F',
//    '#B7791F',
//    '#744210',
//    '#FFF5F5', // red
    '#FED7D7',
    '#FEB2B2',
    '#FC8181',
    '#F56565',
    '#E53E3E',
    '#C53030',
//    '#9B2C2C',
//    '#742A2A',
//    '#F7FAFC', // grey
    '#EDF2F7',
    '#E2E8F0',
    '#CBD5E0',
    '#A0AEC0',
    '#718096',
    '#4A5568',
//    '#2D3748',
//    '#1A202C'
];

export const SPEND_COLORS = [
    '#bf0a30', // fallback color
    'linear-gradient(0deg,#bf0a30,#78041c)',
    '#46cb79', // fallback color
    'linear-gradient(0deg,#46cb79,#136f63)',
    'url(#Gradient1)', // allows us to pull from an SVG in template
    'url(#Gradient2)' // allows us to pull from an SVG in template
];
