<template>
    <!-- Component: organisms/procurement-info-card -->
    <info-card :procurement="$props">
        <div class="card-content">
            <div class="section">
                <div class="content">

                    <p class="card__info__label top">Procurement</p>
                    <p class="card__info__title">{{ titleize(title) }}</p>

                    <div class="columns">
                        <div class="column" v-if="agency">
                            <p class="card__info__label card__info__label__with-tag">Agency</p>
                            <tag-agency :tag-text="agency.name" :tag-url="agency.url"/>
                        </div>
                    </div>

                    <!-- these items are 'columnized' via methods below -->
                    <div class="columns">
                        <div class="column" v-if="status">
                            <p class="card__info__label">Status</p>
                            <p class="card__info__data">{{ status }}</p>
                        </div>

                        <div class="column" v-if="adType">
                            <p class="card__info__label">Ad Type</p>
                            <p class="card__info__data">{{ adType }}</p>
                        </div>
                    </div>

                    <div class="columns">
                        <div class="column" v-if="startDate">
                            <p class="card__info__label">Start Date:</p>
                            <p class="card__info__data">{{ startDateFormatted }}</p>
                        </div>

                        <div class="column" v-if="endDate">
                            <p class="card__info__label">End Date:</p>
                            <p class="card__info__data">{{ endDateFormatted }}</p>
                        </div>
                    </div>

                    <div class="columns">
                        <div class="column" v-if="agencyAdNumber">
                            <p class="card__info__label">Agency Ad Number</p>
                            <p class="card__info__data">{{ agencyAdNumber }}</p>
                        </div>
                    </div>

                    <div class="columns" v-if="description">
                        <div class="column">
                            <p class="card__info__label">Description</p>
                            <div v-html="description"></div>
                        </div>
                    </div>

                    <div class="columns" v-if="id">
                        <div class="column">
                            <p @click.prevent="showIframe = true" class="card__info__label card__info__label__link">View details on MyFloridaMarketPlace Vendor Information Portal</p>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <iframe-modal
            v-if="showIframe"
            @close="showIframe = false"
            @created="newIframeCreated"
            :sourceURL="'https://vendor.myfloridamarketplace.com/search/bids/detail/' + id"
            :sourceTitle="title"
            :title="'Bid Details'"
        ></iframe-modal>

    </info-card>
    <!-- End Component: organisms/procurement-info-card -->
</template>

<script>
    import { reactive, ref, computed } from 'vue';
    import { useStore } from "vuex";
    import InfoCard from "./info-card"
    import ReadMoreLink from "./read-more-link"
    import { priceFormat } from "@/helpers/filter";
    import TagVendor from "./tag--vendor";
    import TagAgency from "./tag--agency";
    import Tag from "./tag";
    import TagBudget from "./tag--budget";
    import IframeModal from "./iframe-modal";
    import Tooltip from "./tooltip";

    export default {
        name: 'ProcurementInformationCard',
        components: {
            InfoCard,
            ReadMoreLink,
            TagVendor,
            TagAgency,
            Tag,
            TagBudget,
            IframeModal,
            Tooltip
        },
        props: {
            contentId: Number,
            locationId: Number,
            title: String,
            status: String,
            adType: String,
            startDate: String,
            endDate: String,
            startDateFormatted: String,
            endDateFormatted: String,
            agencyAdNumber: String,
            agency: Object,
            id: String,
            description: String
        },
        data() {
            return {

             };
        },
        setup(props) {
            const showIframe = ref(false);
            const store = useStore();
            const definitions = computed(() => store.getters.definitions);

            const newIframeCreated = () => {
                showIframe.value = false;
            };

            return {
                priceFormat,
                newIframeCreated,
                showIframe,
                definitions
            };
        },
        mounted: function () {
            this.columnize();
        },
        methods: {
            titleize: function(item) {
                return item.toLowerCase().replace(/(?:^|\s|-)\S/g, x => x.toUpperCase());
            },
            columnize: function() {

                // forEach function
                var forEach = function (array, callback, scope) {
                    for (var i = 0; i < array.length; i++) {
                    callback.call(scope, i, array[i]); // passes back stuff we need
                    }
                }

                // select all .parent divs
                var parentDivs = document.querySelectorAll('.parent');

                //slicing the array
                var chunk = function ( array, size ) {
                    var arr = [];
                    for ( var i = 0; i < array.length; i += size ) {
                        var newSlicedArray = Array.prototype.slice.call( array, i, i + size );
                        arr.push(newSlicedArray);
                    }
                    return arr;
                }

                //run foreach function
                forEach(parentDivs, function (index, value) {

                    var childrens = value.querySelectorAll(".create-rows");

                    var final = chunk(childrens,4);
                    final.map( towrap =>
                        towrap.reduce( (acc, el) =>
                            (acc.appendChild(el),acc) , document.createElement('div') )
                    ).forEach( el => {
                        el.className ="columns created-cols";
                        value.appendChild(el)
                    })

                });

            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";

    .content .card__info__title {
        margin-top: 0.75em !important;
        font-family: $header;
        font-weight: 600;
        font-size: 2em;
    }

    @include block('card') {
        .media {
            display: contents;
        }
        @include element('info') {
            @include element('label') {
                float: left;
                margin-right: 10px;
                display: block;
                width: 100%;
                margin-bottom: 5px !important;
                &.top {
                    margin-bottom: 0px !important;
                }
            }
            @include element('description') {
                line-height: 1.7em;
            }
        }
    }
     .level {
        margin-bottom: 15px;
        justify-content: start;
        display: flow-root;
        .level-item {
            display: inline-flex !important;
            max-width: 200px;
            width: auto;
            margin-right: 40px;
            margin-bottom: 15px;
            .title {
                font-size: 1.25rem;
            }
            .end-date {
                max-width: 250px;
            }
        }
    }

    .card__info__label.card__info__label__link {
        font-size: 15px;
    }
</style>
