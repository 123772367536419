<template>
    <!-- Component: atoms/leadership-marker -->
    <Tooltip :tooltipText="'This Contact is in a Leadership Position.'">
        <img src="@/assets/svg/leadership.svg"
            alt="Leadership Position"
            class="has-tooltip has-tooltip--no-underline"
        />
    </Tooltip>
    <!-- End Component: atoms/leadership-marker -->
</template>


<script>
    import Tooltip from "./tooltip";

    export default {
        name: 'LeadershipMarker',
        props: ['leadership'],
        components: {
            Tooltip
        }
    }
</script>


<style lang="scss" scoped>
    img {
        display: inline-block;
        float: left;
    }
</style>
