<template>
    <!-- Page: pages/relevant-updates -->

    <br/>

    <div class="container">
        <alert/>
    </div>

    <hr/>

    <breadcrumbs :items="path"/>

    <div class="container" style="display: flow-root;">
        <h1 style="float:left;">{{ title }}</h1>
        <user-tools/>
    </div>

    <hr/>

    <div class="container">

        <general-container :customClass="customClass" :title="''" :isLocked="false">

            <preloader v-if="loading"></preloader>
            <p v-if="!loading && results && results.list && results.list.length == 0">Check back later for more relevant updates.</p>

            <template
                v-if="!loading && results && results.list && results.list.length"
                v-for="item in results.list"
                :key="item.contentId"
            >
                <component v-bind="item" v-bind:is="getComponent(item)"/>
            </template>

            <pagination v-if="results && results.pageCount" v-on:paginated="paginate($event)" :current="page" :pageCount="results.pageCount" />

        </general-container>

    </div>

    <!-- End Page: pages/recent-updates -->
</template>

<script>
    import Alert from "@/alivue/components/alert";
    import Breadcrumbs from "@/alivue/components/breadcrumbs";
    import GeneralContainer from "./general-container"
    import InfoBlockLeadership from "./info-block--leadership"
    import InfoBlockBudget from "./info-block--budget"
    import InfoBlockTwitter from "./info-block--twitter"
    import InfoBlockEmployment from "./info-block--employment"
    import ReadMoreLink from "./read-more-link"
    import RequestHandler from "@/handler/RequestHandler"
    import UserTools from "./user-tools";
    import SearchBlock from "./search-block";
    import Pagination from "./pagination.vue";
    import Preloader from "./preloader";
    import { computed } from "vue";
    import { useStore } from "vuex";

    export default {
        name: 'RelevantNewsBlock',
        props: [],
        components: {
            Alert,
            Breadcrumbs,
            GeneralContainer,
            InfoBlockLeadership,
            InfoBlockBudget,
            InfoBlockTwitter,
            InfoBlockEmployment,
            ReadMoreLink,
            SearchBlock,
            UserTools,
            Pagination,
            Preloader,
        },
        mounted() {
            this.type = this.$route.query.type || '';
            this.subtree = this.$route.query.subtree || null;

            if (this.subtree) {
                RequestHandler.loadPath(this.subtree).then(response => {
                    this.path = [];
                    for (let i = 0; i < response.length; i++) {
                        if (
                            response[i].type !== 14 &&
                            response[i].type !== 17 &&
                            response[i].type !== 15
                        ) {
                            continue;
                        }
                        if (i === response.length-1) {
                            let title = 'Relevant Updates';
                            if (this.type === 'employment_update') {
                                title = 'Relevant Employment Updates';
                            }
                            else if (this.type === 'budget_update') {
                                title = 'Relevant Budget Updates';
                            }
                            title += ' for ' + response[i].name;
                            this.title = title;
                        }

                        this.path.push({
                            url: response[i].url,
                            title: response[i].name,
                            isActive: false,
                        });
                    }
                    
                    this.path.push({url: '#', title: this.title, isActive: true});
                }).catch((error) => {
                    console.error(error);
                });
            }

            let title = 'Relevant Updates';
            if (this.type === 'employment_update') {
                title = 'Relevant Employment Updates';
            }
            else if (this.type === 'budget_update') {
                title = 'Relevant Budget Updates';
            }
            this.title = title;
            this.path.push({url: '#', title: title, isActive: true});

            this.loadResults();
        },
        setup(props) {
            const store = useStore();
            const showSearch = computed(() => store.getters.showSearch);

            return {
                showSearch,
            };
        },
        methods: {
            loadResults() {
                this.loading = true;
                RequestHandler.loadAllRecentUpdates(this.pageLimit, (this.page-1)*this.pageLimit, this.type, this.subtree).then(response => {
                    this.results = response.ContentList;
                    this.loading = false;
                }).catch((error) => {
                    console.error(error);
                });
            },
            paginate(pageTo, force) {
                window.scrollTo(0, 0);
                this.page = pageTo;
                this.loadResults();
            },
            getComponent(item) {
                if (item.class === 'EmploymentUpdateRef') {
                    return 'info-block-employment';
                }
                if (item.class === 'TweetRef') {
                    return 'info-block-twitter';
                }
                if (item.class === 'BudgetUpdateRef') {
                    return 'info-block-budget';
                }
                // should never get here
                return '';
            }
        },
        data() {
            return {
                title: "",
                customClass: "since-you-last-block",
                path: [],

                loading: true,
                page: 1,
                pageLimit: 50,
                results: null,
                type: '',
                subtree: null,
            }
        }

    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";
/*
    .media + .media {
        border-top: 1px solid $blue--med;
    }
*/
</style>

