<template>
    <!-- Component: molecules/tag--agency -->
    <template v-if="isTooltip()">
        <Tooltip :tooltipText="ttipText" :cClass="'dilb'">
            <a :href="tagUrl" :class="['gov-tag tag__agency', type]">
                <font-awesome-icon :icon="iconAgency" aria-label="Agency"></font-awesome-icon>
                <span class="tag__text" v-text="toTitleCase(tagText)"></span>
            </a>
        </Tooltip>
    </template>

    <a v-else :href="tagUrl" :class="['gov-tag tag__agency', type]">
        <font-awesome-icon :icon="iconAgency" aria-label="Agency"></font-awesome-icon>
        <span class="tag__text" v-text="toTitleCase(tagText)"></span>
    </a>
    <!-- End Component: molecules/tag--agency -->
</template>

<script>
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
    import { faBuilding } from '@fortawesome/pro-light-svg-icons'
    import { toTitleCase } from "@/helpers/filter";
    import Tooltip from "./tooltip";

    export default {
        name: 'TagAgency',
        components: {
            FontAwesomeIcon,
            Tooltip
        },
        props: ['tagText', 'tagUrl', 'type', 'ttip', 'ttipText'],
        setup() {
            return {
                iconAgency: faBuilding,
                toTitleCase,
            }
        },
        methods: {
            isTooltip() {
                if (this.$props.ttip === true) {
                    return true;
                } else {
                    return false;
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";

    @include block('gov-tag') {
        background-color: $white;
        margin-right: 10px;
        padding: 5px 10px;
        box-shadow: $box-shadow;
        border-radius: 15px;
    }
    @include block('tag') {
        @include element('text') {
            margin-left: 5px;
            font-weight: 600;
        }
        @include element('agency') {
            &.gov-tag {
                background-color: $white;
                color: $blue--dark;
                text-decoration: none;

                &:hover, &:active, &:focus {
                    color: lighten($blue--dark, 10%);
                    text-decoration: none;
                }
            }
            &.outgoing {
                svg {
                    color: $red;
                }
                .tag__text {
                    color: darken($red, 10%);
                }
            }
            &.incoming {
                svg {
                    color: darken($green--light, 25%);
                }
                .tag__text {
                    color: darken($green--light, 25%);
                }
            }
        }
    }
</style>
