<template>
    <!-- Component: atoms/showing-results -->
    <div class="showing-results">
        <span class="showing-results__general pr-1">Showing </span>
        <span class="showing-results__number">{{ number }}</span>
        <span class="showing-results__general pl-1 pr-1"> result{{ number == 1 ? '' : 's'}} for "</span>
        <span class="showing-results__query">{{ query }}</span>
        <span class="showing-results__general pl-1">"</span>
    </div>
    <!-- End Component: atoms/showing-results -->
</template>

<script>
    export default {
        name: 'ShowingResults',
        props: ['number', 'query'],
        data() {
            return {}
        },
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";

    @include block('showing-results') {
        font-weight: 600;
        display: block;
        margin-bottom: 30px;
        margin-left: 15px;
        @include element('number') {
            display: inline-block;
            color: $blue--light;
        }
        @include element('query') {
            display: inline-block;
            color: $blue--light;
        }
        @include element('general') {
            display: inline-block;
            color: $blue--dark;
        }
    }
    .pl-1 {
        padding-left: 1px;
    }
    .pr-1 {
        padding-right: 1px;
    }
</style>
