<template>
    <!-- Component: atoms/read-more-link -->
    <a :href="url" class="readmore-link">
        <i v-if="icon && icon == 'calendar'" class="far fa-calendar"></i>
        {{ contents }}
    </a>
    <!-- End Component: atoms/read-more-link -->
</template>

<script>
    export default {
        name: 'ReadMoreLink',
        props: {
            contents: String,
            icon: String,
            url: {
                type: String,
                default: '#'
            },
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";


</style>
