<template>
    <!-- Page: pages/document-flyout -->
    <div class="page-container document-page">
        <div class="container">
            <document-info-card v-bind="$props"/>
        </div>
    </div>
    <!-- End Page: pages/document-flyout -->
</template>

<script>
    import DocumentInfoCard from "./document-info-card";
    import useUser from "@/helpers/useUser";
    import { computed } from "vue";
    import { useStore } from "vuex";

    export default {
        components: {
            DocumentInfoCard,
        },
        props: {
            contentId: Number,
            locationId: Number,
            title: String,
            type: String,
            id: String,
            file: Object,
        },
        methods: {},
        setup(props) {
            const { user } = useUser();

            const store = useStore();
            const showSearch = computed(() => store.getters.showSearch);

            return {
                user,
                path: [{ title: props.name }],
                showSearch,
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
