<template>
    <!-- Component: molecules/info-block--leadership -->
    <info-block :requestedIcon="requestedIcon" :hasIcon="true" :hasClose="true" :isTweet="false" :color="color">
        <p class="info-block__title">Leadership Update</p>
        <tag-agency :tagText="agency"/>
        <tag-role :tagText="role" :leadership="leadership"/>
        <span class="info-block__spacer">:</span>
        <tag-contact :tagText="outgoingContact" :type="outType"/>
        <span class="info-block__spacer">»</span>
        <tag-contact :tagText="incomingContact" :type="inType"/>
    </info-block>
    <!-- End Component: molecules/info-block--leadership -->
</template>

<script>
    import InfoBlock from "./info-block"
    import TagAgency from "./tag--agency";
    import TagRole from "./tag--role";
    import TagContact from "./tag--contact";

    export default {
        name: 'InfoBlockLeadership',
        components: {
            InfoBlock, TagAgency, TagRole, TagContact
        },
        props: ['agency', 'role', 'incomingContact', 'outgoingContact', 'leadership'],
        data() {
            return {
                requestedIcon: "faCrown",
                color: "blue--dark",
                outType: "outgoing",
                inType: "incoming"
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";

    @include block('info-block') {
        @include element('spacer') {
            margin-right: 10px;
            display: inline-block;
        }
    }
</style>
