<template>
    <!-- Component: molecules/fact-box -->
    <div class="fact-box">
        <p class="fact-box__title">{{ title }}</p>
        <div class="fact-box__content" v-html="content"></div>
    </div>
    <!-- End Component: molecules/fact-box -->
</template>

<script>
    export default {
        name: 'FactBox',
        components: {},
        props: ['title', 'content'],
        data() {
            return {}
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";

    @include block('fact-box') {
        padding: 15px;
        background-color: $white;
        border: 1px solid $blue--light;
        @include element('title') {
            font-family: $header;
            color: $blue--dark;
            font-size: 21px;
            font-weight: 600;
        }
        @include element('content') {

        }
    }
</style>
