<template>
    <!-- Component: organisms/position-budget-block -->
    <general-container
        :custom-class="['todo-block', customClass]" title="Related Budgets" :isLocked="false"
        v-if="budgets && budgets.length > 0"
    >
        <general-container :custom-class="'general-container--background'">
            <div>
                <span class="progress-value" v-if="budgets.length === 1">
                    {{ titleize(budgets[0].name) }}
                </span>

                <!--
                <progress class="progress" :value="disburstPC" max="100">{{ disburstPC }}%</progress>
                -->

                <div class="miniBar">
                    <div :class="'miniBarProgress spent '" :style="'left: 0; width: ' + disburstPC + '%; background-color: gray;'" v-if="disburstPC > 0"><span>{{ disburstPC }}%</span></div>
                    <div :class="'miniBarProgress remains ' + checkAmount(disburstPC)" :style="'left: ' + disburstPC + '%; width: ' + (100 - disburstPC) + '%; background-color: red;'"><span>{{ 100 - disburstPC + '%' }}</span></div>
                </div>

                <span v-show="false" class="progress-value">{{ disburstPC }}%</span>

                <nav class="level progress-level">
                    <div class="level-item has-text-centered">
                        <div>
                            <p class="heading">Amount Spent</p>
                            <p class="title">${{ priceFormat(totalDisburst) }}</p>
                        </div>
                    </div>
                    <div class="level-item has-text-centered">
                        <div>
                            <preloader v-if="loading"/>
                            <span class="slash" v-else>/</span>
                        </div>
                    </div>
                    <div class="level-item has-text-centered">
                        <div>
                            <p class="heading" v-if="chartEl !== null">Budget Remaining</p>
                            <p class="title">${{ priceFormat(totalAppropriation - totalDisburst) }}</p>
                        </div>
                    </div>
                    <div class="level-item has-text-centered">
                        <div>
                            <preloader v-if="loading"/>
                            <span class="slash" v-else>/</span>
                        </div>
                    </div>
                    <div class="level-item has-text-centered">
                        <div>
                            <p class="heading" v-if="chartEl !== null">Total</p>
                            <p class="title">${{ priceFormat(totalAppropriation) }}</p>
                        </div>
                    </div>
                </nav>
            </div>
            <position-budget-block-chart v-if="budgets.length > 1" :budgets="budgets"></position-budget-block-chart>

        </general-container>
    </general-container>

    <read-more-link
        contents="See Full Budget Breakdown"
        v-if="agencyId && budgets.length && budgets.length !== 1"
        :url="'/browse-budgets?agencyId=' + agencyId"
    ></read-more-link>
    <read-more-link
        contents="See Full Budget Breakdown"
        v-else-if="budgets && budgets.length === 1"
        :url="budgets[0].url"
    ></read-more-link>
    <!-- End Component: organisms/position-budget-block -->
</template>

<script>
    import GeneralContainer from "./general-container"
    import InfoBlockTravel from "./info-block--travel"
    import ReadMoreLink from "./read-more-link"
    import { onMounted, ref } from "vue";
    import RequestHandler from "@/handler/RequestHandler";
    import { priceFormat } from "@/helpers/filter";
    import Preloader from "@/alivue/components/preloader";
    import PositionBudgetBlockChart from "./position-budget-block--chart";

    export default {
        name: 'PositionBudgetBlock',
        props: ['positionId', 'agencyId', 'customClass', 'budgets',],
        components: {
            PositionBudgetBlockChart,
            Preloader,
            GeneralContainer,
            InfoBlockTravel,
            ReadMoreLink
        },
        setup(props, context) {
            const loading = ref(true);
            const budgets = ref([]);
            const totalAppropriation = ref(0);
            const totalDisburst = ref(0);
            const disburstPC = ref(0);
            const totalDisp = ref(0);

            onMounted(() => {
                if (props.positionId) {
                    RequestHandler.loadRecursivePositionsBudgets(props.positionId).then((items) => {
                        if (items.length === 0) {
                            context.emit('has-budgets', false);
                        }
                        else {
                            context.emit('has-budgets', true);
                        }

                        totalDisburst.value = items.reduce((acc, item) => acc + item.disbursementAmount, 0);
                        totalAppropriation.value = items.reduce((acc, item) => acc + item.appropriationAmount, 0);
                        totalDisp = totalDisburst.value / totalAppropriation.value;
                        if (totalDisp > 1) {
                            totalDisp = 1;
                        }
                        disburstPC.value = Math.round(totalDisp * 100);

                        budgets.value = items;
                        loading.value = false;
                    }).catch(error => {
                        loading.value = false;
                        console.error(error);
                    });
                }
            });

            return {budgets, totalDisburst, totalAppropriation, disburstPC, loading, priceFormat};
        },
        methods: {
            titleize: function(item) {
                return item.toLowerCase().replace(/(?:^|\s|-)\S/g, x => x.toUpperCase());
            },
            checkAmount(amount) {
                if (amount == 100) {
                    return ' full';
                } else if (amount == 0) {
                    return ' empty';
                };
            },
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";
/*
    .media + .media {
        border-top: 1px solid $blue--med;
    }
*/
    .slash {
        font-size: 42px;
        @media screen and (max-width: 767px) {
            display: none;
        }
    }
    .heading {
        color: $grey--dark;
        font-weight: 400;
        margin-bottom: 0!important;
        font-size: 11px;
        letter-spacing: 1px;
        margin-bottom: 5px;
        text-transform: uppercase !important;
        font-family: $body;
        font-family: $header--alt;
    }
/*
    .progress {
        margin-bottom: 5px;
    }

    .progress-level {
        margin-bottom: 30px;
    }

    .progress::-webkit-progress-value {
        background-color: $blue--dark;
    }

    .progress-value {
        display: block;
        text-align: center;
        font-weight: 600;
        margin-bottom: 15px;
    }
*/
</style>

