<template>
    <!-- Component: organisms/search-result-card--agency -->
    <searchResultCard type="agency" :agency="$props">

        <div class="section">
            <div class="content">

                <p class="card__info__label">Agency</p><br>

                <h1 class="card__info__title"><a :href="url">{{ titleize(name) }}</a></h1>

                <div class="columns">
                    <!--div class="column" v-if="type">
                        <p class="card__info__label">Agency Type</p>
                        <p class="card__info__data">{{ type }}</p>
                    </div-->

                    <!--div class="column">
                        <p class="card__info__label">Web Address</p>
                        <p class="card__info__data">{{ resultWebAddress }}</p>
                    </div-->
                </div>

                <!--div class="columns">
                    <div class="column">
                        <p class="card__info__label">Total Employees</p>
                        <p class="card__info__data">{{ totalEmployees }}</p>
                    </div>

                    <div class="column">
                        <p class="card__info__label">Web Address</p>
                        <p class="card__info__data">{{ resultBudget }}</p>
                    </div>
                </div-->

            </div>

            <div class="media" v-if="logo && logo.src">
                <div class="media-right">
                    <figure class="image">
                        <img :src="logo.src" :alt="logo.alt">
                    </figure>
                </div>
            </div>

        </div>
    </searchResultCard>
    <!-- End Component: organisms/search-result-card--agency -->
</template>

<script>
    import SearchResultCard from "./search-result-card";

    export default {
        name: 'SearchResultCardAgency',
        components: {SearchResultCard},
        props: [
            'type',
            'contentId',
            'totalEmployees',
            'logo',
            'name',
            'url',
            'locationId',
            'budgetAppropriations',
            'budgetDisbursements',
        ],
        data() {
            return {
            }
        },
        methods: {
            titleize: function(item) {
                return item.toLowerCase().replace(/(?:^|\s|-)\S/g, x => x.toUpperCase());
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/mixins/_bemify.scss";

    .gov-tag {
        margin-right: 5px;
    }

    @include block('card') {
        .media {
            display: contents;
        }
        @include element('info') {
            @include element('label') {
                float: left;
                margin-right: 10px;
            }
        }
    }

    .contact-block {
        .card__info__label {
            margin-bottom: 15px !important;
        }

        .columns {
            margin-bottom: 0px !important;

            .column {
                padding-bottom: 0px;
                padding-top: 0px;
                padding-left: 0px;
            }
        }
    }
</style>

