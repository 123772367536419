<template>
  <div>
    <div
      ref="addtohomescreen"
      id="add-to-homescreen"
      :class="[
        'add-to-homescreen-container',
        opened ? 'add-to-homescreen-visible' : 'add-to-homescreen-hidden',
      ]"
    >
      <button class="close_btn" @click="close" />
      <div class="flex">
        <div class="icon-container">
          <span
            class="icon"
            :style="{
              'background-color': getOpt('iconColor'),
              'background-image': 'url(' + getOpt('iconPath') + ')',
              color: iconTextColor,
            }"
            >
                <template v-if="!getOpt('iconPath')">{{ firstCharTitle }}</template>
          </span>
        </div>
        <div class="col">
          <span class="app-title" :style="{ color: getOpt('titleColor') }">{{
            getOpt("title") ? getOpt("title") : appTitle
          }}</span
          ><br />
          <span
            class="app-content"
            :style="{ color: getOpt('contentColor') }"
            v-html="getOpt('content') || appUrl"
          ></span>
        </div>
      </div>
      <div class="flex">
        <div class="col">
          <div class="btn-container">
            <button
              @click="addToHomeScreen"
              class="add-button"
              :style="{
                color: getOpt('buttonTextColor'),
                'background-color': getOpt('buttonColor'),
              }"
            >
                Learn How
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- IOS modal -->
    <div id="IOSmodal" class="modal add-to-homescreen-visible">
      <div class="modal-content">
        <ul>
          <li>
            {{ localizedString.addMessages.ios1 }}
            <img class="shareIOS" src="../../assets/svg/shareios.svg" alt="share" />
          </li>
          <li>
            {{ localizedString.addMessages.ios2 }}
            <img class="addIOS" src="../../assets/svg/addios.svg" alt="add to homescreen" />
          </li>
        </ul>
        <button
          class="closeModal"
          :style="{
            color: iconTextColor,
            background: iconColor,
            border: '1px solid ' + iconColor,
          }"
          label="OK"
          @click="closeModal"
        >
          OK
        </button>
      </div>
    </div>

    <!-- Android modal -->
    <div id="Androidmodal" class="modal add-to-homescreen-visible">
      <div class="modal-content">
        <ul>
          <li>
            <p class="text">{{ localizedString.addMessages.android1 }}</p>
            <img class="shareIOS" src="../../assets/svg/settingsandroid.svg" alt="settings" />
          </li>
          <li>
            <p class="text">{{ localizedString.addMessages.android2 }}</p>
            <img class="addIOS" src="../../assets/svg/addandroid.svg" alt="add to homescreen" />
          </li>
        </ul>
        <button
          class="closeModal"
          :style="{
            color: iconTextColor,
            background: iconColor,
            border: '1px solid ' + iconColor,
          }"
          label="OK"
          @click="closeModal"
        >
          OK
        </button>
      </div>
    </div>
</div>
</template>

<script>
    import Cookies from "js-cookie";
    import uaParser from "ua-parser-js";
    import appLang from "@/helpers/lang";
    import { isStandalone } from "@/helpers/utils";

    export default {
        name: "addToHomeScreen",
        props: {
            title: {
            type: String,
            default: "",
            },
            content: {
            type: String,
            },
            titleColor: {
            type: String,
            default: "#000",
            },
            contentColor: {
            type: String,
            default: "#000",
            },
            iconPath: {
            type: String,
            default: "",
            },
            iconColor: {
            type: String,
            default: "#000",
            },
            iconTextColor: {
            type: String,
            default: "#fff",
            },
            buttonColor: {
            type: String,
            default: "#000",
            },
            buttonTextColor: {
            type: String,
            default: "#fff",
            },
            background: {
            type: String,
            default: "#fff",
            },
            lang: {
            type: String,
            default: "en_GB",
            },
            expires: {
            type: Number,
            default: 8,
            }
        },
        data() {
            return {
                opened: false,
                localizedString: appLang.en_GB
            }
        },
        computed: {
            options() {
                return this.$root.$data;
            },
            appTitle() {
                return document.title;
            },
            appUrl() {
                return window.location.href;
            },
            firstCharTitle() {
                return this.appTitle.substring(0, 1);
            },
        },
        methods: {
            setCookie() {
              // request to make this cookie last forever
              var date = new Date();
              date.setTime(date.getTime() + (365*500*24*60*60*1000));
              var expires = "; expires=" + date.toUTCString();
              document.cookie = "addToHomeScreenCalled=true" + expires + "; path=/";
            },
            getOpt(option) {
                return this.options[option] ? this.options[option] : this[option];
            },
            close() {
                this.setCookie();
                this.opened = false;
                document.getElementById('add-to-homescreen').style.display = 'none';
            },
            closeModal() {
                document.getElementById("IOSmodal").style.display = "none";
            },
            addToHomeScreen() {
                const parsedUa = uaParser(window.navigator);

                if (this.$deferedAddToHomeScreen) {
                    this.$deferedAddToHomeScreen.prompt();
                } else if (parsedUa.os.name === "iOS") {
                    //Open IOS modal only on IOS device
                    document.getElementById("IOSmodal").style.display = "block";
                } else if (parsedUa.os.name === "Android") {
                    document.getElementById("Androidmodal").style.display = "block";
                } else if (
                    parsedUa.os.name === "Windows" &&
                    (parsedUa.browser.name === "Chrome" || parsedUa.browser.name === "Edge")
                ) {
                    alert(this.localizedString.addMessages.windows.chrome);
                } else if (
                    parsedUa.os.name === "Windows" &&
                    parsedUa.browser.name === "Firefox"
                ) {
                    alert(this.localizedString.addMessages.windows.firefox);
                } else if (parsedUa.os.name === "Mac OS") {
                    const isTouchDevice =
                    "ontouchstart" in window ||
                    navigator.maxTouchPoints > 0 ||
                    navigator.msMaxTouchPoints > 0;

                    if (isTouchDevice) {
                        //Open IOS modal only on IPad device
                        document.getElementById("IOSmodal").style.display = "block";
                    } else {
                        if (parsedUa.browser.name === "Firefox") {
                            alert(this.localizedString.addMessages.macos.firefox);
                        } else if (parsedUa.browser.name === "Chrome") {
                            alert(this.localizedString.addMessages.macos.chrome);
                        } else if (parsedUa.browser.name === "Safari") {
                            alert(this.localizedString.addMessages.macos.safari);
                        }
                    }
                } else {
                    alert(this.localizedString.addMessages.others);
                }
                this.opened = false;
            },
        },
        created() {
          if (this.getOpt("lang") && appLang[this.getOpt("lang")]) {
              this.localizedString = appLang[this.getOpt("lang")];
          }
        },
        mounted() {
          const getHomeScreenCalledCookie = Cookies.get("addToHomeScreenCalled");
          if (!isStandalone()) {
            if (getHomeScreenCalledCookie === false || getHomeScreenCalledCookie === undefined) {
              this.opened = true;
              this.setCookie();
            } else {
              // hide the add-to-homescreen
              this.close();
            }
          }

          const parsedUa = uaParser(window.navigator);
          if (parsedUa.os.name == "iOS") {
            //console.log('iOS');
            //console.log(parsedUa);
            document.getElementById("add-to-homescreen").classList.remove('add-to-homescreen-hidden');
          } else if (parsedUa.os.name == "Android") {
            //console.log('Android');
            //console.log(parsedUa);
            document.getElementById("add-to-homescreen").classList.remove('add-to-homescreen-hidden');
          } else {
            //console.log('Other: ' + parsedUa.os.name);
            //console.log(parsedUa);
            document.getElementById("add-to-homescreen").classList.add('add-to-homescreen-hidden');
            document.getElementById("add-to-homescreen").style.display = "none";
          }
        }
    };
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";

    .add-to-homescreen-container {
        z-index: 10000;
        border-top: 1px solid #e0e0e0;
        font-family: $body;
        width: 100%;
        box-sizing: border-box;
        background: white;
        position: fixed;
        bottom: 0;
        left: 0;
        padding: 16px;
        align-items: center;
        transition: all 0.5s;
        padding-bottom: 50px;
    }

    .add-to-homescreen-container.add-to-homescreen-visible {
        transform: translateY(0);
    }
    .add-to-homescreen-container.add-to-homescreen-hidden {
        transform: translateY(100%);
    }

    .close_btn {
        cursor: pointer;
        position: absolute;
        top: 15px;
        right: 15px;
        width: 20px;
        height: 20px;
        border: 0;
        background: url("../../assets/svg/x.svg");
    }

    .flex {
        display: flex;
        flex-wrap: wrap;
    }

    .col {
        flex: 1;
    }

    .icon {
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }

    .icon-container .icon {
        width: 60px;
        height: 60px;
        display: block;
        line-height: 60px;
        text-align: center;
        border-radius: 30px;
        font-size: 1.3rem;
        margin-right: 15px;
        text-transform: uppercase;
    }

    .app-title {
        font-size: 1.3rem;
        display: inline-block;
    }
    .app-content {
        font-size: 0.8rem;
        display: inline-block;
        margin-bottom: 10px;
    }

    .add-button,
    .add-button:hover,
    .add-button:visited {
        width: 100%;
        border: 0;
        outline: 0;
        font-size: 1rem;
        padding: 5px;
    }

    .close:hover,
    .close:focus {
        color: black;
        text-decoration: none;
        cursor: pointer;
    }

    /* IOS modal */
    .modal {
        display: none;
        position: fixed;
        z-index: 10000;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.4);
    }

    .modal-content {
        background-color: white;
        border-radius: 1rem;
        text-align: center;
        margin: 50% auto;
        border: 1px solid $blue--med;
        width: 85%;
        padding: 10px;
        .text {
            width: 100%;
            display: block;
            margin-bottom: 0px;
            padding-bottom: 0px;
        }
    }

    .modal-content ul {
        padding: 0;
        text-align: left;
        list-style-type: none;
    }
    .modal-content ul li {
        text-align: center;
    }
    .shareIOS {
        width: 20px;
        vertical-align: top;
        margin: 5px 0px;
    }
    .addIOS {
        width: 20px;
        vertical-align: top;
        margin: 5px 0px 15px 0px;
    }

    .modal-content .closeModal {
        color: $white !important;
        background-color: $blue--med !important;
        border: solid 0.1rem $blue--med !important;
        border-radius: 0.3rem;
        font-size: 1rem;
        margin-bottom: 14px;
    }
</style>
