<template>
    <!-- Component: organisms/contacts-tree-chart -->
    <organization-chart :agency-id="agencyId" :agency-location-id="agencyLocationId" :tree="contactsTree" :contact-location-id="contactLocationId" v-if="!loading"/>
    <preloader v-if="loading"/>
    <!-- Component: organisms/contacts-tree-chart -->
</template>

<script>
    import { onMounted, ref, toRefs, watch } from "vue";
    import RequestHandler from "@/handler/RequestHandler";
    import Preloader from "./preloader";
    import OrganizationChart from "./organization-chart";

    export default {
        name: 'ContactsTreeChart',
        components: {
            Preloader,
            OrganizationChart,
        },
        props: ['agencyId', 'contactName', 'contactLocationId', 'agencyLocationId',],
        setup(props) {
            const loading = ref(true);
            const contactsTree = ref([]);

            onMounted(() => {
                if (props.agencyId) {
                    RequestHandler.loadAgencyContactsTree(props.agencyId, props.programId).then(tree => {
                        loading.value = false;
                        contactsTree.value = tree;
                    }).catch(error => {
                        loading.value = false;
                        console.error(error);
                    });
                } else {
                    loading.value = false;
                }
            });

            return {loading, contactsTree};
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";

</style>
