<template>
    <!-- Component: molecules/info-block--twitter -->
    <infoBlock
        :requestedIcon="requestedIcon"
        :hasIcon="true"
        :hasClose="false"
        :isTweet="true"
        :color="color"
        :userName="userName"
        :handle="handle"
        :idStr="id"
        :tweetDate="updatedAtTimestamp"
        :tweetContent="text"
    >
        <div class="tweet">
            <p class="name">{{ userName }}</p>
            <a :href="'https://x.com/' + handle" class="handle" target="_blank">@{{ handle }}</a>

            <div class="timestamp">
                {{ formatDate(updatedAtTimestamp) }}
            </div>

            <p class="tweet-content" v-html="text"></p>
        </div>

    </infoBlock>
    <!-- End Component: molecules/info-block--twitter -->
</template>

<script>
    import InfoBlock from "./info-block"
    import moment from "moment";

    export default {
        name: 'InfoBlockTwitter',
        components: {
            InfoBlock
        },
        props: ['text', 'userName', 'handle', 'id', 'updatedAt', 'updatedAtTimestamp',],
        data() {
            return {
                color: "twitter",
                requestedIcon: "faTwitter"
            }
        },
        methods: {
            formatDate: function(timestamp) {
                return moment.unix(timestamp).format('MM/DD/YYYY h:mm a');
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";

    .name {
        font-weight: 600;
        display: block;
        margin-bottom: 0px !important;
    }

    .handle {
        display: block;
        margin-bottom: 10px !important;
    }

    .timestamp {
        margin-bottom: 10px;
    }
</style>
