<template>
    <!-- Component: organisms/social-media-block--twitter -->
    <social-media-block
        :type="type" :title="title" :customClass="customClass"
        :image="image" :name="name" :handle="handle"
        :date="date" :url="url" :buttonLabel="buttonLabel"
        :content="content" :postUrl="postUrl">
    </social-media-block>
    <!-- End Component: organisms/social-media-block--twitter -->
</template>

<script>
    import SocialMediaBlock from "./social-media-block"

    export default {
        name: 'SocialMediaBlockTwitter',

        components: {
            SocialMediaBlock
        },
        props: [
            'type', 'title', 'customClass',
            'image', 'name', 'handle', 'date', 'url', 'buttonLabel', 'content', 'postUrl'
        ],
        data() {
            return {}
        }

    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";

    .pb-40 {
        padding-bottom: 40px;
    }
</style>

