<template>
    <!-- Component: organisms/note-create -->
    <form action="#note-create" v-on:submit.prevent="createNote">
        <p class="note-create__title">New Note {{ objectTitle ? 'for' : '' }} {{ objectTitle }}</p>

        <div class="field">
            <label class="label" for="note-title">Title</label>
            <div class="control">
                <input class="input" type="text" placeholder="Note Title" id="note-title" v-model="createParams.title" required>
            </div>
        </div>

        <div class="field">
            <label class="label" for="note-text">Text</label>
            <div class="control">
                <textarea class="textarea" placeholder="Note Contents" id="note-text" v-model="createParams.text" required></textarea>
            </div>
        </div>

        <div class="mb-15">
            <tag-input @tag-selected="addTag($event)"></tag-input>
            <ul v-if="selectedTagsList.length">
                <li v-for="tag in selectedTagsList">
                    {{ tag.name }}
                    <button @click.prevent="removeTag(tag.id)" v-if="!objectTag || objectTag.id != tag.id">X</button>
                </li>
            </ul>
        </div>

        <div class="field">
            <label class="label" for="note-file">Files</label>
            <div class="control">
                <input id="note-file" type="file" ref="filesRef" class="button is-primary" multiple>
            </div>
        </div>

        <div class="field">
            <button class="button is-primary" type="submit" :disabled="createPending ? 'disabled' : undefined">Save Note</button>
            <preloader v-if="createPending"/>
        </div>
        <div v-if="createError">{{ createError }}</div>
    </form>
    <!-- End Component: organisms/note-create -->
</template>

<script>
    import { reactive, ref } from 'vue';
    import RequestHandler from "@/handler/RequestHandler";
    import { useStore } from "vuex";
    import { contactFullName, getContentId, getContentTitle } from "@/helpers/objects";
    import TagInput from "./tag-input";
    import Preloader from "./preloader";

    export default {
        name: 'note-create',
        components: {Preloader, TagInput},
        props: [
            'contact',
            'position',
            'agency',
            'program',
            'programcategory',
            'vendor',
            'contract',
            'procurement',
            'document',
        ],
        emits: ['created'],
        setup(props, {emit}) {
            const store = useStore();
            const contentId = getContentId(props.contact, props.position, props.agency, props.program, props.programcategory, props.vendor, props.contract, props.procurement, props.document);
            const objectTitle = getContentTitle(props.contact, props.position, props.agency, props.program, props.programcategory, props.vendor, props.contract, props.procurement, props.document);
            let objectTag = contentId ? {
                id: contentId,
                name: objectTitle
            } : null;

            const filesRef = ref(null);
            const selectedTagsList = ref(objectTag ? [objectTag] : []);
            const createPending = ref(false);
            const createError = ref(null);

            const createParams = reactive({
                title: '',
                text: '',
            });

            const createNote = () => {
                createPending.value = true;
                RequestHandler.createNote({
                    title: createParams.title,
                    text: createParams.text,
                    relatedIds: selectedTagsList.value.map(item => item.id),
                    files: filesRef.value.files,
                }, store.getters.csrf).then((response) => {
                    createParams.title = '';
                    createParams.text = '';
                    selectedTagsList.value = objectTag ? [objectTag] : [];

                    emit('created', response);
                    createPending.value = false;
                }).catch((error) => {
                    console.error(error);
                    createError.value = error.message || error;
                    createPending.value = false;
                });
            };

            const addTag = (tag) => {
                for (let i = 0; i < selectedTagsList.value.length; i++) {
                    if (selectedTagsList.value[i].id === tag.contentId) {
                        return; // already listed
                    }
                }
                selectedTagsList.value.push({
                    name: tag.class === 'ContactRef'
                        ? (contactFullName(tag) + (tag.agency ? ' (' + tag.agency.name + ')' : ''))
                        : tag.name,
                    id: tag.contentId
                });
            };

            const removeTag = (id) => {
                selectedTagsList.value = selectedTagsList.value.filter(item => item.id !== id);
            }

            return {
                createNote, createParams, createError, filesRef, addTag, removeTag, objectTitle,
                selectedTagsList, createPending, objectTag,
            };
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";

    @include block('note-create') {
        @include element('title') {
            font-size: 1.5rem;
            font-weight: 600;
            font-family: $header;
            margin-top: 15px
        }
    }
    button.is-primary {
        font-weight: 600;
        font-family: $header;
        margin-top: 15px;
    }
</style>
