<template>
    <!-- Component: organisms/navigation -->
    <div id="primary-navigation__container" class="primary-navigation__container">
        <div class="primary-navigation" id="primary-navigation">
            <div class="container underline-out">

                <a href="/dashboard" id="logo" class="logo">
                    <img src="@/assets/svg/MGG-logo-horiz-white.png" alt="MyGovGuide" class="logo-image"/>
                </a>

                <nav class="navigation" role="navigation" aria-label="main navigation">

                    <div class="navigation-menu" :class="{'is-active': isOpened}">
                        <div class="navigation-start">
                            <a class="navigation-item underline-item"
                               v-for="navItem in navItems"
                               :href="navItem.url"
                               :class="{'is-active': isActive(navItem)}"
                               @click="isOpened = false"
                            >
                                <span class="hover" @click="clickNavItem">
                                    <font-awesome-icon :icon="whichIcon(navItem.title)" :aria-label="whichLabel(navItem.title) + ' icon'"></font-awesome-icon>
                                    <p>{{ navItem.title }}</p>
                                </span>
                            </a>
                            <a href="/gov-guidance" class="navigation-item underline-item" @click="clickNavItem">
                                <span class="hover">
                                    <font-awesome-icon :icon="ggIcon" aria-label="GovGuidance icon"></font-awesome-icon>
                                    <p>GovGuidance</p>
                                </span>
                            </a>
                        </div>
                    </div>

                    <div id="enterprise-search__container" class="search-trigger__container" v-if="user">
                        <userTools/>
                        <search-trigger @click="clickSearch" :customClass="'enterprise-search'"></search-trigger>
                    </div>

                </nav>

                <div id="search-container" class="search-container">
                    <div class="filter-wrapper">
                        <search-block
                            title="What can we help you find?"
                            placeholder="Search agencies, job titles, people and more"
                            type="autocomplete"
                            ref="autofocusinput"
                        />
                    </div>
                </div>

            </div>
        </div>
    </div>
    <!-- End Component: organisms/navigation -->
</template>

<script>
    import { useStore } from "vuex";
    import { computed, ref } from "vue";
    import { useRouter } from "vue-router";
    import useUser from "@/helpers/useUser";
    import SearchTrigger from "./search-trigger.vue";
    import SearchBlock from "./search-block";
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
    import { faTachometerAltFast, faBuilding, faChartLine, faWallet} from '@fortawesome/pro-regular-svg-icons'
    import { faUser, faMapSigns } from '@fortawesome/pro-solid-svg-icons'
    import UserTools from "./user-tools.vue";

    export default {
        name: 'Navigation',
        components: {
            UserTools,
            SearchTrigger,
            SearchBlock,
            FontAwesomeIcon
        },
        props: {
            agencies: Array,
            searchStatus: String
        },
        methods: {
            whichIcon(title) {
                if (title == 'Dashboard') {
                    return faTachometerAltFast
                } else if (title == 'Agencies') {
                    return faBuilding
                } else if (title == 'Budgets') {
                    return faChartLine
                } else if (title == 'Contacts') {
                    return faUser
                } else if (title == 'Procurements') {
                    return faWallet
                }
            },
            whichLabel(title) {
                if (title == 'Dashboard') {
                    return "Dashboard"
                } else if (title == 'Agencies') {
                    return "Agencies"
                } else if (title == 'Budgets') {
                    return "Budgets"
                } else if (title == 'Contacts') {
                    return "Contacts"
                } else if (title == 'Procurements') {
                    return "Procurements"
                }
            },
            clickNavItem() {
                let search = document.getElementById('search-container');
                if (search.classList.contains('is-active')) {
                    search.classList.remove('is-active');
                }
            },
            clickSearch() {
                let element = document.getElementById('search-container');
                if (element.classList.contains('is-active')) {
                    element.classList.remove('is-active');
                } else {
                    element.classList.add('is-active');
                }
                this.$refs.autofocusinput.focusInput()
            },
            isActive(navItem) {
                if (this.$route.fullPath.includes('/budget/') && navItem.url === '/browse-budgets' ) {
                    return true;
                } else {
                    return this.$route.fullPath.startsWith(navItem.url) && !this.$route.fullPath.includes('/budget/') ;
                }
            },
            handleScroll (event) {
                this.scrollPosition = window.scrollY
                var navElm = document.getElementById('primary-navigation__container');
                if (this.scrollPosition > 58) {
                    navElm.classList.add("sticky");
                } else {
                    navElm.classList.remove("sticky");
                }
            }
        },
        mounted() {
            window.addEventListener('scroll', this.handleScroll);

            window.onscroll = function() {
                var scrollLimit = 60;
                if (window.scrollY >= scrollLimit) {
                    document.getElementById('logo').classList.add('is-active');
                    document.getElementById('primary-navigation').classList.add('is-tall');
                } else {
                    document.getElementById('logo').classList.remove('is-active');
                    document.getElementById('primary-navigation').classList.remove('is-tall');
                }
            };
        },
        watch: {
            $route: function (toRoute) {
                let element = document.getElementById('search-container');
                if (element.classList.contains('is-active')) {
                    element.classList.remove('is-active');
                }
            }
        },
        setup() {
            const { user } = useUser();
            const router = useRouter();
            const isOpened = ref(false);
            const toggleNav = () => isOpened.value = !isOpened.value;
            const navItems = [
                {url: '/dashboard', title: 'Dashboard'},
                {url: '/browse-agencies', title: 'Agencies'},
                {url: '/browse-budgets', title: 'Budgets'},
                {url: '/agency-contacts', title: 'Contacts'},
                {url: '/browse-procurements', title: 'Procurements'},
            ];

            const ggIcon = faMapSigns;

            return {isOpened, toggleNav, navItems, user, ggIcon};

        },
    };
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/mixins/_bemify.scss";
    @import "../../assets/scss/animations/_underline-out.scss";

    @include block('primary-navigation') {
        background-color: $blue--med;
        .underline-out {
            z-index: 9; // keeps this behind the preloader
        }
        @include element('container') {
            &.sticky {
                position: sticky;
                top: 0px;
                z-index: 9999;
            }
        }
        .navigation {
            max-height: 70px;
            min-height: 70px;
            background-color: $blue--med;
            @media screen and (max-width: 991px) {
                max-height: 60px;
                min-height: 60px;
            }
            .navigation-brand {
                float: left;
            }
        }
        .navigation-menu {
           // text-align: center;
            position: relative;
            top: 25px;
            display: inline-block;
            margin: 0 auto;
            display: block;
            @media screen and (max-width: 991px) {
                top: 15px;
            }
            .navigation-item {
                padding: 10px 10px 15px 10px;
            }
        }
        .navigation-link, .navigation-item {
            color: $white;
            text-decoration: none;
            font-weight: 600;
            background-color: $blue--med;

            &:hover, &:active, &:focus {
                text-decoration: none;
                background-color: $blue--med;
                color: $white;
            }

            &:not(.is-arrowless)::after {
                border-color: $white;
            }
        }
        .navigation-item {
            @media screen and (max-width: 991px) {
                padding: .5rem 1rem !important;
            }
            &.is-active .hover {
                color: $white;
            }
            &:focus {
                background-color: $blue--dark;
            }
            &.has-dropdown:hover .navigation-link {
                background-color: $blue--med;
            }
            span {
                svg {
                    @media screen and (max-width: 991px) {
                        font-size: 25px;
                    }
                }
                p {
                    display: inline-block;
                    margin-left: 5px;
                    color: #fff;
                }
            }
        }
        .navigation-dropdown {
            background-color: $blue--med;
            border-top: 2px solid darken($blue--med, 5%);
        }
        .navigation-divider {
            background-color: darken($blue--med, 5%);
        }
        .navigation-start {
            margin: 0 auto;
        }
        .container {
            margin-bottom: 0px !important;
        }

        .search-container {
            display: none;
            width: 100%;
            &.is-active {
                display: flow-root;
            }
        }
    }

    .search-trigger__container {
        display: block;
        position: relative;
        top: -30px;
        @media screen and (max-width: 991px) {
            top: -25px;
        }
    }

    #logo {
        display: none;
        position: fixed;
        top: 17px;
        left: 10px;
        &.is-active {
            display: block;
        }
        img {
            max-width: 180px;
        }
        @media screen and (max-width: 1215px) {
            img.logo-image {
                left: 0px;
                top: -7px;
            }
        }
    }

    @media screen and (max-width: 1100px) {
        .primary-navigation.is-tall {
            height: 140px;
            nav.navigation {
                position: relative;
                top: 70px;
            }
        }
        #logo {
            position: absolute;
            top: 20px;
            width: 100%;
            text-align: center;
            img {
                max-width: 240px;
            }
        }
    }

    @media screen and (max-width: 550px) {
        #logo {
            top: 15px;
        }
    }
</style>
