<template>
    <!-- Component: molecules/breadcrumbs -->
    <nav class="breadcrumb" aria-label="breadcrumbs">
        <ul>
            <li class="dashboard-link">
                <router-link :to="{'name': 'dashboard'}"><span class="mobile-only"><img src="@/assets/svg/breadcrumb-separator.svg" alt="arrow icon" class="separator"/> Back to Dashboard</span><span class="desktop-only">Dashboard</span></router-link>
            </li>

            <li class="dashboard-link" v-if="isAgency(items) === 'agency'">
                <img src="@/assets/svg/breadcrumb-separator.svg" alt="arrow icon" class="separator"/>
                <router-link :to="{'name': 'browse-agencies'}">Agencies</router-link>
            </li>

            <li v-for="(item, index) in items" :class="{'is-active': item.isActive}">

                <span v-if="(isAgency(items) === 'agency') && (index === 0) && (acronym(toTitleCase(item.title)) != false)">
                    <img src="@/assets/svg/breadcrumb-separator.svg" alt="arrow icon" class="separator"/>
                    <a v-if="item.url" :href="item.url" aria-current="page">{{ acronym(toTitleCase(item.title)) }}</a>
                    <p v-else>{{ acronym(toTitleCase(item.title)) }}</p>
                </span>

                <span v-else>
                    <img src="@/assets/svg/breadcrumb-separator.svg" alt="arrow icon" class="separator"/>
                    <a v-if="item.url" :href="item.url" aria-current="page">{{ toTitleCase(item.title) }}</a>
                    <p v-else>{{ toTitleCase(item.title) }}</p>
                </span>
            </li>
        </ul>
    </nav>

    <!-- End Component: molecules/breadcrumbs -->
</template>

<script>
    import { toTitleCase } from "@/helpers/filter";

    export default {
        name: 'Breadcrumbs',
        components: {},
        props: {
            items: Array
        },
        methods: {
            titleize: function(item) {
                return item.toLowerCase().replace(/(?:^|\s|-)\S/g, x => x.toUpperCase());
            },
            isAgency: function(items) {
                switch (items[0].title) {
                    case  'Agency For Health Care Administration':
                    case  'Agency For Persons With Disabilities':
                    case  'Commission On Offender Review':
                    case  'Department of Agriculture and Consumer Services':
                    case  'Department of Business and Professional Regulation':
                    case  'Department of Children and Families':
                    case  'Department of Citrus':
                    case  'Department of Corrections':
                    case  'Department of Economic Opportunity':
                    case  'Department of Education':
                    case  'Department of Elder Affairs':
                    case  'Department of Environmental Protection':
                    case  'Department of Financial Services':
                    case  'Department of Health':
                    case  'Department of Highway Safety and Motor Vehicles':
                    case  'Department of Juvenile Justice':
                    case  'Department of Law Enforcement':
                    case  'Department of Lottery':
                    case  'Department of Management Services':
                    case  'Department of Military Affairs':
                    case  'Department of Revenue':
                    case  'Department of State':
                    case  'Department of Transportation':
                    case  "Department Of Veterans' Affairs":
                    case  'Division of Emergency Management':
                    case  'Executive Office of the Governor':
                    case  'Florida Fish and Wildlife Conservation Commission':
                    case  'Justice Administration Commission':
                    case  'Office of the Attorney General':
                    case  'Public Service Commission':
                    case  'State Court System':
                        return 'agency';
                    default:
                        return false;
                }
            },
            acronym: function(title) {
                switch (title) {
                    case  'Agency For Health Care Administration':
                        return 'AHCA';
                    case  'Agency For Persons With Disabilities':
                        return 'APD';
                    case  'Commission On Offender Review':
                        return 'FCOR';
                    case  'Department Of Agriculture And Consumer Services':
                        return 'DACS';
                    case  'Department Of Business And Professional Regulation':
                        return 'DBPR';
                    case  'Department Of Children And Families':
                        return 'DCF';
                    case  'Department Of Citrus':
                        return 'FCC';
                    case  'Department Of Corrections':
                        return 'DOC';
                    case  'Department Of Economic Opportunity':
                        return 'DEO';
                    case  'Department Of Education':
                        return 'DOE';
                    case  'Department Of Elder Affairs':
                        return 'DEA';
                    case  'Department Of Environmental Protection':
                        return 'DEP';
                    case  'Department Of Financial Services':
                        return 'DFS';
                    case  'Department Of Health':
                        return 'DOH';
                    case  'Department Of Highway Safety And Motor Vehicles':
                        return 'DHSMV';
                    case  'Department Of Juvenile Justice':
                        return 'DJJ';
                    case  'Department Of Law Enforcement':
                        return 'FDLE';
                    case  'Department Of Lottery':
                        return 'DOL';
                    case  'Department Of Management Services':
                        return 'DMS';
                    case  'Department Of Military Affairs':
                        return 'DMA';
                    case  'Department Of Revenue':
                        return 'DOR';
                    case  'Department Of State':
                        return 'DOS';
                    case  'Department Of Transportation':
                        return 'DOT';
                    case  "Department Of Veterans' Affairs":
                        return 'FDVA';
                    case  'Division Of Emergency Management':
                        return 'DEM';
                    case  'Executive Office Of The Governor':
                        return 'EOG';
                    case  'Florida Fish And Wildlife Conservation Commission':
                        return 'FWC';
                    case  'Justice Administration Commission':
                        return 'JAC';
                    case  'Office Of The Attorney General':
                        return 'OAG';
                    case  'Public Service Commission':
                        return 'PSC';
                    case  'State Court System':
                        return 'OSCA';
                    default:
                        return false;
                }
            }
        },
        setup() {
            return {
                toTitleCase,
            }
        },
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";

    // Override Bulma breadcrumb slash (/)
    .breadcrumb li + li::before {
        content: none !important;
    }

    @include block('breadcrumb') {
        white-space: initial !important;
        @media screen and (max-width: 1021px) {
            margin: 0 25px;
        }
        a {
            color: $blue--med;
            transition: $animate--long;
            &:hover, &:active, &:focus {
                color: $blue--dark;
            }
        }
        p {
            align-items: center;
            display: flex;
            justify-content: center;
            padding: 0 0.75em;
            margin-bottom: 0px;
        }
        li.is-active {
            text-decoration: none;
        }
        li.dashboard-link:nth-of-type(2) {
            .separator {
                top: 0px;
            }
        }
        @media screen and (max-width: 768px) {
            margin: 0px;
            .dashboard-link {
                padding: 0 .75em;
            }
            ul {
                display: inline-block;
                li {
                    display: none;
                    &:nth-last-child(2){
                        display: block;
                        .separator {
                            transform: rotate(180deg);
                            top: 10px;
                        }
                        a {
                            position: relative;
                            top: -10px;
                        }
                    }
                    a {
                        float: left;
                    }
                }
            }
        }
    }
    .separator {
        width: 15px;
        float: left;
        position: relative;
        top: 7px;
    }

    .mobile-only {
        display: none;
        .separator {
            display: inline-block;
            transform: rotate(180deg);
            top: 2px;
            margin-right: 10px;
        }
        @media screen and (max-width: 768px) {
            display: inline-block;
        }
    }
    .desktop-only {
        display: inline-block;
        @media screen and (max-width: 768px) {
            display: none;
        }
    }
</style>
