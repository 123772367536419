<template>
    <!-- Component: atoms/search-trigger -->
    <button class="search-trigger" :class="customClass" aria-label="Search">
        <font-awesome-icon :icon="icon" aria-label="Search"></font-awesome-icon>
        <span class="aria-hidden">Search</span>
    </button>
    <!-- End Component: atoms/search-trigger -->
</template>

<script>
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
    import { faSearch } from '@fortawesome/pro-solid-svg-icons'

    export default {
        name: 'SearchTrigger',
        props: ['customClass'],
        components: {
            FontAwesomeIcon
        },
        setup() {
            return {
                icon: faSearch
            }
        }
    }
</script>

<style lang="scss">
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";

    .aria-hidden {
        display: none;
        font-size: 0;
    }

    @include block('search-trigger') {
        cursor: pointer;
        background-color: transparent;
        text-align: center;
        width: 52px;
        height: 52px;
        margin: 0 auto;
        border: none;
        transition: $animate--long;
        float: right;
        z-index: 2;
        color: #fff;
        font-size: 24px;
        &:hover, &:active, &:focus {
            background-color: $blue--med !important;
        }
        .fa-search {
            color: $white;
            display: inline-block;
            font-size: 21px;
        }
    }
</style>
