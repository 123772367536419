export default new class LocalStorageHandler {
  constructor() {
    this.localStorage = typeof window !== 'undefined' ? window.localStorage : null;
  }

  remove(key) {
  	if (this.localStorage) {
      this.localStorage.removeItem(key);
	  }
  }

  set(key, value) {
	  if (!this.localStorage) {
		  return false;
	  }
    return this.localStorage.setItem(key, LocalStorageHandler._transform(value));
  }

  get(key) {
	  if (!this.localStorage) {
		  return null;
	  }
    return LocalStorageHandler._reverseTransform(this.localStorage.getItem(key));
  }

  static _transform(data) {
    return JSON.stringify(data);
  }

  static _reverseTransform(data) {
    return JSON.parse(data);
  }
}