<template>
    <!-- Page: pages/info-block-tests -->
    <div>
        <div class="container">
            <h1 style="floar:left;">Info Box Tests</h1>
        </div>

        <hr/>

        <div class="container">
            <info-block-twitter :tweet="tweet"/>
            <info-block-leadership :agency="leadershipAgency" :role="leadershipRole" :incomingContact="leadershipIC" :outgoingContact="leadershipOC"/>
            <info-block-budget :agency="budgetAgency" :role="budgetRole" :changeType="increase" :changeAmount="changeAmount"/>
            <info-block-info/>
        </div>
    </div>
    <!-- End Page: pages/info-block-tests -->
</template>

<script>
    import InfoBlockBudget from "./info-block--budget.vue";
    import InfoBlockInfo from "./info-block--info.vue";
    import InfoBlockLeadership from "./info-block--leadership.vue";
    import InfoBlockTwitter from "./info-block--twitter.vue";


    export default {
        components: {
            InfoBlockLeadership,
            InfoBlockTwitter,
            InfoBlockBudget,
            InfoBlockInfo
        },
        data() {
            return {
                tweet: "Tweet content here",

                budgetAgency: "Agency Here",
                budgetRole: "Role Here",
                increase: "increase",
                changeAmount: "34%",

                leadershipRole: "Role Here",
                leadershipAgency: "Agency Here",
                leadershipIC: "Incoming Contact",
                leadershipOC: "Outgoing Contact"
            }
        }
    }
</script>
