<template>
    <!-- Component: molecules/contact-line -->
    <div class="contact-line">

        <article class="media">
            <div class="media-left">
                <figure class="image">
                    <img :src="imagePath" :alt="name" v-if="imagePath">
                    <img src="@/assets/img/user-default.png" :alt="name" v-else>
                </figure>
                <a :href="url" class="contact-line__business-card">
                    <img src="@/assets/svg/contact.svg" alt="Contact Info" />
                </a>
            </div>
            <div class="media-content">
                <div class="content">
                    <span class="contact-line__title" :class="date ? 'with-date' : ''">
                        <a :href="url">{{ toTitleCase(name) }}</a>
                        <span class="contact-is-leader" v-if="leadership === true">
                            <leadership-marker/>
                        </span>
                    </span>

                    <span class="contact-line__date" v-if="date">
                        <p>Left Previous Position: {{ date }}</p>
                    </span>

                    <p v-if="typeof salary !== 'undefined' && salary">Salary: ${{ priceFormat(salary) }}</p>

                    <p v-if="updated == true">Updated Position:</p>
                    <tag-agency v-if="agency && agencyUrl" :tagText="agency" :tagUrl="agencyUrl"/>
                    <br v-if="agency && agencyUrl"/>
                    <tag-role v-if="role && roleUrl" :tagText="role" :tagUrl="roleUrl"/>
                    <tag v-else :tagText="'This contact has left this state government entity.'" :customClass="'disabled-cursor'" :type="'disabled'"/>
                </div>
            </div>
        </article>

    </div>
    <!-- End Component: molecules/contact-line -->
</template>

<script>
    import TagAgency from "./tag--agency.vue";
    import TagRole from "./tag--role.vue";
    import Tag from "./tag.vue";
    import LeadershipMarker from "./leadership-marker";
    import { priceFormat, toTitleCase } from "@/helpers/filter";

    export default {
        name: 'ContactLine',
        components: {
            TagAgency,
            TagRole,
            Tag,
            LeadershipMarker
        },
        props: ['name', 'imagePath', 'url', 'agency', 'role', 'leadership', 'agencyUrl', 'roleUrl', 'date', 'updated', 'salary'],
        data() {
            return {
                icon: "faAddressCard",
            }
        },
        setup(props) {
            return {
                toTitleCase,
                priceFormat
            };
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";

    @include block('contact-line') {
        margin-bottom: 15px;
        background-color: $white;
        border-radius: .25rem;
        padding: 15px;
        box-shadow: $box-shadow--light;
        .image img {
            width: 100px;
        }
        @include element('title') {
            display: block;
            margin-bottom: 20px;
            a {
                display: inline-block;
                font-weight: 600;
                font-size: 21px;
                color: $blue--dark;
                margin-right: 5px;
            }
            .contact-is-leader {
                display: inline-block;
            }
            &.with-date {
                margin-bottom: 10px;
            }
        }
        @include element('date') {
            p {
                margin-bottom: 0px;
            }
        }
        .content .gov-tag {
            margin-bottom: 10px;
            display: inline-block;
        }
        .media-content {
            padding-top: 0px;
        }
        @include element('business-card') {
            font-size: 44px;
        }
    }
</style>
