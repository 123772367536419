<template>
    <!-- Component: molecules/org-chart-item -->
    <img v-if="imageUrl" :src="imageUrl" :alt="toTitleCase(fullNameText) + '\'s Profile Icon'" :class="'org-chart__item__image item__image_' + random">
    <img v-else-if="name == 'State of Florida'" src="@/assets/svg/profile-default--state.svg" alt="State of Florida Profile Icon" class="org-chart__item__image">
<!-- #25ekhyx: Org Chart and Contact Avatars
    <img v-else-if="type == 'Executive'" src="@/assets/svg/profile-default--executive.svg" alt="Generic Executive Profile Icon" class="org-chart__item__image">
-->
    <img v-else-if="type == 'Executive'" src="@/assets/svg/profile-default.svg" alt="Generic Executive Profile Icon" class="org-chart__item__image">
    <img v-else-if="type == 'Cabinet'" src="@/assets/svg/profile-default--cabinet.svg" alt="Generic Cabinet Profile Icon" class="org-chart__item__image">
    <img v-else-if="type == 'Legislative'" src="@/assets/svg/profile-default--legislative.svg" alt="Generic Legislative Profile Icon" class="org-chart__item__image">
    <img v-else-if="type == 'Position'" src="@/assets/svg/profile-default.svg" alt="Generic Position Profile Icon" class="org-chart__item__image">
    <img v-else-if="type == 'Entity'" src="@/assets/svg/profile-default--entity.svg" alt="Generic Entity Profile Icon" class="org-chart__item__image">
    <img v-else-if="!isStateOrgChart || type == 'contact' || type == 'position'" src="@/assets/svg/profile-default.svg" alt="Generic Profile Icon" class="org-chart__item__image">
    <img v-else src="@/assets/svg/profile-default--gov.svg" alt="Generic Profile Icon" class="org-chart__item__image">

    <div :class="[
           'org-chart__item__container',
            highlightItem ? 'item-highlighted' : '',
            type ? 'org-chart__item__container-type-' + type : '',
        ]"
    >
        <!--button class="org-chart__item__button"><div class="dot"></div></button-->

        <Tooltip v-if="vacant || type == 'position'" :tooltipText="'Some position vacancies may represent an employee who is exempt from public disclosure of personal information.'">
            <p class="org-chart__item__vacant">
                Vacant or Exempt
                <img src="@/assets/svg/circle-info-solid__red.svg" alt="Information about vacant positions." class="info-icon">
            </p>
        </Tooltip>
        <p :class="(vacant || type == 'position') ? 'org-chart__item__title below-vacant' : 'org-chart__item__name'" v-if="fullNameText">
            <a class="org-chart__item__name__link" v-if="nameHref && displayTitle && vacant" :href="nameHref">{{ displayTitle ? toTitleCase(displayTitle) : '' }}</a>
            <a class="org-chart__item__name__link" v-else-if="nameHref" :href="nameHref">{{ fullNameText ? toTitleCase(fullNameText) : '' }}</a>
            <span v-else>{{ fullNameText ? toTitleCase(fullNameText) : '' }}</span>
        </p>
        <p class="org-chart__item__title" v-if="contactTitle">
            <a class="org-chart__item__subtitle__link" v-if="subtitleHref" :href="subtitleHref">{{ contactTitle ? toTitleCase(contactTitle, 'Dep') : '' }}</a>
            <span v-else>{{ contactTitle ? toTitleCase(contactTitle, 'Dep') : '' }}</span>
        </p>
        <p class="org-chart__item__title" v-else-if="displayTitle && !vacant">
            <a class="org-chart__item__subtitle__link" v-if="subtitleHref" :href="subtitleHref">{{ displayTitle ? toTitleCase(displayTitle, 'Dep') : '' }}</a>
            <span v-else>{{ displayTitle ? toTitleCase(displayTitle, 'Dep') : '' }}</span>
        </p>
        <p class="org-chart__item__title" v-else-if="title">
            <a class="org-chart__item__subtitle__link" v-if="subtitleHref" :href="subtitleHref">{{ title ? toTitleCase(title, 'Dep') : '' }}</a>
            <span v-else>{{ title ? toTitleCase(title, 'Dep') : '' }}</span>
        </p>
        <p class="org-chart__item__title org-chart__item__title--empty" v-else></p>
        <div :class="'org-chart__item__downline ' + (downlineNumber == 0 ? 'zero-downline' : '')" v-on:click="clickDownline">{{ downlineNumber }}<img src="@/assets/svg/caret--white.svg" alt="Show More" class="org-chart__item__downline__caret"></div>
    </div>
    <!-- End Component: molecules/org-chart-item -->
</template>

<script>
    import { contactFullName } from "@/helpers/objects";
    import { computed } from "vue";
    import { toTitleCase } from "@/helpers/filter";
    import {removeCanvases, resizeImage} from "@/helpers/contact";
    import Tooltip from "./tooltip";

    export default {
        name: 'OrgChartItem',
        components: {
            Tooltip
        },
        props: {
            name: String,
            title: String,
            displayTitle: String,
            downlineNumber: Number,
            data: Object,
            level: Number,
            group: Number,
            index: Number,
            nameHref: String,
            titleHref: String,
            imageUrl: String,
            imageWidth: Number,
            imageHeight: Number,
            boundingBox: Object,
            vacant: Boolean,
            nameObject: Object,
            highlightItem: Boolean,
            isStateOrgChart: Boolean,
            type: String,
            locationId: Number,
            subtitleHref: String,
        },
        mounted() {
            if (this.imageUrl && this.boundingBox)
            {
                let img = {
                    'src': encodeURI(this.imageUrl),
                    'height': this.imageHeight,
                    'width': this.imageWidth
                };

                resizeImage(img, {...this.boundingBox}, 'item__image_'+ this.random);
            }
        },
        setup(props) {
            const fullNameText = computed(() => props.vacant && props.displayTitle ? props.displayTitle ?? props.name : props.nameObject && props.nameObject.firstName ? contactFullName(props.nameObject) : props.name);
            const contactTitle = computed(() => props.nameObject && props.nameObject.title ? props.nameObject.title : '');
            const random = Math.floor(Math.random() * 1000);

            return {
                fullNameText,
                contactTitle,
                toTitleCase,
                random
            };
        },
        methods: {
            clickDownline() {
                if (this.downlineNumber === 0) {
                    return;
                }
                this.$emit('clickdownline', this.level, this.group, this.locationId, this.index);
            }
        },
        // the button needs work - what is the scope?
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";

    @include block('org-chart') {
        @include element('item') {
            @include element('container') {
                border: 1px solid $grey--dark;
                border-radius: 4px;
                margin: 35px auto 0 auto;
                width: 220px;
                text-align: center;
                background-color: $grey--light;
                -webkit-box-shadow: 0px 0px 15px -3px rgba(0,0,0,0.25);
                box-shadow: 0px 0px 15px -3px rgba(0,0,0,0.25);
                min-height: 133px; // helps keep block size consistent
                &.item-highlighted {
                    background-color: #effaf5;
                }
            }
            @include element('image') {
                margin: 0 auto;
                display: block;
                top: 52px;
                position: relative;
                max-width: 75px;
                max-height: 75px;
                width: 75px;
                height: 75px;
                object-fit: cover;
                border-radius: 100px;
            }
            @include element('downline') {
                background-color: $blue--dark;
                color: $white;
                border-radius: 10px;
                padding: 5px 10px;
                font-size: 14px;
                position: relative;
                top: 12px;
                max-width: 80px;
                margin: 0 auto;
                cursor: pointer;
                transition: all 0.2s ease-in-out;
                &:hover {
                    background-color: $blue--med;
                }
                @include element('caret') {
                    position: relative;
                    top: 4px;
                    padding-left: 5px;
                    transition: all 0.25 ease-in-out;
                    .is-opened {
                        transform: rotate(180deg);
                    }
                }
            }
            @include element('name') {
                margin-bottom: 0px;
                margin-top: 25px;
                font-weight: bold;
                &.below-vacant {
                    margin-top: 0px;
                }
                @include element('link') {
                    color: $blue--dark;
                    font-weight: bold;
                    &:hover, &:focus {
                        color: $blue--med;
                    }
                }
            }
            @include element('vacant') {
                margin-bottom: 0px;
                margin-top: 25px;
            }
            @include element('title') {
                margin-bottom: 0px;
                min-height: 50px; // keeps short titles from making odd block sizes
                padding-left: 5px;
                padding-right: 5px;
                @include modifier('empty') {
                    min-height: 50px !important; // keeps short titles from making odd block sizes
                }
            }
            @include element('subtitle') {}
            @include element('button') {
                float: right;
                background: none;
                border: none;
                width: 50px;
                height: 15px;
                cursor: pointer;
            }
        }
    }

    .zero-downline {
        background-color: $grey--dark;
        display: none;
    }

    $size: 25px;
    .dot {
        display: inline-block;
//        position: relative;
//        top: 10px;
//        left: 90%;
        transform: translateX(-50%);
        width: math.div($size, 5);
        height: math.div($size, 5);
        background: $blue--dark;
        border-radius: 50%;
        transition: all 0.35s ease;
        backface-visibility: hidden;
        &::before, &::after {
            display: block;
            content: '';
            position: absolute;
            bottom: 0;
            background: $blue--dark;
            width: math.div($size, 5);
            height: math.div($size, 5);
            border-radius: 50%;
            transition: all 0.35s ease;

        }
        &::before {
            left: -((math.div($size, 5)) * 2);
        }
        &::after {
            right: -((math.div($size, 5)) * 2);
        }
    }

    .org-chart__item__container-type-Root {
        // leave root color alone
    }
    .org-chart__item__container-type-Executive {
        background-color: rgba(51, 116, 170, 0.075);
    }
    .org-chart__item__container-type-Cabinet {
        background-color: #effaf5;
    }
    .org-chart__item__container-type-Legislative {
        background-color: #fffaeb;
    }
    .org-chart__item__container-type-Position,
    .org-chart__item__container-type-position,
    .org-chart__item__container-type-contact {
        background-color: #feecf0;
    }
    .org-chart__item__container-type-Entity {
        background-color: #f2ecfe;
    }

    .below-vacant + .org-chart__item__title--empty { // this prevents an item from getting too much height
        display: none;
    }
    .info-icon {
        max-width: 20px;
        position: relative;
        top: 4px;
    }
</style>
