<template>
    <!-- Component: organisms/agency-budgets-history-chart -->
    <div ref="chartEl" class="chart-container" v-show="!loading"></div>
    <preloader v-if="loading"/>
    <!-- Component: organisms/agency-budgets-history-chart -->
</template>

<script>
    import { onMounted, ref } from "vue";
    import RequestHandler from "@/handler/RequestHandler";
    import { CHART_COLORS } from "@/constants";
    import Preloader from "./preloader";

    export default {
        name: 'AgencyBudgetsHistoryChart',
        components: {Preloader},
        props: ['agencyId', 'programId'],
        setup(props) {
            const loading = ref(true);
            const chartEl = ref(null);

            const renderGraph = (records) => {
                records.forEach(record => {
                    record.dateObj = new Date(record['@timestamp']);
                    record.left = record.appropriation_amount - record.disbursement_amount;
                });

                const budgetsGroupedObject = records.reduce((grouped, budget) => {
                    grouped[budget.cms_content_id] = grouped[budget.cms_content_id] || {
                        budgets: [],
                        name: budget.name
                    };
                    grouped[budget.cms_content_id].budgets.push(budget);

                    return grouped;
                }, {});
                const budgetsGroupedArray = Object.keys(budgetsGroupedObject).map(item => budgetsGroupedObject[item]);
                budgetsGroupedArray.forEach((item) => {
                    for (let i = 0; i < item.budgets.length; i++) {
                        if (item.budgets[i].category) {
                            if (budgetsGroupedArray.filter(fiterItem => fiterItem.name === item.name).length > 1) {
                                // same name found, add category
                                item.nameWithCategory = item.name + ' (' + item.budgets[i].category + ')';
                            }
                            return;
                        }
                    }
                });

                import('d3')
                    .then(({select, scaleTime, scalePow, extent, axisLeft, axisBottom, line, format}) => {
                        const width = 960;
                        const height = 300;
                        const margin = 0;
                        const padding = 0;
                        const adj = 50;
                        const priceFormat = format('.2s');

                        const container = select(chartEl.value);
                        const svg = container.append("svg")
                            .attr("preserveAspectRatio", "xMinYMin meet")
                            .attr("viewBox", "-" + adj + " -" + adj + " " + (width + adj * 3) + " " + (height + adj * 3))
                            .style("padding", padding)
                            .style("margin", margin)
                            .classed("svg-content", true);

                        const xScale = scaleTime().range([0, width]);
                        const yScale = scalePow().exponent(0.08).range([height, 0]);
                        xScale.domain(extent(records, d => d.dateObj));
                        yScale.domain(extent(records, d => d.left));

                        const yAxis = axisLeft().scale(yScale)
                            .tickFormat((num) => '$' + priceFormat(num));
                        const xAxis = axisBottom().scale(xScale);

                        svg.append("g").attr("class", "axis").attr("transform", "translate(0," + height + ")").call(xAxis);
                        svg.append("g").attr("class", "axis").call(yAxis);

                        const tooltip = container.append("div").attr("class", "chart-tooltip").style('display', 'none');
                        const tooltipPad = padding + margin + 40;
                        const tooltipHide = () => tooltip.style('display', 'none');
                        const tooltipShow = (budget, topPad, leftPad) => {
                            tooltip
                                .text(
                                    '$' + (budget.left).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
                                    + ' - ' + budget.name
                                    + (budget.category ? ' (' + budget.category.replace('PROGRAM: ', '') + ')' : '')
                                )
                                .style('display', 'block')
                                .style('top', yScale(budget.left) + tooltipPad + (topPad || 0) + 'px')
                                .style('left', xScale(budget.dateObj) + tooltipPad + (leftPad || 0) + 'px');
                        };

                        const budgetLine = line()
                            .x(d => xScale(d.dateObj))
                            .y(d => yScale(d.left));

                        budgetsGroupedArray.forEach((budgetGroup, index) => {
                            const color = CHART_COLORS[index] || CHART_COLORS[0];
                            svg.append("path")
                                .datum(budgetGroup.budgets)
                                .attr("class", "line")
                                .attr("d", budgetLine)
                                .style('stroke', color);

                            const budget = budgetGroup.budgets[0];
                            const budgetDiff = budget.left;
                            svg.append("text").style("fill", color)
                                .attr(
                                    "transform",
                                    "translate(" + (width + 10) + "," + yScale(budgetDiff) + ") rotate(-70)"
                                )
                                .attr("dy", ".25em")
                                .attr("text-anchor", "start")
                                .attr('class', 'line-label')
                                .text(budgetGroup.nameWithCategory || budgetGroup.name)
                                .on("mouseover", () => tooltipShow(budget, 50, -250))
                                .on("mouseout", tooltipHide)
                            ;
                        })

                        svg.selectAll(".dot")
                            .data(records)
                            .enter().append("circle") // Uses the enter().append() method
                            .attr("class", "dot") // Assign a class for styling
                            .attr("cx", (d, i) => xScale(d.dateObj))
                            .attr("cy", d => yScale(d.left))
                            .attr("r", 6)
                            .on("mouseover", (event, d) => tooltipShow(d, 0, -200))
                            .on("mouseout", tooltipHide)
                    });
            };

            onMounted(() => {
                if (props.agencyId) {
                    RequestHandler.loadBudgetsHistory(props.agencyId, props.programId).then((records) => {
                        loading.value = false;
                        if (records.length > 0) {
                            setTimeout(() => renderGraph(records), 50);
                        }
                    }).catch(error => {
                        loading.value = false;
                        console.error(error);
                    });
                } else {
                    loading.value = false;
                }
            });

            return {chartEl, loading};
        }
    }
</script>

<style lang="scss">
    @import "../../assets/scss/_variables.scss";

    path.line {
        fill: none;
        stroke-width: 2px;

        &:hover, &:active, &:focus {
            stroke-width: 3px;
        }
    }

    .line-label {
        font-size: 10px;
        display: none; // requested removed
    }

    .chart-container {
        position: relative;
    }

    .chart-tooltip {
        position: absolute;
        background-color: $white;
        border: 2px solid $blue--dark;
        border-radius: 4px;
        padding: 5px;

        &:hover, &:active, &:focus {
            display: block !important;
        }
    }
</style>
