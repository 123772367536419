<template>
    <!-- Page: pages/procurement-flyout -->
    <div class="page-container procurement-page">
        <div class="container">
            <procurement-info-card v-bind="$props"/>
        </div>
    </div>
    <!-- End Page: pages/procurement-flyout -->
</template>

<script>
    import ProcurementInfoCard from "./procurement-info-card";
    import useUser from "@/helpers/useUser";
    import { computed } from "vue";
    import { useStore } from "vuex";

    export default {
        components: {
            ProcurementInfoCard,
        },
        props: {
            contentId: Number,
            locationId: Number,
            title: String,
            status: String,
            adType: String,
            startDate: String,
            startDateFormatted: String,
            endDate: String,
            endDateFormatted: String,
            updatedDateFormatted: String,
            agencyAdNumber: String,
            agency: Object,
            id: String,
            description: String
        },
        methods: {},
        setup(props) {
            const { user } = useUser();

            const store = useStore();
            const showSearch = computed(() => store.getters.showSearch);

            return {
                user,
                path: [{ title: props.name }],
                showSearch,
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
