import PageContractFlyout from './components/contract-flyout';
import PageDocumentFlyout from './components/document-flyout';
import PageProcurementFlyout from './components/procurement-flyout';
import PageVendorFlyout from './components/vendor-flyout';
import PageAgency from './components/agency';
import PageContact from './components/contact';
import PageContract from './components/contract';
import PageFolder from './components/folder';
import PageNotFound from './components/not-found';
import PageOrganization from './components/organization';
import PagePosition from './components/position';
import PageProgram from './components/program';
import PageProgramCategory from './components/program-category';
import PageVendor from './components/vendor';


export default [
{
            name: 'PageContractFlyout',
            component: PageContractFlyout,
            props: ["contentId","locationId","beginDate","contractType","endDate","factsId","flairContractId","grantId","methodOfProcurement","name","newEndDate","orderDate","poNumber","serviceType","totalAmount","vendor","agency","organizationAllotments","programs","budgetEntity","factsDetailsUrl","openFlyout","hideFlyout","user","path","showSearch"],
            pageIdentifier: ["vendor-flyout"]
            },
{
            name: 'PageDocumentFlyout',
            component: PageDocumentFlyout,
            props: ["contentId","locationId","title","type","id","file","user","path","showSearch"],
            pageIdentifier: ["document-flyout"]
            },
{
            name: 'PageProcurementFlyout',
            component: PageProcurementFlyout,
            props: ["contentId","locationId","title","status","adType","startDate","startDateFormatted","endDate","endDateFormatted","updatedDateFormatted","agencyAdNumber","agency","id","description","user","path","showSearch"],
            pageIdentifier: ["procurement-flyout"]
            },
{
            name: 'PageVendorFlyout',
            component: PageVendorFlyout,
            props: ["openFlyoutVendor","hideFlyoutVendor","name","type","locationId","contentId","factsSecondaryName","factsName","transparencyName","definitions"],
            pageIdentifier: ["vendor-flyout"]
            },
{
            name: 'PageAgency',
            component: PageAgency,
            props: ["contentId","locationId","name","type","_url","location","url","purpose","readMoreLink","logo","leadership","acronym","linkedin","twitter","facebook","youtube","rss","vision","info","goals","objectives","totalEmployees","mission","isSubAgency","parentAgencyName","parentAgencyAcronym","parentAgencyUrl","transparencyId","currentBudgetRequest","currentIssueAndDollar","currentAmendedBudget1","currentAmendedBudget2","currentAmendedBudget3","currentAmendedBudget4","currentAmendedBudget5","priorBudgetRequest","priorIssueAndDollar","priorAmendedBudget1","priorAmendedBudget2","priorAmendedBudget3","priorAmendedBudget4","priorAmendedBudget5","getContactName","user","path","showSearch","vendorFlyoutRef","contractFlyoutRef","vendorProps","contractProps","uniqueId"],
            pageIdentifier: ["agency","sub_agency"]
            },
{
            name: 'PageContact',
            component: PageContact,
            props: ["contentId","lastName","firstName","goesBy","title","photo","email","displayEmail","phone","cellPhone","linkedin","twitter","facebook","aboutMe","employeesOversees","middleName","city","age","gender","agencyHead","phoneManual","biography","interestingFacts","modificationMonth","updated","locationId","exempt","position","agency","url","latest_tweet","rssFeed","rss","positionRole","hireDate","salary","budgetEntity","programs","last_salary","executiveAssistantContacts","executiveAssistantPositions","isInOrgChart","hasBudgets","icon","path","fullNameText","showSearch","voterData","formatDate"],
            pageIdentifier: ["contact"]
            },
{
            name: 'PageContract',
            component: PageContract,
            props: ["contentId","locationId","beginDate","contractType","endDate","factsId","flairContractId","grantId","methodOfProcurement","name","newEndDate","orderDate","poNumber","serviceType","totalAmount","vendor","agency","organizationAllotments","programs","factsDetailsUrl","user","path","showSearch"],
            pageIdentifier: ["contract"]
            },
{
            name: 'PageFolder',
            component: PageFolder,
            props: ["name","description","isGovGuidance"],
            pageIdentifier: ["folder"]
            },
{
            name: 'PageNotFound',
            component: PageNotFound,
            props: [],
            pageIdentifier: ["notfound"]
            },
{
            name: 'PageOrganization',
            component: PageOrganization,
            props: ["contentId","name","code","organizationAllotments","contractsIds","agency","getTotal","priceFormat","user","path","showSearch","contractProps","contractFlyoutRef","uniqueId"],
            pageIdentifier: ["organization"]
            },
{
            name: 'PagePosition',
            component: PagePosition,
            props: ["title","contentId","locationId","seniority","positionNumber","payPlan","positionDescription","agency","leadership","contacts","programs","displayTitle","payPlanCode","flairOrgCode","flairOrganization","executiveAssistantContacts","executiveAssistantPositions","path","showSearch","previousContactsEmploymentUpdates","previousContactsLoading","fetchedPreviousPositionEmploymentUpdatesForContentId","getContactName","getPreviousPositionEmploymentUpdates"],
            pageIdentifier: ["position"]
            },
{
            name: 'PageProgram',
            component: PageProgram,
            props: ["agency","category","contentId","locationId","name","keyAnalysis","appropriationAmount","unbudgetedAmount","approvedAmount","releasedAmount","unreleasedAmount","disbursementAmount","encumbrancesAmount","disbursementCategories","leadership","disbursementCategoryGroupTotals","historical","relatedContactsLink","appropriationCategories","dontShowTreemapSearch","getPC","getContactName","getDisbursementCategoryPercent","titleize","checkAmount","getNames","appropriationCategoriesSorted","isTreemapDetailVisible","chartEl","showSearch","path","priceFormat","scrollToTile","percent","user","definitions","parseInt","budgetCategoriesChartEl","budgetCategoriesLegendEl","categoriesTree","categoriesTreeLoading","vendorFlyoutRef","contractFlyoutRef","vendorProps","contractProps","spendingDetailsBlockRef","uniqueId","emit","showlistafter"],
            pageIdentifier: ["budget"]
            },
{
            name: 'PageProgramCategory',
            component: PageProgramCategory,
            props: ["contentId","locationId","name","transparencyId","agency","loading","budgetsGrouped","path","showSearch"],
            pageIdentifier: ["budget_category"]
            },
{
            name: 'PageVendor',
            component: PageVendor,
            props: ["contentId","locationId","name","factsSecondaryName","factsName","transparencyName","user","path","showSearch","contractProps","contractFlyoutRef","uniqueId"],
            pageIdentifier: ["vendor"]
            }
];