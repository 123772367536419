<template>
    <!-- Component: molecules/info-block--info -->
    <infoBlock :icon="icon" :hasIcon="true" :hasClose="true" :isTweet="false" :color="color">
        {{ content }}
    </infoBlock>
    <!-- End Component: molecules/info-block--info -->
</template>

<script>
    import InfoBlock from "./info-block";

    export default {
        name: 'InfoBlockInfo',
        components: {
            InfoBlock
        },
        data() {
            return {
                icon: 'faInfoCircle',
                color: 'blue',
                content: "As we get more good news regarding therapies and vaccines, Florida is working hard to make sure that we serve our state’s most vulnerable, in terms of both current protections and upcoming vaccinations.",
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";

</style>
