<template>
    <!-- Component: organisms/contract-info-card -->
    <info-card :contract="$props">
        <div class="card-content">
            <div class="section">
                <div class="content">

                    <p class="card__info__label top">Contract</p>
                    <p class="card__info__title">{{ titleize(name) }}</p>

                    <template v-if="vendor">
                        <p class="card__info__label card__info__label__with-tag">
                            <Tooltip :tooltipText="definitions && definitions['Vendor'] && definitions['Vendor'].definition ? definitions['Vendor'].definition : 'Loading...'">
                                <span class='has-tooltip'>
                                    Vendor
                                </span>
                            </Tooltip>
                        </p>
                        <tag-vendor :tag-text="vendor.name" :tag-url="vendor.url"/>
                    </template>

                    <div class="columns">
                        <div class="column" v-if="agency">
                            <p class="card__info__label card__info__label__with-tag">Agency</p>
                            <tag-agency :tag-text="agency.name" :tag-url="agency.url"/>
                        </div>
                    </div>

                    <!-- these items are 'columnized' via methods below -->
                    <div class="parent">
                        <div class="column create-rows" v-for="program in programs" v-if="programs">
                            <p class="card__info__label card__info__label__with-tag">Program</p>
                            <tag-budget :tag-text="program.name" :tag-url="program.url"/>
                        </div>
                    </div>

                    <div class="parent">
                        <div class="column create-rows" v-for="organizationAllotment in organizationAllotments" v-if="organizationAllotments && organizationAllotments.length">
                            <p class="card__info__label">Organization Allotment</p>
                            <p class="card__info__data">{{ organizationAllotment.name }}</p>
                        </div>
                    </div>

                    <div class="columns">
                        <div class="column" v-if="contractType">
                            <p class="card__info__label">Contract Type</p>
                            <p class="card__info__data">{{ contractType }}</p>
                        </div>

                        <div class="column" v-if="serviceType">
                            <p class="card__info__label">Service Type</p>
                            <p class="card__info__data">{{ serviceType }}</p>
                        </div>
                    </div>

                    <div class="columns">
                        <div class="column" v-if="methodOfProcurement">
                            <p class="card__info__label">Method of Procurement</p>
                            <p class="card__info__data">{{ methodOfProcurement }}</p>
                        </div>
                    </div>

                   <nav class="level" v-if="totalAmount || orderDate || beginDate || endDate">
                        <div class="level-item" v-if="totalAmount">
                            <div>
                                <p class="card__info__label">Total Amount</p>
                                <p class="title">${{ priceFormat(totalAmount) }}</p>
                            </div>
                        </div>
                        <div class="level-item" v-if="salary">
                            <div>
                                <p class="card__info__label">Order Date</p>
                                <p class="title">{{ orderDate }}</p>
                            </div>
                        </div>
                        <div class="level-item" v-if="beginDate">
                            <div>
                                <p class="card__info__label">Hire Date</p>
                                <p class="title">{{ beginDate }}</p>
                            </div>
                        </div>
                        <div class="level-item end-date" v-if="endDate">
                            <div>
                                <p class="card__info__label">End Date</p>
                                <p class="title">{{ endDate?.includes('9999') ? 'No Current End Date' : endDate}}</p>
                            </div>
                        </div>
                        <div class="level-item end-date" v-if="newEndDate">
                            <div>
                                <p class="card__info__label">
                                    <Tooltip :tooltipText="definitions && definitions['New End Date'] && definitions['New End Date'].definition ? definitions['New End Date'].definition : 'Loading...'">
                                        <span class='has-tooltip'>
                                            New End Date
                                        </span>
                                    </Tooltip>
                                </p>
                                <p class="title">{{ newEndDate?.includes('9999') ? 'No Current End Date' : newEndDate}}</p>
                            </div>
                        </div>
                        <div class="level-item" v-if="budgetEntity">
                            <div>
                                <p class="card__info__label">
                                    <Tooltip :tooltipText="definitions && definitions['Budget Entity'] && definitions['Budget Entity'].definition ? definitions['Budget Entity'].definition : 'Loading...'">
                                        <span class='has-tooltip'>
                                            Budget Entity
                                        </span>
                                    </Tooltip>
                                </p>
                                <p class="title">{{ budgetEntity }}</p>
                            </div>
                        </div>
                    </nav>
                    <div class="columns" v-if="factsDetailsUrl != '' && !factsDetailsUrlLoading">
                        <div class="column">
                            <a :href="factsDetailsUrl" target="_blank" class="card__info__label uppercase card__info__label__link">View contract details on Florida Accountability Contract Tracking System</a>
                        </div>
                    </div>
                    <div class="columns" v-if="!factsDetailsUrl && !factsDetailsUrlLoading">
                        <div class="column">
                            <p>Contract has been archived on the FACTS system</p>
                        </div>
                    </div>
                    <div class="columns" v-if="!factsDetailsUrl && factsDetailsUrlLoading">
                        <div class="column">
                            <Preloader />
                        </div>
                    </div>
                </div>
            </div>
        </div>

<!--        <iframe-modal-->
<!--            v-if="showIframe"-->
<!--            @close="showIframe = false"-->
<!--            @created="newIframeCreated"-->
<!--            :sourceURL="factsDetailsUrl"-->
<!--            :sourceTitle="serviceType"-->
<!--        ></iframe-modal>-->

    </info-card>
    <!-- End Component: organisms/vendor-info-card -->
</template>

<script>
    import { reactive, ref, computed } from 'vue';
    import { useStore } from "vuex";
    import InfoCard from "./info-card"
    import ReadMoreLink from "./read-more-link"
    import { priceFormat } from "@/helpers/filter";
    import TagVendor from "./tag--vendor";
    import TagAgency from "./tag--agency";
    import Tag from "./tag";
    import TagBudget from "./tag--budget";
    import IframeModal from "./iframe-modal";
    import Tooltip from "./tooltip";
    import RequestHandler from "@/handler/RequestHandler";
    import Preloader from "./preloader";

    export default {
        name: 'ContractInformationCard',
        components: {
            InfoCard,
            ReadMoreLink,
            TagVendor,
            TagAgency,
            Tag,
            TagBudget,
            IframeModal,
            Tooltip,
            Preloader,
        },
        props: {
            contentId: Number,
            locationId: Number,
            beginDate: String,
            contractType: String,
            endDate: String,
            factsId: String,
            flairContractId: String,
            grantId: String,
            methodOfProcurement: String,
            name: String,
            newEndDate: String,
            orderDate: String,
            poNumber: String,
            serviceType: String,
            totalAmount: Number,
            vendor: Object,
            agency: Object,
            organizationAllotments: Array,
            programs: Array,
            budgetEntity: String,
            factsDetailsUrl: String,
            factsDetailsUrlLoading: Boolean,
        },
        data() {
            return {

             };
        },
        setup(props) {
            const showIframe = ref(false);
            const store = useStore();
            const definitions = computed(() => store.getters.definitions);

            const newIframeCreated = () => {
                showIframe.value = false;
            };

            if (!props.factsDetailsUrl){
                props.factsDetailsUrlLoading = true;
                RequestHandler.loadContractDetails(props.locationId).then(data => {
                    props.factsDetailsUrl = data.data;
                    props.factsDetailsUrlLoading = false;
                });
            }

            return {
                priceFormat,
                newIframeCreated,
                showIframe,
                definitions
            };
        },
        mounted: function () {
            this.columnize();
        },
        updated() {
            this.$props.factsDetailsUrlLoading = true;
            RequestHandler.loadContractDetails(this.locationId).then(data => {
                this.$props.factsDetailsUrl = data.data;
                this.$props.factsDetailsUrlLoading = false;
            });
        },
        methods: {
            titleize: function(item) {
                return item ? item.toLowerCase().replace(/(?:^|\s|-)\S/g, x => x.toUpperCase()) : '';
            },
            columnize: function() {

                // forEach function
                var forEach = function (array, callback, scope) {
                    for (var i = 0; i < array.length; i++) {
                    callback.call(scope, i, array[i]); // passes back stuff we need
                    }
                }

                // select all .parent divs
                var parentDivs = document.querySelectorAll('.parent');

                //slicing the array
                var chunk = function ( array, size ) {
                    var arr = [];
                    for ( var i = 0; i < array.length; i += size ) {
                        var newSlicedArray = Array.prototype.slice.call( array, i, i + size );
                        arr.push(newSlicedArray);
                    }
                    return arr;
                }

                //run foreach function
                forEach(parentDivs, function (index, value) {

                    var childrens = value.querySelectorAll(".create-rows");

                    var final = chunk(childrens,4);
                    final.map( towrap =>
                        towrap.reduce( (acc, el) =>
                            (acc.appendChild(el),acc) , document.createElement('div') )
                    ).forEach( el => {
                        el.className ="columns created-cols";
                        value.appendChild(el)
                    })

                });

            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";

    .content .card__info__title {
        margin-top: 0.75em !important;
        font-family: $header;
        font-weight: 600;
        font-size: 2em;
    }

    @include block('card') {
        .media {
            display: contents;
        }
        @include element('info') {
            @include element('label') {
                float: left;
                margin-right: 10px;
                display: block;
                width: 100%;
                margin-bottom: 5px !important;
                &.top {
                    margin-bottom: 0px !important;
                }
            }
            @include element('description') {
                line-height: 1.7em;
            }
        }
    }
     .level {
        margin-bottom: 15px;
        justify-content: start;
        display: flow-root;
        .level-item {
            display: inline-flex !important;
            max-width: 200px;
            width: auto;
            margin-right: 40px;
            margin-bottom: 15px;
            .title {
                font-size: 1.25rem;
            }
            .end-date {
                max-width: 250px;
            }
        }
    }

    .card__info__label.card__info__label__link {
        font-size: 15px;
    }
</style>
