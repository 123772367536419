<template>
    <!-- Start Component: organisms/state-budget-chart -->
    <div class="stacked-bar-chart-wrapper">
        <div class="chart-legend">
            <span class="chart-legend-1 chart-legend__item" :style="{background: SPEND_COLORS[0], background: SPEND_COLORS[1]}"></span>Spent
            <span class="chart-legend-2 chart-legend__item" :style="{background: SPEND_COLORS[2], background: SPEND_COLORS[3]}"></span>Unspent
        </div>

        <svg id="defined-gradients" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <defs>
                <linearGradient id="Gradient1" x1="0" x2="0" y1="0" y2="2">
                    <stop offset="0%" stop-color="#bf0a30"/>
                    <stop offset="100%" stop-color="#78041c"/>
                </linearGradient>
                <linearGradient id="Gradient2" x1="0" x2="0" y1="0" y2="2">
                    <stop offset="0%" stop-color="#46cb79"/>
                    <stop offset="100%" stop-color="#136f63"/>
                </linearGradient>
            </defs>
        </svg>

        <div ref="chartEl" class="stacked-bar-chart-container"></div>
    </div>
    <!-- End Component: organisms/state-budget-chart-->
</template>

<script>
    import { onMounted, ref } from "vue";
    import { renderStateBudgetChart } from "@/helpers/charts";
    import { SPEND_COLORS } from "@/constants";
    import { useRouter, useRoute } from 'vue-router';

    export default {
        name: 'StateBudgetChart',
        props: ['historical',],
        components: {},
        setup(props) {
            const chartEl = ref(null);
            const historical = ref(props.historical || []);

            onMounted(() => {
                renderStateBudgetChart(chartEl.value, historical.value);
            });

            return {chartEl, SPEND_COLORS, historical};
        },
    }
</script>

<style lang="scss">
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";

    .stacked-bar-chart-title {
        font-weight: 600;
        font-family: $header;
        font-size: 40px;
        margin-bottom: 0.5em;
    }

    @include block('chart-legend') {
        text-align: left;
        span {
            font-size: 2.4em;
            padding-right: 10px;
            line-height: 10px;
        }
        @include element('item') {
            width: 15px;
            height: 15px;
            display: inline-block;
            margin-right: 5px;
        }
    }

    #defined-gradients {
        height: 0px;
    }
</style>
