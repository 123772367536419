<template>
    <!-- Component: molecules/user-tools -->
    <div class="user-tools">
        <app-button icon="bookmark" @click="$router.push('/my-bookmarks')">My Bookmarks</app-button>
        <app-button icon="notes" @click="$router.push('/my-notes')">My Notes</app-button>
        <!--
        <app-button icon="notification" @click="$router.push('/my-notifications')">My Notification</app-button>
        -->
    </div>
    <!-- End Component: molecules/user-tools -->
</template>


<script>
    import AppButton from "@/alivue/components/app-button";

    export default {
        name: 'UserTools',
        components: {
            AppButton
        }
    }
</script>


<style lang="scss" scoped>
    @import "../../assets/scss/mixins/_bemify.scss";

    @include block('user-tools') {
        float: right;
        margin-bottom: 15px;
        @media screen and (max-width: 1021px) {
            float: none;
            margin-bottom: 15px;
            text-align: center;
            margin-top: 15px;
        }
        .button {
            margin-right: 15px;

            &:last-of-type {
                margin-right: 0px;
            }
        }
    }

</style>

