<template>
    <!-- Component: molecules/info-block--travel -->
    <infoBlock :hasIcon="false" :hasClose="false" :isTweet="false" color="blue--med">
        <p class="info-block__title">{{ dateFrom }} - {{ dateTo }}</p>
        <p class="info-block__travel-item">
            <Tooltip :tooltipText="definitions && definitions['Travel'] && definitions['Travel'].definition ? definitions['Travel'].definition : 'Loading...'">
                <span class='has-tooltip'>
                    Travel
                </span>
            </Tooltip>
            to <strong>{{ destination }}</strong> for <strong>{{ event }}</strong>
        </p>
        <p class="info-block__travel-cost">Total Cost: <span class="info-block__travel-cost__price">{{ cost }}</span>
        </p>
    </infoBlock>
    <!-- End Component: molecules/info-block--travel -->
</template>

<script>
    import { ref, computed } from "vue";
    import { useStore } from "vuex";
    import InfoBlock from "./info-block"
    import Tooltip from "./tooltip";

    export default {
        name: 'InfoBlockTravel',
        components: {
            InfoBlock,
            Tooltip
        },
        props: ['dateFrom', 'dateTo', 'destination', 'event', 'cost'],
        setup(props) {
            const store = useStore();
            const definitions = computed(() => store.getters.definitions);

            return {
                definitions
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";

    .content p:not(:last-child), .content ul:not(:last-child) {
        margin-bottom: 0px !important;
    }

    @include block('info-block') {
        @include element('title') {
            color: #B4B4B4;
            font-size: 18px;
            margin-bottom: 0px;
        }
        @include element('travel-item') {
            color: #777171;
            margin-bottom: 0px !important;
            strong {
                color: $blue--dark;
                font-weight: 600;
            }
        }
        @include element('travel-cost') {
            color: #777171;
            margin-bottom: 0px !important;
            @include element('price') {
                color: $blue--dark;
                font-weight: 600;
            }
        }
    }
</style>
