import { createSSRApp } from 'vue';
import { createConfiguredRouter } from './router';
import { createConfiguredStore } from './store';
import { createWebHistory } from "vue-router";
import { ACTION_TYPES, MUTATION_TYPES } from "@/constants";
import App from './App.vue';

// create router and store instances
const router = createConfiguredRouter(
    createWebHistory(process.env.BASE_URL)
);
const store = createConfiguredStore();

if (window.__INITIAL_STATE__) {
    // We initialize the store state with the data injected from the server
    store.replaceState(window.__INITIAL_STATE__);
}

// Note that to enable hydration on the client, use createSSRApp instead of createApp to create your app instance
// (separate imports allows hydration logic to be tree-shakeable for apps that do not require hydration).
const app = createSSRApp(App)
    .use(store)
    .use(router);

router.beforeEach((to, from, next) => {
    if (to.fullPath.startsWith('/components/preview/')) {
        return next();
    }
    // Save scroll position locally (Expires after 5 minutes)
    // setScrollPosition(from.path, window.scrollY, 5);    // creates a bug (scroll - up not working)

    const unprotected = ['login', 'register', 'teaser', 'reset-password',];
    const unprotectedPaths = ['/terms-of-use', '/privacy-policy', '/dcma-notice', '/about', '/about-us', '/help', '/contact-us', ',teaser', '/subscription-agreement', '/standalone/tableau'];
    if (!store.getters.user) {
        if (!store.getters.isUserLoaded && !store.getters.isUserLoading) {
            if (to.query.code) {
                store.commit(MUTATION_TYPES.setVerificationCode, to.query.code);
            }
            return store.dispatch(ACTION_TYPES.loadUser)
                .then(() => {
                    if (!store.getters.user && !unprotected.includes(to.name) && !unprotectedPaths.includes(to.path)) {
                        store.commit(MUTATION_TYPES.setRedirectPath, to.path);
                        next({ name: 'login' });
                    } else {
                        next();
                    }
                })
                .catch(() => {
                    if (!unprotected.includes(to.name) && !unprotectedPaths.includes(to.path)) {
                        next({ name: 'login' });
                    } else {
                        next();
                    }
                });
        }

        if (store.getters.isUserLoaded && !unprotected.includes(to.name) && !unprotectedPaths.includes(to.path)) {
            store.commit(MUTATION_TYPES.setRedirectPath, to.path);
            next({ name: 'login' });
        } else {
            next();
        }
    } else {
        next();
        window.scroll(0, 0);
    }
});

router.isReady().then(() => {
    app.mount('#app');
});
