<script>
    export default {
        name: 'PDFViewer',
        data () {
            return {
                previewFilePromise: null
            }
        },
        mounted() {
            this.renderPdf();
        },
        methods: {
            renderPdf() {
                let pdf = document.getElementsByClassName('adobe-dc-view')
                for (let i=0; i < pdf.length; i++) {
                    let dataUrl = pdf[i].getAttribute('data-url')
                    let dataFileName =  pdf[i].getAttribute('data-filename')
                    let dataEmbedMode = pdf[i].getAttribute('data-embedmode')
                    let dataId =  pdf[i].getAttribute('id')

                    //start adobe view
                    let adobeDCView = new AdobeDC.View({
                        clientId: process.env.VUE_APP_ADOBE_KEY,
                        divId: dataId
                    })
                    // send data to adobe api
                    const viewerConfig = {
                        embedMode: dataEmbedMode
                    };

                    this.previewFilePromise = adobeDCView.previewFile({
                        content:{ location:
                            { url: dataUrl }},
                        metaData:{fileName: dataFileName}
                        },
                        viewerConfig);
                }
            }
        }
    }
</script>

