import RequestHandler from "@/handler/RequestHandler";

const loadAgencyBudgets = (agencyId, budgetsGroupedRef, budgetsLoadingRef, grouping = true) => {
    budgetsLoadingRef.value = true;
    return RequestHandler.loadAgencyBudgets(agencyId, 100, true)
        .then(({items}) => {
            if (grouping) {
                let budgetsGroupedObject = items.reduce((grouped, budget) => {
                    grouped[budget.program.name] = grouped[budget.program.name] || {
                        budgets: [],
                        url: budget.url,
                        name: budget.name,
                        program: budget.program,
                        disbursementAmount: 0,
                        appropriationAmount: 0,
                        expanded: false
                    };
                    grouped[budget.program.name].budgets.push(budget);
                    grouped[budget.program.name].disbursementAmount += budget.disbursementAmount || 0;
                    grouped[budget.program.name].appropriationAmount += budget.appropriationAmount || 0;

                    return grouped;
                }, {});

                budgetsGroupedRef.value = Object.keys(budgetsGroupedObject).map(item => budgetsGroupedObject[item]);
            } else {
                budgetsGroupedRef.value = items;
            }
            budgetsLoadingRef.value = false;
        })
        .catch(error => {
            budgetsLoadingRef.value = false
            alert(error);
        });
};


export { loadAgencyBudgets };
