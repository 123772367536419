<template>
    <!-- Page: pages/search-results -->

    <div class="spacer"></div>

    <breadcrumbs :items="path"/>

    <br/>

    <div class="container mobile-contain" style="display: flow-root;">
        <h1>Search</h1>
        <userTools/>
    </div>

    <general-container>

        <div class="columns">

            <div class="column is-one-quarter">

                <div class="toggle-container">
                    <p class="search-expand-label search-expand-label--on" @click="clickCollapse();">- <span>Hide Filters</span></p>
                    <p class="search-expand-label search-expand-label--off is-active" @click="clickCollapse();">+ <span>Show Filters</span></p>
                </div>

                <div id="filter-container" class="card">
                    <div class="card-content">
                        <div class="content">
                            <div
                                v-if="!loading && searchResults"
                                v-for="(searchFilters, name) in searchResults.availableFilters"
                                class="search-filter-group"
                                :key="name"
                            >
                                <p class="content-block__title h3 search-filter-name">Filter by {{ name }}</p>
                                <div class="content-block__content">

                                    <ul>
                                        <li v-for="searchFilter in searchFilters" :key="searchFilter">

                                            <div class="field">
                                                <input :id="searchFilter.identifier + '[' + searchFilter.title + ']'"
                                                    class="switch is-rounded"
                                                    v-on:click="clickFilter($event)" type="checkbox"
                                                    :checked="searchFilter.active"
                                                    :name="searchFilter.identifier + '[]'"
                                                    :value="searchFilter.value">
                                                <label :for="searchFilter.identifier + '[' + searchFilter.title + ']'">{{
                                                        searchFilter.title
                                                    }} ({{ searchFilter.count }})</label>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="search-filter-group">
                                <div>
                                    <p class="content-block__title h3 search-filter-name">Agencies</p>
                                    <div class="content-block__content">
                                        <ul>
                                            <li v-for="agency in agencies">
                                                <div class="field">
                                                    <input :id="'agency-' + agency.locationId"
                                                           class="switch is-rounded"
                                                           v-on:click="clickFilter($event)" type="checkbox"
                                                           :checked="(checkedFilters['agencies[]'] || []).includes(
                                                               typeof agency.locationId == 'string'
                                                               ? typeof agency.locationId
                                                               : agency.locationId.toString())"
                                                           name="agencies[]"
                                                           :value="agency.locationId">
                                                    <label :for="'agency-' + agency.locationId"
                                                           class="agency-filter-label">{{ agency.name }}</label>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="column is-three-quarters">

                <div class="search-container" style=" margin: 0 auto;">
                    <search-block :advanced="false" :title="searchTitle" v-on:search="searchQueryUpdate" :initialValue="query" :type="'autocomplete'"/>
                </div>

                <preloader v-if="loading"></preloader>

                <showing-results
                    :number="searchResults.totalCount"
                    :query="searchResults.searchParams.q"
                    v-if="!loading && searchResults && searchResults.totalCount"
                />

                <section class="search-results-section">
                    <template
                        v-if="!loading && searchResults && searchResults.searchResults"
                        v-for="item in searchResults.searchResults"
                        :key="item"
                    >
                        <component v-bind="item" v-bind:is="getSearchComponent(item)"/>
                    </template>

                    <p v-if="query && !loading && searchResults.searchResults.length == 0">
                        There are no results. Please refine your search.
                    </p>
                </section>

                <pagination
                    v-if="!loading && searchResults && searchResults.pageCount"
                    v-on:paginated="paginate($event)"
                    :current="page"
                    :pageCount="searchResults.pageCount"
                />
            </div>

        </div>
    </general-container>
    <!-- End Page: pages/search-results -->
</template>

<script>
    import RequestHandler from "@/handler/RequestHandler";
    import Breadcrumbs from "./breadcrumbs.vue";
    import UserTools from "@/alivue/components/user-tools";
    import GeneralContainer from "./general-container.vue";
    import SearchBlock from "./search-block.vue";
    import ShowingResults from "./showing-results.vue";
    import SearchResultCardAgency from "./search-result-card--agency";
    import SearchResultCardPosition from "./search-result-card--position";
    import SearchResultCardProgram from "./search-result-card--program";
    import SearchResultCardProgramCategory from "./search-result-card--program-category";
    import SearchResultCardVendor from "./search-result-card--vendor";
    import SearchResultCardContract from "./search-result-card--contract";
    import SearchResultCardContact from "./search-result-card--contact";
    import Pagination from "./pagination.vue";
    import Preloader from "./preloader";

    export default {
        components: {
            Breadcrumbs,
            UserTools,
            GeneralContainer,
            SearchBlock,
            ShowingResults,
            SearchResultCardAgency,
            SearchResultCardPosition,
            SearchResultCardContact,
            SearchResultCardProgram,
            SearchResultCardProgramCategory,
            SearchResultCardVendor,
            SearchResultCardContract,
            Pagination,
            Preloader,
        },
        props: {
            name: String,
            searchTitle: String,
        },
        data() {
            return {
                searchResults: {},
                page: 1,
                pageLimit: 10,
                query: '',
                loading: false,
                checkedFilters: []
            };
        },
        computed: {
            agencies: function () {
                return this.$store.getters.agencies;
            },
        },
        mounted() {
            this.loadFiltersFromQuery();
            this.search();
        },
        methods: {
            loadFiltersFromQuery(fromRoute) {
                const route = fromRoute || this.$route;
                this.checkedFilters = [];
                const contentTypes = route.query['content_type[]'];
                if (contentTypes) {
                    this.checkedFilters['content_type[]'] = Array.isArray(contentTypes)
                        ? contentTypes
                        : contentTypes.split(',');
                }
                const agencies = route.query['agencies[]'];
                if (agencies) {
                    this.checkedFilters['agencies[]'] = Array.isArray(agencies)
                        ? agencies
                        : agencies.split(',');
                }

                this.query = route.query.q || '';
                this.page = parseInt(route.query.page || 1);

                let page = route.query.page;
                if (page) {
                    this.page = parseInt(page);
                }
                this.applyFilters();
            },
            clickFilter(event) {
                let name = event.target.name;
                let checked = event.target.checked;
                let value = event.target.value;
                let filters = this.checkedFilters[name] || [];
                let index = filters.indexOf(value);
                if (checked) {
                    filters.push(value);
                } else {
                    if (index !== -1) {
                        filters.splice(index, 1);
                    }
                }
                this.checkedFilters[name] = filters;
                this.page = 1;

                this.applyFilters();
            },
            applyFilters() {
                const params = {
                    'q': this.query,
                };
                if (this.page > 1) {
                    params['page'] = this.page;
                }
                if (this.checkedFilters.hasOwnProperty('content_type[]') && this.checkedFilters['content_type[]']) {
                    params['content_type[]'] = this.checkedFilters['content_type[]'];
                }
                if (this.checkedFilters.hasOwnProperty('agencies[]') && this.checkedFilters['agencies[]']) {
                    params['agencies[]'] = this.checkedFilters['agencies[]'];
                }
                this.addParamsToLocation(params);
            },
            paginate(pageTo) {
                window.scrollTo(0, 0);
                this.page = pageTo;
                this.offset = (pageTo - 1) * this.pageLimit;
                this.applyFilters();
            },
            addParamsToLocation(params) {
                let queryParams = { ...params };
                if (Array.isArray(queryParams['content_type[]'])) {
                    // workaround for vue, not updating URL if array attribute changed here
                    queryParams['content_type[]'] = queryParams['content_type[]'].join(',');
                }
                if (params['content_type[]'] && params['content_type[]'].length === 0) {
                    delete params['content_type[]'];
                }
                if (Array.isArray(queryParams['agencies[]'])) {
                    queryParams['agencies[]'] = queryParams['agencies[]'].join(',');
                }
                if (params['agencies[]'] && params['agencies[]'].length === 0) {
                    delete params['agencies[]'];
                }
                this.$router.push({ 'name': 'search', query: queryParams });
            },
            searchQueryUpdate(query) {
                this.query = query;
                this.page = 1;
                this.applyFilters();
            },
            search() {
                this.loading = true;
                this.query = this.query.replace(new RegExp('-', 'g'), ' ');
                let params = {
                    'q': this.query,
                    'page': this.page,
                };
                if (this.checkedFilters.hasOwnProperty('content_type[]')) {
                    params['content_type'] = this.checkedFilters['content_type[]'];
                }
                if (this.checkedFilters.hasOwnProperty('agencies[]')) {
                    params['agencies'] = this.checkedFilters['agencies[]'];
                }

                RequestHandler.loadSearch(this.query, this.page, params).then(response => {
                    this.searchResults = response;
                    this.searchResults.searchResults.sort(( a, b) => {
                        if (a.class > b.class) return 1
                        else  if (a.class < b.class) return -1
                        return 0
                    });
                    this.loading = false;
                }).catch((error) => {
                    console.error(error);
                    // TODO: show error
                });
            },
            getSearchComponent(item) {
                if (item.class === 'AgencyRef') {
                    return 'search-result-card-agency';
                }
                if (item.class === 'PositionRef') {
                    return 'search-result-card-position';
                }
                if (item.class === 'ContactRef') {
                    return 'search-result-card-contact';
                }
                if (item.class === 'ProgramRef') {
                    return 'search-result-card-program';
                }
                if (item.class === 'ProgramCategoryRef') {
                    return 'search-result-card-program-category';
                }
                if (item.class === 'VendorRef') {
                    return 'search-result-card-vendor';
                }
                if (item.class === 'ContractRef') {
                    return 'search-result-card-contract';
                }
                // should never get here
                return '';
            },
            clickCollapse() {
                let element = document.getElementById('filter-container');
                let toggleA = document.getElementsByClassName('search-expand-label')[0];
                let toggleB = document.getElementsByClassName('search-expand-label')[1];
                let expand = false;
                if (element.classList.contains('is-active')) {
                    element.classList.remove('is-active');
                    toggleA.classList.remove('is-active');
                    toggleB.classList.add('is-active');
                } else {
                    element.classList.add('is-active');
                    toggleA.classList.add('is-active');
                    toggleB.classList.remove('is-active');
                }
            }
        },
        watch: {
            $route: function (toRoute) {
                if (toRoute.name === 'search') {
                    this.loadFiltersFromQuery(toRoute);
                    this.search();
                }
            }
        },
        setup(props) {
            return {
                path: [{title: 'Search'}]
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";


    .spacer {
        display: block;
        height: 50px;
        width: 100%;
    }

    @include block('search-results-section') {
        @include element('agency') {

        }
        @include element('title') {
            text-align: center;
            font-weight: 600;
            margin-top: 30px;
            span {
                color: #777171;
            }
        }
    }

    .search-filter-group {
        margin: 0 auto;
        margin-bottom: 50px;
        ul {
            list-style-type: none;
            margin-left: 0px;
        }
    }

    .search-filter-name {
        font-weight: 600;
        font-family: $header;
        margin-bottom: 0px;
        font-size: 28px;
        border-bottom: 1px solid $blue--med;
    }

    .card {
        background-color: $blue--dark;
        p, label {
            color: $white;
        }
    }

    .mobile-contain {
        h1 {
            float: left;
        }

        @media screen and (max-width: 1021px) {
            margin-left: 15px;
            margin-right: 15px;
            h1 {
                text-align: center;
                float: none;
            }
        }
    }

    .agency-filter-label {
        height: auto !important;
        margin-bottom: 6px;

        &:hover, &:active, &:focus {
            opacity: 0.75;
        }
    }
    @include block('search-expand-label') {
        display: none !important;
        font-weight: bold;
        padding-bottom: 0px;
        background-color: $blue--dark;
        color: $white;
        padding: 5px 10px;
        margin-bottom: 0px;
        border-radius: 0.25rem;
        transition: all .5s ease;
        margin: 0 auto;
        width: 100%;
        text-align: center;
        margin-bottom: 10px;
        cursor: pointer;
        span {
            text-decoration: underline;
        }
        &.is-active {
            display: inline-block !important;
        }
        @include modifier('off') {
            @media screen and (max-width: 900px) {

            }
        }
    }
    #filter-container {
        @media screen and (max-width: 768px) {
            display: none;
            width: 100%;
            &.is-active {
                display: inline-block;
            }
        }
    }
    .toggle-container {
        display: none !important;
        width: 100%;
        display: flex;
        @media screen and (max-width: 768px) {
            display: inline-block !important;
        }
    }
    .column.is-one-quarter, .column.is-three-quarters {
        @media screen and (max-width: 768px) {
            width: 100% !important;
            flex-basis: auto !important;
        }
    }
</style>
