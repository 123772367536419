<template>
    <!-- Component: organisms/gantt -->

    <div class="gstc-wrapper" ref="gstc"></div>

    <!-- End Component: organisms/gantt -->
</template>

<script>
    import GSTC from "gantt-schedule-timeline-calendar";
    import { Plugin as TimelinePointer } from "@/plugins/gantt/timeline-pointer.esm.min.js";
    import { Plugin as Selection } from "@/plugins/gantt/selection.esm.min.js";
    import { Plugin as ItemResizing } from "@/plugins/gantt/item-resizing.esm.min.js";
    import { Plugin as ItemMovement } from "@/plugins/gantt/item-movement.esm.min.js";
    import { Plugin as Bookmarks } from "@/plugins/gantt/time-bookmarks.esm.min.js";

    let gstc, state;

    function generateRows() {
        /**
         * @type { import("gantt-schedule-timeline-calendar").Rows }
         */
        const rows = {};
        for (let i = 0; i < 100; i++) {
            const id = GSTC.api.GSTCID(i.toString());
            rows[id] = {
            id,
            label: `Row ${i}`,
            };
        }
        return rows;
    }

    function generateItems() {
        /**
         * @type { import("gantt-schedule-timeline-calendar").Items }
         */
        const items = {};
        let start = GSTC.api.date().startOf("day").subtract(6, "day");
        for (let i = 0; i < 100; i++) {
            const id = GSTC.api.GSTCID(i.toString());
            const rowId = GSTC.api.GSTCID(Math.floor(Math.random() * 100).toString());
            start = start.add(1, "day");
            items[id] = {
            id,
            label: `Item ${i}`,
            rowId,
            time: {
                start: start.valueOf(),
                end: start.add(1, "day").endOf("day").valueOf(),
            },
            };
        }
        return items;
    }

    export default {
        name: 'Gantt',
        props: {

        },
        components: {
            GSTC
        },
        data() {

            return {

            }
        },
        mounted() {
            /**
            * @type { import("gantt-schedule-timeline-calendar").Config }
            */
            const config = {
                licenseKey: "====BEGIN LICENSE KEY====\nEYY/j/zJnecsfc+xwu7h0mqSFgB9qxn6+z7yE75wuq/VednehS7B9ViJVfNeErium4gOhMMba89cyaYVKZ8JsSpKTiYWI9vVkyzC+T2ZUR0jStCrzPr5Eqtd7/kuY1x4vbGI9y2UUVC8mrHKgJoMU6IlYveoazhRqi+j7GDx82vJS8MUirJEMswNHazO+P9wP8VZJow1paRC7aQaL48+q1XkzsGQYnSDcvQwT0pmMz9n92xPLXSqCKC561uOcJhe9ykFX8120ax8C3gKUA1w/Hvury2sG/OZtFVYmMzB7ArY3zktUDGmJO+GktofiDfNMM83/lHH+3gEfdaFiEI5uQ==||U2FsdGVkX19AD+23hN1kbNJ78V2diaawPvCmbiynSngE7f+sa19QfaJIJqnS+TSiQO0P90J8O9R/CgQ1O1utPIfgtHiybOcEAwLmKSviP2E=\nQvvQcd9jr4jbU3ikJZMVC7R8+Q1d0m8at4WKiQTFHD/3lDm2ejIUz3rKroUR9kVLh233ftHxE0HXqGy3kjSwKY568wxVt8drHun8nJtnX0PCXpExIVGBErn234xH2Hck+BHX/O9MpHE0ZldKO+mFvevOpnpR1CGgjzkO8/+R3+x/gS500ILvFJ60Q3BCURISCXjnDtbr9J/yjdHPUSMGF3r4mIoPoFJFJOLk7liRIKtl+CLVe7jMenaWdsRh9HlNcOaDFO2L5fuTgKzYdw9itHGxSvnj3puoS27rFFMI53X5hZjdZfvbrhHzvETXrfbZ/kSOnHmXVnQbDXjixFCD3g==\n====END LICENSE KEY====",
                plugins: [TimelinePointer(), Selection(), ItemResizing(), ItemMovement(), Bookmarks()],
                list: {
                    columns: {
                        data: {
                            [GSTC.api.GSTCID("id")]: {
                                id: GSTC.api.GSTCID("id"),
                                width: 60,
                                data: ({ row }) => GSTC.api.sourceID(row.id),
                                header: {
                                    content: "ID",
                                },
                            },
                            [GSTC.api.GSTCID("label")]: {
                                id: GSTC.api.GSTCID("label"),
                                width: 200,
                                data: "label",
                                header: {
                                    content: "Label",
                                },
                            },
                        },
                    },
                    rows: generateRows(),
                },
                chart: {
                    items: generateItems(),
                },
            };

            state = GSTC.api.stateFromConfig(config);

            gstc = GSTC({
                element: this.$refs.gstc,
                state,
            });
        },
        beforeUnmount() {
            if (gstc) gstc.destroy();
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";

//    @import "../../assets/scss/plugins/_gantt.scss"; // putting in required or much of this gets tree-shaken before it is required
</style>
