<template>
    <!-- Component: organisms/notes-list -->
    <general-container>

        <section v-if="showFilters">
            <br/>
            <div class="content-block__content">
                <div class="notes-filter">
                    Filter by
                    <span @click.prevent="filterByType('contact')" :class="{'active': typeFilter === 'contact'}"
                          title="Filter by Contact"
                    >
                        <tag-contact :tag-text="'Contact'"></tag-contact>
                    </span>
                    <span @click.prevent="filterByType('position')" :class="{'active': typeFilter === 'position'}"
                          title="Filter by Position"
                    >
                        <tag-role :tag-text="'Position'"></tag-role>
                    </span>
                    <span @click.prevent="filterByType('agency')" :class="{'active': typeFilter === 'agency'}"
                          title="Filter by Agency"
                    >
                        <tag-agency :tag-text="'Agency'"></tag-agency>
                    </span>
                    <span @click.prevent="filterByType('budget')" :class="{'active': typeFilter === 'budget'}"
                          title="Filter by Program"
                    >
                        <tag-budget :tag-text="'Program'"></tag-budget>
                    </span>
                    <span @click.prevent="filterByType('vendor')" :class="{'active': typeFilter === 'vendor'}"
                          title="Filter by Vendor"
                    >
                        <tag-vendor :tag-text="'Vendor'"></tag-vendor>
                    </span>
                    <span @click.prevent="filterByType('contract')" :class="{'active': typeFilter === 'contract'}"
                          title="Filter by Contract"
                    >
                        <tag-contract :tag-text="'Contract'"></tag-contract>
                    </span>
                    <span @click.prevent="filterByType('procurement')" :class="{'active': typeFilter === 'procurement'}"
                          title="Filter by Procurement"
                    >
                        <tag-procurement :tag-text="'Procurement'" :type="'procurement'"></tag-procurement>
                    </span>
                </div>
            </div>
        </section>

        <div class="columns mt-30">
            <div class="column is-one-quarter" v-if="showFilters">

                <div class="toggle-container">
                    <p class="search-expand-label search-expand-label--on" @click="clickCollapse();">- <span>Hide Filters</span></p>
                    <p class="search-expand-label search-expand-label--off is-active" @click="clickCollapse();">+ <span>Show Filters</span></p>
                </div>

                <div id="filter-container" class="card">
                    <div class="card-content">
                        <div class="content">
                            <div class="search-filter-group">
                                <div>
                                    <p class="content-block__title h3 search-filter-name">Agencies</p>
                                    <div class="content-block__content">
                                        <ul>
                                            <li v-for="agency in agencies">
                                                <div class="field">
                                                    <input :id="'agency-' + agency.contentId"
                                                            class="switch is-rounded"
                                                            v-on:click="clickFilter($event)" type="checkbox"
                                                            :checked="(checkedFilters['agencies[]'] || []).includes(
                                                                typeof agency.contentId == 'string'
                                                                ? typeof agency.contentId
                                                                : agency.contentId.toString())"
                                                            name="agencies[]"
                                                            :value="agency.contentId"
                                                    >
                                                    <label :for="'agency-' + agency.contentId"
                                                            class="agency-filter-label">{{ agency.name }}</label>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div :class="showFilters ? 'column is-three-quarters' : 'column is-full'">

                <custom-select
                    v-if="showFilters"
                    :uniqueId="uniqueId"
                    :items="[{value: 'date_modified desc', name: 'Newest'}, {value: 'date_modified asc', name: 'Oldest'}]"
                    name="sort"
                    placeholder="Select a sort..."
                    v-model:value="sort"
                ></custom-select>

                <button class="button btn btn--primary notes-search" @click="reloadPage();">Clear Filters and Search</button>

                <div class="search-container create-note-search-container" style=" margin: 0 auto;">
                    <search-block :advanced="false" v-on:search="searchQueryUpdate" :initialValue="query"/>
                </div>

                <preloader v-if="notesLoading"></preloader>

                <section>
                    <span class="no-results" v-if="notes.length <= 0">
                        <span class="emoji">🕵️</span>
                        <span class="text">We looked but unfortunately we didn't find any notes!</span>
                    </span>
                    <note-line v-for="(note, i) in notes"
                            :date="note.modificationDate"
                            :title="note.name"
                            :text="note.text"
                            :files="note.files || []"
                            :is-odd="i % 2 !== 0"
                            :tags="note.taggedObjects"
                            :location-id="note.locationId"
                            @delete-note="deleteNote($event)"
                            @start-edit-note="startEditNote($event)"
                            @cancel-edit-note="cancelEditNote($event)"
                            @edited="edited($event)"
                            :editing="editNoteLocationId == note.locationId"
                            :relatedContentId="relatedContentId"
                            v-else
                    ></note-line>
                </section>

                <pagination :current="page" :page-count="pageCount" v-on:paginated="paginate($event)" v-if="!notesLoading && pageCount > 1"/>
            </div>
        </div>
    </general-container>
    <!-- End Component: organisms/notes-list -->
</template>

<script>
    import RequestHandler from "@/handler/RequestHandler";
    import GeneralContainer from "./general-container.vue";
    import Pagination from "./pagination.vue";
    import { onMounted, ref, watch } from "vue";
    import Preloader from "./preloader";
    import NoteLine from "./note-line";
    import { useStore } from "vuex";
    import CustomSelect from "./custom-select";
    import TagContact from "./tag--contact";
    import TagRole from "./tag--role";
    import TagBudget from "./tag--budget";
    import TagAgency from "./tag--agency";
    import TagVendor from "./tag--vendor";
    import TagContract from "./tag--contract";
    import TagProcurement from "./tag--procurement";
    import { useRoute } from "vue-router";
    import SearchBlock from "./search-block.vue";

    export default {
        components: {
            NoteLine,
            Preloader,
            GeneralContainer,
            Pagination,
            CustomSelect,
            TagContact,
            TagRole,
            TagBudget,
            TagAgency,
            TagVendor,
            TagContract,
            TagProcurement,
            SearchBlock,
        },
        props: ['relatedContentId', 'showFilters', 'uniqueId'],
        computed: {
            agencies: function () {
                return this.$store.getters.agencies;
            }
        },
        setup(props) {
            const store = useStore();
            const itemsPerPage = 8;

            const route = useRoute();
            const notesLoading = ref(true);
            const notes = ref([]);
            const page = ref(1);
            const totalNotesCount = ref(null);
            const pageCount = ref(1);
            const editNoteLocationId = ref(null);
            const sort = ref('date_modified desc');
            const showFilters = ref(props.showFilters);
            const checkedFilters = ref([]);
            const typeFilter = ref('');
            const query = ref('');

            const loadNotes = () => {
                notesLoading.value = true;
                notes.value = [];
                let agencyContentIds = [];
                if (checkedFilters.value.hasOwnProperty('agencies[]')) {
                    agencyContentIds = checkedFilters.value['agencies[]'];
                }
                RequestHandler.loadNotedObjects(
                    itemsPerPage,
                    (page.value - 1) * itemsPerPage,
                    props.relatedContentId ? [props.relatedContentId] : agencyContentIds,
                    sort.value,
                    typeFilter.value,
                    query.value
                )
                    .then(data => {
                        notesLoading.value = false;
                        totalNotesCount.value = data.totalCount || 0;
                        notes.value = data.list || [];
                        pageCount.value = data.pageCount || 1;
                    })
                    .catch(error => {
                        console.error(error);
                        notesLoading.value = false;
                    });
            };

            const paginate = (pageTo) => {
                page.value = pageTo;
                loadNotes();
            };

            const deleteNote = (locationId) => {
                notesLoading.value = true;
                RequestHandler.deleteNote(locationId, store.getters.csrf)
                    .then(() => {
                        if (notes.value.length > 1) {
                            loadNotes();
                        } else {
                            paginate(page.value > 1 ? page.value - 1 : 1);
                        }
                    })
                    .catch((error) => {
                        notesLoading.value = false;
                        alert(error.message || error);
                    });
            };

            const startEditNote = (locationId) => {
                editNoteLocationId.value = locationId;
            };

            const cancelEditNote = () => {
                editNoteLocationId.value = null;
            };

            const edited = () => {
                editNoteLocationId.value = null;
                page.value = 1;
                loadNotes();
            };

            onMounted(() => {
                if (route.query.type) {
                    typeFilter.value = route.query.type;
                }
                loadNotes();
            });

            watch(sort, (now) => {
                page.value = 1;
                loadNotes();
            });

            function clickFilter(event) {
                let name = event.target.name;
                let checked = event.target.checked;
                let value = event.target.value;
                let filters = this.checkedFilters[name] || [];
                let index = filters.indexOf(value);
                if (checked) {
                    filters.push(value);
                } else {
                    if (index !== -1) {
                        filters.splice(index, 1);
                    }
                }
                checkedFilters.value[name] = filters;
                page.value = 1;
                loadNotes();
            };

            const filterByType = (type) => {
                if (type === typeFilter.value) {
                    type = '';
                }
                typeFilter.value = type;
                page.value = 1;
                loadNotes();
            };

            const searchQueryUpdate = (q) => {
                page.value = 1;
                query.value = q;
                loadNotes();
            };

            return {
                paginate, notesLoading, notes, page, totalNotesCount, pageCount,
                deleteNote, startEditNote, cancelEditNote, editNoteLocationId,
                edited, sort, showFilters, checkedFilters, clickFilter,
                filterByType, typeFilter, query, searchQueryUpdate,
            }
        },
        methods: {
            clickCollapse() {
                let element = document.getElementById('filter-container');
                let toggleA = document.getElementsByClassName('search-expand-label')[0];
                let toggleB = document.getElementsByClassName('search-expand-label')[1];
                let expand = false;
                if (element.classList.contains('is-active')) {
                    element.classList.remove('is-active');
                    toggleA.classList.remove('is-active');
                    toggleB.classList.add('is-active');
                } else {
                    element.classList.add('is-active');
                    toggleA.classList.add('is-active');
                    toggleB.classList.remove('is-active');
                }
            },
            reloadPage(){
                window.location.reload();
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";


    .notes-filter span {
        opacity: 0.7;

        &.active {
            opacity: 1;
        }
    }

    .custom-select {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .card {
        background-color: $blue--dark;
        p, label {
            color: $white;
        }
    }

    .search-filter-group {
        margin: 0 auto;
        margin-bottom: 50px;
        ul {
            list-style-type: none;
            margin-left: 0px;
        }
    }

    .search-filter-name {
        font-weight: 600;
        font-family: $header;
        margin-bottom: 0px;
        font-size: 28px;
        border-bottom: 1px solid $blue--med;
    }

    .agency-filter-label {
        height: auto !important;
        margin-bottom: 6px;
        transition: all 0.3s ease;
        &:hover, &:active, &:focus {
            opacity: 0.75;
        }
    }
    .notes-filter span {
        opacity: 0.7;
        &.active {
            opacity: 1;
        }
    }
    .no-results {
        span {
            text-align: center;
            font-size: 25px;
            font-weight: 700;
            width: 100%;
            display: block;
            margin-top: 30px;
            &.emoji {
                font-size: 72px;
            }
            &.text {
                margin-top: 0px;
            }
        }
    }

    @include block('search-expand-label') {
        display: none !important;
        font-weight: bold;
        padding-bottom: 0px;
        background-color: $blue--dark;
        color: $white;
        padding: 5px 10px;
        margin-bottom: 0px;
        border-radius: 0.25rem;
        transition: all .5s ease;
        margin: 0 auto;
        width: 100%;
        text-align: center;
        margin-bottom: 10px;
        cursor: pointer;
        span {
            text-decoration: underline;
        }
        &.is-active {
            display: inline-block !important;
        }
        @include modifier('off') {
            @media screen and (max-width: 900px) {

            }
        }
    }
    #filter-container {
        @media screen and (max-width: 768px) {
            display: none;
            width: 100%;
            &.is-active {
                display: inline-block;
            }
        }
    }
    #filter-container {
        @media screen and (max-width: 768px) {
            display: none;
            width: 100%;
            &.is-active {
                display: inline-block;
            }
        }
    }
    .toggle-container {
        display: none !important;
        width: 100%;
        display: flex;
        @media screen and (max-width: 768px) {
            display: inline-block !important;
        }
    }
   .column.is-one-quarter, .column.is-three-quarters {
        @media screen and (max-width: 768px) {
            width: 100% !important;
            flex-basis: auto !important;
        }
    }

    .button {
        float: right;
        margin-top: 20px;
        border: none;
    }
</style>
