<template>
    <!-- Page: pages/contract -->
    <div class="page-container contract-page">

        <br/>

        <breadcrumbs :items="path"/>

        <div class="container">
            <alert/>
        </div>

        <hr/>

        <div class="container" style="display: flow-root;" v-if="user">
            <userTools/>
        </div>

        <br/>

        <div class="container">
            <contract-info-card v-bind="$props"/>
        </div>

    </div>
    <!-- End Page: pages/contract -->
</template>

<script>
    import Alert from "@/alivue/components/alert";
    import Breadcrumbs from "./breadcrumbs";
    import UserTools from "@/alivue/components/user-tools";
    import ContractInfoCard from "./contract-info-card";
    import useUser from "@/helpers/useUser";
    import ButtonLink from "./button-link";
    import GeneralContainer from "./general-container";
    import SearchBlock from "./search-block";
    import { computed } from "vue";
    import { useStore } from "vuex";

    export default {
        components: {
            Alert,
            UserTools,
            Breadcrumbs,
            ContractInfoCard,
            ButtonLink,
            GeneralContainer,
            SearchBlock,
        },
        props: {
            contentId: Number,
            locationId: Number,
            beginDate: String,
            contractType: String,
            endDate: String,
            factsId: String,
            flairContractId: String,
            grantId: String,
            methodOfProcurement: String,
            name: String,
            newEndDate: String,
            orderDate: String,
            poNumber: String,
            serviceType: String,
            totalAmount: Number,
            vendor: Object,
            agency: Object,
            organizationAllotments: Array,
            programs: Array,
            factsDetailsUrl: String,
        },
        methods: {
        },
        setup(props) {
            const {user} = useUser();

            const store = useStore();
            const showSearch = computed(() => store.getters.showSearch);

            return {
                user,
                path: [{title: props.name}],
                showSearch,
            }
        }
    }
</script>

<style lang="scss" scoped>
    .filter-wrapper {
        max-width: 50%;
        margin: auto;
        margin-top: 20px;
        @media screen and (max-width: 768px) {
            width: 90%;
            max-width: 90%;
        }
        .search-block {
            margin-bottom: 30px;
        }
    }
</style>
