<template>
    <!-- Component: organisms/search-result-card--position -->
    <searchResultCard type="position" :position="$props">

        <div class="section">
            <div class="content">

                <p class="card__info__label">Position</p><br>

                <h1 class="card__info__title" >
                    <a :href="url">{{ name }}</a>
                </h1>

                <div class="columns">
                    <div class="column">
                        <p class="card__info__label">Agency</p>
                        <tagAgency :tagText="agency.name" :tagUrl="agency.url"/>
                    </div>

                    <!--div class="column">
                        <p class="card__info__label">Seniority</p>
                        <p class="card__info__data">{{ resultSeniority }}</p>
                    </div>

                    <div class="column">
                        <p class="card__info__label">Employees Overseen</p>
                        <p class="card__info__data">{{ resultEmployees }}</p>
                    </div-->
                </div>

                <!--div class="columns">
                    <div class="column is-two-thirds">
                        <p class="card__info__label">Currently in Position</p>
                        <tagContact :tagText="resultContact" :leadership="true"/>
                    </div>

                </div-->

            </div>

        </div>
    </searchResultCard>
    <!-- End Component: organisms/search-result-card--position -->
</template>

<script>
    import SearchResultCard from "./search-result-card";
    import TagAgency from "./tag--agency";
    import TagContact from "./tag--contact";
    import LeadershipMarker from "./leadership-marker";

    export default {
        name: 'SearchResultCardPosition',
        components: {SearchResultCard, TagAgency, TagContact, LeadershipMarker},
        props: [
            'url',
            'name',
            'contentId',
            'locationId',
            'agency',
        ],
        data() {
            return {
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/mixins/_bemify.scss";

    @include block('card') {
        @include element('info') {
            @include element('label') {
                float: left;
                margin-right: 10px;
            }
        }
    }
    .gov-tag {
        margin-right: 5px;
    }

    .contact-is-leader {
        display: flow-root;

        h1 {
            display: inline-block;
            float: left;
            margin-right: 5px;
        }
    }

    @include block('card') {
        .media {
            display: contents;
        }
    }

    .contact-block {
        .card__info__label {
            margin-bottom: 15px !important;
        }

        .columns {
            margin-bottom: 0px !important;

            .column {
                padding-bottom: 0px;
                padding-top: 0px;
                padding-left: 0px;
            }
        }
    }
</style>

