import RequestHandler from '../handler/RequestHandler';

export const createDefinitionsStore = () => {
    return {
        state: {
            definitions: {},
        },
        actions: {
            loadAllDefinitions(context) {
                return RequestHandler.loadAllDefinitions()
                    .then(ContentList => {
                        let list = ContentList.list;
                        let keyed = {};
                        if (!list || !list.length) {
                            context.commit('setDefinitions', keyed);
                            
                            /*setTimeout(function() {
                                context.dispatch('loadAllDefinitions');
                            }, 1000);*/

                            return;
                        }
                        for (let i = 0; i < list.length; i++) {
                            keyed[list[i].name] = list[i];
                        }
                        context.commit('setDefinitions', keyed);
                    })
                    .catch(response => {
                        //setTimeout(function() {
                        //    context.dispatch('loadAllDefinitions');
                        //}, 3000);
                    });
            },
        },
        mutations: {
            setDefinitions(state, x) {
                return state.definitions = x;
            },
        },
        getters: {
            definitions: state => state.definitions,
        },
    };
};
