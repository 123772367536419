import LocalStorageHandler from "@/handler/LocalStorageHandler";

export const scrollBehavior = function (to, from, savedPosition) {
    let position = 0;
    window.onpopstate = function() {
        position = getScrollPosition(to.path)
    }

    if (to.hash) {
        return {
          el: to.hash,
          behavior: 'smooth',
        };
    }

    return new Promise((resolve, reject) => {
        setTimeout(() => {
            resolve({ top: position })
        }, 800)
    });
}

export const setScrollPosition = function (key, position, expiration) {
    let pageScroll = { expiration: new Date().getTime() + (1000 * 60 * expiration), scrollY: position };
    LocalStorageHandler.set(key, JSON.stringify(pageScroll));
}

function getScrollPosition(key) {
    let savedLocal = JSON.parse(LocalStorageHandler.get(key));
    if (savedLocal) {
        if (isExpired(savedLocal.expiration)) {
            LocalStorageHandler.remove(key);
            return 0;
        }
        return savedLocal.scrollY;
    }
    return 0;
}

function isExpired(expiration) {
    return expiration < new Date().getTime();
}
