<template>
    <!-- Component: organisms/iframe-modal -->
    <div id="iframe-modal" class="modal contact-notes-modal is-active">
        <div class="modal-background" @click="$emit('close')"></div>

        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title" v-if="title">{{ title }}</p>
                <p class="modal-card-title" v-else>Contract Details</p>
                <button class="delete" aria-label="close" @click="$emit('close')"></button>
            </header>

            <section class="modal-card-body">
                <div class="modal-card-content">
                    <slot></slot>
                    <iframe :src="sourceURL" :title="sourceTitle + ' contract details'" width="100%" height="100%"></iframe>
                </div>
            </section>
        </div>

    </div>
    <!-- End Component: organisms/iframe-modal -->
</template>

<script>
    export default {
        name: 'IframeModal',
        props: [
            'sourceURL',
            'sourceTitle',
            'title',
        ],
        emits: ['close', 'created'],
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";

    #iframe-modal {
        padding-top: 80px;
        padding-bottom: 80px;
    }
    .modal-card {
        height: 100vh;
        @media screen and (min-width: 769px) {
            width: 1060px;
            height: 100vh;
        }
    }
    .modal-card-title {
        margin-bottom: 0px;
    }
    .modal-card-content {
        display: contents;
    }
    .modal-card-foot {
        display: block;
        a {
            float: right;
        }
    }
</style>

