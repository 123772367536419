<template>
    <!-- Component: atoms/button-link -->
    <a :href="href" class="button-link" :class="customClass" :target="target">{{ contents }}</a>
    <!-- End Component: atoms/button-link -->
</template>

<script>
    export default {
        name: 'ButtonLink',
        props: ['href', 'contents', 'customClass', 'target'],
        data() {
            return {}
        },
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";

    .button-link {
        text-align: center;
        width: 100%;
        display: block;
        text-decoration: none;
        border-bottom: 2px dashed $blue--med;
        padding-left: 30px;
        padding-right: 30px;
        margin-top: 30px;
        margin-bottom: 30px;
        transition: $animate--long;

        &:hover, &:active, &:focus {
            color: darken($blue--med, 20%);
        }

        @media screen and (min-width: 768px) {
            max-width: 50%;
            margin: 0 auto;
        }
    }
</style>
