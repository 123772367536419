<template>
    <!-- Component: organisms/travel-history-block -->
    <general-container
        :custom-class="['travel-history-block', customClass]"
        :title="calculatedTitle"
        :isLocked="false"
        v-if="travels && travels.length > 0"
    >
        <info-block-travel
            v-for="travel in travels"
            :dateFrom="travel.startDate && travel.startDate.format"
            :dateTo="travel.endDate && travel.endDate.format"
            :destination="travel.destination"
            :event="travel.purpose"
            :cost="travel.totalCost ? ('$' + travel.totalCost) : null"
        />

        <read-more-link
            contents="See Full Travel History" v-if="showReadMore"
            :url="contactUrl ? '/contact-travels' + contactUrl : null"
        />
    </general-container>

    <general-container
        :custom-class="['travel-history-block', customClass]"
        :title="calculatedTitle"
        :isLocked="true"
        :isMemberOnly="false"
        v-else
    >
        <div class="no-data">
            <p class=" no-data__icon">😔</p>
            <p class=" no-data__title">Sorry!</p>
            <p>There is no state travel history for this contact.</p>
        </div>
    </general-container>
    <!-- End Component: organisms/travel-history-block -->
</template>

<script>
    import GeneralContainer from "./general-container"
    import InfoBlockTravel from "./info-block--travel"
    import ReadMoreLink from "./read-more-link"
    import { onMounted, ref } from "vue";
    import RequestHandler from "@/handler/RequestHandler";

    export default {
        name: 'TravelHistoryBlock',
        props: ['contactId', 'contactUrl', 'contactName', 'customClass', 'showName'],
        components: {
            GeneralContainer,
            InfoBlockTravel,
            ReadMoreLink
        },
        setup(props) {
            const travels = ref([]);
            const showReadMore = ref(false);

            onMounted(() => {
                if (props.contactId) {
                    RequestHandler.loadContactTravels(props.contactId).then(({items, hasNextPage}) => {
                        travels.value = items;
                        showReadMore.value = hasNextPage;
                    }).catch(error => console.error(error));
                }
            });

            return {travels, showReadMore};
        },
        computed: {
            calculatedTitle() {
                if (this.showName == false) {
                    return 'Travel History'
                } else {
                    return "'Travel History' + (contactName ? ' for ' + contactName : '')"
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";
/*
    .media + .media {
        border-top: 1px solid $blue--med;
    }
*/
    @include block('no-data') {
        font-weight: 600;
        font-family: $header;
        font-size: 1.25rem;
        text-align: center;
        margin: 0 auto;
        @include element('title') {
            font-size: 1.5rem;
            margin-bottom: 5px;
        }
        @include element('icon') {
            font-size: 3.5rem;
            margin: 0 auto -15px auto;
            width: 70px;
            height: 96px;
        }
    }
</style>

