<template>
    <!-- Component: organisms/contact-notes-modal -->
    <div id="contact-notes-modal" class="modal contact-notes-modal is-active">
        <div class="modal-background" @click="$emit('close')"></div>
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">{{ typeTitle }} Notes</p>
                <button class="delete" aria-label="close" @click="$emit('close')"></button>
            </header>

            <section class="modal-card-body">
                <span class="contact-is-leader" v-if="contact?.agencyHead">
                  <p class="modal-card-title modal-card-title__name">{{ objectTitle }}</p>
                  <leadership-marker/>
                </span>
                <p class="modal-card-title modal-card-title__name" v-else>{{ objectTitle }}</p>

                <nav class="level">
                    <div class="level-left">
                        <div class="level-item">
                            <button class="button button--plus" @click.prevent="showCreate = !showCreate">
                                <img src="@/assets/svg/plus.svg" alt="plus icon" v-if="!showCreate"/>
                                <span class="label">{{ showCreate ? 'Hide' : 'Create' }} New Note</span>
                            </button>
                        </div>
                    </div>

                    <!--div class="level-right">
                        <div class="level-item">
                            <button class="button button--list">
                                <span class="label">Select Multiple</span>
                                <img src="@/assets/svg/list.svg"/>
                            </button>
                        </div>
                    </div-->
                </nav>

                <note-create
                    v-if="showCreate"
                    :contact="contact"
                    :agency="agency"
                    :position="position"
                    :program="program"
                    :programcategory="programcategory"
                    :vendor="vendor"
                    :contract="contract"
                    :procurement="procurement"
                    :document="document"
                    @created="newNoteCreated"
                />

                <div class="modal-card-content">
                    <slot></slot>
                </div>
            </section>

            <footer class="modal-card-foot">
                <!-- pagination inside note list slot <pagination></pagination>-->
            </footer>
        </div>
    </div>
    <!-- End Component: organisms/contact-notes-modal -->
</template>

<script>
    import LeadershipMarker from "./leadership-marker"
    import NoteCreate from "./note-create";
    import { ref } from "vue";
    import { getContentId, getContentTitle, contactFullName } from "@/helpers/objects";

    export default {
        name: 'ContactNotesModal',
        props: [
            'contact',
            'position',
            'agency',
            'program',
            'programcategory',
            'vendor',
            'contract',
            'procurement',
            'document',
        ],
        emits: ['close', 'created'],
        components: {
            NoteCreate,
            LeadershipMarker
        },
        setup(props, {emit}) {
            const showCreate = ref(false);

            let typeTitle = '';
            const objectTitle = getContentTitle(props.contact, props.position, props.agency, props.program, props.programcategory, props.vendor, props.contract, props.procurement, props.document);
            const contentId = getContentId(props.contact, props.position, props.agency, props.program, props.programcategory, props.vendor, props.contract, props.procurement, props.document);
            if (props.agency) {
                typeTitle = 'Agency';
            } else if (props.position) {
                typeTitle = 'Position';
            } else if (props.contact) {
                typeTitle = 'Contact';
            } else if (props.program) {
                typeTitle = 'Program';
            } else if (props.programcategory) {
                typeTitle = 'Program Category';
            } else if (props.vendor) {
                typeTitle = 'Vendor';
            } else if (props.contract) {
                typeTitle = 'Contract';
            }  else if (props.procurement) {
                typeTitle = 'Procurement';
            } else if (props.document) {
                typeTitle = 'Document';
            }

            const newNoteCreated = () => {
                showCreate.value = false;
                emit('created');
            }

            return {typeTitle, contentId, objectTitle, contactFullName, showCreate, newNoteCreated};
        },
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";

    .pagination {
        margin: 0 auto;
    }

    .modal-card {
        z-index: 2147483647;
    }

    .modal-card-head {
        padding-bottom: 0px;
    }

    .modal-card-head, .modal-card-foot {
        border: none;
        background-color: white;
    }

    .modal-card-title {
        color: $grey--dark;
        font-size: 18px;
        margin-bottom: 0px;

        &.modal-card-title__name {
            font-weight: 600;
            color: $blue--dark;
            font-size: 28px;
            margin-bottom: 30px;
        }
    }

    .contact-is-leader {
        .modal-card-title {
            display: inline-block;
        }

        img {
            position: relative;
            top: -7px;
            left: 3px;
        }
    }

    @include block('button') {
        background-color: transparent;
        color: $blue--dark;
        border: none;
        @include modifier('plus') {
            .label {
                margin-left: 10px;
            }
        }
        @include modifier('list') {
            .label {
                margin-right: 10px;
            }
            img {
                position: relative;
                top: -3px;
            }
        }
    }

    .level {
        border-bottom: 2px solid $blue--dark;
        padding-bottom: 10px;
    }

    @media screen and (min-width: 769px) {
        .modal-card {
            width: 740px;
        }
    }

    @media screen and (min-width: 1200px) {
        .modal-card {
            min-width: 1100px;
            width: 80%;
        }
    }

    @include block('contact-notes-modal') {

    }

    #contact-notes-modal {
        z-index: 2147483646;
        .tag-0 button {
            display: none;
        }
    }
</style>

