<template>
    <!-- Component: organisms/contracts-list -->

    <general-container :title="title" :titleClass="titleClass" :custom-class="customContainerClass">


        <div class="responsive-table" :class="scrollable == false ? '' : 'tbl-scrollable'" @scroll.passive="loadMoreOnScroll($event)">
            <table class="zebra" :key="random">
                <thead>
                <tr v-if="date=='end'">
                    <th v-if="typeof hideAgencyName == 'undefined' || !hideAgencyName" class="not-sortable" scope="col">
                        Agency
                    </th>
                    <th class="clickable" @click="sortField = 'name'; sortIsAsc = !sortIsAsc" scope="col">
                        Name {{ sortField === 'name' ? (sortIsAsc ? '⇩' : '⇧') : '' }}
                    </th>
                    <th class="clickable has-tooltip-hover" @click="sortField = 'end_date'; sortIsAsc = !sortIsAsc" scope="col" v-if="definitions['End date']">
                        <span class="word">End date</span>
                        <span class="tooltip">
                                {{ definitions['End date'].definition }}
                            </span>
                        {{ sortField === 'end_date' ? (sortIsAsc ? '⇩' : '⇧') : '' }}
                    </th>
                    <th class="clickable" @click="sortField = 'end_date'; sortIsAsc = !sortIsAsc" scope="col" v-else>
                        End date {{ sortField === 'end_date' ? (sortIsAsc ? '⇩' : '⇧') : '' }}
                    </th>
                </tr>

                <tr v-if="date=='begin'">
                    <th v-if="typeof hideAgencyName == 'undefined' || !hideAgencyName" class="not-sortable" scope="col">
                        Agency
                    </th>
                    <th class="clickable" @click="sortField = 'name'; sortIsAsc = !sortIsAsc" scope="col">
                        Name {{ sortField === 'name' ? (sortIsAsc ? '⇩' : '⇧') : '' }}
                    </th>
                    <th class="clickable has-tooltip-hover" @click="sortField = 'begin_date'; sortIsAsc = !sortIsAsc" scope="col" v-if="definitions['Begin date']">
                        <span class="word">Begin date</span>
                        <span class="tooltip">
                                {{ definitions['Begin date'].definition }}
                            </span>
                        {{ sortField === 'begin_date' ? (sortIsAsc ? '⇩' : '⇧') : '' }}
                    </th>
                    <th class="clickable" @click="sortField = 'begin_date'; sortIsAsc = !sortIsAsc" scope="col" v-else>
                        Begin date {{ sortField === 'begin_date' ? (sortIsAsc ? '⇩' : '⇧') : '' }}
                    </th>
                </tr>
                </thead>

                <tbody>

                <template v-if="date=='end'" v-for="(contractGroup, contractGroupIndex) in contractsGrouped" :key="'contractsGrouped-' + i">
                    <tr>
                        <td v-if="typeof hideAgencyName == 'undefined' || !hideAgencyName" data-label="Agency">
                            <template v-if="contractGroup['aggregate'].agency">
                                <a :href="contractGroup['aggregate'].agency.AgencyRef.url">
                                    {{ contractGroup['aggregate'].agency.AgencyRef.name }}
                                </a>
                            </template>
                        </td>
                        <td data-label="Name">
                            <template v-if="contractGroup['contracts'].length > 1">
                                {{ titleize(contractGroup['aggregate'].name) }}
                            </template>
                            <template v-if="contractGroup['contracts'].length == 1">
                                <template v-if="contractGroup['aggregate'].url">
                                    <a :href="contractGroup['aggregate'].url" v-if="subflyout == false">{{ titleize(contractGroup['aggregate'].name) }}</a>
                                    <button class="contract-button" :aria-label="titleize(contractGroup['aggregate'].name)" @click="emit('call-open-flyout-contract', contractGroup['contracts'][0].locationId)" v-else>{{ titleize(contractGroup['aggregate'].name) }}</button>
                                </template>
                                <template v-else>
                                    <div :class="contractGroup['aggregate'].name == 'Total' ? 'total total-name' : ''">{{ titleize(contractGroup['aggregate'].name) }}</div>
                                </template>
                            </template>
                        </td>
                        <td data-label="End date">{{ contractGroup['aggregate'].endDate?.includes('9999') ? 'No Current End Date' : contractGroup['aggregate'].endDate }}</td>
                    </tr>

                    <tr
                        v-if="contractGroup['contracts'].length > 1"
                        v-for="(contract, i) in contractGroup['contracts']"
                        :key="contract.contentId"
                        :class="[
                            'extra',
                            isContractGroupShown(contractGroupIndex) ? 'shown' : 'hidden'
                        ]"
                    >
                        <td v-if="typeof hideAgencyName == 'undefined' || !hideAgencyName" data-label="Agency">
                            <template v-if="contractGroup['aggregate'].agency">
                                <a :href="contractGroup['aggregate'].agency.AgencyRef.url">
                                    {{ contractGroup['aggregate'].agency.AgencyRef.name }}
                                </a>
                            </template>
                        </td>
                        <td data-label="Name">
                            <template v-if="contract.url">
                                <a :href="contract.url" v-if="subflyout == false" target="_blank">{{ contract.name }}</a>
                                <button class="contract-button" :aria-label="contract.name" @click="emit('call-open-flyout-contract', contract.locationId)" v-else>{{ contract.name }}</button>
                            </template>
                            <template v-else>
                                <div :class="contract.name == 'Total' ? 'total total-name' : ''">{{ contract.name }}</div>
                            </template>
                        </td>
                        <td  data-label="End date">{{ contract.endDate?.includes('9999') ? 'No Current End Date' : contractGroup['aggregate'].endDate }}</td>
                    </tr>
                </template>
                <template v-if="date=='begin'" v-for="(contractGroup, contractGroupIndex) in contractsGrouped" :key="'contractsGrouped-' + i">
                    <tr>
                        <td v-if="typeof hideAgencyName == 'undefined' || !hideAgencyName" data-label="Agency">
                            <template v-if="contractGroup['aggregate'].agency">
                                <a :href="contractGroup['aggregate'].agency.AgencyRef.url">
                                    {{ contractGroup['aggregate'].agency.AgencyRef.name }}
                                </a>
                            </template>
                        </td>
                        <td data-label="Name">
                            <template v-if="contractGroup['contracts'].length > 1">
                                {{ titleize(contractGroup['aggregate'].name) }}
                            </template>
                            <template v-if="contractGroup['contracts'].length == 1">
                                <template v-if="contractGroup['aggregate'].url">
                                    <a :href="contractGroup['aggregate'].url" v-if="subflyout == false">{{ titleize(contractGroup['aggregate'].name) }}</a>
                                    <button class="contract-button" :aria-label="titleize(contractGroup['aggregate'].name)" @click="emit('call-open-flyout-contract', contractGroup['contracts'][0].locationId)" v-else>{{ titleize(contractGroup['aggregate'].name) }}</button>
                                </template>
                                <template v-else>
                                    <div :class="contractGroup['aggregate'].name == 'Total' ? 'total total-name' : ''">{{ titleize(contractGroup['aggregate'].name) }}</div>
                                </template>
                            </template>
                        </td>

                        <td data-label="Begin date">{{ contractGroup['aggregate'].beginDate }}</td>
                    </tr>

                    <tr
                        v-if="contractGroup['contracts'].length > 1"
                        v-for="(contract, i) in contractGroup['contracts']"
                        :key="contract.contentId"
                        :class="[
                            'extra',
                            isContractGroupShown(contractGroupIndex) ? 'shown' : 'hidden'
                        ]"
                    >
                        <td v-if="typeof hideAgencyName == 'undefined' || !hideAgencyName" data-label="Agency">
                            <template v-if="contractGroup['aggregate'].agency">
                                <a :href="contractGroup['aggregate'].agency.AgencyRef.url">
                                    {{ contractGroup['aggregate'].agency.AgencyRef.name }}
                                </a>
                            </template>
                        </td>
                        <td data-label="Name">
                            <template v-if="contract.url">
                                <a :href="contract.url" v-if="subflyout == false" target="_blank">{{ contract.name }}</a>
                                <button class="contract-button" :aria-label="contract.name" @click="emit('call-open-flyout-contract', contract.locationId)" v-else>{{ contract.name }}</button>
                            </template>
                            <template v-else>
                                <div :class="contract.name == 'Total' ? 'total total-name' : ''">{{ contract.name }}</div>
                            </template>
                        </td>
                        <td  data-label="Start date">{{ contract.beginDate }}</td>
                    </tr>
                </template>
                <tr v-if="(loading || page < pageCount) && (scrollable == true)">
                    <td colspan="7">
                        <preloader/>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </general-container>
    <!-- End Component: organisms/contracts-list -->
</template>

<script>
import RequestHandler from "@/handler/RequestHandler";
import GeneralContainer from "./general-container";
import { computed, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import Preloader from "./preloader";
import { priceFormat } from "@/helpers/filter";
import CustomSelect from "./custom-select";
import { useEventsBus } from "@/helpers/eventBus";
import * as dateFormat from 'dateformat';

export default {
    components: {
        Preloader,
        GeneralContainer,
        CustomSelect,
    },
    props: ['vendorId',
        'uniqueId',
        'programId',
        'title',
        'titleClass',
        'contractsIds',
        'customContainerClass',
        'scrollable',
        'hideAgencyName',
        'subflyout',
        'minAmount',
        'maxAmount',
        'beginDate',
        'endDate',
        'date'
    ],
    data() {
        return {
            contractGroupShowing: {},
            random: Math.random(),
        };
    },
    methods: {
        isContractGroupShown(contractGroupIndex) {
            if (!this.contractGroupShowing.hasOwnProperty(contractGroupIndex)) {
                return false;
            }
            return this.contractGroupShowing[contractGroupIndex];
        },
        showContractGroup(contractGroupIndex) {
            if (!this.contractGroupShowing.hasOwnProperty(contractGroupIndex)) {
                this.contractGroupShowing[contractGroupIndex] = true;
                this.random = Math.random();
                this.$forceUpdate();
                return;
            }

            this.contractGroupShowing[contractGroupIndex] = !this.contractGroupShowing[contractGroupIndex];
            this.random = Math.random();
            this.$forceUpdate();
        },
        titleize: function(item) {
            return item.toLowerCase().replace(/(?:^|\s|-)\S/g, x => x.toUpperCase());
        },
        updateFilters: function (val) {
            if (this.appliedFilters.indexOf(val) !== -1) {
                return;
            }

            if (val === 'year' || val === 'type' ||val === 'agencyId') {
                let allRemove = document.querySelectorAll("[data-name='" + val + "']");
                for (let i = 0; i < allRemove.length; i++) {
                    allRemove[i].parentNode.removeChild(allRemove[i]);
                }

                if (val === "year") {
                    this.yearValues = [];
                } else  if (val === "type") {
                    this.typesValues = [];
                } else   {
                    this.agencyId = '';
                }
                this.loadContracts(true);
                return;
            }

            let filter = val;
            let filterType = '';
            this.appliedFilters = [filter.toString()];

            if (this.years.find(o => o.value === val)) {
                filterType = 'year';
                //this.yearValues.push(filter);
                this.yearValues = [filter];
            } else if (this.types.find(o => o.value === val)) {
                filterType = 'type';
                this.typesValues = [filter]
            } else if (this.agencies.find(o => o.name === val)) {
                filterType = 'agency';
                this.agency.name = [filter]
                let agency = this.agencies.find(o => o.locationId === val);
            }

            this.loadContracts(true);
        },
        removeFilter: function (elem) {
            let filterValue = ''; let filterName = '';
            if (elem.target.nodeName === 'SPAN') {
                filterValue = elem.target.parentNode.getAttribute('data-value');
                filterName = elem.target.parentNode.getAttribute('data-name');
                elem.target.parentNode.remove();
            } else {
                filterValue = elem.target.dataset.value;
                filterName = elem.target.dataset.name;
                elem.target.remove();
            }

            let valType = false;
            let index = this.appliedFilters.indexOf(filterValue);
            if (index !== -1) {
                this.appliedFilters.splice(index, 1);
            }

            if (filterName === "type") {
                this.typesValues.splice(this.typesValues.indexOf(filterValue),1);
                if (!this.typesValues.length) {
                    valType = 'type';
                }
            } else {
                this.agencyIds.splice(this.agencyIds.indexOf(parseInt(filterValue)),1);
                if (!this.agencyIds.length) {
                    valType = 'agencyId';
                }
            }

            if (valType) {
                this.hideDropdown = true;
                let valSelect = document.getElementById('dm' + valTyp + '-' + this.uniqueId);
                let valOption = (valType === 'agencyId') ? 'agencies' : valType + 's';
                valSelect.click();

                setTimeout(() => {
                    let itemSelect = document.getElementById(valType + '-all-' + valOption);
                    itemSelect.click();
                    this.hideDropdown = false;
                }, 400)
            } else {
                this.loadContracts(true);
            }

            let buttonEl = document.createElement('button');
            buttonEl.setAttribute("class", "button-filter button btn");
            buttonEl.addEventListener("click", this.removeFilter);
        },
    },
    computed: {
        contractsGrouped: function() {
            let v = [];
            let seenNames = [];
            for (let i = 0; i < this.contracts.length; i++) {
                let name = this.contracts[i].name;
                if (seenNames.indexOf(name) >= 0) {
                    continue;
                }
                let contracts = [];
                let contractType = this.contracts[i].type
                contracts.push(this.contracts[i]);
                for (let j = i + 1; j < this.contracts.length; j++) {
                    let jName = this.contracts[j].name;
                    if (name !== jName) {
                        continue;
                    }
                    contracts.push(this.contracts[j]);
                    contractType =contractType !== this.contracts[j].type ? '' : this.contracts[j].type;
                }
                seenNames.push(name);

                let aggregate = {
                    'name': contracts[0].name,
                    'url': contracts[0].url,
                    'type': contractType,
                    'totalAmount': 0,
                    'beginDate': null,
                    'endDate': null,
                    'newEndDate': null,
                    'agency': contracts[0].agency,
                };
                let dateKeys = ['beginDate', 'endDate',];
                for (let j = 0; j < contracts.length; j++) {
                    aggregate['totalAmount'] += contracts[j].totalAmount;
                    for (let k = 0; k < dateKeys.length; k++) {
                        if (!contracts[j][dateKeys[k]]) {
                            continue;
                        }
                        let thisDate = new Date(contracts[j][dateKeys[k]]);
                        if (aggregate[dateKeys[k]] === null) {
                            aggregate[dateKeys[k]] = contracts[j][dateKeys[k]];
                            continue;
                        }
                        let aggregateDate = new Date(aggregate[dateKeys[k]]);
                        if (dateKeys[k] === 'beginDate') {
                            if (thisDate < aggregateDate) {
                                aggregate[dateKeys[k]] = contracts[j][dateKeys[k]];
                            }
                        }
                        else {
                            if (thisDate > aggregateDate) {
                                aggregate[dateKeys[k]] = contracts[j][dateKeys[k]];
                            }
                        }
                    }
                }

                let item = {
                    'name': name,
                    'aggregate': aggregate,
                    'contracts': contracts,
                };
                v.push(item);
            }
            return v;
        },
    },
    setup(props) {
        const itemsPerPage = 5;

        const {emit}=useEventsBus();

        const loading = ref(true);
        const contracts = ref([]);
        const page = ref(1);
        const keyword = ref('');
        const totalContractsCount = ref(null);
        const pageCount = ref(1);
        const sortField = ref('total_amount');
        const sortIsAsc = ref(false);
        const selectedContract = ref(null);
        const store = useStore();
        const definitions = computed(() => store.getters.definitions);
        const contractContentId = ref(null);
        const contractProps = ref(null);
        const appliedFilters = ref([]);
        let currentYear = new Date().getFullYear();
        let yrs = [
            {
                value: currentYear.toString(),
                name: "Current fiscal year",
            },
            {
                value: "all",
                name: "All years",
            }
        ];
        const years = ref(yrs);
        const year = ref(currentYear.toString());
        const yearValues = ref([currentYear.toString()]);
        const type = ref('');
        const typesValues = ref();
        const types = ref([
            {
                value: 'type',
                name: 'All Types',
            },
            {
                value: 'Contract',
                name: 'Contract',
            },
            {
                value: 'PO',
                name: 'Purchase Order',
            },
            {
                value: 'Grant',
                name: 'Grant',
            },
        ]);
        const agencies = computed(() => store.getters.agencies || []);
        const agencyId = ref('');
        const agencyIds = ref([]);

        const loadContracts = (reset) => {
            if (reset) {
                contracts.value = [];
                page.value = 1;
                totalContractsCount.value = null;
            }
            loading.value = true;
            const beginDateFilter = props.date === 'begin' ? dateFormat(new Date(), "yyyy-mm-dd") : props.beginDate; 
            const endDateFilter = props.date === 'end' ? dateFormat(new Date(), "yyyy-mm-dd") : props.endDate; 
            const dateOrderFilter = props.date === 'begin' ? 'begin_date' : 'end_date';
            const dateOrderFilterSort = (props.date === 'begin' || props.date === 'end') ? 1 : sortIsAsc.value;
            const typedata = (props.date === 'begin' || props.date === 'end') ? 'Contract' : typesValues.value;

            RequestHandler.loadContracts(
                itemsPerPage,
                (page.value - 1) * itemsPerPage,
                props.vendorId,
                props.programId,
                agencyId.value,
                props.contractsIds,
                keyword.value,
                dateOrderFilter,
                dateOrderFilterSort,
                props.minAmount,
                props.maxAmount,
                props.beginDate,
                props.endDate,
                year.value,
                typedata,
                false,
                props.date,
            )
                .then(data => {
                    loading.value = false;
                    totalContractsCount.value = data.totalCount || 0;
                    contracts.value = contracts.value.concat(...(data.list || []));
                    pageCount.value = data.pageCount || 1;

                    if (page.value * itemsPerPage >= totalContractsCount.value && (props.date !== 'begin' && props.date !== 'end')) {
                        let total = 0;
                        for (let j = 0; j < contracts.value.length; j++) {
                            total += contracts.value[j].totalAmount;
                        }
                        contracts.value.push({
                            'name': 'Total',
                            'url': null,
                            'totalAmount': total,
                            'beginDate': null,
                            'endDate': null,
                            'newEndDate': null,
                        });
                    }
                })
                .catch(error => {
                    console.error(error);
                    loading.value = false;
                });
        };

        let timer = null;
        watch(keyword, (newVal) => {
            if (timer) {
                clearTimeout(timer);
            }
            timer = setTimeout(() => loadContracts(true), 300);
        });

        watch(sortIsAsc, () => loadContracts(true));
        watch(props, () => loadContracts(true));

        const paginate = (pageTo) => {
            page.value = pageTo;
            loadContracts();
        };

        const loadMoreOnScroll = (e) => {
            const target = e.target;
            const scrolledTo = target.scrollTop + target.clientHeight;
            const loadAtScroll = target.scrollHeight - 350;
            if (!loading.value && scrolledTo >= loadAtScroll && page.value * itemsPerPage < totalContractsCount.value) {
                page.value = page.value + 1;
                loadContracts();
            }
        };

        onMounted(() => {
            loadContracts();
        });

        return {
            paginate,
            loading,
            contracts,
            page,
            totalContractsCount,
            pageCount,
            keyword,
            priceFormat,
            sortField,
            sortIsAsc,
            loadMoreOnScroll,
            selectedContract,
            contractContentId,
            contractProps,
            definitions,
            years,
            yearValues,
            year,
            agencies,
            agencyId,
            agencyIds,
            types,
            typesValues,
            type,
            appliedFilters,
            loadContracts,
            emit,
        }
    }
}
</script>

<style lang="scss" scoped>

    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";
    @import "../../assets/scss/helpers/responsive-tables";

    table {
        margin: auto;
        display: inline-table;

        th {
            color: $white;
            background: $blue--dark;
            background: $blue-gradient;
        }

        tfoot td {
            background-color: $grey--light;
        }

        td, th {
            padding: 10px;
            border: solid 1px $grey--dark;
        }
    }

    .zebra tr:nth-child(odd) {
        background-color: $white;
    }

    .tbl-scrollable {
        max-height: 75vh;
        overflow-y: auto;
        @media screen and (min-width: 601px) {
            background-color: $white;
        }
        table {
            width: 100%;
        }
        thead th {
            position: sticky;
            top: 0;
        }
    }

    .fitler-container {
        display: flex!important;
        justify-content: space-evenly;
        flex-wrap: wrap;
    }

    .filter-input {
        .label {
            display: none;
        }
    }
    .filter-info {
        padding-top: 15px;
        padding-left: 12px;
        padding-bottom: 10px;
    }

    .extra.hidden {
        display: none;
    }
    .extra.shown {
    }
    .extra td:first-child a {
        margin-left: 10px;
    }

    .total-name {
        text-align: right;
    }
    .total {
        font-weight: bold;
    }

    .clickable {
        cursor: pointer;
    }
    .clickable span.tooltip {
        display: none;
        position: relative;
        border: 5px solid $blue--dark;
        &:after {
            bottom: 100%;
            left: 20%;
            border: solid transparent;
            content: " ";
            position: absolute;
        }
        &:after {
            border-bottom-color: $blue--dark;
            border-width: 15px;
        }
    }
    .clickable:hover span.tooltip {
        position: absolute;
        top: 45px;
        left: 0%;
        display: inline;
        background-color: $blue--dark;
        color: $white;
        padding: 5px;
        font-weight: 400;
        border-radius: 4px;
    }

    /* Animations to fade the snackbar in and out */
    @-webkit-keyframes fadein {
        from {bottom: 0; opacity: 0;}
        to {bottom: 30px; opacity: 1;}
    }

    @keyframes fadein {
        from {bottom: 0; opacity: 0;}
        to {bottom: 30px; opacity: 1;}
    }

    @-webkit-keyframes fadeout {
        from {bottom: 30px; opacity: 1;}
        to {bottom: 0; opacity: 0;}
    }

    @keyframes fadeout {
        from {bottom: 30px; opacity: 1;}
        to {bottom: 0; opacity: 0;}
    }

    .close-item {
        position: absolute;
        right: 10px;
        &:before {
            font-size: 30px;
            cursor: pointer;
            content: "×";
            color: $blue--dark;
            transition: color .3s;
            font-weight: 600;
            margin-left: 10px;
            position: relative;
            float: right;
            top: -5px;
            left: -5px;
        }
    }
    .contract-button {
        color: $blue--med;
        transition: all 0.3s ease-in-out;
        border: none;
        background: none;
        font-size: 16px;
        text-decoration: underline;
        font-family: Montserrat, sans-serif;
        line-height: 1.5em;
        text-align: left;
        cursor: pointer;
        &:hover, &:focus, &:active {
            color: $blue--dark;
        }
    }

    .filter-container--tags {
        padding: 0 15px;
    }

    .filter-container--main {
        display: inline-block;
        width: 100%;
        .filter-input {
            display: inline-block;
            input {
                height: 60px;
                margin-left: 15px;
            }
        }
        .filter-info {
            display: inline-block;
            float: right;
            margin-top: 15px;
        }
    }
    .button-filter.button.btn {
        &:focus, &:hover {
            color: $white;
        }
    }

</style>