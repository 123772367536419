<template>
    <!-- Component: molecules/info-block--empty -->
    <infoBlock requestedIcon="faInfoCircle" :hasIcon="true" :hasClose="false" :isTweet="false" color="green--light">
        <p class="info-block__title">It looks like you haven't bookmarked any contacts, positions, or agencies yet. Updates to bookmarked objects will show in this list.</p>
    </infoBlock>
    <!-- End Component: molecules/info-block--empty -->
</template>

<script>
    import InfoBlock from "./info-block"

    export default {
        name: 'InfoBlockEmpty',
        components: {
            InfoBlock,
        },
        props: [],
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";

    @include block('info-block') {
        @include element('amount') {

        }
        @include element('spacer') {

        }
    }
</style>
