<template>
    <!-- Component: organisms/search-block -->
    <div class="search-block" :class="setType()">
        <p :id="id" class="search-block__title search-block__title--filter" v-if="type == 'filter'">{{ title }}</p>
        <p class="search-block__title" :id="id"  v-else>{{ title }}</p>
        <a href="#" class="search-block__hide" v-if="hideable == true">Hide Search</a>
<!--        <button @click="focusInput"  > toooo</button>-->
        <div class="field has-addons">

            <div class="control full-width" v-if="button == false">
                <input
                    v-model="modelValue"
                    v-on:focus=""
                    v-on:keyup.enter="emitSearch"
                    v-on:keyup="keyup"
                    v-on:keydown="keydown"
                    class="input search-block__search-input"
                    type="text"
                    :placeholder="placeholder"
                    :aria-labelledby="id"
                    :id="customId"
                    ref="boo"
                >
            </div>
            <div class="control" v-else>
                <input
                    v-model="modelValue"
                    v-on:focus="focus"
                    v-on:keyup.enter="emitSearch"
                    v-on:keyup="keyup"
                    v-on:keydown="keydown"
                    class="input search-block__search-input"
                    type="text"
                    :placeholder="placeholder"
                    :aria-labelledby="id"
                    :id="customId"
                    ref="boo"
                >
            </div>

            <span class="placeholder" v-if="button == false"></span>
            <div class="control" v-else>
                <a class="button" @click="emitSearch">
                    <img src="@/assets/svg/search.svg" class="search-block__search-input__icon" alt="Search"/>
                </a>
            </div>
        </div>
        <div id="autocomplete-results" class="autocomplete-results" ref="autocomplete-results" v-if="showAutocomplete && autocompleteResults && autocompleteResults.length">
            <p class="close-autocomplete" @click="closeLiveSearch">close</p>
            <div v-for="searchItem in autocompleteResults" @click="showAutocomplete = false">
                <p class="card__info__label" v-if="searchItem.objectNameString">{{ searchItem.objectNameString }}</p>
                <a :href="searchItem.url">
                    <span v-if="searchItem.class == 'ContactRef'">
                        {{ searchItem.firstName }} {{ searchItem.middleName }} {{ searchItem.lastName }}
                    </span>
                    <span v-else>
                        {{ searchItem.name }}
                    </span>
                </a>
                <div v-if="searchItem.agency">
                    <span class="card__info__label">
                        Agency:
                    </span>
                    <a :href="searchItem.agency.url">{{ searchItem.agency.name }}</a>
                </div>
            </div>
        </div>
        <a href="#" class="search-block__advanced" v-if="advanced == true">Advanced Search</a>
    </div>
    <!-- End Component: organisms/search-block -->
</template>

<script>
    import RequestHandler from "@/handler/RequestHandler";
    import { v4 as uuidv4 } from "uuid";
    import {ref} from "vue";

    export default {
        name: 'SearchBlock',
        props: [
            'type', 'title', 'placeholder', 'advanced', 'hideable', 'initialValue', 'darkMode', 'customId', 'button'
        ],
        data() {
            return {
                modelValue: '',
                autocompleteResults: [],
                lastAutocompleteSearchQ: '',
                typingTimer: null,
                doneTypingInterval: 200,
                showAutocomplete: false,
                id: null
            };
        },
        components: {},
        mounted() {
            if (this.$props.initialValue) {
                this.modelValue = this.$props.initialValue;
            } else if (this.$route.query.q) {
                this.modelValue = this.$route.query.q;
            }
            this.id = uuidv4();
        },
        watch: {
            $route: function (toRoute) {
                showAutocomplete: false;
                this.modelValue = toRoute.query.q || '';
            }
        },
        methods: {
            clickOutside(event) {
                this.showAutocomplete = false;
            },
            focus(event) {
                this.showAutocomplete = true;
            },
            focusInput () {
                // this.$refs.fullwidthinput.focus()
                this.$refs.boo.focus();
            },
            keydown() {
                clearTimeout(this.typingTimer);
            },
            keyup() {
                this.$emit('search-keyup', this.modelValue);
                if (this.$props.type !== 'autocomplete') {
                    return;
                }

                clearTimeout(this.typingTimer);
                this.typingTimer = setTimeout(this.autocompleteSearch, this.doneTypingInterval);
            },
            closeLiveSearch() {
                this.showAutocomplete = false;
            },
            autocompleteSearch() {
                if (this.modelValue === this.lastAutocompleteSearchQ) {
                    return;
                }

                this.lastAutocompleteSearchQ = this.modelValue;

                if (this.modelValue.length < 2) {
                    this.autocompleteResults = [];
                    return;
                }

                RequestHandler.loadSearch(this.modelValue).then(response => {
                    this.autocompleteResults = response.searchResults || [];
                }).catch((error) => {
                    console.error(error);
                });
            },
            emitSearch() {
                this.showAutocomplete = false;
                this.$emit('search', this.modelValue);


                if (this.$props.type === 'autocomplete') {
                    // close the search first
                    var element = document.getElementById('search-container');
                    element.classList.remove('is-active');


                    // then emit the search
                    this.$router.push('/search?q=' + encodeURIComponent(this.modelValue));
                }
            },
            setType() {
                if (this.darkMode == true) {
                    const compiledType = 'search-block--' + this.type + ' dark-mode';
                    return compiledType;
                }
            }
        },
        setup() {

        }
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";

    .input:hover, .textarea:hover, .select select:hover, .button:hover {
        border-color: $blue--dark;
    }

    @include block('search-block') {
        position: relative;
        margin-bottom: 10px;
        .control:first-of-type:not(.full-width) {
            @media screen and (min-width: 1216px) {
                width: calc(100% - 70px) !important;
            }
            @media screen and (max-width: 960px) {
                width: calc(100% - 70px) !important;
            }
            @media screen and (max-width: 768px) {
                width: calc(100% - 50px) !important;
            }
        }
        .autocomplete-results {
            z-index: 100;
            position: absolute;
            background: white;
            margin-top: -14px;
            border: 1px solid $blue--dark !important;
            width: 100%;
            max-width: 98%;
            > div {
                padding: 10px;
                &:nth-of-type(odd) {
                    background-color: $grey--light;
                }
                &:last-of-type {
                    margin-bottom: 0px;
                }
            }
            &.hidden {
                display: none;
            }
        }
        @include element('title') {
            font-size: 1.75em;
            font-weight: 600;
            color: $white;
            font-family: $header;
            margin-bottom: 5px;
            @include modifier('filter') {
                color: $blue--dark;
            }
        }
        @include element('search-input') {
            border: 2px solid $blue--dark;
            border-right: none;
            height: 55px;
            @include element('icon') {
                width: 28px;
            }
        }
        .button {
            height: 55px;
        }
        @include element('advanced') {
            float: right;
            margin-top: 5px;
        }
        @include element('hide') {
            position: absolute;
            top: 20px;
            right: 0px;
        }
        @include modifier('filter') {

        }
        &.dark-mode {
            .search-block__title {
                color: $white !important;
                font-size: 21px;
            }
            .control {
                &.full-width:first-of-type {
                    width: 100% !important;
                    input {
                        width: 100% !important;
                    }
                }
                input {
                    width: 280px !important;
                    @media screen and (max-width: 768px) {
                        width: 100% !important;
                    }
                }
                &:first-of-type {
                    width: calc(100% - 50px) !important;
                }
            }
        }
    }

    .button {
        background-color: $white;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        border: 2px solid $blue--dark;
        border-left: none;
        padding-right: 0.5em;
    }

    .close-autocomplete {
        float: right;
        text-decoration: underline;
        cursor: pointer;
        font-size: 12px;
        color: #3374aa;
        margin-right: 10px;
        margin-top: 5px;
    }

</style>

