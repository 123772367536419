<template>

    <contract-flyout
        ref="contractFlyoutRef"
        v-bind="contractProps"
        :vendor="contractProps && contractProps.vendor && contractProps.vendor.VendorRef ? contractProps.vendor.VendorRef : null"
        :agency="contractProps && contractProps.agency && contractProps.agency.AgencyRef ? contractProps.agency.AgencyRef : null"
    ></contract-flyout>
    
    <!-- Page: pages/organization -->
    <div class="page-container contract-page">

        <br/>

        <breadcrumbs :items="path"/>

        <div class="container">
            <alert/>
        </div>

        <hr/>

        <div class="container" style="display: flow-root;" v-if="user">
            <userTools/>
        </div>

        <br/>

        <div class="container">
            <div class="info-card__container">
                <div class="card card-overflow card__info">
                    <div class="card-content">
                        <div class="section">
                            <div class="content">
                                <p class="card__info__label top">Organization</p>
                                <p class="card__info__title">{{ name }}</p>

                                <p class="card__info__label">Code</p>
                                <p class="card__info__title">{{ code }}</p>

                                <template v-if="agency">
                                    <div class="tag-container">
                                        <p class="card__info__label card__info__label__with-tag" v-if="agency">
                                            Agency</p>
                                        <tag-agency :tag-text="agency.name" :tag-url="agency.url" v-if="agency"/>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container">
            <div class="columns">
                <div class="column">
                    <general-container
                        customClass="column"
                        title="Related Programs"
                        titleClass="has-text-centered"
                        :isLocked="false"
                    >
                        <div class="tbl-scrollable">
                            <table class="zebra">
                                <thead>
                                <tr>
                                    <th>Program</th>
                                    <th>Allotments</th>
                                    <th>Expenditures</th>
                                    <th>Encumbrances</th>
                                    <th>Total</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="allotment in organizationAllotments">
                                    <td>
                                        <a :href="allotment.budget.url" v-if="allotment.budget">
                                            {{ allotment.budget.name }}
                                        </a>
                                    </td>
                                    <td class="is-family-monospace has-text-right">${{ priceFormat(allotment.allotments) }}</td>
                                    <td class="is-family-monospace has-text-right">${{ priceFormat(allotment.expenditures) }}</td>
                                    <td class="is-family-monospace has-text-right">${{ priceFormat(allotment.encumbrances) }}</td>
                                    <td class="is-family-monospace has-text-right">${{ priceFormat(allotment.total) }}</td>
                                </tr>
                                </tbody>
                                <tfoot v-if="organizationAllotments.length > 1">
                                <tr class="total">
                                    <td class="total-1">Total</td>
                                    <td class="total-2 is-family-monospace has-text-right">${{ priceFormat(getTotal('allotments')) }}</td>
                                    <td class="total-3 is-family-monospace has-text-right">${{ priceFormat(getTotal('expenditures')) }}</td>
                                    <td class="total-4 is-family-monospace has-text-right">${{ priceFormat(getTotal('encumbrances')) }}</td>
                                    <td class="total-5 is-family-monospace has-text-right">${{ priceFormat(getTotal('total')) }}</td>
                                </tr>
                                </tfoot>
                            </table>
                        </div>
                    </general-container>
                </div>
            </div>
        </div>

        <div class="container contracts-container" v-if="contractsIds && contractsIds.length">
            <div class="columns">
                <div class="column">
                    <contracts-table
                        :uniqueId="uniqueId"
                        :key="contentId"
                        :contracts-ids="contractsIds"
                        :search-agency="true"
                        :search-type="true"
                        :search-fiscal="true"
                        title="Organization Contracts"
                        title-class="has-text-centered"
                        :subflyout="true"
                    />
                </div>
            </div>
        </div>

    </div>
    <!-- End Page: pages/organization -->
</template>

<script>
    import Alert from "@/alivue/components/alert";
    import Breadcrumbs from "./breadcrumbs";
    import UserTools from "@/alivue/components/user-tools";
    import useUser from "@/helpers/useUser";
    import ButtonLink from "./button-link";
    import GeneralContainer from "./general-container";
    import SearchBlock from "./search-block";
    import { priceFormat } from "@/helpers/filter";
    import { computed, ref, watch } from "vue";
    import { useStore } from "vuex";
    import TagAgency from "./tag--agency";
    import { useEventsBus } from "@/helpers/eventBus";
    import ContractsTable from "./contracts-table";
    import ContractFlyout from "./contract-flyout";
    import { v4 as uuidv4 } from "uuid";
    
    export default {
        components: {
            ContractsTable,
            TagAgency,
            Alert,
            UserTools,
            Breadcrumbs,
            ButtonLink,
            GeneralContainer,
            SearchBlock,
            ContractFlyout,
        },
        props: {
            contentId: Number,
            name: String,
            code: String,
            organizationAllotments: Array,
            contractsIds: Array,
            agency: Object,
        },
        setup(props) {

            // Generate a unique ID for each instance
            const uniqueId = computed(() => {
                return uuidv4();
            });

            const { user } = useUser();

            const contractProps = ref(null);

            const { bus } = useEventsBus();

            const contractFlyoutRef = ref(null);

            watch( () => bus.value.get('call-open-flyout-contract'),
                async (val) => {
                    contractProps.value = null;
                    if (contractFlyoutRef.value) {
                        console.log(contractFlyoutRef.value);
                        try {
                            const contractRef = await contractFlyoutRef.value.openFlyout(val[0]);
                            contractProps.value = contractRef;
                            var x = document.getElementById("snackbar-vendor-contract");
                            x.classList.add('show');
                            x.classList.add('fadein');
                        } catch (error) {
                            console.error(error);
                        }
                    }
                }
            );

            const store = useStore();
            const showSearch = computed(() => store.getters.showSearch);
            const getTotal = field => props.organizationAllotments.reduce((acc, item) => acc + item[field], 0);

            return {
                getTotal,
                priceFormat,
                user,
                path: [{ title: props.agency?.name, url: props.agency?.url }, { title: props.name }],
                showSearch,
                contractProps,
                contractFlyoutRef,
                uniqueId,
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";

    .tbl-scrollable {
        max-height: 90vh;
        overflow-y: auto;

        table {
            width: 100%;
            background-color: $white;
        }

        thead th {
            position: sticky;
            top: 0;
        }

        tfoot td {
            position: sticky;
            bottom: 0;
        }
    }

    table {
        margin: auto;
        display: inline-table;

        th {
            color: $white;
            background: $blue--dark;
            background: $blue-gradient;
            border-top: 2px; // this prevents a sliver of text from appearing above the table heads when the table cells below are scrolled
        }

        tfoot td {
            background-color: $grey--light;
        }

        td, th {
            padding: 10px;
            border: solid 1px $grey--dark;
        }
        &.zebra {
            width: 100%;
        }
    }

    .zebra tr:nth-child(even) {
        //background: #eee;
    }

    .zebra tr:nth-child(odd) {
        background-color: $white;
    }

    .contracts-container {
        margin-top: 30px;
        margin-bottom: 30px;
    }
</style>
