<template>
    <!-- Component: molecules/tag--contract -->
    <a :href="tagUrl" :class="['gov-tag tag__contract', type]">
        <font-awesome-icon :icon="iconContract" aria-label="Contract"></font-awesome-icon>
        <span class="tag__text" v-text="toTitleCase(tagText)"></span>
    </a>
    <!-- End Component: molecules/tag--contract -->
</template>

<script>
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
    import { faFileContract } from '@fortawesome/pro-light-svg-icons'
    import { toTitleCase } from "@/helpers/filter";

    export default {
        name: 'TagContract',
        components: {
            FontAwesomeIcon
        },
        props: ['tagText', 'tagUrl', 'type'],
        setup() {
            return {
                iconContract: faFileContract,
                toTitleCase,
            }
        },
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";

        @include block('gov-tag') {
        background-color: $white;
        margin-right: 10px;
        padding: 5px 10px;
        box-shadow: $box-shadow;
        border-radius: 15px;
    }

    .contact-is-leader {
        margin-left: 5px;
    }

    @include block('tag') {
        @include element('text') {
            margin-left: 5px;
            font-weight: 600;
        }
        @include element('contract') {
            &.gov-tag {
                background-color: $white;
                color: $blue--dark;
                text-decoration: none;

                &:hover, &:active, &:focus {
                    color: lighten($blue--dark, 10%);
                    text-decoration: none;
                }
            }
            &.outgoing {
                svg {
                    color: $red;
                }
                .tag__text {
                    color: darken($red, 10%);
                }
            }
            &.incoming {
                svg {
                    color: darken($green--light, 25%);
                }
                .tag__text {
                    color: darken($green--light, 25%);
                }
            }
        }
    }
</style>
