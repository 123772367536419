import { h, onMounted, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import PageHandler from './handler/PageHandler';

export default {
  name: 'app-dynamic-page',

  setup() {
    const router = useRouter();
    const store = useStore();

    const ViewComponent = computed(() => store.getters['currentPageComponent'] || 'script');
    const ViewComponentProps = computed(() => store.getters['currentPageProps'] || {});

    onMounted(() => {
      const route = router.currentRoute.value;

      if (route.name === 'PageEmpty' && !store.getters['currentPageComponentName']) {
        store.dispatch('fetchPage', { url: route.fullPath })
          .then(response => PageHandler.updatePageHead(response, store));
      }
    });

    return {
      ViewComponent,
      ViewComponentProps,
      router,
      store
    };
  },

  computed: {
    ViewComponent() {
      return this.ViewComponent.value;
    },
    ViewComponentProps() {
      return this.ViewComponentProps.value;
    }
  },

  render() {
    return h(this.ViewComponent, this.ViewComponentProps);
  },

  watch: {
    $route: function (toRoute) {
      if (toRoute.name === 'PageEmpty') {
        this.store.dispatch('fetchPage', {
          url: toRoute.fullPath,
          redirectOnFail: PageHandler.navigatedFromLink
        }).then(response => PageHandler.updatePageHead(response, this.store));
      } else {
        PageHandler.updatePageHeadByRoute(toRoute, this.store);
        this.store.dispatch('resetPage');
      }
      PageHandler.navigatedFromLink = false;
    }
  },

  serverPrefetch() {
    const route = this.$router.currentRoute.value;
    if (route.name === 'PageEmpty' && !this.$store.getters['currentPageComponentName']) {
      return this.$store.dispatch('fetchPage', { url: route.fullPath })
        .then(response => PageHandler.updatePageHead(response, this.$store));
    } else {
      PageHandler.updatePageHeadByRoute(route, this.$store);
    }
  }
};
