<template>
    <div class="container" v-if="historical && historical.length">
        <div class="columns no-flex__container">
            <div class="column">
                <general-container
                    customClass="column"
                    title="Program Budget History"
                    titleClass="has-text-centered"
                    :isLocked="false"
                >
                    <div class="container view-type-container" v-if="historical && historical.length">
                        <span class="toggle__title">Switch View:</span>
                        <span id="view-toggle--table--one"
                              @click="toggleViewBudget()" type="checkbox"
                              :checked="budgetGraphView"
                              class="view-toggle"
                        >
                                    <img src="@/assets/svg/table--inactive.svg"
                                         alt="Show Graph View"
                                         v-if="!budgetGraphView"
                                         class="mr-10"
                                    />
                                    <img src="@/assets/svg/table--active.svg"
                                         alt="Show Table View"
                                         v-else
                                         class="mr-10"
                                    />
                                </span>

                        <span id="view-toggle--card--one"
                              @click="toggleViewBudget()" type="checkbox"
                              :checked="budgetGraphView"
                              class="view-toggle"
                        >
                                    <img src="@/assets/svg/card--active.svg"
                                         alt="Show Graph View"
                                         v-if="!budgetGraphView"
                                    />
                                    <img src="@/assets/svg/card--inactive.svg"
                                         alt="Show Table View"
                                         v-else
                                    />
                                </span>
                        <div class="container container--historical-chart" v-if="budgetGraphView && historical && historical.length">
                            <state-budget-chart :historical="historical"/>
                        </div>
                        <div class="container container--historical" v-if="(!budgetGraphView && historical && historical.length)">
                            <div class="columns is-block">
                                <div class="column">

                                    <div class="tbl-scrollable responsive-table">
                                        <table class="zebra">
                                            <thead>
                                            <tr>
                                                <th scope="col">Fiscal Year</th>
                                                <th scope="col">Spent</th>
                                                <th scope="col">Total</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr v-for="historicalBudget in historical">
                                                <td data-label="Fiscal Year">{{
                                                        historicalBudget.fiscalYearFrom
                                                    }} / {{ (historicalBudget.fiscalYearFrom + 1 + '').substr(2) }}
                                                </td>
                                                <td class="is-family-monospace has-text-right" data-label="Spent">
                                                    ${{ historicalBudget.disbursementAmount < historicalBudget.appropriationAmount ? priceFormat(historicalBudget.disbursementAmount) : priceFormat(historicalBudget.appropriationAmount) }}
                                                    ({{
                                                        historicalBudget.disbursementAmount ?
                                                            historicalBudget.disbursementAmount < historicalBudget.appropriationAmount ?
                                                                (Math.round(
                                                                    historicalBudget.disbursementAmount
                                                                    / historicalBudget.appropriationAmount * 100
                                                                )) :
                                                                100
                                                            :
                                                            0
                                                    }}%)
                                                </td>
                                                <td class="is-family-monospace has-text-right" data-label="Total">
                                                    ${{ priceFormat(historicalBudget.appropriationAmount) }}
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </general-container>
            </div>
        </div>
    </div>
</template>

<script>
import {defineComponent, onMounted, ref} from "vue";
import GeneralContainer from "./general-container.vue";
import StateBudgetChart from "./state-budget-chart.vue";
import { priceFormat } from "@/helpers/filter";
import { renderProgramBudgetHistoryChart } from "@/helpers/charts";

export default defineComponent({
    components: {StateBudgetChart, GeneralContainer},
    props:['historical'],
    setup(props) {
        const budgetGraphView = ref(true)
        const budgetHistoryData= ref([])
        const programBudgetHistoryChartEl = ref(null);
        const programBudgetHistoryLegendEl = ref(null);

        function  toggleViewBudget  () {
            budgetGraphView.value = !budgetGraphView.value;
        }

        onMounted( () =>{
                 budgetHistoryData.value = props.historical;
            function calculateCurrentFiscalYear() {
                let currentYear = (new Date()).getFullYear();
                let currentMonth = (new Date()).getMonth();
                if (currentMonth < 6) {
                    return currentYear - 1;
                }
                return currentYear;
            }
            let currentFiscalYear = calculateCurrentFiscalYear();
            if (budgetHistoryData.length && budgetHistoryData[0].fiscalYearFrom !== currentFiscalYear) {
                let year = currentFiscalYear;
                budgetHistoryData.unshift({
                    fiscalYearFrom: year,
                    appropriationAmount: props.appropriationAmount,
                    disbursementAmount: props.disbursementAmount,
                });
            }
            setTimeout(() => renderProgramBudgetHistoryChart(
                programBudgetHistoryChartEl.value,
                programBudgetHistoryLegendEl.value,
                budgetHistoryData.value
            ), 100);

        })
        return{
            priceFormat,
            budgetHistoryData,
            budgetGraphView,
            toggleViewBudget,
        }
    }

})
</script>

<style lang="scss" scoped>

@import "../../assets/scss/_variables.scss";
@import "../../assets/scss/mixins/_bemify.scss";
@import "../../assets/scss/helpers/responsive-tables";

</style>
