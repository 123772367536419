<template>
    <!-- Component: atoms/general-container -->
    <div class="general-container" :class="customClass" :id="customId">

        <div class="general-container__content" :class="{'is-fullscreen': allowFullscreen && isFullscreen}">
            <a class="is-pulled-right" aria-label="fullscreen"
               v-if="allowFullscreen && !isFullscreen"
               @click="isFullscreen = true"
               title="Full Screen"
            >
                <font-awesome-icon :icon="faExpand"></font-awesome-icon>
            </a>
            <button class="delete is-pulled-right" aria-label="close"
                    v-if="isFullscreen"
                    @click="isFullscreen = false"
            ></button>

            <Tooltip :tooltipText="ttText" v-if="titleTooltip">
                <span class="w100">
                    <p class="general-container__title has-tooltip has-tooltip__custom" :class="titleClass">{{ title }}</p>
                </span>
            </Tooltip>

            <p class="general-container__title" v-else-if="title" :class="titleClass">{{ title }}</p>

            <div class="general-container--is-locked" v-if="isLocked">

                <template v-if="isMemberOnly">
                    <img class="general-container__lock" src="@/assets/svg/lock.svg" alt="Subscriber-only content"/>
                    <p class="general-container__title">Subscriber-only content</p>
                    <a href="/subscribe"><i>Subscribe today to unlock this content</i></a>
                </template>

                <template v-else>
                    <slot></slot>
                </template>

            </div>
            <!-- this doesnt work so great...
                  <div class="general-container__blur-background" v-if="isLocked === true"></div>
            -->
            <slot v-else></slot>
        </div>

    </div>
    <!-- End Component: atoms/general-container -->
</template>

<script>
    import { onBeforeUnmount, onMounted, ref, watch } from "vue";
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
    import { faExpand } from '@fortawesome/pro-light-svg-icons'
    import { useRoute } from "vue-router";
    import Tooltip from "./tooltip";

    export default {
        name: 'GeneralContainer',
        props: ['title', 'content', 'customClass', 'isLocked', 'isMemberOnly', 'allowFullscreen', 'titleClass', 'titleTooltip', 'ttText'],
        components: {FontAwesomeIcon, Tooltip},
        setup() {
            const isFullscreen = ref(false);
            const route = useRoute();
            watch(() => route.fullPath, () => isFullscreen.value = false);

            const handleEsc = (e) => {
                if (e.key === 'Escape') {
                    isFullscreen.value = false
                }
            };

            onMounted(() => document.addEventListener('keydown', handleEsc));
            onBeforeUnmount(() => document.addEventListener('keydown', handleEsc));

            return {isFullscreen, faExpand};
        },
    }
</script>

<style lang="scss">
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";

    .is-fullscreen {
        background-color: $white;
        position: fixed;
        z-index: 999;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        padding: 25px;
    }

    @include block('general-container') {
/*
        background-color: rgba(255, 255, 255, 0.5);
        border-radius: $border-radius;
        padding: 15px;
        box-shadow: $box-shadow;
*/
        @include element('title') {
            font-weight: 600;
            font-family: $header;
            font-size: 28px;
            margin-bottom: 0.5em;
        }
        @include modifier('background') {
            border-radius: $border-radius;
            padding: 15px;
            background-color: $white;
            box-shadow: $box-shadow--light;
        }
        @include modifier('is-locked') {
            margin: 0 auto;
            text-align: center;

            // temp replacement for blur background
            border-radius: $border-radius;
//            background-image: linear-gradient(125deg, #eff5fa 25%, #ffffff 25%, #ffffff 50%, #eff5fa 50%, #eff5fa 75%, #ffffff 75%, #ffffff 100%);
            background-color: $white;
            background-size: 48.83px 69.74px;
            box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
            padding: 30px;
            .general-container__title {
                margin-bottom: 0px;
            }
        }
        @include element('blur-background') {
            width: 100%;
            height: 100%;
            background-color: rgba(255, 255, 255, 0.5);
            position: absolute;
            top: 0px;
            left: 0px;
            filter: blur(15px);
            z-index: 1;
        }
    }

    .org-chart-container {
        padding-bottom: 40px;
    }

    .w100 {
        width: 100%;
    }

    .has-tooltip__custom {
        width: fit-content;
        text-align: center;
        margin: 0 auto;
        margin-bottom: 10px;
    }
</style>
