<template>
    <!-- Component: organisms/search-result-card--contact -->
    <searchResultCard type="contact" :contact="$props">

        <div class="section">
            <div class="content">

                <p class="card__info__label">Contact</p><br>

                <span class="contact-is-leader" v-if="agencyHead === true">
                    <h1 class="card__info__title">
                        <a :href="url">{{ toTitleCase(fullNameText) }}</a>
                    </h1>
                    <leadershipMarker/>
                </span>

                <h1 class="card__info__title" v-else-if="agencyHead === false">
                    <a :href="url">{{ toTitleCase(fullNameText) }}</a>
                </h1>

                <div class="columns" v-if="position && position.agency">
                    <div class="column mb20">
                        <p class="card__info__label">Agency</p>
                        <tag-agency :tag-text="position.agency.name" :tag-url="position.agency.url"/>
                        <!--span v-if="agencyHead === true">
                            <(Previously {{ previousRole }}<span v-if="previousAgency != ''"> at {{ previousAgency }}</span>)>
                        </span-->
                    </div>

                </div>

                <div class="columns" v-if="position">
                    <div class="column is-three-fourths">
                        <p class="card__info__label">Position</p>
                        <tag-role
                            :tag-text="position.name + (positionRole ? ' (' + positionRole + ')' : '')"
                            :tag-url="position.url"/>
                        <!--span v-if="agencyHead === true">({{ leadershipType }})</span-->
                    </div>

                    <!--div class="column">
                        <p class="card__info__label">Seniority</p>
                        <p class="card__info__data">{{ resultSeniority }}</p>
                    </div>

                    <div class="column">
                        <p class="card__info__label">Employees Overseen</p>
                        <p class="card__info__data">{{ resultEmployees }}</p>
                    </div-->
                </div>

            </div>

            <div class="media" v-if="photo && photo.url">
                <div class="media-right">
                    <figure class="image">
                        <img :src="photo.url" :alt="photo.alt">
                    </figure>
                </div>
            </div>

        </div>
    </searchResultCard>
    <!-- End Component: organisms/search-result-card--contact -->
</template>

<script>
    import SearchResultCard from "./search-result-card";
    import TagAgency from "./tag--agency";
    import TagRole from "./tag--role";
    import LeadershipMarker from "./leadership-marker";
    import { contactFullName } from "@/helpers/objects";
    import { computed } from "vue";
    import { toTitleCase } from "@/helpers/filter";

    export default {
        name: 'SearchResultCardContact',
        components: { SearchResultCard, TagAgency, TagRole, LeadershipMarker },
        props: [
            'agencyHead',
            'locationId',
            'contentId',
            'email',
            'firstName',
            'goesBy',
            'lastName',
            'middleName',
            'phoneNumber',
            'phone',
            'position',
            'positionRole',
            'url',
            'photo',
            'agency',
        ],
        setup(props) {
            const fullNameText = computed(() => contactFullName(props));
            return {
                fullNameText,
                toTitleCase
            };
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/mixins/_bemify.scss";

    .image img {
        width: auto;
        max-height: 150px;
    }

    .mb20 {
        margin-bottom: 20px;
    }

    .gov-tag {
        margin-right: 5px;
    }

    .contact-is-leader {
        display: flow-root;

        h1 {
            display: inline-block;
            float: left;
            margin-right: 5px;
        }
    }

    @include block('card') {
        .media {
            display: contents;
        }
        @include element('info') {
            @include element('label') {
                float: left;
                margin-right: 10px;
            }
            @include element('title') {
                text-transform: capitalize;
            }
        }
    }

    .contact-block {
        .card__info__label {
            margin-bottom: 15px !important;
        }

        .columns {
            margin-bottom: 0px !important;

            .column {
                padding-bottom: 0px;
                padding-top: 0px;
                padding-left: 0px;
            }
        }
    }
</style>

