<template>
    <!-- Component: molecules/note-line -->
    <div class="cd-popup" role="alert">
        <div class="cd-popup-container">
            <p class="bold">Are you sure you want to delete this note?</p>
            <p>This action cannot be undone.</p>
            <ul class="cd-buttons">
                <li><a href="#0" @click="$emit('delete-note', locationId)">Yes</a></li>
                <li><a href="#0" @click="closeModal()">No</a></li>
            </ul>
            <a href="#0" class="cd-popup-close img-replace" @click="closeModal()">Close</a>
        </div>
    </div>

    <div class="mt-30 mb-30 card note-line" :class="isOdd ? 'note-line--odd' : 'note-line--even'" v-if="!editing">
        <p class="note-line__date">{{ date }}</p>

        <p class="note-line__title">{{ title }}</p>

        <div v-if="textHtml" v-html="textHtml"></div>

        <div class="note-line__tags" v-if="tags && tags.length">
            <span class="label">Tagged: </span>

            <ul class="note-line__tags__list">
                <li v-for="tag in tags" :key="tag.contentId">

                    <tag-agency v-if="tag.type === 'agency'"
                                :tag-text="tag.agency.AgencyRef.name"
                                :tag-url="tag.agency.AgencyRef.url">
                    </tag-agency>

                    <tag-role v-else-if="tag.type === 'position'"
                                :tag-text="tag.position.PositionRef.name"
                                :tag-url="tag.position.PositionRef.url">
                    </tag-role>

                    <tag-contact v-else-if="tag.type === 'contact'"
                                 :tag-text="tag.contact.ContactRef.firstName + ' ' + tag.contact.ContactRef.lastName"
                                 :tag-url="tag.url"
                                 :leadership="tag.agencyHead">
                    </tag-contact>

                    <tag-role v-if="tag.type === 'contact' && tag.contact.ContactRef.position"
                              :tag-text="tag.contact.ContactRef.position.PositionRef.name
                              + (tag.contact.ContactRef.positionRole ? ' (' + tag.contact.ContactRef.positionRole + ')' : '')"
                              :tag-url="tag.contact.ContactRef.position.PositionRef.url">
                    </tag-role>

                    <tag-agency v-if="tag.type === 'contact' && tag.contact.ContactRef.agency"
                                :tag-text="tag.contact.ContactRef.agency.AgencyRef.name"
                                :tag-url="tag.contact.ContactRef.agency.AgencyRef.url">
                    </tag-agency>

                    <tag-budget v-if="tag.type == 'budget'"
                                :tag-text="tag.budget.ProgramRef.name"
                                :tag-url="tag.budget.ProgramRef.url">
                    </tag-budget>
                    <tag-budget v-if="tag.type == 'budget_category'"
                                :tag-text="tag.name"
                                :tag-url="tag.url">
                    </tag-budget>

                    <tag-contract v-if="tag.type === 'contract'"
                                :tag-text="tag.contract.ContractRef.name"
                                :tag-url="tag.contract.ContractRef.url">
                    </tag-contract>

                    <tag-procurement v-if="tag.type === 'procurement'"
                                     :type="tag.type"
                                     :tag-text="tag.procurement.ProcurementRef.title"
                                     :procurement="tag.procurement.ProcurementRef">
                    </tag-procurement>

                    <tag-vendor v-if="tag.type === 'vendor'"
                                :tag-text="tag.vendor.VendorRef.name"
                                :tag-url="tag.vendor.VendorRef.url">
                    </tag-vendor>

                    <tag-program v-if="tag.type === 'program'"
                                :tag-text="tag.program.ProgramRef.name"
                                :tag-url="tag.program.ProgramRef.url">
                    </tag-program>

                    <tag-agency v-if="tag.type === 'budget' && tag.budget.ProgramRef.agency && tagContentIds.indexOf(tag.budget.ProgramRef.agency.AgencyRef.contentId) == -1"
                                :tag-text="tag.budget.ProgramRef.agency.AgencyRef.name"
                                :tag-url="tag.budget.ProgramRef.agency.AgencyRef.url">
                    </tag-agency>
                    <tag-program v-if="tag.type == 'budget' && tag.budget.ProgramRef.programCategory"
                                :tag-text="tag.budget.ProgramRef.programCategory.ProgramCategoryRef.name"
                                :tag-url="tag.budget.ProgramRef.programCategory.ProgramCategoryRef.url">
                    </tag-program>

                </li>
            </ul>
        </div>

        <div class="note-line__tags" v-if="files && files.length">
            <span class="label">Files: </span>

            <ul class="note-line__tags__list">
                <li v-for="file in files" :key="file.locationId">
                    <a :href="file.url" class="note-line__file">
                        {{ file.name }}
                        {{ file.size ? '(' + humanFileSize(file.size) + ')' : '' }}
                    </a>
                </li>
            </ul>
        </div>

        <nav class="level">
            <div class="level-item has-text-centered note-line__edit__container">
                <button class="note-line__edit" @click="$emit('start-edit-note', locationId)" v-if="locationId">Edit this note</button>
            </div>
            <div class="level-item has-text-centere note-line__delete__container">
                <button class="note-line__delete cd-popup-trigger" @click="openModal()" v-if="locationId">
                    Delete this note
                </button>
            </div>
        </nav>

    </div>

    <div class="note-line note-line-edit" :class="isOdd ? 'note-line--odd' : 'note-line--even'" v-if="editing">
        <note-edit v-bind="$props" @cancel-edit-note="cancelEditNote($event)" @edited="edited($event)"/>
    </div>
    <!-- End Component: molecules/note-line -->
</template>

<script>
    import TagAgency from "./tag--agency"
    import TagContact from "./tag--contact"
    import TagRole from "./tag--role"
    import TagBudget from "./tag--budget"
    import TagContract from "./tag--contract"
    import TagVendor from "./tag--vendor"
    import TagProgram from "./tag--program"
    import TagProcurement from "./tag--procurement";
    import NoteEdit from "./note-edit"
    import { humanFileSize } from '@/helpers/filter';
    import { computed, onMounted, ref, watch } from "vue";

    export default {
        name: 'NoteLine',
        components: {
            TagAgency,
            TagContact,
            TagBudget,
            TagContract,
            TagProgram,
            TagVendor,
            TagRole,
            TagProcurement,
            NoteEdit,
        },
        props: [
            'date', 'title', 'text', 'isOdd', 'tags', 'locationId', 'editing', 'relatedContentId', 'files'
        ],
        emits: ['delete-note', 'start-edit-note', 'cancel-edit-note', ],
        methods: {
            cancelEditNote($event) {
                this.$emit('cancel-edit-note', $event);
            },
            edited($event) {
                this.$emit('edited', $event);
            },
            openModal() {
                event.preventDefault();
                document.body.classList.add('position-fixed');
                let elm = document.getElementsByClassName('cd-popup')[0];
                elm.classList.add('is-visible');
            },
            closeModal() {
                event.preventDefault();
                document.body.classList.remove('position-fixed');
                let elm = document.getElementsByClassName('cd-popup')[0];
                elm.classList.remove('is-visible');
            }
        },
        setup(props) {
            const tagContentIds = ref([]);
            for (let i = 0; i < props.tags.length; i++) {
                tagContentIds.value.push(props.tags[i].contentId);
            }

            const textHtml = props.text && props.text.replace(/(\r\n|\n\r|\r|\n)/g, '<br>');
            return {textHtml, humanFileSize, tagContentIds};
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";

    @include block('note-line') {
        padding: 30px;
        @include modifier('odd') {

        }
        @include modifier('even') {

        }
        @include element('date') {
            color: #B4B4B4;
            font-size: 18px;
            margin-bottom: 5px;
        }
        @include element('file') {
            background-color: $grey--light;
            margin-right: 10px;
            padding: 0 5px;
            box-shadow: $box-shadow;
            border-radius: 4px;
            display: inline-block;
            margin-bottom: 15px;
            line-height: 2em;
            text-decoration: none;
        }
        @include element('title') {
            font-size: 2rem;
            color: $blue--dark;
            font-weight: 600;
            font-family: $header;
            margin-bottom: 30px;
            display: block;
            text-align: left;
            margin-top: 0px !important;
        }
        @include element('tags') {
            margin-top: 30px;
            .label {
                display: block;
                margin-right: 10px;
                position: relative;
            }
            @include element('list') {
                list-style-type: none;
                display: inline-block;
                li {
                    display: inline-block;
                    float: left;
                    line-height: 2.4em;
                }
            }
        }
        @include element('delete') {
            text-decoration: underline;
            background-color: transparent;
            border: none;
            cursor: pointer;
            @include element('container') {
                text-align: right;
                button {
                    background-color: $red;
                    border-radius: 6px;
                    color: $white;
                    padding: 10px;
                    font-size: 16px;
                    font-weight: 600;
                    text-decoration: none;
                }
            }
        }
        @include element('edit') {
            //color: $red;
            text-decoration: underline;
            background-color: transparent;
            border: none;
            padding-top: 10px;
            cursor: pointer;
            @include element('container') {
                text-align: right;
                button {
                    background-color: $blue--dark;
                    border-radius: 6px;
                    color: $white;
                    padding: 10px;
                    font-size: 16px;
                    font-weight: 600;
                    text-decoration: none;
                }
            }
        }
    }

    // based on https://codyhouse.co/demo/simple-confirmation-popup/index.html
    .cd-popup {
        position: fixed;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.7);
        opacity: 0;
        visibility: hidden;
        -webkit-transition: opacity 0.3s 0s, visibility 0s 0.3s;
        -moz-transition: opacity 0.3s 0s, visibility 0s 0.3s;
        transition: opacity 0.3s 0s, visibility 0s 0.3s;
    }
    .cd-popup.is-visible {
        opacity: 1;
        visibility: visible;
        -webkit-transition: opacity 0.3s 0s, visibility 0s 0s;
        -moz-transition: opacity 0.3s 0s, visibility 0s 0s;
        transition: opacity 0.3s 0s, visibility 0s 0s;
        z-index: 9;
    }
    .cd-popup-container {
        position: relative;
        width: 90%;
        max-width: 400px;
        margin: 4em auto;
        background: #FFF;
        border-radius: .25em .25em .4em .4em;
        text-align: center;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
        -webkit-transform: translateY(-40px);
        -moz-transform: translateY(-40px);
        -ms-transform: translateY(-40px);
        -o-transform: translateY(-40px);
        transform: translateY(-40px);
        /* Force Hardware Acceleration in WebKit */
        -webkit-backface-visibility: hidden;
        -webkit-transition-property: -webkit-transform;
        -moz-transition-property: -moz-transform;
        transition-property: transform;
        -webkit-transition-duration: 0.3s;
        -moz-transition-duration: 0.3s;
        transition-duration: 0.3s;
    }
    .cd-popup-container .cd-buttons:after {
        content: "";
        display: table;
        clear: both;
    }
    .cd-popup-container .cd-buttons li {
        float: left;
        width: 50%;
    }
    .cd-popup-container .cd-buttons a {
        display: block;
        height: 60px;
        line-height: 60px;
        text-transform: uppercase;
        color: $white;
        -webkit-transition: background-color 0.2s;
        -moz-transition: background-color 0.2s;
        transition: background-color 0.2s;
        text-decoration: none;
        font-weight: bold;
    }
    .cd-popup-container .cd-buttons li:first-child a {
        background: #d00000;
        border-radius: 0 0 0 .25em;
    }
    .no-touch .cd-popup-container .cd-buttons li:first-child a:hover {
        background-color: #fc8982;
    }
    .cd-popup-container .cd-buttons li:last-child a {
        background: $blue--dark;
        border-radius: 0 0 .25em 0;
    }
    .no-touch .cd-popup-container .cd-buttons li:last-child a:hover {
        background-color: #c5ccd8;
    }
    .cd-popup-container p:last-of-type {
        margin-bottom: 30px;
    }
    .cd-popup-container .cd-popup-close {
        position: absolute;
        top: 8px;
        right: 8px;
        width: 30px;
        height: 30px;
    }
    .cd-popup-container .cd-popup-close::before, .cd-popup-container .cd-popup-close::after {
        content: '';
        position: absolute;
        top: 12px;
        width: 14px;
        height: 3px;
        background-color: #8f9cb5;
    }
    .cd-popup-container .cd-popup-close {
        font-size: 0px;
    }
    .cd-popup-container .cd-popup-close::before {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
        left: 8px;
    }
    .cd-popup-container .cd-popup-close::after {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
        right: 8px;
    }
    .is-visible .cd-popup-container {
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
    }
    .bold {
        font-weight: bold;
        font-size: 20px;
        padding: 50px 20px 0px;
        margin-bottom: 10px;
    }
    @media only screen and (min-width: 1170px) {
        .cd-popup-container {
            margin: 8em auto;
        }
    }
    .level {
        padding: 20px 0px;
        background-color: $grey--light;
    }
</style>
