<template>
    <general-container
        :customClass="'column'"
        :title="'Program Breakdown'"
        :titleClass="'has-text-centered'"
        :title-tooltip="true"
        :tt-text="`How programs within this budget area plan to spend their money in the current fiscal year. *These allocations and encumbrances can be changed at the agency's discretion.`"
        :isLocked="false"
    >
        <div class="column">
            <div class="filter-info">{{ organizationAllotmentsTotalCount || '0' }} items found</div>
            <div class="filter-limit">
                <label>
                    Show
                    <select name="filter-limit--value" v-model="organizationAllotmentsLimit">
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="100">100</option>
                    </select>
                    items
                </label>
            </div>
        </div>
        <div class="organizationAllotmentsTable  responsive-table">
            <table class="zebra">

            <thead>
                <tr>
                    <th class="clickable" @click="orgSortField = 'name'; orgSortIsAsc = !orgSortIsAsc;" scope="col">
                        Organization {{ orgSortField === 'name' ? (orgSortIsAsc ? '⇩' : '⇧') : '' }}
                    </th>
                    <th class="clickable" @click="orgSortField = 'allotments'; orgSortIsAsc = !orgSortIsAsc; " scope="col">
                        Allotments {{ orgSortField === 'allotments' ? (orgSortIsAsc ? '⇩' : '⇧') : '' }}
                    </th>
                    <th class="clickable has-tooltip-hover" @click="orgSortField = 'expenditures'; orgSortIsAsc = !orgSortIsAsc;" scope="col">
                        <span class="word">Expenditures</span>
                        <span class="tooltip">
                                                {{ definitions.Expenditures.definition }}
                                            </span>
                        {{ orgSortField === 'expenditures' ? (orgSortIsAsc ? '⇩' : '⇧') : '' }}
                    </th>
                    <th class="clickable has-tooltip-hover" @click="orgSortField = 'encumbrances'; orgSortIsAsc = !orgSortIsAsc; hasEncumbrances.value=true" scope="col">
                        <span class="word">Encumbrances</span>
                        <span class="tooltip">
                                                {{ definitions.Encumbered.definition }}
                                            </span>
                        {{ orgSortField === 'encumbrances' ? (orgSortIsAsc ? '⇩' : '⇧') : '' }}
                    </th>
                    <th scope="col">
                        Remaining Allotments
                    </th>
                </tr>
                </thead>
                <tbody>
                <preloader v-if="organizationAllotmentsLoading"/>
                <tr v-if="!organizationAllotmentsLoading" v-for="organizationAllotment in organizationAllotmentsSorted">
                    <td data-label="Organization">
                        <a :href="organizationAllotment.organizationUrl" target="_blank"
                           v-if="organizationAllotment.organizationUrl">
                            {{ titleize(organizationAllotment.name) }}
                        </a>
                        <span v-else>{{ titleize(organizationAllotment.name) }}</span>
                    </td>
                    <td class="is-family-monospace has-text-right" data-label="Allotments">${{ priceFormat(organizationAllotment.allotments) }}</td>
                    <td class="is-family-monospace has-text-right" data-label="Expenditures">${{ priceFormat(organizationAllotment.expenditures) }}</td>
                    <td class="is-family-monospace has-text-right" data-label="Encumberances">${{ priceFormat(organizationAllotment.encumbrances) }}</td>
                    <td class="is-family-monospace has-text-right" data-label="Total">${{ priceFormat(organizationAllotment.total) }}</td>
                </tr>
                </tbody>
            </table>

        </div>
        <div class="pagination-container">
            <pagination v-if="organizationAllotmentsSorted && organizationAllotmentsPageCount"
                        v-on:paginated="paginateOrganizationAllotments($event)"
                        :current="organizationAllotmentsPage"
                        :pageCount="organizationAllotmentsPageCount"
            />
        </div>
    </general-container>
</template>


<script>

import {computed, defineComponent, onMounted, ref, watch} from "vue";
import GeneralContainer from "./general-container.vue";
import Pagination from "./pagination.vue";
import {useStore} from "vuex";
import { priceFormat } from "@/helpers/filter";
import RequestHandler from "@/handler/RequestHandler";

export default defineComponent({
    components: {Pagination, GeneralContainer},
    props:[
        'definitions',
        'locationId',
    ],
    methods: {
        titleize: function(item) {
            return item.toLowerCase().replace(/(?:^|\s|-)\S/g, x => x.toUpperCase());
        },
    },
    setup(props) {
        const hasEncumbrances = ref(false);
        const store = useStore();
        const showSearch = computed(() => store.getters.showSearch);
        const organizationAllotmentsLimit =ref(25)
        const organizationAllotmentsPage =ref(1)
        const organizationAllotmentsLoading = ref(true)
        const organizationAllotments = ref([])
        const organizationAllotmentsPageCount = ref(1)
        const orgSortField = ref('name')
        const orgSortIsAsc =ref(true)
        const organizationAllotmentsSorted = ref([])
        const organizationAllotmentsTotalCount = ref()
       const setOrganizationAllotments = () =>  {
            let d = organizationAllotments.value;
            for (let i = 0; i < d.length; i++) {
                d[i].total = Math.max(0, d[i].allotments - d[i].expenditures - d[i].encumbrances);
            }

            function compare(a, b) {
                if (a[orgSortField.value] < b[orgSortField.value]) {
                    return orgSortIsAsc.value ? -1 : 1;
                }
                if (a[orgSortField.value] > b[orgSortField.value]) {
                    return orgSortIsAsc.value ? 1 : -1;
                }
                return 0;
            }

            organizationAllotmentsSorted.value = d.sort(compare);
        }
        const loadOrganizationAllotments = () => {
            RequestHandler.loadProgramOrganizationAllotments(
                props.locationId,
                (organizationAllotmentsPage.value - 1) * organizationAllotmentsLimit.value,
                organizationAllotmentsLimit.value,
                orgSortField.value,
                orgSortIsAsc.value
            ).then(response => {
                organizationAllotmentsLoading.value = false;
                organizationAllotments.value = response.ContentList.list;
                organizationAllotmentsPageCount.value = response.ContentList.pageCount;
                organizationAllotmentsTotalCount.value = response.ContentList.totalCount
                setOrganizationAllotments();
            });
        }

        const     paginateOrganizationAllotments =(pageTo) =>  {
            organizationAllotmentsPage.value = pageTo;
            loadOrganizationAllotments();
        }
        watch(orgSortIsAsc, () => loadOrganizationAllotments());
        watch(orgSortField, () => loadOrganizationAllotments());
        watch(organizationAllotmentsLimit, () => loadOrganizationAllotments())

        onMounted(()=> {
            loadOrganizationAllotments();
        })
        return {
            showSearch,
            priceFormat,
            hasEncumbrances,
            organizationAllotmentsLimit,
            organizationAllotmentsPage,
            organizationAllotmentsLoading,
            organizationAllotmentsSorted,
            organizationAllotments,
            organizationAllotmentsPageCount,
            organizationAllotmentsTotalCount,
            orgSortField,
            orgSortIsAsc,
            setOrganizationAllotments,
            paginateOrganizationAllotments,
            loadOrganizationAllotments,
        };
    }
})
</script>

<style scoped lang="scss">
@import "../../assets/scss/_variables.scss";
@import "../../assets/scss/mixins/_bemify.scss";
@import "../../assets/scss/helpers/responsive-tables";


.filter-info {
    text-align: center;
    font-size: 21px;
    font-weight: 600;
}
.filter-limit {
    text-align: right;
    margin-bottom: .75rem;
}

table {
    margin: auto;
    display: inline-table;

    th {
        color: $white;
        background: $blue--dark;
        background: $blue-gradient;
        border-top: 0px !important; // this prevents a sliver of text from appearing above the table heads when the table cells below are scrolled
    }

    tfoot td {
        background-color: $grey--light;
    }

    td, th {
        padding: 10px;
        border: solid 1px $grey--dark;
    }
    &.zebra {
        width: 100%;
    }
}

.zebra tr:nth-child(even) {
    //background: #eee;
}

.zebra tr:nth-child(odd) {
    background-color: $white;
}

.chart1, .chart2, .contracts-container {
    margin-top: 30px;
    margin-bottom: 30px;
}

.tbl-scrollable {
    max-height: 90vh;
   // overflow-y: auto;
    @media screen and (min-width: 601px) {
        background-color: $white;
    }

    table {
        width: 100%;
    }

    thead th {
        color: $white;
        position: sticky;
        top: 0;
    }

    tfoot td {
        position: sticky;
        bottom: 0;
    }
}

.organizationAllotmentsTable {
    overflow-x: hidden;
    max-width: 100%;
    margin-bottom: 45px;
    table {
        overflow-x: hidden;
        max-width: 100%;
    }
    .loader-container {
        margin-left: 200%;
    }
}
.clickable span.tooltip {
    display: none;
    position: relative;
    border: 5px solid $blue--dark;
    &:after {
        bottom: 100%;
        left: 20%;
        border: solid transparent;
        content: " ";
        position: absolute;
    }
    &:after {
        border-bottom-color: $blue--dark;
        border-width: 15px;
    }
}
.clickable:hover span.tooltip {
    position: absolute;
    top: 45px;
    left: 0%;
    display: inline;
    background-color: $blue--dark;
    color: $white;
    padding: 5px;
    font-weight: 400;
    border-radius: 4px;
}
.clickable.has-tooltip-hover:hover {
    position: sticky;
}
</style>
