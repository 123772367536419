<template>
    <!-- Component: molecules/info-block -->
    <article class="media info-block">
        <figure class="media-left" v-if="hasIcon === true">
            <p class="image is-64x64 info-block__icon">
                <img :src="require('@/assets/img/icons/pie-chart.png')" alt="chart icon" aria-hidden="true" v-if="requestedIcon === 'faChartPie'"/>
                <img :src="require('@/assets/img/icons/leader.png')" alt="chart icon" aria-hidden="true" v-else-if="requestedIcon === 'faCrown'"/>
                <img :src="require('@/assets/img/icons/twitter.png')" alt="chart icon" aria-hidden="true" v-else-if="requestedIcon === 'faTwitter'"/>
                <img :src="require('@/assets/img/icons/user.png')" alt="chart icon" aria-hidden="true" v-else-if="requestedIcon === 'faUser'"/>
                <img :src="require('@/assets/img/icons/information.png')" alt="chart icon" aria-hidden="true" v-else="requestedIcon === 'faInfoCircle'"/>
            </p>
        </figure>
        <div class="media-content">
            <div class="content">
                <slot>{{ content }}</slot>
            </div>

            <!--nav class="level is-mobile info-block__twitter__controls" v-if="isTweet === true">
                <div class="level-left">
                    <a class="level-item">
                        <span class="icon is-small"><i class="fas fa-reply"></i></span>
                    </a>
                    <a class="level-item">
                        <span class="icon is-small"><i class="fas fa-retweet"></i></span>
                    </a>
                    <a class="level-item">
                        <span class="icon is-small"><i class="fas fa-heart"></i></span>
                    </a>
                </div>
            </nav-->

            <span class="more" v-if="requestedIcon === 'faTwitter'">
                     <a :href="'https://x.com/' + handle + (idStr ? ('/status/' + idStr) : '')" target="_blank" class="more--twitter"> View on X</a>
                <img class="external" src="@/assets/svg/external-link.svg" alt="This link leaves this site"/>
            </span>
        </div>
        <div class="media-right" v-if="hasClose === true">
            <button class="delete"></button>
        </div>
    </article>
    <!-- End Component: molecules/info-block -->
</template>

<script>

    export default {
        name: 'InfoBlock',
        components: {

        },
        props: ['hasIcon', 'requestedIcon', 'handle', 'hasClose', 'idStr', 'userName', 'color', 'isTweet', 'tweetDate', 'tweetContent'],
        data() {
            return {
                faInfoCircle: "faInfoCircle",
                faCrown: "faCrown",
                faChartPie: "faChartPie",
                faTwitter: "faTwitter",
                faUserTag: "faUserTag",
            }
        }
    }
</script>

<style lang="scss">
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";

    .media-content {
        padding: 10px;
    }

    .media-right {
        padding-right: 5px;
        margin-top: 5px;
    }

    .more {
        float: right;
    }

    .more--twitter {
        float: right;
        margin-left: 5px;
    }
</style>
