import { capitalize } from "@/helpers/filter";

function getContentId(contact, position, agency, program, programCategory, vendor, contract, procurement, document) {
    return (contact && contact.contentId)
        || (agency && agency.contentId)
        || (position && position.contentId)
        || (program && program.contentId)
        || (programCategory && programCategory.contentId)
        || (vendor && vendor.contentId)
        || (contract && contract.contentId)
        || (procurement && procurement.contentId)
        || (document && document.contentId);
}

function getContentTitle(contact, position, agency, program, programCategory, vendor, contract, procurement, document) {
    if (contact) {
        return contactFullName(contact)
    }

    return (agency && agency.name)
        || (position && position.title)
        || (program && program.name)
        || (programCategory && programCategory.name)
        || (vendor && vendor.name)
        || (contract && contract.name)
        || (procurement && procurement.title)
        || (document && document.title);
}

function getLocationId(contact, position, agency, program, programCategory, vendor, contract, procurement, document) {
    return (contact && contact.locationId)
        || (agency && agency.locationId)
        || (position && position.locationId)
        || (program && program.locationId)
        || (programCategory && programCategory.locationId)
        || (vendor && vendor.locationId)
        || (contract && contract.locationId)
        || (procurement && procurement.locationId)
        || (document && document.locationId);
}


function contactFullName(contact, includeMiddleName = false) {
    if (includeMiddleName){
        return capitalize(contact.firstName)
        + (contact.goesBy ? ' "' + contact.goesBy + '"' : '')
        + (contact.middleName ? ' ' + capitalize(contact.middleName) : '')
        + ' ' + capitalize(contact.lastName)
    }
    return capitalize(contact.firstName)
    + (contact.goesBy ? ' "' + contact.goesBy + '"' : '')
    + ' ' + capitalize(contact.lastName)

}

export { getContentId, getLocationId, getContentTitle, contactFullName };
