<template>
    <!-- Component: molecules/tag -->
    <span class="gov-tag tag__text" :class="[customClass, type]">{{ tagText }}</span>
    <!-- End Component: molecules/tag -->
</template>

<script>
    export default {
        name: 'Tag',
        props: ['tagText', 'customClass', 'type']
    }
</script>

<style lang="scss">
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";

    @include block('gov-tag') {
        background: rgb(246,246,246);
        background: radial-gradient(circle, rgba(246,246,246,0.5) 0%, rgba(246,246,246,1) 70%);
        margin-right: 10px;
        padding: 5px 10px;
        box-shadow: $box-shadow;
        border-radius: 15px;
        display: inline-block;
        margin-bottom: 15px;
    }
    @include block('tag') {
        @include element('text') {
            margin-left: 5px;
            font-weight: 600;
        }
    }
</style>
