<template>
    <!-- Page: pages/position -->

    <br/>

    <breadcrumbs :items="path"/>

    <div class="container">
        <alert/>
    </div>

    <hr/>

    <div class="container">
            <userTools/>
    </div>

    <hr/>

    <div class="container">


        <position-information-card v-bind="$props"/>

        <hr/>

        <div class="col-container">
            <div class="columns">
                <div class="column">
                    <general-container
                        :title="'Contact' + (contacts.length > 1 ? 's' : '') + ' in this Position'"
                        :isLocked="false"
                    >
                        <contact-line
                            v-for="contact in contacts"
                            :name="getContactName(contact)"
                            :agency="agency.name"
                            :role="contact.position.name + (contact.positionRole ? ' (' + contact.positionRole + ')' : '')"
                            :url="contact.url"
                            :agencyUrl="agency.url"
                            :roleUrl="contact.position.url"
                            :key="contact.position.name"
                            :imagePath="contact.photo ? contact.photo.url : null"
                            :leadership="contact.agencyHead"
                            :salary="contact.salary"
                        />

                        <div class="vacant" v-if="contacts.length == 0">
                            This position is currently vacant
                        </div>

                        <button-link :href="'/agency-contacts?agencyId=' + agency.locationId" :target="'_self'" :contents="'See All Agency Contacts'"/>
                    </general-container>

                    <br/>

                    <general-container
                        :title="'Previous Contact' + (previousContactsEmploymentUpdates.length > 1 ? 's' : '') + ' in this Position'"
                        :isLocked="false"
                    >
                        <preloader v-if="previousContactsLoading"></preloader>

                        <contact-line
                            v-if="!previousContactsLoading"
                            v-for="update in previousContactsEmploymentUpdates"
                            :name="getContactName(update.contact.ContactRef)"
                            :agency="update.toAgencyName"
                            :role="update.toPositionName"
                            :date="update.updatedAt"
                            :url="update.contact.ContactRef.url"
                            :agencyUrl="update.toAgency ? update.toAgency.AgencyRef.url : null"
                            :roleUrl="update.toPosition ? update.toPosition.PositionRef.url : null"
                            :key="update.contact.ContactRef.name"
                            :imagePath="update.contact.ContactRef.photo ? update.contact.ContactRef.photo.url : null"
                            :leadership="update.contact.ContactRef.agencyHead"
                            :updated="true"
                            :salary="update.fromSalary ? update.fromSalary : null"
                        />

                        <div class="vacant" v-if="!previousContactsLoading && previousContactsEmploymentUpdates.length == 0">
                            We have no record of a previous contact in this position
                        </div>

                        <button-link :href="'/previous-contacts?positionId=' + contentId" :target="'_self'" :contents="'See All Previous Contacts'"/>
                    </general-container>
                </div>
                <div class="column">
                    <general-container
                        :title="'Related Contact' + (leadership.length != 1 ? 's' : '')"
                        :isLocked="false"
                    >
                        <contact-line
                            v-for="leader in leadership"
                            :name="getContactName(leader)"
                            :agency="leader.position.agency.name"
                            :role="leader.position.name + (leader.positionRole ? ' (' + leader.positionRole + ')' : '')"
                            :url="leader.url"
                            :agencyUrl="agency.url"
                            :roleUrl="leader.position.url"
                            :leadership="leader.agencyHead"
                            :key="leader.position.name"
                            :imagePath="leader.photo ? leader.photo.url : null"
                        />

                        <button-link :href="'/agency-contacts?agencyId=' + agency.locationId" :target="'_self'" :contents="'See All Agency Contacts'"/>

                    </general-container>
                </div>
            </div>
        </div>
    </div>
    <!-- End Page: pages/position -->
</template>

<script>
    import Alert from "@/alivue/components/alert";
    import Breadcrumbs from "@/alivue/components/breadcrumbs";
    import ContactLine from "@/alivue/components/contact-line";
    import GeneralContainer from "./general-container";
    import ButtonLink from "./button-link";
    import PositionInformationCard from "@/alivue/components/position-info-card";
    import UserTools from "@/alivue/components/user-tools";
    import SearchBlock from "./search-block";
    import { computed } from "vue";
    import { useStore } from "vuex";
    import { contactFullName } from "@/helpers/objects";
    import Preloader from "./preloader";
    import RequestHandler from "@/handler/RequestHandler"

    export default {
        components: {
            Alert,
            Breadcrumbs,
            ContactLine,
            GeneralContainer,
            ButtonLink,
            PositionInformationCard,
            UserTools,
            SearchBlock,
            Preloader,
        },
        props: [
            'title',
            'contentId',
            'locationId',
            'seniority',
            'positionNumber',
            'payPlan',
            'positionDescription',
            'agency',
            'leadership',
            'contacts',
            'programs',
            'displayTitle',
            'payPlanCode',
            'flairOrgCode',
            'flairOrganization',
            'executiveAssistantContacts',
            'executiveAssistantPositions',
        ],
        setup(props) {
            const path = [];
            if (props.agency && props.agency.url) {
                path.push({url: props.agency.url, title: props.agency.name});
            }
            path.push({title: props.title});

            const store = useStore();
            const showSearch = computed(() => store.getters.showSearch);

            return {
                path,
                showSearch,
            }
        },
        data() {
            return {
                previousContactsEmploymentUpdates: [],
                previousContactsLoading: true,
                fetchedPreviousPositionEmploymentUpdatesForContentId: null,
            }
        },
        mounted() {
            this.getPreviousPositionEmploymentUpdates();
        },
        updated() {
            if (this.fetchedPreviousPositionEmploymentUpdatesForContentId !== this.$props.contentId) {
                this.getPreviousPositionEmploymentUpdates();
            }
        },
        methods: {
            getContactName: function (contact) {
                return contactFullName(contact);
            },
            getPreviousPositionEmploymentUpdates() {
                this.fetchedPreviousPositionEmploymentUpdatesForContentId = this.$props.contentId;
                this.previousContactsLoading = true;
                RequestHandler.loadPreviousPositionEmploymentUpdates(this.$props.contentId, 0, 2).then(ContentList => {
                    this.previousContactsEmploymentUpdates = ContentList.list;
                    let ref = this;
                    this.previousContactsEmploymentUpdates.slice().reverse().forEach(function(item, index, object) {
                        let formerEmployee = ref.getContactName(item.contact.ContactRef);
                        ref.contacts.forEach((element) => {
                            let currentEmployee = ref.getContactName(element);
                             if (currentEmployee === formerEmployee){
                                ref.previousContactsEmploymentUpdates.splice(object.length - 1 - index, 1);
                            }
                        })
                    });
                    this.previousContactsLoading = false;
                }).catch((error) => {
                    console.error(error);
                });
            },
        }
    }
</script>


<style lang="scss">
    .vacant {
        margin-bottom: 20px;
    }
    .col-container {
        .columns .column {
            flex: 0 50%;
            margin: 0px;
        }
    }
    @media screen and (max-width: 768px) {
        .col-container .columns .column {
            flex: 0 100% !important;
        }
    }
</style>
