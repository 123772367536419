<template>

    <div id="snackbar-procurement">
        <div class="snackbar-container">
            <div @click="hideFlyout()" class="close-item"></div>
            <procurement-flyout
                v-if="procurementContentId"
                v-bind="procurementProps"
                :agency="procurementProps.agency && procurementProps.agency.AgencyRef ? procurementProps.agency.AgencyRef : null"
            ></procurement-flyout>
        </div>
    </div>

    <general-container
        v-if="totalProcurementsCount > 0 ||  hasFilters"
        :title="title"
    >

        <div class="fitler-container filter-container--main">

            <div class="filter-input field">
                <div class="control">
                    <custom-select
                        :uniqueId="uniqueId"
                        :items="statuses"
                        name="status"
                        defaultSelected=""
                        placeholder="Select a status..."
                        v-model:value="status"
                        @update:value="updateFilters"
                    ></custom-select>
                </div>
            </div>

            <div class="filter-input field">
                <div class="control">
                    <custom-select
                        :uniqueId="uniqueId"
                        :items="types"
                        name="type"
                        placeholder="Select a Type..."
                        v-model:value="type"
                        @update:value="updateFilters"
                    ></custom-select>
                </div>
            </div>

            <div class="filter-input field">
                <div class="control">
                    <input
                        type="search"
                        :id="'tbl-keyword-' + uniqueId"
                        v-model="keyword"
                        class="input"
                        placeholder="Filter by keyword"
                    />
                </div>
            </div>
        </div>

        <div class="columns">
            <div class="column">
                <div :id="'applied-filters-' + uniqueId"></div>
            </div>
        </div>
        <div class="columns">
            <div class="column">
              <div class="filter-info">{{ totalProcurementsCount || '0' }} items found</div>
                <div class="filter-limit">
                    <label>
                        Show
                        <select name="filter-limit--value" v-model="itemsPerPage">
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                        </select>
                        items
                    </label>
                </div>
            </div>
        </div>

        <div  class="responsive-table tbl-scrollable">

            <table class="zebra" id="procurementsTable">
                <thead>
                <tr>
                    <th class="clickable mw-110" @click="sortField = 'status'; sortIsAsc = !sortIsAsc; loadProcurements()" scope="col">
                        Status
                        <span class="sort-status">
                        <span :class="sortField === 'status' && sortIsAsc ? 'active-sort' : 'inactive-sort'">⇩</span>
                        <span :class="sortField === 'status' && !sortIsAsc ? 'active-sort' : 'inactive-sort'">⇧</span>
                    </span>
                    </th>
                    <th class="clickable" @click="sortField = 'agency'; sortIsAsc = !sortIsAsc; loadProcurements()" scope="col">
                        Agency
                        <span class="sort-status">
                        <span :class="sortField === 'agency' && sortIsAsc ? 'active-sort' : 'inactive-sort'">⇩</span>
                        <span :class="sortField === 'agency' && !sortIsAsc ? 'active-sort' : 'inactive-sort'">⇧</span>
                </span>
                </th>
                <th class="clickable" @click="sortField = 'ad_type'; sortIsAsc = !sortIsAsc; loadProcurements()" scope="col">
                    Ad Type
                    <span class="sort-status">
                        <span :class="sortField === 'ad_type' && sortIsAsc ? 'active-sort' : 'inactive-sort'">⇩</span>
                        <span :class="sortField === 'ad_type' && !sortIsAsc ? 'active-sort' : 'inactive-sort'">⇧</span>
                    </span>
                    </th>
                    <th class="clickable" @click="sortField = 'title'; sortIsAsc = !sortIsAsc; loadProcurements()" scope="col">
                        Title
                        <span class="sort-status">
                        <span :class="sortField === 'title' && sortIsAsc ? 'active-sort' : 'inactive-sort'">⇩</span>
                        <span :class="sortField === 'title' && !sortIsAsc ? 'active-sort' : 'inactive-sort'">⇧</span>
                    </span>
                    </th>
                    <th class="clickable" @click="sortField = 'start_date'; sortIsAsc = !sortIsAsc; loadProcurements()" scope="col">
                        Start
                        <span class="sort-status">
                        <span :class="sortField === 'start_date' && sortIsAsc ? 'active-sort' : 'inactive-sort'">⇩</span>
                        <span :class="sortField === 'start_date' && !sortIsAsc ? 'active-sort' : 'inactive-sort'">⇧</span>
                    </span>
                    </th>
                    <th class="clickable" @click="sortField = 'end_date'; sortIsAsc = !sortIsAsc; loadProcurements()" scope="col">
                        End
                        <span class="sort-status">
                        <span :class="sortField === 'end_date' && sortIsAsc ? 'active-sort' : 'inactive-sort'">⇩</span>
                        <span :class="sortField === 'end_date' && !sortIsAsc ? 'active-sort' : 'inactive-sort'">⇧</span>
                    </span>
                    </th>
                    <th class="clickable" @click="sortField = 'agency_ad_number'; sortIsAsc = !sortIsAsc; loadProcurements()" scope="col">
                        Number
                        <span class="sort-status">
                        <span :class="sortField === 'agency_ad_number' && sortIsAsc ? 'active-sort' : 'inactive-sort'">⇩</span>
                        <span :class="sortField === 'agency_ad_number' && !sortIsAsc ? 'active-sort' : 'inactive-sort'">⇧</span>
                    </span>
                    </th>
                </tr>
                </thead>
                <tbody v-if=" procurementsSorted.length>0">

                <tr  v-if="!procurementsLoading" v-for="procurement in procurementsSorted">
                    <td data-label="Status">
                        {{ procurement.status }}
                    </td>
                    <td data-label="Agency">
                        <a v-if="procurement.agency" :href="procurement.url">
                            {{ procurement.agency.AgencyRef.name }}
                        </a>
                    </td>
                    <td data-label="Ad Type">
                        {{ procurement.adType }}
                    </td>
                    <td data-label="Title" class="title-cell">
                        <div class="title__bookmark">
                            <bookmark-note-icons
                                :procurement="procurement"
                                :customClass="'icon-only'"
                            />
                        </div>
                        <div class="title__content">
                            <button class="contract-button" @click="openFlyout(procurement.locationId)">
                                {{ procurement.title }}
                            </button>
                        </div>
                    </td>
                    <td data-label="Start">
                        {{ procurement.startDateFormatted }}
                    </td>
                    <td data-label="End">
                        {{ procurement.endDateFormatted }}
                    </td>
                    <td data-label="Number">
                        {{ procurement.agencyAdNumber }}
                    </td>
                </tr>
                </tbody>
                <tbody v-else>
                <td colspan="7">
                    <h5 class="search-info" v-if="!procurementsLoading">"Currently no procurements"</h5>
                </td>
                </tbody>
            </table>

            <div class="preloader-container">
                <preloader v-if="procurementsLoading"/>
            </div>
        </div>
        <pagination v-if=" pageCount > 1"
                    v-on:paginated="paginateProcurements($event)"
                    :current="page"
                    :pageCount="pageCount"
        />
    </general-container>
</template>

<script>
import Preloader from "./preloader.vue";
import BookmarkNoteIcons from "./bookmark-note-icons.vue";
import {onMounted, ref, watch, computed} from "vue";
import RequestHandler from "@/handler/RequestHandler";
import GeneralContainer from "./general-container.vue";
import ProcurementFlyout from "./procurement-flyout.vue";
import CustomSelect from "./custom-select.vue";
import Pagination from "./pagination.vue";
import { v4 as uuidv4 } from "uuid";

export default {
    name: "procurements-table-short",
    components: {
        Pagination,
        CustomSelect,
        ProcurementFlyout,
        GeneralContainer,
        BookmarkNoteIcons,
        Preloader
    },
    data() {
        return{
            procurementContentId: null,
            procurementProps: null,
        }
    },
    props: {
        locationId: Number,
        contentId: Number,
        title: String,
        titleClass: Object,
        customContainerClass: Object,
    },
    methods: {
        openFlyout(locationId) {
            RequestHandler.loadProcurement(locationId).then((data) => {
                if (data && data.ProcurementRef) {
                    this.procurementContentId = data.ProcurementRef.contentId;
                    this.procurementProps = data.ProcurementRef;
                    var x = document.getElementById("snackbar-procurement");
                    x.classList.add('show');
                    x.classList.add('fadein');
                }
            }).catch(e => {
                console.log(e);
            })
        },
        hideFlyout: function() {
            var x = document.getElementById("snackbar-procurement");
            x.classList.remove('fadein');
            x.classList.add('fadeout');
            document.body.classList.remove('position-fixed');
            setTimeout(function(){
                x.classList.remove('show'); x.classList.remove('fadeout');
            }, 500);
        },
        updateFilters: function (val) {
            if (this.appliedFilters.indexOf(val) !== -1) {
                return;
            }

            if (val === 'status' || val === 'type' ) {
                let allRemove = document.querySelectorAll("[data-name='" + val + "']");
                for (let i = 0; i < allRemove.length; i++) {
                    allRemove[i].parentNode.removeChild(allRemove[i]);
                }

                if (val === "status") {
                    this.statusValues = [];
                } else  if (val === "type") {
                    this.typesValues = [];
                }
                this.loadProcurements(true);
                return;
            }

            let filter = val;
            let filterType = '';

            this.appliedFilters = [filter.toString()];

            if (this.statuses.find(o => o.value === val)) {
                filterType = 'status';
                this.statusValues = [filter];
            } else if (this.types.find(o => o.value === val)) {
                filterType = 'type';
                this.typesValues = [filter]
            }
            this.loadProcurements(true);
        }
    },
    setup (props) {
        const hasFilters = ref(true);
        const procurements = ref([]);
        const sortField = ref('end_date');
        const procurementsLoading = ref(true);
        const procurementsSorted = ref([]);
        const keyword = ref('');
        const dateStartStart = ref();
        const dateStartEnd = ref();
        const dateEndStart = ref();
        const dateEndEnd = ref();
        const sortIsAsc = ref(true);
        const totalProcurementsCount = ref(0);
        const loading = ref(true);
        const appliedFilters =ref([]);
        const type = ref([]);
        const typeValues = ref();
        const types = ref([
            {
                value: '',
                name: "All Types",
            },
            {
                value: "Public Meeting Notice",
                name: "Public Meeting Notice",
            },
            {
                value: "Invitation to Bid",
                name: "Invitation to Bid",
            },
            {
                value: "Request for Proposals",
                name: "Request for Proposals",
            },
            {
                value: "Invitation to Negotiate",
                name: "Invitation to Negotiate",
            },
        ]);
        const statusValues = ref();
        const status = ref('');
        const statuses = ref([
            {
                value: '',
                name: "All Status",
            },
            {
                value: "open",
                name: "Open",
            },
            {
                value: "withdrawn",
                name: "Withdrawn",
            },
            {
                value: "closed",
                name: "Closed",
            },
            {
                value: "preview",
                name: "Preview",
            },
        ]);
        const page = ref(1);
        const pageCount = ref(1);
        const itemsPerPage = ref(5);
        const loadProcurements = (reset) => {
            if (reset) {
                page.value = 1;
                pageCount.value = null
                procurements.value = [];
                totalProcurementsCount.value = null;
            }
            procurementsLoading.value = true;
            let dStartStart = '';
            if (dateStartStart.value) {
                dStartStart = dateStartStart.value.toISOString().split('T')[0];
            }
            let dStartEnd = '';
            if (dateStartEnd.value) {
                dStartEnd = dateStartEnd.value.toISOString().split('T')[0];
            }
            let dEndStart = '';
            if (dateEndStart.value) {
                dEndStart = dateEndStart.value.toISOString().split('T')[0];
            }
            let dEndEnd = '';
            if (dateEndEnd.value) {
                dEndEnd = dateEndEnd.value.toISOString().split('T')[0];
            }
            loading.value = true;
            RequestHandler.loadProcurements(
                keyword.value,
                (page.value - 1) * itemsPerPage.value,
                itemsPerPage.value,
                sortField.value,
                sortIsAsc.value,
                props.locationId,
                type.value,
                [],
                0,
                status.value,
                dStartStart,
                dStartEnd,
                dEndStart,
                dEndEnd,
            ).then(response => {
                loading.value = false;
                procurementsLoading.value = false;
                procurements.value = response.ContentList.list;
                totalProcurementsCount.value = response.ContentList.totalCount
                pageCount.value = response.ContentList.pageCount ;
                setProcurements();
            })
                .catch(error => {
                    console.error(error);
                    loading.value = false;
                });
        };

        // Generate a unique ID for each instance
        const uniqueId = computed(() => {
            return uuidv4();
        });

        const paginateProcurements = (pageTo) => {
            page.value = pageTo;
            loadProcurements();
        };
        let timer = null;
        watch(keyword, (newVal) => {
            hasFilters.value = true;
            if (timer) {
                clearTimeout(timer);
            }
            timer = setTimeout(() =>loadProcurements(true));
        });
        watch (itemsPerPage, () => loadProcurements(true));
        watch(type, () => loadProcurements(true));
        watch(status, () => loadProcurements(true));
        const setProcurements = () => {
            let d = procurements.value;
            function compare(a, b) {
                if (a[sortField.value] < b[sortField.value]) {
                    return sortIsAsc.value ? -1 : 1;
                }
                if (a[sortField.value] > b[sortField.value]) {
                    return sortIsAsc.value ? 1 : -1;
                }
                return 0;
            }
            procurementsSorted.value = d.sort(compare);
        };
        onMounted(() => {
            loadProcurements();
        });
        return {
            sortField,
            sortIsAsc,
            procurementsSorted,
            procurementsLoading,
            procurements,
            loadProcurements,
            totalProcurementsCount,
            types,
            typeValues,
            type,
            keyword,
            loading,
            status,
            statusValues,
            statuses,
            hasFilters,
            appliedFilters,
            itemsPerPage,
            page,
            pageCount,
            paginateProcurements,
            uniqueId,
        }
    }

}
</script>

<style  lang="scss" scoped>
@import "../../assets/scss/_variables.scss";
@import "../../assets/scss/mixins/_bemify.scss";
@import "../../assets/scss/helpers/_responsive-tables.scss";

.contract-button {
    color: $blue--med;
    transition: all 0.3s ease-in-out;
    border: none;
    background: none;
    font-size: 16px;
    text-decoration: underline;
    font-family: Montserrat, sans-serif;
    line-height: 1.5em;
    text-align: left;
    cursor: pointer;
    &:hover, &:focus, &:active {
        color: $blue--dark;
    }
}
.fitler-container {
    display: flex!important;
    justify-content: space-evenly;
    flex-wrap: wrap;
}
.filter-container--main {
    display: inline-block;
    width: 100%;
    .filter-input {
        display: inline-block;
        input {
            height: 60px;
            margin-left: 15px;
        }
    }

}
.filter-info {
  text-align: center;
  font-size: 21px;
  font-weight: 600;
}
.filter-limit {
  text-align: right;
  margin-bottom: .75rem;
}
table {
    margin: auto;
    display: inline-table;
    border-bottom: 1px solid;
}
.title-cell {
    display: flex;
    border-bottom: none;
    border-left: none;
    border-right: none;
}
th {
    color: $white;
    background: $blue--dark;
    background: $blue-gradient;
    z-index: 2;
}

td, th {
    padding: 10px;
    border: solid 1px $grey--dark;
}

#snackbar-procurement {
    visibility: hidden; /* Hidden by default. Visible on click */
    min-width: 250px; /* Set a default minimum width */
    max-width: 95vw; /* for mobile */
    margin-left: -125px; /* Divide value of min-width by 2 */
    padding: 16px; /* Padding */
    position: fixed; /* Sit on top of the screen */
    z-index: 9999; /* allows to display in front of nav */
    right: 15px;
    bottom: 50px;
    max-height: 90vh;
    overflow-y: scroll;
    background-color: $blue--lighter !important;
    color: $blue--dark !important;
    box-shadow: $box-shadow !important;
    border-radius: 8px;
    opacity: 0;
    &.show {
        visibility: visible;
        opacity: 1;
        -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
        animation: fadein 0.5s, fadeout 0.5s 2.5s;
        &.fadein {
            -webkit-animation: fadein 0.5s;
            animation: fadein 0.5s;
        }
        &.fadeout {
            -webkit-animation: fadeout 0.5s;
            animation: fadeout 0.5s;
        }
    }
}


.tbl-scrollable {
    max-height: 75vh;
    overflow-y: auto;
    table {
        width: 100%;
        background-color: $white;
    }
    thead th {
        position: sticky;
        top: 0;
    }
}
.modal-background {
    background-color: rgba(0,0,0,.7);
}

.close-item:before {
    font-size: 30px;
    cursor: pointer;
    content: "×";
    color: $blue--dark;
    transition: color .3s;
    font-weight: 600;
    margin-left: 10px;
    position: relative;
    float: right;
    top: -5px;
    left: -5px;
}
.close-item {
    position: absolute;
    right: 20px;
    z-index: 1;
}

.inactive-sort {
    opacity: 0.45;
}
.active-sort {
    opacity: 1.0;
}
.agency-page .container .columns.multi-cols .column {
    height: 100%;
}
.search-info {
    text-align: center;
}
.clickable {
    cursor: pointer;
}
.clickable span.tooltip {
    display: none;
    position: relative;
    border: 5px solid $blue--dark;
&:after {
     bottom: 100%;
     left: 20%;
     border: solid transparent;
     content: " ";
     position: absolute;
 }
&:after {
     border-bottom-color: $blue--dark;
     border-width: 15px;
 }
}
.procurements-container .column {
    margin-top: 50px;
&.is-half-mobile {
     margin-top: 0px;
 }
}
.procurements-container--stats {
    margin-bottom: 30px;
}
.procurements-container--stats .column {
    cursor: pointer;
    text-align: center;
    padding: 15px;
> div {
    box-shadow: 0 0.5em 1em -0.125em rgb(0 0 0 / 10%), 0 0 0 1px rgb(0 0 0 / 0%);
    border-radius: 6px;
    padding: 15px;
    background-color: #fff;
&:hover {
     background-color: #f6f6f6;
 }
}
}

</style>
