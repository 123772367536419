<template>
    <!-- Component: molecules/app-footer -->
    <div class="floating-menu" v-if="user">
        <label for="floating-menu" id='floating-menu-label' class="aria-hidden">User Tools</label>
        <input id="floating-menu" type="checkbox" aria-labelledby="floating-menu-label">
        <span class="plus-icon">
            <font-awesome-icon :icon="iconPlus" aria-label="Floating Menu"></font-awesome-icon>
            <span class="aria-hidden">Open tools menu</span>
        </span>
        <ul class="floating-nav">
            <li>
                <div class="scrolltop-wrap">
                    <Tooltip :tooltipText="`Scroll to top`" position="left" cClass="user-tooltip">
                        <span @click="scrollTop()" role="button" aria-label="Scroll to top" class="top-scroller">
                            <svg viewBox="0 0 48 48" width="48" height="48px" xmlns="http://www.w3.org/2000/svg">
    <!--                            <path id="scrolltop-bg" d="M0 0h48v48h-48z"></path> -->
                                <path id="scrolltop-arrow" d="M14.83 30.83l9.17-9.17 9.17 9.17 2.83-2.83-12-12-12 12z"></path>
                            </svg>
                            <span class="aria-hidden">Scroll to top</span>
                        </span>
                    </Tooltip>
                </div>
            </li>
            <li>
                <div class="glossary-button__wrap">
                    <Tooltip :tooltipText="`Glossary`" position="left" cClass="user-tooltip">
                        <a href="#" class="glossary-button" aria-label="Open glossary" v-on:click.stop.prevent="showGlossary()">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                <rect id="Rectangle" fill="#FFFFFF" x="52" y="64" width="351" height="409"></rect>
                                <path fill="#022c43" d="M448,336 L448,48 C448,21.49 426.5,0 400,0 L96,0 C42.98,0 0,42.98 0,96 L0,416 C0,469.02 42.98,512 96,512 L416,512 C433.67,512 448,497.67 448,480.9 C448,469.18 441.393,459.38 432,453.8 L432,372.44 C441.8,362.8 448,350.2 448,336 Z M143.1,128 L335.1,128 C344.8,128 352,135.2 352,144 C352,152.8 344.8,160 336,160 L143.1,160 C135.2,160 128,152.8 128,144 C128,135.2 135.2,128 143.1,128 Z M143.1,192 L335.1,192 C344.8,192 352,199.2 352,208 C352,216.8 344.8,224 336,224 L143.1,224 C135.2,224 128,216.8 128,208 C128,199.2 135.2,192 143.1,192 Z M384,448 L96,448 C78.33,448 64,433.67 64,416 C64,398.33 78.33,384 96,384 L384,384 L384,448 Z" id="Shape"></path>
                            </svg>
                            <span class="aria-hidden">Open glossary</span>
                        </a>
                    </Tooltip>
                </div>
            </li>
            <li>
                <div class="notes-button__wrap">
                    <Tooltip :tooltipText="`My Notes`" position="left" cClass="user-tooltip">
                        <a href="/my-notes" class="navigation-item underline-item mobile-menu-item user-tools-item" aria-label="My Notes">
                            <font-awesome-icon :icon="iconPencil" aria-label="My Bookmarks icon" class="yellow"></font-awesome-icon>
                            <span class="aria-hidden">My Notes</span>
                        </a>
                    </Tooltip>
                </div>
            </li>
            <li>
                <div class="bookmark-button__wrap">
                    <Tooltip :tooltipText="`My Bookmarks`" position="left" cClass="user-tooltip">
                        <a href="/my-bookmarks" class="navigation-item underline-item mobile-menu-item user-tools-item" aria-label="Open Bookmarks">
                            <font-awesome-icon :icon="iconBookmark" aria-label="My Notes icon" class="red"></font-awesome-icon>
                            <span class="aria-hidden">My Bookmarks</span>
                        </a>
                    </Tooltip>
                </div>
            </li>
        </ul>
    </div>


    <div id="snackbar" class="mosha__toast default">
        <div @click="hideGlossary()" class="close-item"></div>
        <glossary v-if="renderComponent"></glossary>
    </div>

    <footer class="footer">
        <div class="container">
            <div class="columns">
                <div class="column pt-0 foot-col">
                    <img :src="require('@/assets/img/footer-background.png')" class="footer__background" alt="background lines">
                    <router-link :to="{'name': 'dashboard'}" class="footer__logo">
                        <img src="@/assets/svg/MGG-logo-stacked-white.png" alt="MyGovGuide" class="logo-image"/>
                    </router-link>
                </div>
                <div class="column foot-col">
                    <div class="footer__contact mt-30" v-html="content"></div>
                </div>
            </div>
        </div>

        <div class="container">
            <div class="columns">
                <div class="column foot-col has-text-centered footer__social">
                    <a :href="linkedinUrl" v-if="linkedinUrl" target="_blank">
                        <img src="@/assets/svg/social-media/footer/linkedin.svg" alt="Connect with us on Linkedin"/>
                    </a>
                    <a :href="twitterUrl" v-if="twitterUrl" target="_blank">
                        <img src="@/assets/svg/social-media/footer/twitter.svg" alt="Connect with us on X"/>
                    </a>
                    <a :href="facebookUrl" v-if="facebookUrl" target="_blank">
                        <img src="@/assets/svg/social-media/footer/facebook.svg" alt="Connect with us on Facebook"/>
                    </a>
                </div>
            </div>
        </div>

        <div class="container">
            <div class="columns">
                <div class="column foot-col has-text-centered footer__util-menu">
                    <ul>
                        <li v-for="(link, k) in footerLinks">
                            <a :href="link.href" :key="k">{{ link.name }}</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="container footer__foot">
            <div class="columns">
                <div class="column foot-col has-text-centered footer__copyright">
                    <p class="footer__copyright">{{ copyright }}</p>
                </div>
            </div>
        </div>
    </footer>
    <!-- End Component: molecules/app-footer -->
</template>

<script>
    import useUser from "@/helpers/useUser";
    import ToastGlossary from "@/alivue/components/toast--glossary.vue";
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { faBookmark, faPlus } from '@fortawesome/pro-solid-svg-icons';
    import { faPencil } from '@fortawesome/pro-duotone-svg-icons';
    import Glossary from "./glossary";
    import Tooltip from "./tooltip";

    export default {
        name: 'app-footer',
        components: {
            ToastGlossary,
            Glossary,
            FontAwesomeIcon,
            Tooltip
        },
        props: ['footerLinks', 'content', 'copyright', 'twitterUrl', 'linkedinUrl', 'facebookUrl'],
        setup() {
            const { user } = useUser();

            return {
                iconBookmark: faBookmark,
                iconPencil: faPencil,
                iconPlus: faPlus,
                user
            }
        },
        methods: {
            scrollTop: function() {
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            },
            whichIcon(title) {
                if (title == 'My Bookmarks') {
                    return faBookmark;
                } else if (title == 'My Notes') {
                    return faPencil;
                }
            },
            showGlossary: function() {
                //this.showGlossary = true;
                // Get the snackbar DIV
                var x = document.getElementById("snackbar");

                // Add the "show" class to DIV
                x.classList.add('show');
                x.classList.add('fadein');

                // After 3 seconds, remove the show class from DIV
                ////setTimeout(function(){ x.className = x.className.replace("show", ""); }, 3000);
            },
            hideGlossary: function() {
                //this.showGlossary = false;
                var x = document.getElementById("snackbar");
                var y = document.getElementById('glossarySearch');
                //x.className = x.className.replace("show", "");
                x.classList.remove('fadein');
                x.classList.add('fadeout');
                setTimeout(function(){
                    x.classList.remove('show');
                    x.classList.remove('fadeout');
                    y.value = " ";
                }, 100);

                // Remove my-component from the DOM
                this.renderComponent = false;

                this.$nextTick(() => {
                    // Add the component back in
                    this.renderComponent = true;
                });
            }
        },
        data() {
            return {
                renderComponent: true,
            };
        }
    }
</script>


<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";

    .aria-hidden {
        display: none;
        opacity: 0;
        font-size: 0px;
    }

    @include block('footer') {
        margin-top: 50px;
        padding-top: 0px !important;
        padding-bottom: 50px !important;
        color: $white;
        @media screen and (max-width: 768px) {
            text-align: center;
        }
        p, strong {
            color: $white;
        }
        .logo-image {
            width: 230px;
            position: relative;
            top: 15px;
            left: 0px;
        }
        @include element('background') {
            position: absolute;
            left: -100px;
        }
        @include element('logo') {
            display: inline-block;
            color: $white;
            font-family: $header;
            font-weight: 700;
            font-size: 32px;
            text-decoration: none;
            z-index: 9;
            @media screen and (max-width: 768px) {
                position: initial;
            }
            &:hover, &:active, &:focus {
                color: $blue--lighter;
            }
        }
        @include element('util-menu') {
            ul {
                list-style-type: none;
                width: fit-content;
                margin: 0 auto;
                @media screen and (max-width: 768px) {
                    display: inline-flex;
                }
                li {
                    float: left;
                    margin-right: 15px;
                    a {
                        color: $blue--lighter;

                        &:hover, &:active, &:focus {
                            color: $white;
                        }
                    }
                    &:after {
                        content: "|";
                        color: $blue--lighter;
                        margin-left: 15px;
                        @media screen and (max-width: 450px) {
                            content: "";
                        }
                    }
                    &:last-of-type {
                        margin-right: 0px;

                        &:after {
                            content: "";
                        }
                    }
                }
            }
            @media screen and (max-width: 450px) {
                content: "";
                ul {
                    display: block;
                    li {
                        margin-right: 0px;
                        margin-bottom: 5px;
                        width: 100%;
                    }
                }
            }
        }
        @include element('social') {
            a {
                margin-right: 15px;

                &:last-of-type {
                    margin-right: 0px;
                }
            }
        }
        @include element('copyright') {
            color: $blue--lighter;
        }
        @include element('foot') {
            @media screen and (max-width: 768px) {
                .foot-col {
                    width: 100%;
                    flex: auto;
                }
            }
        }
        @include element('contact') {
            color: $blue--lighter;
            text-align: right;
            span {
                font-family: $header;
                font-size: 21px;
                font-weight: 600;
            }
        }
        .foot-col {
            @media screen and (max-width: 768px) {
                display: block;
            }
        }
        .has-text-left, .has-text-right {
            @media screen and (max-width: 768px) {
                text-align: inherit !important;
            }
        }
    }

    // based on https://www.w3schools.com/howto/howto_js_snackbar.asp
    /* The snackbar - position it at the bottom and in the middle of the screen */
    #snackbar {
        display: none; /* Hidden by default. Visible on click */
        min-width: 250px; /* Set a default minimum width */
        margin-left: -125px; /* Divide value of min-width by 2 */
        padding: 16px; /* Padding */
        position: fixed; /* Sit on top of the screen */
        z-index: 99999; /* Add a z-index if needed */
        right: 15px;
        bottom: 50px;
        max-height: 90vh;
        overflow-y: scroll;
        background-color: $blue--lighter !important;
        color: $blue--dark !important;
        box-shadow: $box-shadow !important;
        border-radius: 8px;
        overflow: scroll;
        overflow-x: hidden;
        -webkit-overflow-scrolling: touch;
        @media screen and (max-width: 768px) {
            right: 0px;
        }
        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
            background-color: #F5F5F5;
        }

        &::-webkit-scrollbar {
            width: 10px;
            background-color: #F5F5F5;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background-color: $blue--med;
        }
    }

    /* Show the snackbar when clicking on a button (class added with JavaScript) */
    #snackbar.show {
        display: block; /* Show the snackbar */
        /* Add animation: Take 0.5 seconds to fade in and out the snackbar.
        However, delay the fade out process for 2.5 seconds */
        &.fadein {
            -webkit-animation: fadein 0.5s;
            animation: fadein 0.5s;
        }
        &.fadeout {
            -webkit-animation: fadeout 0.5s;
            animation: fadeout 0.5s;
        }
    }

    /* Animations to fade the snackbar in and out */
    @-webkit-keyframes fadein {
        from {bottom: 0; opacity: 0;}
        to {bottom: 30px; opacity: 1;}
    }

    @keyframes fadein {
        from {bottom: 0; opacity: 0;}
        to {bottom: 30px; opacity: 1;}
    }

    @-webkit-keyframes fadeout {
        from {bottom: 30px; opacity: 1;}
        to {bottom: 0; opacity: 0;}
    }

    @keyframes fadeout {
        from {bottom: 30px; opacity: 1;}
        to {bottom: 0; opacity: 0;}
    }

    .close-item {
        position: absolute;
        color: #fff;
        z-index: 9;
        top: 15px;
        right: 15px;
        @media screen and (max-width: 500px) {
            position: fixed;
        }
        &:before {
            font-size: 30px;
            cursor: pointer;
            content: "x";
            color: $white;
            transition: color .3s;
            font-weight: 600;
            margin-left: 10px;
            position: relative;
            float: right;
            top: -5px;
            left: -10px;
            @media screen and (max-width: 500px) {
                background-color: $blue--med;
                padding-left: 10px;
                padding-right: 10px;
                padding-bottom: 0px;
                margin: 0px;
                border-radius: 10px;
            }
        }
    }

    .bookmark-button__wrap,
    .notes-button__wrap {
        a {
            text-align: center;
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
            display: inline-block;
            text-decoration: none;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            pointer-events: all;
            outline: none;
            overflow: hidden;
            position: relative;
            top: 3px;
            svg {
                font-size: 18px;
                &.red {
                    color: #D00101;
                }

                &.yellow {
                    color: #FFBA06;
                }
            }
        }
    }

    .floating-menu {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background: $blue--med;
        position: fixed;
        bottom: 35px;
        right: 35px;
        z-index: 1;
        @media screen and (min-width: 961px) {
            width: 65px;
            height: 65px;
            font-size: 28px;
        }
        input{
            position: absolute;
            width: 50px;
            height: 50px;
            margin: 0;
            opacity: 0;
            cursor: pointer;
            @media screen and (min-width: 961px) {
                width: 65px;
                height: 65px;
            }
            &:checked{
                & ~ .plus-icon {
                    transform: rotate(135deg);
                }
                & ~ .floating-nav {
                    li{
                        box-shadow: 1px 1px 3px 1px rgba(0,0,0,.2);
                        &:nth-child(1) {
                            transform: translatey(-50px);
                            div span .top-scroller {
                                top: 3px;
                            }
                            @media screen and (min-width: 961px) {
                                transform: translatey(-63px);
                                div span .top-scroller {
                                    position: relative;
                                    top: 6px;
                                }
                            }
                        }
                        &:nth-child(2) {
                            transform: translatey(-90px);
                            @media screen and (min-width: 961px) {
                                transform: translatey(-118px);
                                div span a {
                                    top: 6px;
                                    left: 3px;
                                }
                            }
                        }
                        &:nth-child(3) {
                            transform: translatey(-130px);
                            div {
                                @media screen and (min-width: 961px) {
                                    span a {
                                        top: 2px;
                                        transform: scale(1.25);
                                    }
                                }
                            }
                            @media screen and (min-width: 961px) {
                                transform: translatey(-173px);
                            }
                        }
                        &:nth-child(4) {
                            transform: translatey(-170px);
                            div {
                                span a {
                                    top: 4px;
                                    left: 1px;
                                    @media screen and (min-width: 961px) {
                                        transform: scale(1.25);
                                    }
                                }
                            }
                            @media screen and (min-width: 961px) {
                                transform: translatey(-228px);
                                div span a {
                                    top: 2px;
                                    left: 0px;
                                }
                            }
                        }
                    }
                }
            }
            & ~ .plus-icon{
                transform: rotate(0);
            }
        }
        & > .plus-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 50px;
            height: 50px;
            font-size: 24px;
            line-height: 1;
            color: #FFFFFF;
            background: $blue--med;
            border-radius: 50%;
            transform: rotate(0);
            transition: all 0.5s ease;
            pointer-events: none;
            position: absolute;
            box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.2);
            @media screen and (min-width: 961px) {
                width: 65px;
                height: 65px;
            }
        }
        & > .floating-nav {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0;
            margin: 0;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            z-index: -1;
            li {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 35px;
                height: 35px;
                border-radius: 50%;
                background: $blue--med;
                cursor: pointer;
                position: absolute;
                transition: all 0.5s ease;
                @media screen and (min-width: 961px) {
                    width: 50px;
                    height: 50px;
                }
                span {
                    font-size: 14px;
                    line-height: 1;
                    color: #ffffff;
                    @media screen and (min-width: 961px) {
                        font-size: 18px;
                    }
                }
            }
        }
    }
    .top-scroller {
        cursor: pointer;
    }
</style>
