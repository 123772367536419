<template>
    <!-- Page: pages/budget-dashboard -->

    <div class="container">
        <alert/>
    </div>

    <hr/>

    <div class="container">
        <h1>Budget Dashboard</h1>
    </div>

    <tableau-embed
        :view-type="'AllPhasesDashboard'"
        :view-names-breakpoints="{
            0: 'BudgetDashboard',
        }"
        height="2650px"
    />
    <!-- End Page: pages/budget-dashboard -->
</template>

<script>
    import Alert from "./alert";
    import RequestHandler from "@/handler/RequestHandler";
    import { useRouter } from "vue-router";
    import Preloader from "./preloader";
    import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome'
    import { faChartLine, faSearch, faUsers, faLocation, faCalculator, faBadge, faCompass, faFileContract, faFileInvoice } from '@fortawesome/pro-light-svg-icons'
    import { faCircle } from '@fortawesome/pro-solid-svg-icons'
    import Gantt from "./gantt";
    import TableauEmbed from "./tableau-embed.vue";

    export default {
        name: 'ContractsDashboard',
        components: {
            Alert,
            Preloader,
            FontAwesomeIcon,
            FontAwesomeLayers,
            TableauEmbed,
        },
        setup(props) {

            return {

            }
        },
        data() {
            return {
            };
        },
        methods: {
        },
        mounted() {
            const router = useRouter();
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";
</style>
