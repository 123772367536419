<template>
    <!-- Component: molecules/info-block--employment -->
    <info-block :requestedIcon="requestedIcon" :hasIcon="true" :hasClose="false" :isTweet="false" :color="color">
        <p class="info-block__title">Employment Update {{ updatedAt }}</p>
        <tag-contact v-if="contact" :tagText="getFullNameText(contact.ContactRef)" :tagUrl="contact.ContactRef.url" />
        <span class="info-block__spacer">:</span>
        <tag-agency v-if="fromAgency" :tagText="fromAgencyName" :tagUrl="fromAgency.AgencyRef.url" :type="outType" :ttip="true" :ttipText="'Former Agency'"/>
        <tag-role v-if="fromPosition" :tagText="fromPositionName" :tagUrl="fromPosition.PositionRef.url" :type="outType" :ttip="true" :ttipText="'Former Role'"/>
        <tag v-else :tagText="'No information on this contact\'s previous position.'" :customClass="'disabled-cursor'" :type="'disabled'"/>
        <span class="info-block__spacer icon is-small"><i class="fas fa-arrow-right" aria-hidden="true"></i></span>
        <tag-agency v-if="toAgency && ((fromAgency && fromAgencyName != toAgencyName) || !fromAgency)" :tagText="toAgencyName" :tagUrl="toAgency.AgencyRef.url" :type="inType" :ttip="true" :ttipText="'Current Agency'"/>
        <tag-role v-if="toPosition" :tagText="toPositionName" :tagUrl="toPosition.PositionRef.url" :type="inType" :ttip="true" :ttipText="'Current Role'"/>
        <tag v-else :tagText="'This contact has left this state government entity.'" :customClass="'disabled-cursor'" :type="'disabled'"/>
    </info-block>
    <!-- End Component: molecules/info-block--employment -->
</template>

<script>
    import InfoBlock from "./info-block"
    import TagAgency from "./tag--agency";
    import TagRole from "./tag--role";
    import Tag from "./tag";
    import TagContact from "./tag--contact";
    import { capitalize } from '@/helpers/filter';

    export default {
        name: 'InfoBlockEmployment',
        components: {
            InfoBlock, TagAgency, TagRole, TagContact, Tag,
        },
        props: [
            'class',
            'contentId',
            'fromAgency',
            'fromAgencyName',
            'fromClassCode',
            'fromPosition',
            'fromPositionName',
            'fromPositionNumber',
            'fromSalary',
            'locationId',
            'name',
            'objectNameString',
            'toAgency',
            'toAgencyName',
            'toClassCode',
            'toPosition',
            'toPositionName',
            'toPositionNumber',
            'toSalary',
            'updatedAt',
            'url',
            'contact',
        ],
        data() {
            return {
                requestedIcon: "faUser",
                color: "blue--dark",
                outType: "outgoing",
                inType: "incoming"
            }
        },
        methods: {
            getFullNameText(contact) {
                return capitalize(contact.firstName)
                    + (contact.middleName ? ' ' + capitalize(contact.middleName) : '')
                    + ' ' + capitalize(contact.lastName);
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";

    @include block('info-block') {
        @include element('spacer') {
            margin-right: 10px;
            display: inline-block;
            @media screen and (max-width: 768px) {
                display: none;
            }
        }
    }
</style>

