<template>
    <!-- Component: molecules/tag--contact -->
    <a :href="tagUrl" :class="['gov-tag tag__contact', type]">
        <font-awesome-icon :icon="iconAssistant" aria-label="Contact" v-if="assistant"></font-awesome-icon>
        <font-awesome-icon :icon="iconContact" aria-label="Contact" v-else></font-awesome-icon>
        <span class="tag__text" v-text="toTitleCase(tagText)"></span>
        <span class="contact-is-leader" v-if="leadership === true">
          <font-awesome-icon :icon="iconLeader" aria-label="Department Head"></font-awesome-icon>
        </span>
    </a>
    <!-- End Component: molecules/tag--contact -->
</template>

<script>
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
    import { faCrown, faUser, faUsersCrown } from '@fortawesome/pro-light-svg-icons'
    import { toTitleCase } from "@/helpers/filter";

    export default {
        name: 'TagContact',
        components: {
            FontAwesomeIcon
        },
        props: ['tagText', 'tagUrl', 'leadership', 'type', 'assistant'],
        data() {
            return {
                iconContact: faUser,
                iconLeader: faCrown,
                iconAssistant: faUsersCrown,
                toTitleCase,
            }
        },
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";

    @include block('gov-tag') {
        background-color: $white;
        margin-right: 10px;
        padding: 5px 10px;
        box-shadow: $box-shadow;
        border-radius: 15px;
    }

    .contact-is-leader {
        margin-left: 5px;
    }

    @include block('tag') {
        @include element('text') {
            margin-left: 5px;
            font-weight: 600;
        }
        @include element('contact') {
            &.gov-tag {
                background-color: $white;
                color: $blue--dark;
                text-decoration: none;

                &:hover, &:active, &:focus {
                    color: lighten($blue--dark, 10%);
                    text-decoration: none;
                }
            }
            &.outgoing {
                svg {
                    color: $red;
                }
                .tag__text {
                    color: darken($red, 10%);
                }
            }
            &.incoming {
                svg {
                    color: darken($green--light, 25%);
                }
                .tag__text {
                    color: darken($green--light, 25%);
                }
            }
        }
    }
</style>
