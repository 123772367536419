<template>
    <!-- Component: organisms/search-result-card--vendor -->
    <searchResultCard type="vendor" :vendor="$props">

        <div class="section">
            <div class="content">

                <p class="card__info__label">
                    <Tooltip :tooltipText="definitions && definitions['Vendor'] && definitions['Vendor'].definition ? definitions['Vendor'].definition : 'Loading...'">
                        <span class='has-tooltip'>
                            Vendor
                        </span>
                    </Tooltip>
                </p>

                <br>

                <h1 class="card__info__title"><a :href="url">{{ titleize(name) }}</a></h1>

                <div class="columns">
                    <div class="column" v-if="showSecondName">
                        <p class="card__info__label">Secondary Name</p>
                        <p class="card__info__data">{{ factsSecondaryName }}</p>
                    </div>
                </div>

            </div>

        </div>
    </searchResultCard>
    <!-- End Component: organisms/search-result-card--vendor -->
</template>

<script>
    import SearchResultCard from "./search-result-card";
    import { computed, ref } from "vue";
    import { useStore } from "vuex";
    import Tooltip from "./tooltip";

    export default {
        name: 'SearchResultCardVendor',
        components: {
            SearchResultCard,
            Tooltip
        },
        props: [
            'name',
            'url',
            'locationId',
            'contentId',
            'factsSecondaryName',
        ],
        data() {
            return {
            }
        },
        methods: {
            titleize: function(item) {
                return item.toLowerCase().replace(/(?:^|\s|-)\S/g, x => x.toUpperCase());
            }
        },
        setup(props) {
            const store = useStore();
            const definitions = computed(() => store.getters.definitions);
            const showSecondName  = ref( false)
            function isNumeric(val) {
                return /[^\d\/\.]/g.test(val);
            }

            isNumeric(props.factsSecondaryName) ? showSecondName.value = true : showSecondName.value = false
            return {
                definitions,
                showSecondName,
            };
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/mixins/_bemify.scss";

    @include block('card') {
        .media {
            display: contents;
        }
        @include element('info') {
            @include element('label') {
                float: left;
                margin-right: 10px;
            }
        }
    }
</style>
