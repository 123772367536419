<template>
    <!-- Page: pages/homepage -->
    <div class="container">
        <alert/>
    </div>

    <hr/>

<!--    <div class="container mobile-contain">-->
        <userTools/>
<!--    </div>-->

    <hr/>

    <div class="container">
        <agencyInformationCard/>
    </div>
    <!-- End Page: pages/homepage -->
</template>

<script>
    import Alert from "@/alivue/components/alert";
    import UserTools from "@/alivue/components/user-tools";
    import AgencyInformationCard from "@/alivue/components/agency-info-card";
    import { useRouter } from "vue-router";
    import useUser from "@/helpers/useUser";
    import { computed, reactive, ref, watchEffect } from 'vue';

    export default {
        components: {
            Alert,
            UserTools,
            AgencyInformationCard
        },
        setup() {
            const loading = ref(true);

            const {redirectIfLoggedIn, userLoaded} = useUser();
            const router = useRouter();
            watchEffect(() => {
                redirectIfLoggedIn(router);
                if (userLoaded.value) {
                    loading.value = false;
                }
            });

            return {loading};
        }
    }
</script>

<style lang="scss" scoped>
    .mobile-contain {
        h1 {
            float: left;
        }
        @media screen and (max-width: 1021px) {
            margin-left: 15px;
            margin-right: 15px;
            h1 {
                text-align: center;
                float: none;
            }
        }
    }
</style>
