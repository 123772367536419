<template>
  <div class="skip-to-main-content">
    <a href="#main-content" @click="handleClick" class="skip-link">
      Skip to Main Content
    </a>
  </div>
</template>

<script>
  export default {
    name: 'SkipToMainContent',
    methods: {
      handleClick() {
        const mainContent = document.getElementById('main-content') || document.querySelector('[role="main"]');
        if (mainContent) {
          mainContent.tabIndex = -1; // Ensure the element can receive focus
          mainContent.focus();
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .skip-to-main-content {
    text-align: center;
    position: absolute;
    width: 100%;
    padding: 15px;
    margin: -1px;
    overflow: hidden;
    white-space: nowrap;
    border-width: 0;
    font-weight: 700;
    a {
      opacity: 0;
      color: #fff;
      pointer-events: none;
      &:focus {
        color: #fff;
        opacity: 1;
      }
    }
  }
</style>
