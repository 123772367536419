<template>
    <!-- Component: molecules/alert -->

    <!-- End Component: molecules/alert -->
</template>

<script>
    import { onMounted, ref } from 'vue';
    import RequestHandler from "@/handler/RequestHandler";
    import LocalStorageHandler from "@/handler/LocalStorageHandler";
    import { createToast, withProps } from 'mosha-vue-toastify';
    import { useRouter } from "vue-router";

    export default {
        name: 'Alert',
        data() {
            return {
                alerts: [],
                isFadedOut: [],
                isHidden: [],
                hiddenIds: LocalStorageHandler.get('alert-hidden') || [],
            };
        },
        methods: {
            iconClass: function(icon) {
                return (!icon || icon.includes(' ')) ? icon : 'fas ' + icon;
            },
            fadeOut: function(i) {
                this.hiddenIds.push(this.alerts[i].id);
                LocalStorageHandler.set('alert-hidden', this.hiddenIds);

                this.isFadedOut[i] = true;
                setTimeout(() => {
                    this.isHidden[i] = true;
                }, 300);
            }
        },
        mounted: function() {
            const router = useRouter();
            RequestHandler.loadPageAlerts(router.currentRoute.value.fullPath).then(response => {
                this.alerts = response.ContentList.list.filter(item => !this.hiddenIds.includes(item.id));
                for (let i = 0; i < this.alerts.length; i++) {
                    this.isFadedOut[i] = false;
                    this.isHidden[i] = false;
                }

                let _this = this;
                this.alerts.forEach(function (arrayItem, index) {
                    if (_this.isHidden[index]) {
                        return;
                    }
                    createToast(
                        {
                            title: arrayItem.title,
                            description: arrayItem.text
                        }, {
                            type: 'default', // accepts: 'info', 'danger', 'warning', 'success', 'default'
                            showIcon: true, // icons based on type above
                            timeout: 60000, // 60 seconds
                            hideProgressBar: true,
                            onClose: function() {
                                _this.hiddenIds.push(_this.alerts[index].id);
                                LocalStorageHandler.set('alert-hidden', _this.hiddenIds);

                                _this.isFadedOut[index] = true;
                                setTimeout(() => {
                                    _this.isHidden[index] = true;
                                }, 300);
                            }
                        }
                    );
                });

            }).catch((error) => {
                this.alerts = [];
                console.error(error);
            });
        },
    }
</script>

<style lang="scss">
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";
    @import "../../assets/scss/plugins/_mosha-vue-toastify.scss";
    @import "../../assets/scss/plugins/_mosha-vue-toastify-theme.scss";

</style>
