<template>
    <!-- Component: molecules/pagination -->
    <nav class="pagination is-centered" role="navigation" aria-label="pagination">
        <a class="pagination-previous" v-if="current !== 1"
           @click="updatePage(current - 1)">Previous</a>

        <a class="pagination-next" v-if="current !== pageCount" @click="updatePage(current + 1)">Next page</a>

        <ul class="pagination-list">
            <li v-for="page in pages" :key="page">
                <a class="pagination-link" v-if="page !== '...'"
                   :class="{'is-current': page === current}" :aria-current="page === current ? 'page' : undefined"
                   :aria-label="'Goto page ' + page"
                   @click="updatePage(page)"
                >{{ page }}</a>

                <span v-else class="pagination-ellipsis">&hellip;</span>
            </li>
        </ul>
    </nav>
    <!-- End Component: molecules/pagination -->
</template>

<script>
    import range from '@/helpers/range';
    import { computed, ref } from "vue";

    export default {
        name: 'Pagination',
        props: [
            'current', 'pageCount'
        ],
        emits: ['paginated'],
        setup(props, {emit}) {
            const current = ref(props.current);

            const pages = computed(() => {
                if (props.pageCount < 10) {
                    return range(1, props.pageCount);
                }

                const pageNumbers = [];
                for (let i = 1; i <= props.pageCount; ++i) {
                    if (i < 2 || i > props.pageCount - 1) {
                        pageNumbers.push(i); // first-last pages always displayed
                        continue;
                    }

                    if (i === current.value) {
                        pageNumbers.push(current.value);
                        continue;
                    }
                    if (
                        i >= current.value - 3 && i < current.value
                        || i <= current.value + 3 && i > current.value
                    ) {
                        pageNumbers.push(i);
                        continue;
                    }
                    if (i === current.value - 6 || i === current.value + 5) {
                        pageNumbers.push('...');
                    }
                }

                return pageNumbers
            });

            const updatePage = (page) => {
                current.value = page;
                emit('paginated', page);
            }

            return {pages, current, updatePage};
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";

    .pagination {
        margin-top: 15px;
        a {
            background-color: $blue--dark;
            border-color: $blue--dark;
            color: $white;
            text-decoration: none;

            &:hover, &:active, &:focus {
                text-decoration: underline;
            }
        }
    }

    .pagination-link.is-current {
        background-color: $blue--med;
        border-color: $blue--dark;
        color: $white;
        transition: all 0.5s ease-in-out;
        &:hover, &:active, &:focus {
            border-color: $blue--dark;
            color: $white;
        }
    }

    .pagination-previous,
    .pagination-next,
    .pagination-link {
        transition: $animate--long;

        &:hover, &:active, &:focus {
            border-color: $blue--med;
        }
    }
</style>
