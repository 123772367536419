function resizeImage(img, boundingBox, className) {
    // setup image dimension
    let dimensions = setImageDimension(img.width, img.height, boundingBox);

    let contacts = [...document.getElementsByClassName(className)];
    contacts.forEach(function (contact) {
        // only replace img if it has same src
        if (contact.src === img.src && contact.tagName === 'IMG'){
            contact.parentElement.prepend(createCanvas(contact, img, dimensions.width, dimensions.height, dimensions.x, dimensions.y));

            contact.style.display = 'none';
        }

    });
}

function removeCanvases(className){
    let canvases = [...document.getElementsByTagName('canvas')];
    canvases.forEach(function (canvas) {
        if (canvas.className === className){
            canvas.parentNode.removeChild(canvas);
        }
    });
}

function setImageDimension(imgWidth, imgHeight, boundingBox)
{
    // check if boundingBox is empty
    if (!boundingBox || !boundingBox.length)
    {
        let square = imgWidth;
        if (imgHeight < square) {
            square = imgHeight;
        }

        return {
            'width': square,
            'height': square,
            'x': 0,
            'y': 0
        };
    }

    let width = imgWidth * boundingBox.Width;
    let height = imgHeight * boundingBox.Height;
    let x = imgWidth * boundingBox.Left;
    let y = imgHeight * boundingBox.Top;

    // make square image
    if (height > width) {
        x -= (height - width)/2;
        width = height;
    } else {
        y -= (width - height) / 2;
        height = width;
    }

    // make large as possible
    let x2 = imgWidth - x - width;
    let y2 = imgHeight - y - height;
    let minDiff = smallest(x, y, x2, y2)

    //update values
    width += (minDiff * 2);
    height += (minDiff * 2);
    x -= minDiff;
    y -= minDiff;

    return {
        'width': width,
        'height': height,
        'x': x,
        'y': y
    };
}

function createCanvas(original, img, width, height, x, y)
{
    let canvas = document.createElement('canvas');
    let context = canvas.getContext('2d');
    let imageObj = new Image();
    imageObj.src = img.src;
    //canvas.style.borderRadius = "100%";

    // set canvas dimensions
    canvas.width = width;
    canvas.height = height;

    imageObj.onload = function () {
        context.drawImage(imageObj, x, y, width, height, 0, 0, width, height);
    };

    //set original attributes
    for (let i = 0; i < original.attributes.length; i++) {
        let attr = original.attributes.item(i);
        canvas.setAttribute(attr.nodeName, attr.nodeValue);
    }
    canvas.removeAttribute('style');
    canvas.removeAttribute('src');

    return canvas;
}

function smallest(a, b, c, d) {
    return Math.min.apply(null, arguments)
}

export { resizeImage, removeCanvases };
