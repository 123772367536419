<template>
    <!-- Component: organisms/agency-readmore-modal -->
    <div id="agency-readmore-modal" class="modal agency-readmore-modal is-active">
        <div class="modal-background" @click="$emit('close')"></div>
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">{{ titleize(agencyName) }}</p>
                <button class="delete" aria-label="close" @click="$emit('close')"></button>
            </header>
            <section class="modal-card-body">
                <div class="modal-card-content">
                    <div v-if="vision.length > 10">
                        <strong class="modal-label">Agency Vision: </strong>
                        <div v-html="vision"></div>
                    </div>
                    <div v-if="info.length > 10">
                        <strong class="modal-label">Agency Information: </strong>
                        <div v-html="info"></div>
                    </div>
                    <div v-if="goals.length > 10">
                        <strong class="modal-label">Agency Goals: </strong>
                        <div v-html="goals"></div>
                    </div>
                    <div v-if="objectives.length > 10">
                        <strong class="modal-label">Agency Objectives: </strong>
                        <div v-html="objectives"></div>
                    </div>
                </div>
            </section>
        </div>
    </div>
    <!-- End Component: organisms/agency-readmore-modal -->
</template>

<script>
    import { ref } from "vue";

    export default {
        name: 'AgencyReadmoreModal',
        props: {
            agencyName: String,
            vision: String,
            info: String,
            goals: String,
            objectives: String
        },
        emits: ['close', 'created'],
        components: {

        },
        setup(props, {emit}) {
            const showCreate = ref(false);

            const newModalCreated = () => {
                showCreate.value = false;
                emit('created');
            }

            return {
                showCreate,
                newModalCreated
            };
        },
        methods: {
            titleize: function(item) {
                return item.toLowerCase().replace(/(?:^|\s|-)\S/g, x => x.toUpperCase());
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";

    .modal-card-head, .modal-card-foot {
        border: none;
        background-color: white;
    }

    .modal-card-body {
        border-top: 1px solid $grey--dark;
    }

    .modal-card-title {
        color: $blue--dark;
        font-size: 24px;
        margin-bottom: 0px;
        font-weight: 600;
        flex-shrink: initial !important;
        line-height: 1.4em !important;
        &.modal-card-title__name {
            font-weight: 600;
            color: $blue--dark;
            font-size: 28px;
            margin-bottom: 30px;
        }
    }
    @media screen and (min-width: 769px) {
        .modal-card {
            width: 740px;
        }
    }

    @media screen and (min-width: 1200px) {
        .modal-card {
            min-width: 1100px;
            width: 80%;
        }
    }
</style>

