<template>
    <div id="snackbar-vendor">

        <div class="snackbar-container">

            <div @click="hideFlyoutVendor()" class="close-item"></div>
            <!-- Component: organisms/vendor-info-card -->
            <info-card :vendor="$props">
                <div class="card-content">
                    <div class="section">
                        <div class="content">

                            <p class="card__info__label top">
                                <Tooltip :tooltipText="definitions && definitions['Vendor'] && definitions['Vendor'].definition ? definitions['Vendor'].definition : 'Loading...'">
                                    <span class='has-tooltip'>
                                        Vendor
                                    </span>
                                </Tooltip>
                            </p>
                            <p class="card__info__title">{{ name }}</p>

                            <template v-if="factsSecondaryName">
                                <p class="card__info__label">Secondary Name</p>
                                <p class="card__info__data">{{ factsSecondaryName }}</p>
                            </template>
                        </div>
                    </div>
                </div>
            </info-card>
            <!-- End Component: organisms/vendor-info-card -->

            <div class="container">

                <vendor-contracts
                    :subflyout="subflyout"
                    :search-agency="true"
                    :search-type="true"
                    :search-fiscal="true"
                    :key="contentId"
                    :scrollable="false"
                    :vendor-id="contentId"
                    title="Contracts for Vendor"
                />

            </div>

        </div>

    </div>

</template>

<script>
    import { reactive, computed, ref } from "vue";
    import { useStore } from "vuex";
    import InfoCard from "./info-card"
    import ReadMoreLink from "./read-more-link"
    import Tooltip from "./tooltip";
    import VendorContracts from "./vendor-contracts";
    import RequestHandler from "@/handler/RequestHandler";

export default {
        name: 'VendorInformationCard',
        components: {
            InfoCard,
            ReadMoreLink,
            Tooltip,
            VendorContracts
        },
        methods: {
            openFlyoutVendor(vendorLocationId) {
                return new Promise((resolve, reject) => {
                RequestHandler.loadVendor(vendorLocationId)
                    .then((data) => {
                        if (data && data.VendorRef) {
                            resolve(data.VendorRef); // Resolve the Promise with the data
                        } else {
                            reject("Vendor data not found");
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error);
                    });
                });
            },
            hideFlyoutVendor() {
                var x = document.getElementById( "snackbar-vendor");
                x.classList.remove('fadein');
                x.classList.add('fadeout');
                setTimeout(function(){
                    x.classList.remove('show');
                    x.classList.remove('fadeout');
                }, 500);
            },
        },
        props: {
            name: String,
            type: String,
            locationId: Number,
            contentId: Number,
            factsSecondaryName: String,
            factsName: String,
            transparencyName: Object,
        },
        setup(props) {
            /*
            let readMoreLink = props.readMoreLink

            if (!readMoreLink) {
                readMoreLink = {};
            }
            if (!readMoreLink.name) {
                readMoreLink.name = "Read More About The " + props.name
            }

            return reactive({readMoreLink})
            */

            const store = useStore();
            const definitions = computed(() => store.getters.definitions);

            return {
                definitions
            };
        }
    }
</script>

<style lang="scss" scoped>


    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";

    // based on https://www.w3schools.com/howto/howto_js_snackbar.asp
    /* The snackbar - position it at the bottom and in the middle of the screen */
    #snackbar-vendor {
        display: none; /* Hidden by default. Visible on click */
        min-width: 250px; /* Set a default minimum width */
        margin-left: -125px; /* Divide value of min-width by 2 */
        padding: 16px; /* Padding */
        position: fixed; /* Sit on top of the screen */
        z-index: 10000; /* Add a z-index if needed */
        right: 15px;
        bottom: 50px;
        max-height: 90vh;
        overflow-y: scroll;
        background-color: $blue--lighter !important;
        color: $blue--dark !important;
        box-shadow: $box-shadow !important;
        border-radius: 8px;
    }

    /* Show the snackbar when clicking on a button (class added with JavaScript) */
    #snackbar-vendor.show {
        visibility: hidden;
        opacity: 0;
        &.show {
            display: block;
            visibility: visible;
            opacity: 1;
            -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
            animation: fadein 0.5s, fadeout 0.5s 2.5s;
            &.fadein {
                -webkit-animation: fadein 0.5s;
                animation: fadein 0.5s;
            }
            &.fadeout {
                -webkit-animation: fadeout 0.5s;
                animation: fadeout 0.5s;
            }
        }
    }

    .content .card__info__title {
        margin-top: 0.75em !important;
        font-family: $header;
        font-weight: 600;
        font-size: 2em;
    }

    @include block('card') {
        .media {
            display: contents;
        }
        @include element('info') {
            @include element('label') {
                float: left;
                margin-right: 10px;
                display: block;
                width: 100%;
                margin-bottom: 5px !important;
                &.top {
                    margin-bottom: 0px !important;
                }
            }
            @include element('description') {
                line-height: 1.7em;
            }
        }
    }
</style>

