import { toTitleCase } from "@/helpers/filter";

export default new class PageHandler {

    constructor() {
        // ignore links to pdf and image files
        // custom - ignore links to file download URL
        this.ignoreLinks = /(\.pdf$)|(\.jpe?g$)|(\.png$)|(\.html$)|(^\/content\/download\/)|(^mailto)/i;
        this.navigatedFromLink = false;
    }

    parsePageResponse(response) {
        const pageIdentifier = response.type;
        let pageProps = Object.assign({}, response.data || {});
        delete pageProps.metaTitle;
        delete pageProps.metaKeywords;
        delete pageProps.metaDescription;

        return {pageIdentifier, pageProps};
    }

    updatePageHeadByRoute(route, store) {
        const nearestWithMeta = route.matched && route.matched.slice().reverse().find(r => r.meta);

        const data = nearestWithMeta && nearestWithMeta.meta ? {
            metaTitle: toTitleCase(nearestWithMeta.meta.title) || null,
            metaKeywords: nearestWithMeta.meta.keywords || null,
            metaDescription: nearestWithMeta.meta.description || null,
            metaImageUrl: nearestWithMeta.meta.imageUrl || null,
        } : {};
        this.updatePageHead({data: data}, store);
    }

    updatePageHead(apiResponse, store) {
        let title = process.env.VUE_APP_DEFAULT_TITLE;
        if (apiResponse && apiResponse.data) {
            title = apiResponse.data.metaTitle || apiResponse.data.title || apiResponse.data.name || title;
        }
        if (title !== process.env.VUE_APP_DEFAULT_TITLE) {
            if (process.env.VUE_APP_TITLE_PREFIX) {
                title = process.env.VUE_APP_TITLE_PREFIX + title;
            }
            if (process.env.VUE_APP_TITLE_POSTFIX) {
                title += process.env.VUE_APP_TITLE_POSTFIX;
            }
        }
        title = toTitleCase(title);

        if (process.env.VUE_ENV === 'server') {
            // we don't need meta tags for users, only robots, feel free to add more SEO info here
            let keywords = process.env.VUE_APP_DEFAULT_KEYWORDS;
            let description = process.env.VUE_APP_DEFAULT_DESCRIPTION;
            let imageUrl = process.env.VUE_APP_DEFAULT_META_IMAGE_URL;
            if (apiResponse && apiResponse.data) {
                keywords = apiResponse.data.metaKeywords || keywords;
                description = apiResponse.data.metaDescription || description;
                imageUrl = apiResponse.data.metaImageUrl || imageUrl;
            }

            store.dispatch('setPageMeta', {
                'title': title,
                'keywords': keywords || '',
                'description': description || '',
                'imageUrl': imageUrl || '',
            });
        } else {
            document.title = title;
        }
    }

    catchLinkToRoute(e, router) {
        if (e.shiftKey || e.ctrlKey || e.metaKey) {
            return; // allow open in new tab / window
        }
        const linkRoute = this.findLinkRoute(e.target);
        if (linkRoute) {
            e.preventDefault();
            this.navigatedFromLink = true;
            router.push(linkRoute);
        }
    }

    findLinkRoute(node) {
        let maxDepth = 10;
        while (node !== null && maxDepth-- > 0) {
            if (node.tagName && node.tagName.toLowerCase() === 'a') {
                if (node.getAttribute('target') && node.getAttribute('target') !== '_self') {
                    return null;
                }
                const href = node.getAttribute('href');
                if (!href) {
                    return null;
                }
                const matchAbsoluteLink = href.match(new RegExp('^(?:[a-z]+:)?//([A-z0-9:.]*?)/', 'i'));
                if (matchAbsoluteLink) {
                    if (matchAbsoluteLink[1].toLowerCase() === window.location.host) {
                        return href.substring(href.indexOf(matchAbsoluteLink[1]) + matchAbsoluteLink[1].length) || null;
                    }
                    return null;
                }
                if (this.ignoreLinks && this.ignoreLinks.test(href)) {
                    return null;
                }

                return href;
            }
            node = node.parentNode;
        }

        return null;
    }
}
