<template>
    <!-- Page: pages/contact -->
    <br/>

    <div class="container mobile-container">
        <breadcrumbs :items="path"/>
        <alert/>
    </div>

    <hr/>

    <div class="container mobile-container left-gov" v-if="position == null && agency == null">
        <p>This contact has left this state government entity.</p>
        <Tooltip :tooltipText="'This notification may also show for some government employees that may be or become exempt from public disclosure of personal information.'">
            <font-awesome-icon :icon="icon" aria-label="More Information"></font-awesome-icon>
        </Tooltip>
    </div>
    <div class="container mobile-container exempt" v-else-if="exempt">
        <font-awesome-icon :icon="icon" aria-label="More Information"></font-awesome-icon>
        <p>Some contacts may have limited information because of an exempt or confidential status.</p>
    </div>

    <div class="container mobile-container">
        <contact-information-card v-bind="$props" :isactive="true"/>
    </div>

    <div class="container block-container" v-if="rss">
        <div class="block-column is-full">
            <recent-news-block :items="rss" :contentId="contentId" title="Recent News Block"/>
        </div>
    </div>

    <div class="container block-container">

        <div class="block-column is-full">

            <div class="columns pb-30 scenario-1">
                <div class="column is-half mobile-full twitter-block-container" v-if="voterData && voterData.voteActive && latest_tweet">
                    <social-media-block-twitter
                        :type="'twitter'"
                        :name="latest_tweet.user.name"
                        :handle="latest_tweet.user.screen_name"
                        :buttonLabel="'Follow ' + latest_tweet.user.name + ' on X'"
                        :date="formatDate(latest_tweet.created_at)"
                        :url="'https://www.x.com/' + latest_tweet.user.screen_name"
                        :content="latest_tweet.text"
                        :postUrl="'https://www.x.com/' + latest_tweet.user.screen_name + (latest_tweet.id_str ? ('/status/' + latest_tweet.id_str):'')"
                        :image="latest_tweet.user.image"
                        :customClass="'general-container--background'"
                    />
                </div>
                <div class="column is-full mobile-full twitter-block-container" v-else-if="latest_tweet">
                    <social-media-block-twitter
                        :type="'twitter'"
                        :name="latest_tweet.user.name"
                        :handle="latest_tweet.user.screen_name"
                        :buttonLabel="'Follow ' + latest_tweet.user.name + ' on X'"
                        :date="formatDate(latest_tweet.created_at)"
                        :url="'https://www.x.com/' + latest_tweet.user.screen_name"
                        :content="latest_tweet.text"
                        :postUrl="'https://www.x.com/' + latest_tweet.user.screen_name + (latest_tweet.id ? ('/status/' + latest_tweet.id):'')"
                        :image="latest_tweet.user.image"
                        :customClass="'general-container--background pb-40'"
                    />
                </div>

                <div class="column is-half mobile-full" v-if="voterData && voterData.voteActive">
                    <voter-data-block v-bind="voterData" :customClass="'general-container--background'"/>
                </div>

                <div class="column is-half mobile-full" v-if="!voterData">
                    <voter-data-block-locked :customClass="'general-container--background'"/>
                </div>

                <div class="column is-half mobile-full" v-if="contentId">
                    <travel-history-block :key="contentId"
                        :contact-id="contentId"
                        :contact-url="url"
                        :contact-name="firstName + ' ' + lastName"
                        :show-name="false"
                    />
                </div>

                <div class="column is-half mobile-full">
                    <interested-in-block
                        :key="locationId"
                        :withContainer="true"
                        :view="'full'"
                        :title="'Recent position changes...'"
                        :num="5"
                        :type="'employment_update_all'"
                        :subtree="locationId"/>
                </div>

                <div class="column is-full mobile-full" v-if="isInOrgChart && ((!agencyHead && position && position.contentId) || (agencyHead && agency && agency.contentId))">
                    <position-budget-block v-if="!agencyHead" :key="position.contentId"
                        :position-id="position.contentId"
                        :agency-id="agency?.contentId"
                        v-on:has-budgets="hasBudgets"
                    />
                    <agency-budget-block v-if="agencyHead" :agency-id="agency.contentId"></agency-budget-block>
                </div>

            </div>

        </div>

        <div class="block-column is-full" v-if="agency">
            <div class="columns pb-30">
                <div class="column max100">
                    <general-container title="Organizational Chart" custom-class="org-chart-container">
                        <contacts-tree-chart
                            :contact-location-id="locationId"
                            :agency-id="agency.contentId"
                            :contact-name="lastName + ', ' + firstName"
                            :key="agency.contentId"
                        />
                    </general-container>
                </div>
            </div>
        </div>

    </div>
    <!-- End Page: pages/contact -->
</template>

<script>
    import * as dateFormat from 'dateformat';
    import RequestHandler from "@/handler/RequestHandler";
    import ContactInformationCard from "./contact-info-card";
    import TravelHistoryBlock from "./travel-history-block";
    import Alert from "./alert";
    import Breadcrumbs from "./breadcrumbs";
    import PositionBudgetBlock from "@/alivue/components/position-budget-block";
    import SocialMediaBlockTwitter from "./social-media-block--twitter";
    import VoterDataBlock from "./voter-data-block";
    import VoterDataBlockLocked from "./voter-data-block--locked";
    import RecentNewsBlock from "./recent-news-block";
    import SearchBlock from "./search-block";
    import { computed, onMounted, ref, toRefs, watch } from "vue";
    import { useStore } from "vuex";
    import { contactFullName } from "@/helpers/objects";
    import ContactsTreeChart from "./contacts-tree-chart";
    import GeneralContainer from "./general-container";
    import InterestedInBlock from '@/alivue/components/interested-in-block.vue';
    import AgencyBudgetBlock from "./agency-budget-block";
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
    import Tooltip from "./tooltip";

    export default {
        components: {
            GeneralContainer,
            ContactsTreeChart,
            PositionBudgetBlock,
            Breadcrumbs,
            Alert,
            TravelHistoryBlock,
            ContactInformationCard,
            SocialMediaBlockTwitter,
            VoterDataBlock,
            VoterDataBlockLocked,
            RecentNewsBlock,
            SearchBlock,
            InterestedInBlock,
            AgencyBudgetBlock,
            FontAwesomeIcon,
            Tooltip
        },
        props: [
            "contentId", "lastName", "firstName", "goesBy", "title", "photo", "email", "displayEmail", "phone", "cellPhone", "linkedin",
            "twitter", "facebook", "aboutMe", "employeesOversees", "middleName", "city", "age", "gender", "agencyHead", "phoneManual",
            "biography", "interestingFacts", "modificationMonth", "updated", "locationId", "exempt",
            "position", "agency", "url", "latest_tweet", "rssFeed", "rss", "positionRole",
            "hireDate", "salary", "budgetEntity", "programs", "last_salary",
            'executiveAssistantContacts', 'executiveAssistantPositions', "isInOrgChart"
        ],
        data() {
            return {
                hasBudgets: true, // assume we have budgets until we check
                icon: faInfoCircle
             };
        },
        methods: {
            hasBudgets(x) {
                this.hasBudgets = x;
            },
        },
        setup(props) {
            const store = useStore();
            const voterData = ref(null);
            const {contentId} = toRefs(props);
            const showSearch = computed(() => store.getters.showSearch);
            const fullNameText = computed(() => contactFullName(props));
            const path = computed(() => {
                const pathItems = [];
                if (props.agency && props.agency.name) {
                    pathItems.push({url: props.agency.url, title: props.agency.name});
                }
                if (props.position && props.position.title) {
                    pathItems.push({url: props.position.url, title: props.position.title});
                }
                pathItems.push({title: fullNameText.value});

                return pathItems;
            });

            const loadVotersBlock = () => {
                voterData.value = null;
                RequestHandler.loadVoterInfo(props.contentId).then(response => {
                    voterData.value = response;
                }).catch(error => console.error(error));
            };

            watch(contentId, loadVotersBlock);
            onMounted(loadVotersBlock);

            const formatDate = date => dateFormat(date, "mm/dd/yy h:MM TT").replace("AM", "am").replace("PM","pm");

            return {
                path,
                fullNameText,
                showSearch,
                voterData,
                formatDate,
            };
        },
    }
</script>

<style lang="scss" scoped>
    .pb-30 {
        padding-bottom: 30px;
    }

    .max100 {
        max-width: 100%;
        flex: auto !important;
        margin: 0px !important;
        padding: 0px !important;
    }

    .filter-wrapper {
        max-width: 50%;
        margin: auto;
        margin-top: 20px;
        @media screen and (max-width: 768px) {
            width: 90%;
            max-width: 90%;
        }
        .search-block {
            margin-bottom: 30px;
        }
    }

    .container.block-container {
        display: inline-block;
        width: 100%;

        .block-column {
            width: calc(50% - 10px);
            float: left;
            margin-bottom: 30px;
            @media screen and (max-width: 991px) {
                width: calc(100% - 30px) !important;
                margin: 0 15px;
                &:not(.is-full) {
                    &:nth-of-type(odd) {
                        margin-right: 0px;
                    }
                }
                &.is-full {
                    width: auto;
                }
            }

            &:not(.is-full) {
                &:nth-of-type(even) {
                    margin-right: 15px;
                }
            }

            &.is-full {
                width: 100%;
                margin-bottom: 30px;
            }
        }
    }
    .columns {
        display: flex;
        align-items: left;
        justify-content: left;
        flex-direction: row;
        flex-wrap: wrap;
        flex-flow: row wrap;
        align-content: flex-end;
        .column {
            display: inline-block;
            width: 100%;
            flex: 0 47%;
            margin: 0 15px;
            .general-container {
                height: 100%;
            }
            @media screen and (max-width: 1215px) {
                flex: 0 100%;
                margin: 0;
            }
        }
    }
    .col-container {
        .columns .column {
            flex: 0 50%;
            margin: 0px;
        }
    }
    @media screen and (max-width: 768px) {
        .is-half.mobile-full {
            width: 100% !important;
            flex: 0 100%;
        }
    }
    @media screen and (min-width: 769px) {
        .columns .column.is-half.mobile-full {
            flex: 0 50%;
            margin: 0px;
        }
        .columns .column.is-full.mobile-full {
            flex: 0 100%;
            margin: 0px;
        }
    }
    .left-gov,
    .exempt {
        background-color: #feecf0;
        border: 1px solid #cc0f35;
        padding: 6px;
        text-align: center;
        margin-bottom: 30px;
        p {
            color: #cc0f35;
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 10px;
            margin-top: 10px;
            padding: 0 10px;
            display: inline-block;
        }
    }

    .fa-info-circle {
        color: #cc0f35;
    }
</style>
