import RequestHandler from '../handler/RequestHandler';
import PageHandler from '../handler/PageHandler';
import pageComponents from "../alivue/dynamicPageComponents";

export const createPageStore = () => {
    return {
        state: {
            siteConfig: null,
            agencies: null,
            pageProps: {},
            pageMeta: {},
            pageComponentName: null,
            pageLoading: null,
            showSearch: false,
        },
        actions: {
            resetPage(context) {
                context.commit('setPageLoading', false);
                return context.commit('resetPage');
            },
            setPageByComponentName(context, componentDetails) {
                return context.commit('setPageByComponentName', componentDetails);
            },
            setPageMeta(context, pageMeta) {
                return context.commit('setPageMeta', pageMeta);
            },
            fetchPage(context, request) {
                context.commit('setPageLoading', true);
                return RequestHandler.loadPage(request.url)
                    .then(response => {
                        context.commit('setPage', response);
                        context.commit('setPageLoading', false);

                        return response;
                    })
                    .catch(error => {
                        if (request.redirectOnFail && process.env.VUE_ENV !== 'server') {
                            window.location.replace(request.url);
                            return error;
                        }
                        context.commit('setPageLoading', false);

                        if (error && error.response && error.response.status === 404) {
                            const notFound = {'type': 'notfound', 'data': {'title': 'Page Not Found'}};
                            context.commit('setPage', notFound);

                            return notFound;
                        }

                        return error;
                    })
            },
            fetchSiteConfig(context) {
                return RequestHandler.loadSiteConfig()
                    .then(response => {
                        context.commit('setSiteConfig', response);

                        return response;
                    })
            },
            fetchAgencies(context) {
                return RequestHandler.loadAgencies()
                    .then(response => {
                        context.commit('setAgencies', response);

                        /*if (response.ContentList.list.length === 0) {
                            setTimeout(function() {
                                context.dispatch('fetchAgencies');
                            }, 1000);
                        }*/

                        return response;
                    })
                    .catch(response => {
                        //setTimeout(function() {
                        //    context.dispatch('fetchAgencies');
                        //}, 3000);
                    });
            },
        },
        mutations: {
            setPageLoading(state, val) {
                return state.pageLoading = val;
            },
            setShowSearch(state, show) {
                return state.showSearch = show;
            },
            setPage(state, response) {
                const {pageIdentifier, pageProps} = PageHandler.parsePageResponse(response);
                let newPageComponentName = null;

                for (let i = 0; i < pageComponents.length; ++i) {
                    if (pageComponents[i].pageIdentifier.includes(pageIdentifier)) {
                        newPageComponentName = pageComponents[i].name;

                        for (let prop in pageProps) {
                            if (!pageComponents[i].props.includes(prop)) {
                                delete pageProps[prop];
                            }
                        }
                    }
                }
                state.pageComponentName = newPageComponentName;
                state.pageProps = pageProps;
            },
            setSiteConfig(state, response) {
                state.siteConfig = response;
            },
            setAgencies(state, response) {
                state.agencies = response.ContentList.list;
            },
            setPageByComponentName(state, componentDetails) {
                let pageComponentName = null;

                for (let i = 0; i < pageComponents.length; ++i) {
                    if (pageComponents[i].name === componentDetails.name) {
                        pageComponentName = componentDetails.name;

                        for (let prop in componentDetails.props) {
                            if (!pageComponents[i].props.includes(prop)) {
                                delete componentDetails.props[prop];
                            }
                        }
                        break;
                    }
                }

                state.pageComponentName = pageComponentName;
                state.pageProps = componentDetails.props || {};
            },
            resetPage(state) {
                state.pageProps = {};
                state.pageComponentName = null;
            },
            setPageMeta(state, meta) {
                state.pageMeta = meta;
            },
        },
        getters: {
            showSearch: state => state.showSearch,
            pageLoading: state => state.pageLoading,
            currentSiteConfig: state => state.siteConfig,
            agencies: state => state.agencies,
            currentPageProps: state => state.pageProps,
            currentPageMeta: state => state.pageMeta,
            currentPageComponentName: state => state.pageComponentName,
            currentPageComponent: state => {
                if (!state.pageComponentName) {
                    return null;
                }

                for (let i = 0; i < pageComponents.length; ++i) {
                    if (pageComponents[i].name === state.pageComponentName) {
                        return pageComponents[i].component;
                    }
                }

                return null;
            }
        }
    };
};
