<template>
    <!-- Component: organisms/agency-budget-block -->
    <general-container title="Agency Budgets" :titleClass="titleClass" :customClass="'break-768 agency-budget ' + containerClass" v-if="loading || (budgets && budgets.length)">

        <infoBlock :hasIcon="false" :hasClose="false" :isTweet="false">

            <p class="agency-budget__total-budget has-text-centered">Total Budget</p>
            <p class="tac" v-if="budgets.length === 1">{{ budgets[0].name }}</p>

            <div class="miniBar large">
                <div :class="'miniBarProgress spent '" :style="'left: 0; width: ' + disburstPC + '%; background-color: gray;'" v-if="disburstPC > 0"><span>{{ disburstPC }}%</span></div>
                <div :class="'miniBarProgress remains ' + checkAmount(disburstPC)" :style="'left: ' + disburstPC + '%; width: ' + (100 - disburstPC) + '%; background-color: red;'"><span>{{ 100 - disburstPC + '%' }}</span></div>
            </div>

            <nav class="level progress-level">
                <div class="level-item has-text-right">
                    <div>
                        <p class="card__info__label mb-5">Amount Spent</p>
                        <p class="title">${{ priceFormat(totalDisburst) + ' (' + disburstPC + '%)' }}</p>
                    </div>
                </div>
                <div class="level-item has-text-centered">
                    <div>
                        <preloader v-if="loading"/>
                        <span class="slash" v-else>/</span>
                    </div>
                </div>
                <div class="level-item has-text-left">
                    <div>
                        <p class="card__info__label mb-5">Total</p>
                        <p class="title">${{ priceFormat(totalAppropriation) + ' (100%)' }}</p>
                    </div>
                </div>
            </nav>

            <nav class="level progress-level" v-if="budgets.length > 0">
                <div class="level-item has-text-right">
                    <div>
                        <p class="card__info__label mb-5">
                            <Tooltip :tooltipText="definitions && definitions['General Revenue'] && definitions['General Revenue'].definition ? definitions['General Revenue'].definition : 'Loading...'">
                                <span class='has-tooltip'>
                                    General Revenue
                                </span>
                            </Tooltip>
                        </p>
                        <p class="title">{{ percentFormat(totalAppropriationGeneralRevenue / totalAppropriation) }}%</p>
                    </div>
                </div>
                <div class="level-item has-text-centered">
                    <div>
                        <preloader v-if="loading"/>
                        <span class="slash" v-else>/</span>
                    </div>
                </div>
                <div class="level-item has-text-left">
                    <div>
                        <Tooltip :tooltipText="definitions && definitions['Trust Fund'] && definitions['Trust Fund'].definition ? definitions['Trust Fund'].definition : 'Loading...'">
                            <span class='has-tooltip card__info__label mb-5'>
                                Trust Fund
                            </span>
                        </Tooltip>
                        <p class="title">{{ percentFormat(totalAppropriationTrustFunds / totalAppropriation) }}%</p>
                    </div>
                </div>
            </nav>
        </infoBlock>

        <div class="columns">
            <div class="column">
                <position-budget-block-chart v-if="budgets.length > 1" :budgets="budgets" :grouped="true"></position-budget-block-chart>
            </div>
            <div class="column">
                <position-budget-type-chart v-if="budgets.length > 1" :budgets="budgets"></position-budget-type-chart>
            </div>
        </div>

        <read-more-link
            contents="See Full Budget Breakdown" v-if="budgets.length && !hideFullBudgetLink"
            :url="'/browse-budgets?agencyId=' + agencyId"
        ></read-more-link>

    </general-container>
    <!-- Component: organisms/agency-budget-block -->
</template>

<script>
    import GeneralContainer from "./general-container"
    import InfoBlock from "./info-block"
    import ReadMoreLink from "./read-more-link"
    import { onMounted, ref, computed } from "vue";
    import { useStore } from "vuex";
    import { renderBudgetChart } from "@/helpers/charts";
    import { priceFormat } from "@/helpers/filter";
    import RequestHandler from "@/handler/RequestHandler";
    import Preloader from "@/alivue/components/preloader";
    import PositionBudgetBlockChart from "./position-budget-block--chart";
    import PositionBudgetTypeChart from "./position-budget-type-chart"
    import { useRouter } from "vue-router";
    import Tooltip from "./tooltip";

    export default {
        name: 'AgencyBudgetBlock',
        props: ['agencyId', 'progressStyle', 'hideFullBudgetLink', 'titleClass', 'containerClass'],
        components: {
            PositionBudgetBlockChart,
            PositionBudgetTypeChart,
            Preloader,
            InfoBlock,
            GeneralContainer,
            ReadMoreLink,
            Tooltip
        },
        methods: {
            percentFormat(value) {
                if (value === NaN) {
                    return 0;
                }

                if (value > 1) {
                    value = 1;
                }

                return (value * 100).toFixed(2);
            },
            checkAmount(amount) {
                if (amount == 100) {
                    return ' full';
                } else if (amount == 0) {
                    return ' empty';
                };
            },
        },
        setup(props) {
            const loading = ref(true);
            const chartEl = ref(null);
            const budgets = ref([]);
            const totalAppropriation = ref(0);
            const totalAppropriationGeneralRevenue = ref(0);
            const totalAppropriationTrustFunds = ref(0);
            const totalDisburst = ref(0);
            const disburstPC = ref(0);
            const router = useRouter();
            const store = useStore();
            const definitions = computed(() => store.getters.definitions);

            onMounted(() => {
                if (props.agencyId) {
                    RequestHandler.loadAgencyBudgets(props.agencyId, 100, true).then(({items}) => {
                        totalDisburst.value = items.reduce((acc, item) => acc + item.disbursementAmount, 0);
                        totalAppropriation.value = items.reduce((acc, item) => acc + item.appropriationAmount, 0);
                        disburstPC.value = Math.round(totalDisburst.value / totalAppropriation.value * 100);

                        totalAppropriationGeneralRevenue.value = items.reduce((acc, item) => acc + item.appropriationAmountGeneralRevenue, 0);
                        totalAppropriationTrustFunds.value = items.reduce(( acc, item) => acc + item.appropriationAmountTrustFunds, 0);

                        budgets.value = items;
                        loading.value = false;
                        // removing stuff about renderBudgetChart -- not needed now charts are sub components
                    }).catch(error => {
                        loading.value = false;
                        console.error(error);
                    });
                } else {
                    loading.value = false;
                }
            });

            return {
                budgets,
                totalDisburst,
                totalAppropriation,
                totalAppropriationGeneralRevenue,
                totalAppropriationTrustFunds,
                disburstPC,
                chartEl,
                loading,
                priceFormat,
                definitions
            };
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";

    @include block('agency-budget') {
        @include element('total-budget') {
            font-weight: 600;
            color: $blue--dark;
            font-size: 18px;
        }
    }

    .slash {
        font-size: 42px;
        @media screen and (max-width: 768px) {
            display: none;
        }
    }

    .budget-chart {
        margin-bottom: 30px;
        width: 50%;
        height: 50%;
    }

    .title {
        font-size: 1rem;
        &.tac {
            text-align: center;
        }
    }

    .level-item {
        &.has-text-right {
            margin-right: 15px;
        }
        &.has-text-left {
            margin-left: 15px;
        }
        @media screen and (max-width: 768px) {
            &.has-text-right, &.has-text-left {
                margin-right: 0 !important;
                margin-left: 0 !important;
                text-align: center !important;
            }
        }
    }

    .card__info__label.mb-5 {
        margin-bottom: 5px !important;
    }

    .break-768 {
        .columns .column {
            @media screen and (max-width: 768px) {
                width: 100%;
                flex-basis: initial;
            }
        }
    }
</style>

