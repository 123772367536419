<template>
    <!-- Component: organisms/info-card -->
    <div class="info-card__container">

        <card-tag :modification-month="modificationMonth" v-if="updated === true"></card-tag>

        <div class="card card-overflow card__info" :class="[search ? 'card__search-results' : '', 'card__search-results__' + type]">

            <card-tools
                :contact="contact"
                :agency="agency"
                :position="position"
                :program="program"
                :programcategory="programcategory"
                :vendor="vendor"
                :contract="contract"
                :procurement="procurement"
                :size="cardtoolsize"
                :document="document"
            />

            <slot></slot>

        </div>

    </div>
    <!-- End Component: organisms/info-card -->
</template>

<script>
    import CardTools from "./card-tools";
    import CardTag from "./card-tag";

    export default {
        name: 'InfoCard',
        props: [
            'search',
            'type',
            'contact',
            'agency',
            'position',
            'program',
            'programcategory',
            'vendor',
            'contract',
            'procurement',
            'document',
            'updated',
            'modificationMonth',
            'cardtoolsize'
        ],
        components: {CardTools, CardTag},
    }
</script>

<style lang="scss">
    @import "../../assets/scss/partials/_cards--info.scss";
    .card-overflow {
        overflow: initial !important;
    }
</style>
