<template>
  <!-- Component: molecules/toast--content -->
  <div class="glossary__masthead">
    <p class="glossary__header">GovSpeak</p>
  </div>

  <div class="glossary__item">
    <p class="glossary__item__title">{{ title }}</p>

    <div class="simple-tabs">

        <div class="simple-tab">
            <input type="radio" id="tab1" name="tab-group" checked>
            <label for="tab1">Plain Language</label>
            <div class="tab-content">
                <div v-html="plainLang"></div>
            </div>
        </div>

        <div class="simple-tab">
            <input type="radio" id="tab2" name="tab-group">
            <label for="tab2">Official Definition</label>
            <div class="tab-content">
                <div v-html="definition"></div>
            </div>
        </div>

    </div>

    <a href="/gov-guidance" class="readmore-link">See Full Glossary</a>

  </div>
  <!-- End Component: molecules/toast--content -->
</template>

<script>
  import { defineComponent, onUnmounted } from "vue";

  export default defineComponent({
    name: 'ToastContent',
    props: {
      title: String,
      definition: String,
      plainLang: String
    },
  })
</script>

<style lang='scss' scoped>
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";

    @include block('glossary') {
      @include element('masthead') {}
      @include element('header') {
        font-style: italic;
        font-weight: 600;
        font-size: 28px;
      }
      @include element('item') {
        @include element('title') {
          font-weight: 600;
          font-size: 21px;
        }
      }
    }

    // simple-tabs: https://codepen.io/MichaelBondar/pen/QdOrYx
    .simple-tabs {
      position: relative;
      margin: 25px 0;
      width: 455px;
      height: 200px;
    }
    .simple-tab {
      float: left;
    }
    .simple-tab label {
      background: $blue--dark;
      padding: 10px;
      border: 1px solid #ccc;
      cursor: pointer;
      margin-left: -1px;
      color: $white;
    }
    .simple-tab:first-child label {
      margin-left: 0;
    }
    .simple-tab input[type=radio] {
      display: none;
    }
    .tab-content {
      position: absolute;
      top: 28px;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 20px;
      border: 1px solid #ccc;
      display: none;
    }
    input[type=radio]:checked ~ label {
      background: white;
      color: $blue--dark;
      border-bottom: 1px solid white;
    }
    input[type=radio]:checked ~ label ~ .tab-content {
      display: block;
    }
</style>
