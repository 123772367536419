<template>

    <contract-flyout
        ref="contractFlyoutRef"
        v-bind="contractProps"
        :vendor="contractProps && contractProps.vendor && contractProps.vendor.VendorRef ? contractProps.vendor.VendorRef : null"
        :agency="contractProps && contractProps.agency && contractProps.agency.AgencyRef ? contractProps.agency.AgencyRef : null"
    ></contract-flyout>
    
    <!-- Page: pages/vendor -->
    <div class="page-container vendor-page">

        <br/>

        <breadcrumbs :items="path"/>

        <div class="container">
            <alert/>
        </div>

        <hr/>

        <div class="container" style="display: flow-root;" v-if="user">
            <userTools/>
        </div>

        <br/>

        <div class="container">
            <vendor-info-card v-bind="$props"/>
        </div>
        <div class="container">
            <contracts-table :key="contentId"
                             :uniqueId="uniqueId"
                             :vendor-id="contentId"
                             :search-agency="true"
                             :search-type="true"
                             :search-fiscal="true"
                             :scrollable="true"
                             :subflyout="true"
                             :vendor-info="true"
                             title="Executive Agency Contracts for Vendor"
            />
        </div>
    </div>
    <!-- End Page: pages/vendor -->
</template>

<script>
    import Alert from "@/alivue/components/alert";
    import Breadcrumbs from "./breadcrumbs";
    import UserTools from "@/alivue/components/user-tools";
    import VendorInfoCard from "./vendor-info-card";
    import useUser from "@/helpers/useUser";
    import ButtonLink from "./button-link";
    import GeneralContainer from "./general-container";
    import SearchBlock from "./search-block";
    import { computed, ref, watch } from "vue";
    import { useStore } from "vuex";
    import ContractsTable from "./contracts-table";
    import ContractFlyout from "./contract-flyout";
    import { useEventsBus } from "@/helpers/eventBus";
    import { v4 as uuidv4 } from "uuid";
    
    export default {
        components: {
            ContractsTable,
            Alert,
            UserTools,
            Breadcrumbs,
            VendorInfoCard,
            ButtonLink,
            GeneralContainer,
            SearchBlock,
            ContractFlyout,
        },
        props: {
            contentId: Number,
            locationId: Number,
            name: String,
            factsSecondaryName: String,
            factsName: String,
            transparencyName: String,
        },
        methods: {},
        setup(props) {
            const { user } = useUser();

            const contractProps = ref(null);

            const { bus } = useEventsBus();

            const contractFlyoutRef = ref(null);

            watch( () => bus.value.get('call-open-flyout-contract'),
                async (val) => {
                    contractProps.value = null;
                    if (contractFlyoutRef.value) {
                        console.log(contractFlyoutRef.value);
                        try {
                            const contractRef = await contractFlyoutRef.value.openFlyout(val[0]);
                            contractProps.value = contractRef;
                            var x = document.getElementById("snackbar-vendor-contract");
                            x.classList.add('show');
                            x.classList.add('fadein');
                        } catch (error) {
                            console.error(error);
                        }
                    }
                }
            );

            const store = useStore();
            const showSearch = computed(() => store.getters.showSearch);

            // Generate a unique ID for each instance
            const uniqueId = computed(() => {
                return uuidv4();
            });

            return {
                user,
                path: [{ title: props.name }],
                showSearch,
                contractProps,
                contractFlyoutRef,
                uniqueId,
            }
        }
    }
</script>

<style lang="scss" scoped>
    .filter-wrapper {
        max-width: 50%;
        margin: auto;
        margin-top: 20px;
        @media screen and (max-width: 768px) {
            width: 90%;
            max-width: 90%;
        }
        .search-block {
            margin-bottom: 30px;
        }
    }
</style>
