import { priceFormat } from "@/helpers/filter";
import { removeClone } from "@/helpers/budgetTreeMap";
import { CHART_COLORS, SPEND_COLORS } from "@/constants";
import { faGameConsoleHandheld } from "@fortawesome/pro-duotone-svg-icons";
import { text } from "@fortawesome/fontawesome-svg-core";
import { useEventsBus } from "@/helpers/eventBus";

const renderStateBudgetChart = function(chartDomEl, historical) {
    import('d3').then(({schemeSpectral, stack, select, scaleLinear, scaleOrdinal, scaleLog, scaleBand, max, axisBottom, axisLeft, axisTop, range}) => {
        const width = chartDomEl.parentElement.offsetWidth;

        const container = select(chartDomEl);
        const margin = ({top: 30, right: 10, bottom: 0, left: 50})

        historical.forEach(function (v, i) {
            historical[i].appropriationAmountMinusDisbursement = historical[i].appropriationAmount - historical[i].disbursementAmount;
        });

        const columns = [
            'disbursementAmount',
            'appropriationAmountMinusDisbursement',
        ];

        const data = historical;

        const series = stack()
            .keys(columns)
        (data)
            .map(d => (d.forEach(v => v.key = d.key), d));

        const height = data.length * 50 + margin.top + margin.bottom;
        const formatValue = x => isNaN(x) ? "N/A" : x.toLocaleString("en");
        const yAxis = g => g
            .attr("transform", `translate(${margin.left},0)`)
            .style("font-size", "11px")
            .call(axisLeft(y).tickSizeOuter(0))
            .call(axisLeft(y).ticks().tickFormat((d, i) => {
                return ('FY' + (d - 2000) + "-" + (d - 1999))
            }))
            .call(g => g.selectAll(".domain").remove());

        const xAxis = g => g
            .attr("transform", `translate(0,${margin.top})`)
            .style("font-size", "14px")
            .call(axisTop(x).ticks(width / 100, "s").tickFormat((d, i) => {
                if (d < 1000000000) {
                    return ('$' + priceFormat(d/1000000) + 'M')
                }
                else {
                    return ('$' + priceFormat(d/1000000000) + 'B')
                }
            }))
            .call(g => g.selectAll(".domain").remove());

        const colorSpectral = [
            SPEND_COLORS[4],
            SPEND_COLORS[5],
        ];

        const tooltip = container.append("div").attr("class", "bar-chart-tooltip chart-tooltip").style('display', 'none');
        const tooltipHide = () => tooltip.style('display', 'none');
        const tooltipShow = (event, d) => {
            let title = 'FY ' + d.data.fiscalYearFrom + "-" + (d.data.fiscalYearFrom + 1) + ' ';
            if (d.key === 'disbursementAmount') {
                title += 'Disbursement Amount';
            }
            else {
                title += 'Remaining';
            }
            title += ': $' + formatValue(d.data[d.key]);
            tooltip
                .text(title)
                .style('display', 'block')
                .style('position', 'absolute')
                .style('top', (event.offsetY + 'px'))
                .style('left', (event.clientX/2) + 'px');
        };

        const color = scaleOrdinal()
            .domain(series.map(d => d.key))
            .range(colorSpectral)
            .unknown("#ccc");

        const y = scaleBand()
            .domain(data.map(d => d.fiscalYearFrom))
            .range([margin.top, height - margin.bottom])
            .padding(0.4);

        const x = scaleLinear()
            .domain([0, max(series, d => max(d, d => d[1]))])
            .range([margin.left, width - margin.right]);

        const svg = container.append("svg")
            .attr("viewBox", [0, 0, width, height]);

        svg.append("g")
            .selectAll("g")
            .data(series)
            .join("g")
            .attr("fill", d => color(d.key))
            .selectAll("rect")
            .data(d => d)
            .join("rect")
            .attr("x", d => x(d[0]))
            .attr("y", (d, i) => y(d.data.fiscalYearFrom))
            .attr("width", d => Math.abs(x(d[1]) - x(d[0])))
            .attr("height", y.bandwidth())
            .on("mouseover", (event, d) => tooltipShow(event, d))
            .on("mouseout", tooltipHide);
            /*.append("title")
            .text(d => {
                let title = d.data.fiscalYearFrom + ' ';
                if (d.key === 'disbursementAmount') {
                    title += 'Disbursement Amount';
                }
                else {
                    title += 'Remaining';
                }
                title += ': $' + formatValue(d.data[d.key]);
                return title;
            });*/
        svg.append("g")
            .call(xAxis);

        svg.append("g")
            .call(yAxis);

    });
}

const renderProgramBudgetHistoryChart = function(chartEl, legendEl, historical) {
    let yMin = -1;
    let yMax = -1;
    for (let i = 0; i < historical.length; i++) {
        if (yMin === -1 || yMin > historical[i].appropriationAmount) {
            yMin = historical[i].appropriationAmount;
        }
        if (yMin === -1 || yMin > historical[i].disbursementAmount) {
            yMin = historical[i].disbursementAmount;
        }

        if (yMax === -1 || yMax < historical[i].appropriationAmount) {
            yMax = historical[i].appropriationAmount;
        }
        if (yMax === -1 || yMax < historical[i].disbursementAmount) {
            yMax = historical[i].disbursementAmount;
        }
    }

    let historicalGrouped = [
        [],
        []
    ];
    for (let i = 0; i < historical.length; i++) {
        historicalGrouped[0].push({
            value: historical[i].appropriationAmount,
            fiscalYearFrom: historical[i].fiscalYearFrom
        });
        historicalGrouped[1].push({
            value: historical[i].disbursementAmount,
            fiscalYearFrom: historical[i].fiscalYearFrom
        });
    }

    import('d3')
    .then(({select, scaleTime, scalePow, extent, axisLeft, axisBottom, line, format}) => {
        const width = 960;
        const height = 300;
        const margin = 0;
        const padding = 0;
        const adj = 50;
        const priceFormat = format('.2s');

        const container = select(chartEl);
        const svg = container.append("svg")
            .attr("preserveAspectRatio", "xMinYMin meet")
            .attr("viewBox", "-" + adj + " -" + adj + " " + (width + adj * 3) + " " + (height + adj * 2 ))
            .style("padding", padding)
            .style("margin", margin)
            .classed("svg-content", true);

        const xScale = scaleTime().range([0, width]);
        const yScale = scalePow().exponent(0.08).range([height, 0]);
        xScale.domain(extent(historical, d => new Date(d.fiscalYearFrom, 0)));
        yScale.domain([yMin, yMax]);

        const yAxis = axisLeft().scale(yScale)
            .tickFormat((num) => '$' + priceFormat(num));
        const xAxis = axisBottom().scale(xScale);

        svg.append("g").attr("class", "axis").attr("transform", "translate(0," + height + ")").call(xAxis);
        svg.append("g").attr("class", "axis").call(yAxis);

        const tooltip = container.append("div").attr("class", "chart-tooltip").style('display', 'none');
        const tooltipPad = padding + margin + 40;
        const tooltipHide = () => tooltip.style('display', 'none');
        const tooltipShow = (d, field, label, topPad, leftPad) => {
            tooltip
                .text(
                    '$' + (d[field]).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
                    + ' - ' + label
                )
                .style('display', 'block')
                .style('top', yScale(d[field]) + tooltipPad + (topPad || 0) + 'px')
                .style('left', xScale(new Date(d.fiscalYearFrom, 0)) + 'px');
        };

        const budgetLine = line()
            .x(d => xScale(new Date(d.fiscalYearFrom, 0)))
            .y(d => yScale(d.value));

        historicalGrouped.forEach((historicalGroup, index) => {
            const color = CHART_COLORS[index] || CHART_COLORS[0];
            svg.append("path")
                .datum(historicalGroup)
                .attr("class", "line")
                .attr("d", budgetLine)
                .style('stroke', color);

            /*const budget = budgetGroup.budgets[0];
            const budgetDiff = budget.left;
            svg.append("text").style("fill", color)
                .attr(
                    "transform",
                    "translate(" + (width + 10) + "," + yScale(budgetDiff) + ") rotate(-70)"
                )
                .attr("dy", ".25em")
                .attr("text-anchor", "start")
                .attr('class', 'line-label')
                .text(budgetGroup.nameWithCategory || budgetGroup.name)
                //.on("mouseover", () => tooltipShow(budget, 50, -250))
                //.on("mouseout", tooltipHide)
            ;
            */
        });

        svg.selectAll(".dot")
            .data(historical)
            .enter().append("circle") // Uses the enter().append() method
            .attr("class", "dot") // Assign a class for styling
            .attr("cx", (d, i) => xScale(new Date(d.fiscalYearFrom, 0)))
            .attr("cy", d => yScale(d.appropriationAmount))
            .attr("r", 6)
            .on("mouseover", (event, d) => tooltipShow(d, 'appropriationAmount', 'Appropriation Amount', 40, -30))
            .on("mouseout", tooltipHide);

        svg.selectAll(".dot2")
            .data(historical)
            .enter().append("circle") // Uses the enter().append() method
            .attr("class", "dot2") // Assign a class for styling
            .attr("cx", (d, i) => xScale(new Date(d.fiscalYearFrom, 0)))
            .attr("cy", d => yScale(d.disbursementAmount))
            .attr("r", 6)
            .on("mouseover", (event, d) => tooltipShow(d, 'disbursementAmount', 'Disbursement Amount', 40, -30))
            .on("mouseout", tooltipHide);

        if (legendEl) {
            const legendContainer = select(legendEl);
            let legend = legendContainer.append('svg');
            legend.attr('width', width);
            legend.attr('height', '70px');
            let legendRectSize = 20;
            let legendSpacing = 10;

            legend.append('rect')
                .attr('width', legendRectSize)
                .attr('height', legendRectSize)
                .attr('x', 60)
                .attr('y', (legendRectSize + legendSpacing) * (0))
                .style('fill', CHART_COLORS[0])
                .style('stroke', CHART_COLORS[0]);

            legend.append('text')
                .attr('class', 'legend')
                .attr('x', 60 + legendRectSize + legendSpacing)
                .attr('y', (legendRectSize + legendSpacing) * (0 + 1) - legendSpacing)
                .text('Total Amount');

            legend.append('rect')
                .attr('width', legendRectSize)
                .attr('height', legendRectSize)
                .attr('x', 60 + legendRectSize + legendSpacing + 60 + 140)
                .attr('y', (legendRectSize + legendSpacing) * (0))
                .style('fill', CHART_COLORS[1])
                .style('stroke', CHART_COLORS[1]);

            legend.append('text')
                .attr('class', 'legend')
                .attr('x', (60 + legendRectSize + legendSpacing + 60) + 140 + legendRectSize + legendSpacing)
                .attr('y', (legendRectSize + legendSpacing) * (0 + 1) - legendSpacing)
                .text('Spent Amount');
        }
    });
};

const renderDisbursementCategoriesChart = function(chartDomEl, disbursementCategories) {
    let data = [];
    for (let i = 0; i < disbursementCategories.length; i++) {
        data.push({
            name: disbursementCategories[i].name,
            amount: disbursementCategories[i].amount,
        });
    }

    data.sort(function(a, b) {
        if (a.amount < b.amount) return 1;
        if (a.amount > b.amount) return -1;
        return 0;
    });
    //data = data.slice(0, 5);

      import('d3').then(({select, scaleLinear, scaleLog, scaleBand, max, axisBottom, axisLeft, range}) => {
        const width = chartDomEl.parentElement.offsetWidth;

        const container = select(chartDomEl);

        let margin = ({top: 0, right: 0, bottom: 30, left: 200});
        let barHeight = 70;
        let height = Math.ceil((data.length + 0.1) * barHeight) + margin.top + margin.bottom;

        let x = scaleLinear()
            .domain([1, max(data, d => d.amount)])
            .range([margin.left, width - margin.right])

        let y = scaleBand()
            .domain(range(data.length))
            .rangeRound([margin.top, height - margin.bottom])
            .padding(0.4);

        let yAxis = g => g
            .attr("transform", `translate(${margin.left},0)`)
            .style("font-size", "12px")
            .call(axisLeft(y).tickFormat(i => data[i].name).tickSizeOuter(0));

        let xAxis = g => g
            .attr("transform", `translate(0,${height - margin.bottom})`)
            .style("font-size", "12px")
            .call(axisBottom(x).ticks(width / 120, data.format).tickFormat((d, i) => ('$' + priceFormat(d))))
            .call(g => g.select(".domain").remove());

        const tooltip = container.append("div").attr("class", "bar-chart-tooltip chart-tooltip").style('display', 'none');
        const tooltipHide = () => tooltip.style('display', 'none');
        const tooltipShow = (event, item) => {
            tooltip
                .text('$' + priceFormat(item.amount) + ' - ' + item.name)
                .style('display', 'block')
                .style('top', (event.offsetY - container['_groups'][0][0].scrollTop + 170) + 'px')
                .style('left', (event.clientX + 20) + 'px');
        };

        const svg = container.append("svg")
            .attr("width", width)
            .attr("height", height)
            .append("g");

        svg.append("g")
            .attr("fill", '#022c43')
            .selectAll("rect")
            .data(data)
            .join("rect")
            .attr("x", x(0))
            .attr("y", (d, i) => y(i))
            .attr("width", d => x(d.amount) - x(0))
            .attr("height", y.bandwidth())
            .on("mouseover", (e, d) => tooltipShow(e, d))
            .on("mousemove", (e, d) => tooltipShow(e, d))
            .on("mouseout", tooltipHide);

        svg.append("g")
            .call(xAxis);

        svg.append("g")
            .call(yAxis);
    });
}

const renderBudgetChart = function (chartDomEl, budgets, router) {
    const totalAppropriation = budgets.reduce((acc, item) => acc + item.appropriationAmount, 0);
    budgets.forEach(item => {
        if (item._location?.parentLocation?.contentInfo.name && budgets.filter(x => x.name === item.name).length > 1) {
            item.name += ' (' + item._location.parentLocation.contentInfo.name + ')';
        }
        if (item.programCategory?.ProgramCategoryRef?.name) {
            item.name += ' (' + item.programCategory.ProgramCategoryRef.name + ')';
        }
        item.pcFromTotal = Math.round(item.appropriationAmount / totalAppropriation * 100) || 0;
        if (item.pcFromTotal === 0 && item.appropriationAmount) {
            item.pcFromTotal = 0.9;
        }
        if (item.pcFromTotal === 100) {
            if (item.appropriationAmount < totalAppropriation) {
                item.pcFromTotal = 99;
            }
        }
        item.percent = (item.appropriationAmount / totalAppropriation * 100).toFixed(2) || 0;
    });
    const hasHighlighted = budgets.some(item => item.chartHighlighted);
    import('d3').then(({select, pie, arc}) => {
        var wrapperWidth = Math.min(450, chartDomEl.offsetWidth - 30);
        const width = wrapperWidth, height = wrapperWidth, margin = 20;

        // The radius of the pieplot is half the width or half the height (smallest one). I subtract a bit of margin.
        const radius = Math.min(width, height) / 2 - margin

        const container = select(chartDomEl);

        // append the svg object to the div called 'my_dataviz'
        const svg = container.append("svg")
            .attr("width", width)
            .attr("height", height)
            .append("g")
            .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");

        // Creating arc
        const arcGenerator = arc()
            .innerRadius(radius * 0.5)         // This is the size of the donut hole
            .outerRadius(radius * 0.8);

        // Another arc that won't be drawn. Just for labels positioning
        var outerArc = arc()
            .innerRadius(radius * 0.9)
            .outerRadius(radius * 0.9)

        const tooltip = container.append("div").attr("class", "pie-chart-tooltip").style('display', 'none').style('top', wrapperWidth + 'px');
        const tooltipHide = () => tooltip.style('display', 'none');
        const tooltipShow = (event, item) => {
            tooltip
                .text( item.percent + '% ' + ' ($' + priceFormat(item.appropriationAmount) + ')' + ' - ' + item.name )
                .style('display', 'block')
                .style('top', (event.offsetY + 20 + 'px'))
                .style('left', (event.offsetX + 40) + 'px');
        };

        // Grouping different arcs
        const pieGen = pie().value(item => item.pcFromTotal);
        const arcs = svg.selectAll("arc")
            .data(pieGen(budgets))
            .enter()
            .append("g");

        // Appending path
        arcs.append("path")
            .attr("fill", d => d.data.color)
            .attr("d", arcGenerator)
            .style('stroke-width', d => d.data.chartHighlighted ? 0 : '1px')
            .style('opacity', d => (!hasHighlighted || d.data.chartHighlighted) ? 1 : 0.3)
            .style('cursor', 'pointer')
            .on("mouseover", (e, d) => tooltipShow(e, d.data))
            .on("mouseout", tooltipHide)
            .on("click", (e, d) => {

                // #1m36jgn: We want the first click to only highlight the slice, to do that we need to simulate a click on the label
                let wanted = d.data.name.replace(/[^a-zA-Z0-9 ]/g, '');
                let dataWanted = document.querySelector("[data-name='" + wanted + "']");

                // if something is already highlighted on the chart
                if (hasHighlighted) {
                    // if the highlighted slice is the current slice
                    if (wanted = document.querySelector(".chart-highlighted[data-name='" + wanted + "']")) {
                        // go to the page
                        router.push(d.data.url);
                    } else {
                        // otherwise click the new slice
                        dataWanted.click();
                    }
                } else {
                    // click the slice
                    dataWanted.click();
                }

                setTimeout(() => {
                    for (const a of document.querySelectorAll("tspan")) {
                        if (a.textContent.includes("← Back to")) {
                            const index = a.textContent.indexOf("|");
                            const pre = a.textContent.substr(0, index);
                            const post = a.textContent.substr(index + 1);
                            const new_html = "<tspan id='underline'>" + pre.trim() + "</tspan> | " + post;
                            a.innerHTML = new_html;
                            a.setAttribute('underline', 'true');
                        } else {
                            a.removeAttribute('underline');
                        }
                    }
                }, 100);
            });
        ;

        /*
        // Add the polylines between chart and labels:
        svg
            .selectAll('allPolylines')
            .data(pieGen(budgets))
            .enter()
            .append('polyline')
            .attr("stroke", "black")
            .style("fill", "none")
            .attr("stroke-width", 1)
            .attr('points', function(d) {
                var posA = arcGenerator.centroid(d) // line insertion in the slice
                var posB = outerArc.centroid(d) // line break: we use the other arc generator that has been built only for that
                var posC = outerArc.centroid(d); // Label position = almost the same as posB
                var midangle = d.startAngle + (d.endAngle - d.startAngle) / 2 // we need the angle to see if the X position will be at the extreme right or extreme left
                posC[0] = radius * 0.95 * (midangle < Math.PI ? 1 : -1); // multiply by 1 or -1 to put it on the right or on the left
                return [posA, posB, posC]
            });

        // Add the polylines between chart and labels:
        svg
            .selectAll('allLabels')
            .data(pieGen(budgets))
            .enter()
            .append('text')
            .text( function(d) { console.log(d.data) ; return d.data.name } )
            .attr('transform', function(d) {
                var pos = outerArc.centroid(d);
                var midangle = d.startAngle + (d.endAngle - d.startAngle) / 2
                pos[0] = radius * 0.99 * (midangle < Math.PI ? 1 : -1);
                return 'translate(' + pos + ')';
            })
            .style('text-anchor', function(d) {
                var midangle = d.startAngle + (d.endAngle - d.startAngle) / 2
                return (midangle < Math.PI ? 'start' : 'end')
            });
            */
        // Adding data to each arc
        /*arcs.append("text")
            .attr("transform", (d, index) => "translate(" + arcGenerator.centroid(d) + ")")
            .text(d => d.data.pcFromTotal > 5 ? d.data.pcFromTotal + '%' : '');*/
    });
}

const renderBudgetTypeChart = function (chartDomEl, budgets) {
    const totalAppropriation = budgets.reduce((acc, item) => acc + item.appropriationAmount, 0);
    const totalAppropriationGeneralRevenue = budgets.reduce((acc, item) => acc + item.appropriationAmountGeneralRevenue, 0);
    const totalAppropriationTrustFunds = budgets.reduce(( acc, item) => acc + item.appropriationAmountTrustFunds, 0);

    const data = [
        {
            originalValue: totalAppropriationGeneralRevenue,
            value: (totalAppropriationGeneralRevenue / totalAppropriation * 100).toFixed(2),
            name: 'General Revenue',
            color: CHART_COLORS[0],
        },
        {
            originalValue: totalAppropriationTrustFunds,
            value: (totalAppropriationTrustFunds / totalAppropriation * 100).toFixed(2),
            name: 'Trust Funds',
            color: CHART_COLORS[1],
        },
    ];

    import('d3').then(({select, pie, arc}) => {
        var wrapperWidth = Math.min(450, chartDomEl.offsetWidth - 30);
        const width = wrapperWidth, height = wrapperWidth, margin = 20;

        // The radius of the pieplot is half the width or half the height (smallest one). I subtract a bit of margin.
        const radius = Math.min(width, height) / 2

        const container = select(chartDomEl);

        // append the svg object to the div called 'my_dataviz'
        const svg = container.append("svg")
            .attr("width", width)
            .attr("height", height)
            .append("g")
            .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");

        // Creating arc
        const arcGenerator = arc()
            .innerRadius(radius * 0.5)         // This is the size of the donut hole
            .outerRadius(radius * 0.8);

        // Another arc that won't be drawn. Just for labels positioning
        var outerArc = arc()
            .innerRadius(radius * 0.9)
            .outerRadius(radius * 1.1)


        const tooltip = container.append("div").attr("class", "pie-chart-tooltip").style('display', 'none');
        const tooltipHide = () => tooltip.style('display', 'none');
        const tooltipShow = (event, item) => {
            tooltip
                .text(item.value + '% ($' + priceFormat(item.originalValue) + ') - ' + item.name)
                .style('display', 'block')
                .style('top', (event.offsetY + 20 + 'px'))
                .style('left', (event.offsetX + 40) + 'px');
        };

        // Grouping different arcs
        const pieGen = pie().value(item => item.value);
        const arcs = svg.selectAll("arc")
            .data(pieGen(data))
            .enter()
            .append("g");

        // Appending path
        arcs.append("path")
            .attr("fill", d => d.data.color)
            .attr("d", arcGenerator)
            .style('stroke-width', d => '1px')
            .on("mouseover", (e, d) => tooltipShow(e, d.data))
            .on("mouseout", tooltipHide);

        /*
        // Add the polylines between chart and labels:
        svg
            .selectAll('allPolylines')
            .data(pieGen(budgets))
            .enter()
            .append('polyline')
            .attr("stroke", "black")
            .style("fill", "none")
            .attr("stroke-width", 1)
            .attr('points', function(d) {
                var posA = arcGenerator.centroid(d) // line insertion in the slice
                var posB = outerArc.centroid(d) // line break: we use the other arc generator that has been built only for that
                var posC = outerArc.centroid(d); // Label position = almost the same as posB
                var midangle = d.startAngle + (d.endAngle - d.startAngle) / 2 // we need the angle to see if the X position will be at the extreme right or extreme left
                posC[0] = radius * 0.95 * (midangle < Math.PI ? 1 : -1); // multiply by 1 or -1 to put it on the right or on the left
                return [posA, posB, posC]
            });

        // Add the polylines between chart and labels:
        svg
            .selectAll('allLabels')
            .data(pieGen(budgets))
            .enter()
            .append('text')
            .text( function(d) { console.log(d.data) ; return d.data.name } )
            .attr('transform', function(d) {
                var pos = outerArc.centroid(d);
                var midangle = d.startAngle + (d.endAngle - d.startAngle) / 2
                pos[0] = radius * 0.99 * (midangle < Math.PI ? 1 : -1);
                return 'translate(' + pos + ')';
            })
            .style('text-anchor', function(d) {
                var midangle = d.startAngle + (d.endAngle - d.startAngle) / 2
                return (midangle < Math.PI ? 'start' : 'end')
            });
            */

            /*
        // Adding data to each arc
        arcs.append("text")
            .attr("transform", (d, index) => "translate(" + arcGenerator.centroid(d) + ")")
            .text(d => d.data.value + '%');
            */
    });
}

const renderProcurementChart = function (chartDomEl, procurements, router) {
    const totalValue = procurements.reduce((acc, item) => acc + item.value, 0);
    procurements.forEach(item => {
        item.pcFromTotal = Math.round(item.value / totalValue * 100) || 0;
        if (item.pcFromTotal === 0 && item.value) {
            item.pcFromTotal = 0.9;
        }
        if (item.pcFromTotal === 100) {
            if (item.value < totalValue) {
                item.pcFromTotal = 99;
            }
        }
    });
    const hasHighlighted = procurements.some(item => item.chartHighlighted);
    import('d3').then(({select, pie, arc}) => {
        var wrapperWidth = Math.min(450, chartDomEl.offsetWidth - 30);
        const width = wrapperWidth, height = wrapperWidth, margin = 20;

        // The radius of the pieplot is half the width or half the height (smallest one). I subtract a bit of margin.
        const radius = Math.min(width, height) / 2 - margin

        const container = select(chartDomEl);

        // append the svg object to the div called 'my_dataviz'
        const svg = container.append("svg")
            .attr("width", width)
            .attr("height", height)
            .append("g")
            .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");

        // Creating arc
        const arcGenerator = arc()
            .innerRadius(radius * 0.5)         // This is the size of the donut hole
            .outerRadius(radius * 0.8);

        // Another arc that won't be drawn. Just for labels positioning
        var outerArc = arc()
            .innerRadius(radius * 0.9)
            .outerRadius(radius * 0.9)

        const tooltip = container.append("div").attr("class", "pie-chart-tooltip").style('display', 'none').style('top', wrapperWidth + 'px');
        const tooltipHide = () => tooltip.style('display', 'none');
        const tooltipShow = (event, item) => {
            tooltip
                .text(item.value + ' - ' + item.name)
                .style('display', 'block')
                .style('top', (event.offsetY + 20 + 'px'))
                .style('left', (event.offsetX + 40) + 'px');
        };

        // Grouping different arcs
        const pieGen = pie().value(item => item.pcFromTotal);
        const arcs = svg.selectAll("arc")
            .data(pieGen(procurements))
            .enter()
            .append("g");

        // Appending path
        arcs.append("path")
            .attr("fill", d => d.data.color)
            .attr("d", arcGenerator)
            .style('stroke-width', d => d.data.chartHighlighted ? 0 : '1px')
            .style('opacity', d => (!hasHighlighted || d.data.chartHighlighted) ? 1 : 0.3)
            .style('cursor', 'pointer')
            .on("mouseover", (e, d) => tooltipShow(e, d.data))
            .on("mouseout", tooltipHide)
            .on("click", (e, d) => {

                // #1m36jgn: We want the first click to only highlight the slice, to do that we need to simulate a click on the label
                let wanted = d.data.name.replace(/[^a-zA-Z0-9 ]/g, '');
                let dataWanted = document.querySelector("[data-name='" + wanted + "']");

                // if something is already highlighted on the chart
                if (hasHighlighted) {
                    // if the highlighted slice is the current slice
                    if (wanted = document.querySelector(".chart-highlighted[data-name='" + wanted + "']")) {
                        // go to the page
                        router.push(d.data.url);
                    } else {
                        // otherwise click the new slice
                        dataWanted.click();
                    }
                } else {
                    // click the slice
                    dataWanted.click();
                }

                setTimeout(() => {
                    for (const a of document.querySelectorAll("tspan")) {
                        if (a.textContent.includes("← Back to")) {
                            const index = a.textContent.indexOf("|");
                            const pre = a.textContent.substr(0, index);
                            const post = a.textContent.substr(index + 1);
                            const new_html = "<tspan id='underline'>" + pre.trim() + "</tspan> | " + post;
                            a.innerHTML = new_html;
                            a.setAttribute('underline', 'true');
                        } else {
                            a.removeAttribute('underline');
                        }
                    }
                }, 100);
            }
        );
    });
}


const renderContractChart = function (chartDomEl, contracts, router) {
    const totalValue = contracts.reduce((acc, item) => acc + item.totalAmount, 0);

    contracts.forEach(item => {
        item.pcFromTotal = Math.round(item.totalAmount / totalValue * 100) || 0;
        if (item.pcFromTotal === 0 && item.totalAmount) {
            item.pcFromTotal = 0.9;
        }
        if (item.pcFromTotal === 100) {
            if (item.value < totalValue) {
                item.pcFromTotal = 99;
            }
        }
    });
    const hasHighlighted = contracts.some(item => item.chartHighlighted);
    import('d3').then(({select, pie, arc}) => {
        var wrapperWidth = Math.min(450, chartDomEl.offsetWidth - 30);
        const width = wrapperWidth, height = wrapperWidth, margin = 20;

        // The radius of the pieplot is half the width or half the height (smallest one). I subtract a bit of margin.
        const radius = Math.min(width, height) / 2 - margin

        const container = select(chartDomEl);

        // append the svg object to the div called 'my_dataviz'
        const svg = container.append("svg")
            .attr("width", width)
            .attr("height", height)
            .append("g")
            .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");

        // Creating arc
        const arcGenerator = arc()
            .innerRadius(radius * 0.5)         // This is the size of the donut hole
            .outerRadius(radius * 0.8);

       // Another arc that won't be drawn. Just for labels positioning
       //  var outerArc = arc()
       //      .innerRadius(radius * 0.9)
       //      .outerRadius(radius * 0.9)

        // const tooltip = container
        //     .append("div")
        //     .attr("class", "pie-chart-tooltip")
        const tooltipHide = () => tooltip.style('display', 'none');
        const tooltipShow = (event, item) => {
            tooltip
                .text(item.value + ' - ' + item.name)
                .style('display', 'block')
                .style('top', (event.offsetY + 20 + 'px'))
                .style('left', (event.offsetX + 40) + 'px');
        };

        // Grouping different arcs
        const pieGen = pie().value(item => item.pcFromTotal);
        const arcs = svg.selectAll("arc")
            .data(pieGen(contracts))
            .enter()
            .append("g");

    //     // Appending path
        arcs.append("path")
            .attr("fill", d => d.data.color)
            .attr("d", arcGenerator)
            .style('stroke-width', d => d.data.chartHighlighted ? 0 : '1px')
            .style('opacity', d => (!hasHighlighted || d.data.chartHighlighted) ? 1 : 0.3)
            .style('cursor', 'pointer')
            .on("mouseover", (e, d) => tooltipShow(e, d.data))
            .on("mouseout", tooltipHide)
            // .on("click", (e, d) => {
            //
            //         // #1m36jgn: We want the first click to only highlight the slice, to do that we need to simulate a click on the label
            //         let wanted = d.data.name.replace(/[^a-zA-Z0-9 ]/g, '');
            //         let dataWanted = document.querySelector("[data-name='" + wanted + "']");
            //
            //         // if something is already highlighted on the chart
            //         if (hasHighlighted) {
            //             // if the highlighted slice is the current slice
            //             if (wanted = document.querySelector(".chart-highlighted[data-name='" + wanted + "']")) {
            //                 // go to the page
            //                 router.push(d.data.url);
            //             } else {
            //                 // otherwise click the new slice
            //                 dataWanted.click();
            //             }
            //         } else {
            //             // click the slice
            //             dataWanted.click();
            //         }
            //
            //         // setTimeout(() => {
            //         //     for (const a of document.querySelectorAll("tspan")) {
            //         //         if (a.textContent.includes("← Back to")) {
            //         //             const index = a.textContent.indexOf("|");
            //         //             const pre = a.textContent.substr(0, index);
            //         //             const post = a.textContent.substr(index + 1);
            //         //             const new_html = "<tspan id='underline'>" + pre.trim() + "</tspan> | " + post;
            //         //             a.innerHTML = new_html;
            //         //             a.setAttribute('underline', 'true');
            //         //         } else {
            //         //             a.removeAttribute('underline');
            //         //         }
            //         //     }
            //         // }, 100);
            //     }
            // );
    });
}

const renderDocumentsChart = function (chartDomEl, documents, router) {
    const totalValue = documents.reduce((acc, item) => acc + item.value, 0);
    documents.forEach(item => {
        item.pcFromTotal = Math.round(item.value / totalValue * 100) || 0;
        if (item.pcFromTotal === 0 && item.value) {
            item.pcFromTotal = 0.9;
        }
        if (item.pcFromTotal === 100) {
            if (item.value < totalValue) {
                item.pcFromTotal = 99;
            }
        }
    });
    const hasHighlighted = documents.some(item => item.chartHighlighted);
    import('d3').then(({select, pie, arc}) => {
        var wrapperWidth = Math.min(450, chartDomEl.offsetWidth - 30);
        const width = wrapperWidth, height = wrapperWidth, margin = 20;

        // The radius of the pieplot is half the width or half the height (smallest one). I subtract a bit of margin.
        const radius = Math.min(width, height) / 2 - margin

        const container = select(chartDomEl);

        // append the svg object to the div called 'my_dataviz'
        const svg = container.append("svg")
            .attr("width", width)
            .attr("height", height)
            .append("g")
            .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");

        // Creating arc
        const arcGenerator = arc()
            .innerRadius(radius * 0.5)         // This is the size of the donut hole
            .outerRadius(radius * 0.8);

        // Another arc that won't be drawn. Just for labels positioning
        var outerArc = arc()
            .innerRadius(radius * 0.9)
            .outerRadius(radius * 0.9)

        const tooltip = container.append("div").attr("class", "pie-chart-tooltip").style('display', 'none').style('top', wrapperWidth + 'px');
        const tooltipHide = () => tooltip.style('display', 'none');
        const tooltipShow = (event, item) => {
            tooltip
                .text(item.value + ' - ' + item.name)
                .style('display', 'block')
                .style('top', (event.offsetY + 20 + 'px'))
                .style('left', (event.offsetX + 40) + 'px');
        };

        // Grouping different arcs
        const pieGen = pie().value(item => item.pcFromTotal);
        const arcs = svg.selectAll("arc")
            .data(pieGen(documents))
            .enter()
            .append("g");

        // Appending path
        arcs.append("path")
            .attr("fill", d => d.data.color)
            .attr("d", arcGenerator)
            .style('stroke-width', d => d.data.chartHighlighted ? 0 : '1px')
            .style('opacity', d => (!hasHighlighted || d.data.chartHighlighted) ? 1 : 0.3)
            .style('cursor', 'pointer')
            .on("mouseover", (e, d) => tooltipShow(e, d.data))
            .on("mouseout", tooltipHide)
            .on("click", (e, d) => {

                // #1m36jgn: We want the first click to only highlight the slice, to do that we need to simulate a click on the label
                let wanted = d.data.name.replace(/[^a-zA-Z0-9 ]/g, '');
                let dataWanted = document.querySelector("[data-name='" + wanted + "']");

                // if something is already highlighted on the chart
                if (hasHighlighted) {
                    // if the highlighted slice is the current slice
                    if (wanted = document.querySelector(".chart-highlighted[data-name='" + wanted + "']")) {
                        // go to the page
                        router.push(d.data.url);
                    } else {
                        // otherwise click the new slice
                        dataWanted.click();
                    }
                } else {
                    // click the slice
                    dataWanted.click();
                }

                setTimeout(() => {
                    for (const a of document.querySelectorAll("tspan")) {
                        if (a.textContent.includes("← Back to")) {
                            const index = a.textContent.indexOf("|");
                            const pre = a.textContent.substr(0, index);
                            const post = a.textContent.substr(index + 1);
                            const new_html = "<tspan id='underline'>" + pre.trim() + "</tspan> | " + post;
                            a.innerHTML = new_html;
                            a.setAttribute('underline', 'true');
                        } else {
                            a.removeAttribute('underline');
                        }
                    }
                }, 100);
            }
        );
    });
}

const renderStateAgencyBudgetsTreemap = function(chartDomEl, data, treemapCallback) {

    let treemapController;

    const {emit}=useEventsBus();

    var graphResult = import('d3').then(({select, treemap, hierarchy, treemapSquarify, treemapBinary, scaleLinear, interpolate}) => {
        let width = chartDomEl.parentElement.offsetWidth;

        treemapController = {
            // Method to zoom in to a specific node
            zoomInToNode: (d) => {
                zoomin(d);
            },
            // Method to zoom out to a specific level
            zoomOutToLevel: (toLevel, callback, callback_args) => {
                callback(callback_args);
            },
            // Add more methods as needed
        };

        const container = select(chartDomEl);

        let height = width;

        function tile(node, x0, y0, x1, y1) {
            treemapBinary(node, 0, 0, width, height);
            for (const child of node.children) {
              child.x0 = x0 + child.x0 / width * (x1 - x0);
              child.x1 = x0 + child.x1 / width * (x1 - x0);
              child.y0 = y0 + child.y0 / height * (y1 - y0);
              child.y1 = y0 + child.y1 / height * (y1 - y0);
            }
        }

        const name = d => {
            let n = d.ancestors().reverse().map(d => d.data.name).join(" | Currently Viewing: ");
            if (d.ancestors().length > 1) {
                n = '← Back to all ' + n;
            }
            return n;
        };

        const treemapFunc = data => treemap()
        .tile(tile)
        (hierarchy(data)
        .sum(d => d.value)
        .sort((a, b) => b.value - a.value))

        var testMe = treemapFunc(data);
        if (testMe.value == 0) treemapCallback(false);

        //const x = scaleLinear().domain([0, width]).range([0, width]);//rangeRound([0, width]);
        const x = scaleLinear().rangeRound([0, width]);
        //const y = scaleLinear().domain([0, height]).range([0, height]);//.rangeRound([0, height]);
        const y = scaleLinear().rangeRound([0, height]);

        //x.domain([0, width]);
        //y.domain([0, height]);

        let maxWidthShowText = 100;

        const svg = container.append('svg')
            .attr("viewBox", [0.5, -60.5, width, height + 70])
        //.style("font", "10px sans-serif");

        let group = svg.append("g")
            .call(render, treemapFunc(data));

        const tooltip = container.append("div").attr("class", "treemap-tooltip").style('display', 'none');
        const tooltipHide = (event) => {
            event.target.style.opacity = 1.0;
            tooltip.style('display', 'none');
        }
        const tooltipShow = (event, d) => {
            let hrefLink = ((d.data.agencyContentId || d.data.url ) ? (d.data.agencyContentId ? ('/browse-budgets?agencyId=' + d.data.agencyContentId) : d.data.url): '');
            let middleX = x(d.x0) + (x(d.x1) - x(d.x0))/2;
            let middleY = y(d.y0) + (y(d.y1) - y(d.y0))/2;
            if (y(d.y1) - y(d.y0) > 100) {
                middleY += 100;
            }
            else {
                middleY += 60;
            }
            event.target.style.opacity = 0.5;
            tooltip
                .html(
                    d.data.name + '<br/>$' + priceFormat(d.value)
                )
                .on("click", function(){
                    window.location.href = hrefLink;
                })
                .attr("href", hrefLink)
                .style("cursor", "pointer")
                .style('display', 'block')
                .style('top', middleY + 'px')
                .style('left', middleX + 'px');
        };

        function render(group, root) {
            const node = group
                .selectAll("g")
                .data(root.children.concat(root))
                .join("g");

            node.filter(d => d === root ? d.parent : d.children)
                .attr("cursor", "pointer")
                .on("click", (event, d) => {
                    removeClone();
                    tooltipHide(event);
                    let hrefLink = ((d.data.agencyContentId || d.data.url ) ? (d.data.agencyContentId ? ('/browse-budgets?agencyId=' + d.data.agencyContentId) : d.data.url): '');
                    window.location.href = hrefLink;
                })
                //.on("mouseover", (event, d) => { tooltipShow(event, d) })
                //.on("mouseout", tooltipHide);

            //node.append("title")
            //    .text(d => `${name(d)}\n\$${priceFormat(d.value)}`);

            node.append("rect")
                .attr("id", d => (d.leafUid = Math.random()))
                .attr("fill", (d, i) => { return d === root ? "#fff" : d.children ? CHART_COLORS[(i+1) % CHART_COLORS.length] : CHART_COLORS[(i+1) % CHART_COLORS.length] })
                .attr("stroke", "#fff")
                .on("mouseover", (event, d) => { tooltipShow(event, d); })
                .on("mouseout", (event, d) => { tooltipHide(event); });

            group.call(position, root);

            setTimeout(() => {
                node.append("clipPath")
                    .attr("id", d => (d.clipUid = Math.random()))
                    .append("use")
                    .attr("xlink:href", d => d.leafUid.href);

                node.append("text")
                    .attr("clip-path", d => d.clipUid)
                    .attr("font-weight", d => d === root ? "bold" : null)
                    .attr("class", "text-container")
                    .selectAll("tspan")
                    .data(d => [(d === root ? name(d) : d.data.acronym)].concat('$' + priceFormat(d.value)))
                    .join("tspan")
                    .text(d => d)
                    .attr("data-anchor", (d => d.replace(/[^A-Z0-9]/ig, "_")))
                    .text((d, i, nodes) => {
                        let t = d;
                        let rect = nodes[0].parentElement.previousElementSibling.previousElementSibling;
                        let rectWidth = Number.parseFloat(rect.getAttribute('width'));
                        let textWidth = nodes[i].getComputedTextLength();
                        if (textWidth > rectWidth - 50 && t.length > 20) {
                            t = t.substring(0, 20) + '...';
                        }
                        return t;
                    })
                    .attr("x", (d, i, nodes) => {
                        let rect = nodes[0].parentElement.previousElementSibling.previousElementSibling;
                        let rectWidth = Number.parseFloat(rect.getAttribute('width'));
                        let textWidth = nodes[i].getComputedTextLength();
                        return 3 + rectWidth/2 - textWidth/2;
                    })
                    .attr("y", (d, i, nodes) => {
                        let rect = nodes[0].parentElement.previousElementSibling.previousElementSibling;
                        let rectHeight = Number.parseFloat(rect.getAttribute('height'));
                        return `${rectHeight/2 + i * 20}px`
                    })
                    .attr("fill-opacity", (d, i, nodes) => i === nodes.length - 1 ? 0.7 : null)
                    .attr("font-weight", (d, i, nodes) => i === 0 ? "bold" : "normal")
            }, 100);

        }

        function position(group, root) {
            group.selectAll("g")
                .attr("transform", d => {
                    return d === root ? `translate(0,-70)` : `translate(${x(d.x0)},${y(d.y0)})`
                })
                .select("rect")
                .attr("width", d => {
                    const w = d === root ? width : x(d.x1) - x(d.x0);
                    return w;
                })
                .attr("height", d => {
                    const h = d === root ? 70 : y(d.y1) - y(d.y0);
                    return h;
                })
                .attr('class', d => {
                    let rectWidth = d === root ? width : x(d.x1) - x(d.x0);
                    let acronym = d.data.acronym;
                    let classStr = 'show-text';
                    if (rectWidth < maxWidthShowText || acronym === undefined || acronym === "") {
                        classStr = 'hide-text';
                    }
                    return classStr;
                });
        }

        // When zooming in, draw the new nodes on top, and fade them in.
        function zoomin(d) {
            const group0 = group.attr("pointer-events", "none");
            const group1 = group = svg.append("g").call(render, d);

            x.domain([d.x0, d.x1]);
            y.domain([d.y0, d.y1]);

            svg.transition()
                .duration(0)
                .call(t => group0.transition(t).remove()
                .call(position, d.parent))
                .call(t => group1.transition(t)
                .attrTween("opacity", () => interpolate(0, 1))
                .call(position, d));
            }

        // When zooming out, draw the old nodes on top, and fade them out.
        function zoomout(d) {
            const group0 = group.attr("pointer-events", "none");
            const group1 = group = svg.insert("g", "*").call(render, d.parent);

            x.domain([d.parent.x0, d.parent.x1]);
            y.domain([d.parent.y0, d.parent.y1]);

            svg.transition()
                .duration(0)
                .call(t => group0.transition(t).remove()
                .attrTween("opacity", () => interpolate(1, 0))
                .call(position, d))
                .call(t => group1.transition(t)
                .call(position, d.parent));
        }

        return {
            controller: treemapController, // Expose the controller
        };

    });

    return graphResult;
}

const renderBudgetDetailsTreemap = function(chartDomEl, data, treemapCallback) {

    chartDomEl.innerHTML = '';

    let treemapController;

    const {emit}=useEventsBus();

    var graphResult = import('d3').then(({select, treemap, hierarchy, treemapSquarify, treemapBinary, scaleLinear, interpolate}) => {
        let realData = {
            name: 'Budget Details',
            children: [],
        };

        treemapController = {
            // Method to zoom in to a specific node
            zoomInToNode: (d) => {
                zoomin(d);
            },
            // Method to zoom out to a specific level
            zoomOutToLevel: (toLevel, callback, callback_args) => {
                callback(callback_args);
            },
            // Add more methods as needed
        };

        for (let i = 0; i < data.length; i++) {
            let programName = null;
            if (data[i].program && data[i].program.name) {
                programName = data[i].program.name;
            }
            realData.children.push({
                name: data[i].name,
                value: data[i].appropriationAmount,
                url: data[i].url,
                programName: programName,
            });
        }

        let width = chartDomEl.parentElement.offsetWidth;

        const container = select(chartDomEl);

        let height = width;

        function tile(node, x0, y0, x1, y1) {
            treemapBinary(node, 0, 0, width, height);
            for (const child of node.children) {
              child.x0 = x0 + child.x0 / width * (x1 - x0);
              child.x1 = x0 + child.x1 / width * (x1 - x0);
              child.y0 = y0 + child.y0 / height * (y1 - y0);
              child.y1 = y0 + child.y1 / height * (y1 - y0);
            }
        }

        const name = d => {
            let s = '';
            let n = d.ancestors().reverse().map(d => d.data.name);
            for (let i = 0; i < n.length; i++) {
                if (s.length) {
                    s += ' > ';
                }
                if (n[i].length > 10 && i > 0) {
                    s += n[i].substring(0, 10) + '...';
                }
                else {
                    s += n[i] + ' ';
                }
            }
            if (d.ancestors().length > 1) {
                s = '← Back | ' + s;
            }
            return s;
        };

        const treemapFunc = realData => treemap()
        .tile(tile)
        (hierarchy(realData)
        .sum(d => d.value)
        .sort((a, b) => b.value - a.value))

        var testMe = treemapFunc(realData);
        if (testMe.value == 0) treemapCallback(false);

        //const x = scaleLinear().domain([0, width]).range([0, width]);//rangeRound([0, width]);
        const x = scaleLinear().rangeRound([0, width]);
        //const y = scaleLinear().domain([0, height]).range([0, height]);//.rangeRound([0, height]);
        const y = scaleLinear().rangeRound([0, height]);

        //x.domain([0, width]);
        //y.domain([0, height]);

        let maxWidthShowText = 230;

        const svg = container.append('svg')
            .attr("viewBox", [0.5, -60.5, width, height + 70])
        //.style("font", "10px sans-serif");

        let group = svg.append("g")
            .call(render, treemapFunc(realData));

        const tooltip = container.append("div").attr("class", "treemap-tooltip").style('display', 'none');
        const tooltipHide = (event) => {
            event.target.style.opacity = 1.0;
            tooltip.style('display', 'none');
        }
        const tooltipShow = (event, d) => {
            let hrefLink = (d.data.url ? d.data.url : '');
            let middleX = x(d.x0) + (x(d.x1) - x(d.x0))/2;
            let middleY = y(d.y0) + (y(d.y1) - y(d.y0))/2;
            if (y(d.y1) - y(d.y0) > 100) {
                middleY += 100;
            }
            else {
                middleY += 60;
            }
            event.target.style.opacity = 0.5;
            let programName = d.data.programName;
            tooltip
                .html(
                    (programName ? '<div class="treemap-tooltip-type">' + programName + '</div>' : '') + '<div class="treemap-tooltip-title">' + d.data.name + '</div>$' + priceFormat(d.value)
                )
                .on("click", function(){
                    window.location.href = hrefLink;
                })
                .attr("href", hrefLink)
                .style("cursor", "pointer")
                .style('display', 'block')
                .style('top', middleY + 'px')
                .style('left', middleX + 'px');
        };

        function render(group, root) {
            const node = group
                .selectAll("g")
                .data(root.children.concat(root))
                .join("g");

            node.filter(d => d === root ? d.parent : d.children)
                .attr("cursor", "pointer")
                .on("click", (event, d) => {
                    removeClone();
                    tooltipHide(event);
                    d === root ? zoomout(root) : zoomin(d);
                    setTimeout(() => {
                        for (const a of document.querySelectorAll("tspan")) {
                            if (a.textContent.includes("← Back")) {
                                const index = a.textContent.indexOf("|");
                                const pre = a.textContent.substr(0, index);
                                const post = a.textContent.substr(index + 1);
                                const new_html = "<tspan id='underline'>" + pre.trim() + "</tspan> | " + post;
                                a.innerHTML = new_html;
                                a.setAttribute('underline', 'true');
                            } else {
                                a.removeAttribute('underline');
                            }
                        }
                    }, 100);
                })
                //.on("mouseover", (event, d) => { tooltipShow(event, d) })
                //.on("mouseout", tooltipHide);

            //node.append("title")
            //    .text(d => `${name(d)}\n\$${priceFormat(d.value)}`);

            node.append("rect")
                .attr("id", d => (d.leafUid = Math.random()))
                .attr("fill", (d, i) => { return d === root ? "#fff" : d.children ? CHART_COLORS[(i+1) % CHART_COLORS.length] : CHART_COLORS[(i+1) % CHART_COLORS.length] })
                .attr("stroke", "#fff")
                .on("mouseover", (event, d) => { tooltipShow(event, d); })
                .on("mouseout", (event, d) => { tooltipHide(event); });

            group.call(position, root);

            setTimeout(() => {
                node.append("clipPath")
                    .attr("id", d => (d.clipUid = Math.random()))
                    .append("use")
                    .attr("xlink:href", d => d.leafUid.href);

                node.append("text")
                    .attr("clip-path", d => d.clipUid)
                    .attr("font-weight", d => d === root ? "bold" : null)
                    .attr("class", "text-container")
                    .selectAll("tspan")
                    .data(d => [(d === root ? name(d) : d.data.name)].concat('$' + priceFormat(d.value)))
                    .join("tspan")
                    .text(d => d)
                    .attr("data-anchor", (d => d.replace(/[^A-Z0-9]/ig, "_")))
                    .text((d, i, nodes) => {
                        let t = d;
                        let rect = nodes[0].parentElement.previousElementSibling.previousElementSibling;
                        let rectWidth = Number.parseFloat(rect.getAttribute('width'));
                        let textWidth = nodes[i].getComputedTextLength();
                        if (textWidth > rectWidth - 50 && t.length > 20 && (!t.includes('← Back') || width < 600)) {
                            t = t.substring(0, 20) + '...';
                        }
                        return t;
                    })
                    .attr("x", (d, i, nodes) => {
                        let rect = nodes[0].parentElement.previousElementSibling.previousElementSibling;
                        let rectWidth = Number.parseFloat(rect.getAttribute('width'));
                        let textWidth = nodes[i].getComputedTextLength();
                        return 3 + rectWidth/2 - textWidth/2;
                    })
                    .attr("y", (d, i, nodes) => {
                        let rect = nodes[0].parentElement.previousElementSibling.previousElementSibling;
                        let rectHeight = Number.parseFloat(rect.getAttribute('height'));
                        return `${rectHeight/2 + i * 20}px`
                    })
                    .attr("fill-opacity", (d, i, nodes) => i === nodes.length - 1 ? 0.7 : null)
                    .attr("font-weight", (d, i, nodes) => i === 0 ? "bold" : "normal");
            }, 100);

        }

        function position(group, root) {
            group.selectAll("g")
                .attr("transform", d => {
                    return d === root ? `translate(0,-70)` : `translate(${x(d.x0)},${y(d.y0)})`
                })
                .select("rect")
                .attr("width", d => {
                    const w = d === root ? width : x(d.x1) - x(d.x0);
                    return w;
                })
                .attr("height", d => {
                    const h = d === root ? 70 : y(d.y1) - y(d.y0);
                    return h;
                })
                .attr('class', d => {
                    let rectWidth = d === root ? width : x(d.x1) - x(d.x0);
                    let classStr = 'show-text';
                    if (rectWidth < maxWidthShowText) {
                        classStr = 'hide-text';
                    }
                    return classStr;
                });
        }

        // When zooming in, draw the new nodes on top, and fade them in.
        function zoomin(d) {
            const group0 = group.attr("pointer-events", "none");
            const group1 = group = svg.append("g").call(render, d);

            x.domain([d.x0, d.x1]);
            y.domain([d.y0, d.y1]);

            svg.transition()
                .duration(0)
                .call(t => group0.transition(t).remove()
                .call(position, d.parent))
                .call(t => group1.transition(t)
                .attrTween("opacity", () => interpolate(0, 1))
                .call(position, d));
        }

        // When zooming out, draw the old nodes on top, and fade them out.
        function zoomout(d) {
            const group0 = group.attr("pointer-events", "none");
            const group1 = group = svg.insert("g", "*").call(render, d.parent);

            x.domain([d.parent.x0, d.parent.x1]);
            y.domain([d.parent.y0, d.parent.y1]);

            svg.transition()
                .duration(0)
                .call(t => group0.transition(t).remove()
                .attrTween("opacity", () => interpolate(1, 0))
                .call(position, d))
                .call(t => group1.transition(t)
                .call(position, d.parent));
        }

        return {
            controller: treemapController, // Expose the controller
        };

    });

    return graphResult;
}

const renderBudgetCategoriesDetailsTreemap = function(chartDomEl, data, legendEl, selectedVendor, treemapCallback) {

    chartDomEl.innerHTML = '';

    let exportRoot;

    let currentRoot = exportRoot;

    let treemapController;

    const {emit}=useEventsBus();

    var graphResult = import('d3').then(({select, treemap, hierarchy, treemapSquarify, treemapBinary, scaleLinear, interpolate}) => {
        let realData = data;

        treemapController = {
            // Method to zoom in to a specific node
            zoomInToNode: (d) => {
                zoomin(d);
            },
            // Method to zoom out to a specific level
            zoomOutToLevel: (toLevel, callback, callback_args) => {
                zoomOutToLevel(null, currentRoot, toLevel, callback, callback_args);
            },
            // Add more methods as needed
        };

        let width = chartDomEl.offsetWidth;
        const container = select(chartDomEl);
        const legend = select(legendEl);
        let firstRender = true;
        let level = 0;

        let height = width;

        function tile(node, x0, y0, x1, y1) {
            treemapBinary(node, 0, 0, width, height);
            for (const child of node.children) {
              child.x0 = x0 + child.x0 / width * (x1 - x0);
              child.x1 = x0 + child.x1 / width * (x1 - x0);
              child.y0 = y0 + child.y0 / height * (y1 - y0);
              child.y1 = y0 + child.y1 / height * (y1 - y0);
            }
        }

        const name = d => {
            let s = '';
            let n = d.ancestors().reverse().map(d => d.data.name);
            for (let i = 0; i < n.length; i++) {
                if (s.length) {
                    s += ' > ';
                }
                if (n[i].length > 10 && i > 0) {
                    s += n[i].substring(0, 10) + '...';
                }
                else {
                    s += n[i] + ' ';
                }
            }
            if (d.ancestors().length > 1) {
                s = '← Back | ' + s;
            }
            return s;
        };

        const contentTypes = d => {
            let s = '';
            let n = d.ancestors().reverse().map(d => d.data.childrenContentTypes);
            /*
            for (let i = 0; i < n.length; i++) {
                if (s.length) {
                    s += ' > ';
                }
                if (n[i].length > 10 && i > 0) {
                    s += n[i];
                }
                else {
                    s += n[i] + ' ';
                }
            }
            */
            if (n.length) {
                s += n[n.length-1];
            }
            if (d.ancestors().length > 1) {
                s = '← Back | ' + s;
            }
            return s;
        };

        const treemapFunc = realData => treemap()
        .tile(tile)
        (hierarchy(realData)
        .sum(d => d.value)
        .sort((a, b) => b.value - a.value))

        var testMe = treemapFunc(realData);
        exportRoot = testMe;

        if (testMe.value == 0) treemapCallback(false);

        //const x = scaleLinear().domain([0, width]).range([0, width]);//rangeRound([0, width]);
        const x = scaleLinear().rangeRound([0, width]);
        //const y = scaleLinear().domain([0, height]).range([0, height]);//.rangeRound([0, height]);
        const y = scaleLinear().rangeRound([0, height]);

        //x.domain([0, width]);
        //y.domain([0, height]);

        let maxWidthShowText = 270;
        let minHeightShowText = 70;

        const svg = container.append('svg')
            .attr("viewBox", [0.5, -60.5, width, height + 70])
        //.style("font", "10px sans-serif");

        let group = svg.append("g")
            .call(render, treemapFunc(realData));

        const tooltip = container.append("div").attr("class", "treemap-tooltip").style('display', 'none');
        const tooltipHide = (event) => {
            setTimeout ( () => {
                if (event === null || event.target === null) {
                    if (document.getElementById('view-vendor-link')) {
                        document.getElementById('view-vendor-link').style.opacity = 0.0;
                    }
                }
                else {
                    event.target.style.opacity = 1.0;
                }
                tooltip.style('display', 'none');
            }, 1500)
        }

        const tooltipShow = (event, d) => {
            let middleX = x(d.x0) + (x(d.x1) - x(d.x0))/2;
            let middleY = y(d.y0) + (y(d.y1) - y(d.y0))/2;
            if (y(d.y1) - y(d.y0) > 100) {
                middleY += 100;
            }
            else {
                middleY += 60;
            }
            event.target.style.opacity = 0.5;
            tooltip
                .html(
                    '<div class="treemap-tooltip-type">' + d.data.contentType + '</div>'
                    + '<div class="treemap-tooltip-title">' + d.data.name + '</div>'
                    // + '<div>$' + priceFormat(d.value) + '</div>'
                )
                .attr("id", "view-vendor-link")
                .attr("data-type", d.data.contentType)
                .attr("data-content-id", d.data.vendorContentId)
                .attr("data-location-id", d.data.vendorLocationId)
                .attr("data-name", d.data.name)
                .style('display', 'block')
                .style('top', middleY + 'px')
                .style('left', middleX + 'px')
                .style('opacity', '1.0');
            /*if (d.data.contentType === 'Disbursement Vendor' && d.data.vendorUrl) {
                tooltip
                    .attr("id", "view-vendor-link")
                    .style("cursor", "pointer");
            }*/
        };

        function getPercentage(root, data) {
            try {
                let thisLevelTotal = 0;
                let siblings = data.parent.children;
                if (siblings) {
                    for (let i = 0; i < siblings.length; i++) {
                        if (siblings[i].value < 0) {
                            continue;
                        }
                        thisLevelTotal += siblings[i].value;
                    }
                }
                if (!thisLevelTotal) {
                    return '';
                }
                let x = Math.round((data.value / thisLevelTotal) * 100) + '%';
                if (x === '0%' && data.value) {
                    return '<1%';
                }
                if (x === '100%' && data.value !== thisLevelTotal) {
                    return '>99%';
                }
                return x;
            } catch (e) {
                return '';
            }
        }

        function clickedTooltip(event) {
            let d = event.currentTarget.d;
            let dataType = document.getElementById('view-vendor-link').getAttribute('data-type');
            if (dataType !== 'Disbursement Vendor') {
                zoomin(d);
                tooltipHide(null);
            }
            else {
                emit('call-open-flyout-vendor', d.data.vendorLocationId || d.data.locationId);
            }
        }

        function render(group, root, callback, callback_args) {

            emit('render-done', true)

            if (firstRender) {
                firstRender = false;
                addItemToLegend(root);
            }

            const node = group
                .selectAll("g")
                .data(root.children.concat(root))
                .join("g");

            node.filter(d => d === root ? d.parent : d.children)
                .attr("cursor", "pointer")
                .on("click", (event, d) => {
                    removeClone();
                    tooltipHide(event);
                    d === root ? zoomout(root, (level-1)) : zoomin(d);
                    setTimeout(() => {
                        for (const a of document.querySelectorAll("tspan")) {
                            if (a.textContent.includes("← Back")) {
                                const index = a.textContent.indexOf("|");
                                const pre = a.textContent.substr(0, index);
                                const post = a.textContent.substr(index + 1);
                                const new_html = "<tspan id='underline'>" + pre.trim() + "</tspan> | " + post;
                                a.innerHTML = new_html;
                                a.setAttribute('underline', 'true');
                            } else {
                                a.removeAttribute('underline');
                            }
                        }
                    }, 100);
                })
                //.on("mouseover", (event, d) => { tooltipShow(event, d) })
                //.on("mouseout", tooltipHide);

            //node.append("title")
            //    .text(d => `${name(d)}\n\$${priceFormat(d.value)}`);

            node.append("rect")
                .attr("id", d => (d.leafUid = Math.random()))
                .attr("fill", (d, i) => { return d === root ? "#fff" : d.children ? CHART_COLORS[(i+1) % CHART_COLORS.length] : CHART_COLORS[(i+1) % CHART_COLORS.length] })
                .attr("stroke", "#fff")
                .on("mouseover", (event, d) => {
                    tooltipShow(event, d);

                    if (document.getElementById('view-vendor-link')) {
                        // remove event listener
                        let viewVendor = document.getElementById('view-vendor-link');

                        viewVendor.classList.add("disabled");
                        viewVendor.removeEventListener('click', clickedTooltip, false);
                        tooltip.style('cursor', 'default');

                        if (viewVendor.getAttribute('data-type')!== 'Disbursement Vendor' || (d.data.vendorContentId && d.data.vendorLocationId)) {
                            viewVendor.classList.remove("disabled");
                            tooltip.style('cursor', 'pointer');
                            viewVendor.addEventListener('click', clickedTooltip, false);
                            viewVendor.d = d;
                        }
                    }
                })
                .on("mouseout", (event, d) => { tooltipHide(event); });

            group.call(position, root);

            setTimeout(() => {
                node.append("clipPath")
                    .attr("id", d => (d.clipUid = Math.random()))
                    .append("use")
                    .attr("xlink:href", d => d.leafUid.href);

                node.append("text")
                    .attr("clip-path", d => d.clipUid)
                    .attr("font-weight", d => d === root ? "bold" : null)
                    .attr("class", "text-container")
                    .selectAll("tspan")
                    .data(d => [(d === root ? contentTypes(d) : d.data.name)].concat(d === root ? ('$' + priceFormat(d.value) + (getPercentage(root, d) ? ' (' + getPercentage(root, d) + ')' : '')) : getPercentage(root, d)))
                    .join("tspan")
                    .text(d => d)
                    .attr("data-anchor", (d => d.replace(/[^A-Z0-9]/ig, "_")))
                    .text((d, i, nodes) => {
                        let t = d;
                        let rect = nodes[0].parentElement.previousElementSibling.previousElementSibling;
                        let rectWidth = Number.parseFloat(rect.getAttribute('width'));
                        let textWidth = nodes[i].getComputedTextLength();
                        if (textWidth > rectWidth - 50 && t.length > 20 && (!t.includes('← Back') || width < 600)) {
                            t = t.substring(0, 20) + '...';
                        }
                        return t;
                    })
                    .attr("x", (d, i, nodes) => {
                        let rect = nodes[0].parentElement.previousElementSibling.previousElementSibling;
                        let rectWidth = Number.parseFloat(rect.getAttribute('width'));
                        let textWidth = nodes[i].getComputedTextLength();
                        return 3 + rectWidth/2 - textWidth/2;
                    })
                    .attr("y", (d, i, nodes) => {
                        let rect = nodes[0].parentElement.previousElementSibling.previousElementSibling;
                        let rectHeight = Number.parseFloat(rect.getAttribute('height'));
                        return `${rectHeight/2 + i * 20}px`
                    })
                    .attr("fill-opacity", (d, i, nodes) => i === nodes.length - 1 ? 0.7 : null)
                    .attr("font-weight", (d, i, nodes) => i === 0 ? "bold" : "normal");

                node.append("path")
                    .attr("d", "M201.4 278.6C207.6 284.9 215.8 288 224 288s16.38-3.125 22.62-9.375l192-192c12.5-12.5 12.5-32.75 0-45.25s-32.75-12.5-45.25 0L224 210.8L54.63 41.38c-12.5-12.5-32.75-12.5-45.25 0s-12.5 32.75 0 45.25L201.4 278.6zM393.4 233.4L224 402.8L54.63 233.4c-12.5-12.5-32.75-12.5-45.25 0s-12.5 32.75 0 45.25l192 192C207.6 476.9 215.8 480 224 480s16.38-3.125 22.62-9.375l192-192c12.5-12.5 12.5-32.75 0-45.25S405.9 220.9 393.4 233.4z")
                    .attr("class", "treemap-icon")
                    .attr("x", 10 + "px")
                    .attr("y", 10 + "px")

                node.append("path")
                    .attr("d", "M31.9965818,348.699822 C14.3189123,348.699822 -3.55271368e-14,334.38091 -3.55271368e-14,316.70324 C-3.19744231e-14,299.025571 14.3189123,284.706658 31.9965818,284.706658 L31.9965818,284.706658 L223.934,284.723 L415.871711,284.706658 C433.549381,284.706658 447.868293,299.025571 447.868293,316.70324 C447.868293,334.38091 433.549381,348.699822 415.871711,348.699822 Z M393.491646,9.375 C405.991646,-3.125 426.241646,-3.125 438.741646,9.375 C451.241646,21.875 451.241646,42.125 438.741646,54.625 L438.741646,54.625 L246.741646,246.625 C240.501646,252.875 232.321646,256 224.121646,256 C215.921646,256 207.721646,252.9 201.521646,246.6 L201.521646,246.6 L9.50164643,54.63 C-2.99835357,42.13 -2.99835357,21.88 9.50164643,9.38 C22.0016464,-3.12 42.2516464,-3.12 54.7516464,9.38 L54.7516464,9.38 L224.121646,178.8 Z")
                    .attr("class", "treemap-icon--stop")
                    .attr("x", 10 + "px")
                    .attr("y", 10 + "px");
                    if (callback != undefined) callback(callback_args);
            }, 100);

        }

        function position(group, root) {
            group.selectAll("g")
                .attr("transform", d => {
                    return d === root ? `translate(0,-70)` : `translate(${x(d.x0)},${y(d.y0)})`
                })
                .select("rect")
                .attr("width", d => {
                    const rectWidth = d === root ? width : Math.min(width, x(d.x1) - x(d.x0));
                    return rectWidth;
                })
                .attr("height", d => {
                    const rectHeight = d === root ? 70 : Math.min(height, (d.value ? Math.max(y(d.y1) - y(d.y0), 10) : 0));
                    return rectHeight;
                })
                .attr('class', d => {
                    const rectWidth = d === root ? width : x(d.x1) - x(d.x0);
                    const rectHeight = d === root ? height : y(d.y1) - y(d.y0);
                    let classStr = 'show-text';
                    if (rectWidth < maxWidthShowText) {
                        if (rectHeight < minHeightShowText) {
                            classStr = 'hide-text';
                        }
                        classStr = 'hide-text';
                    }
                    return classStr;
                });
        }

        function zoomOutToLevel(event, d, toLevel, callback, callback_args) {
            if (toLevel < 0 || toLevel === level) {
                if (callback != undefined) callback(callback_args);
                return;
            }
            zoomout(d, toLevel, callback, callback_args);
        }

        function zoomOutToLevelClickListener(event) {
            zoomOutToLevel(event, event.currentTarget.d, event.currentTarget.zoomToLevel);
        }

        function addItemToLegend(d) {
            if (legendEl) {
                try {
                    let items = legendEl.querySelectorAll('.legend-items')[0];
                    let template = items.querySelectorAll('.legend-item-template')[0];
                    let newItem = template.cloneNode(true);
                    newItem.classList.remove('legend-item-template');
                    newItem.classList.add('legend-item');
                    newItem.querySelectorAll('.legend-item-title')[0].innerHTML = d.data.name;
                    newItem.querySelectorAll('.legend-item-type')[0].innerHTML = d.data.contentType;
                    newItem.querySelectorAll('.legend-item-amount')[0].innerHTML = '$' + priceFormat(d.value);
                    newItem.setAttribute('data-clipuid', d.clipUid);
                    newItem.setAttribute('data-level', level);
                    let zoomToLevel = d.data.level-1;
                    let legendItems = items.querySelectorAll('.legend-item');
                    if (legendItems.length) {
                        let lastLegendItem = legendItems[legendItems.length - 1];
                        if (lastLegendItem) {
                            lastLegendItem.addEventListener('click', zoomOutToLevelClickListener, false);
                            lastLegendItem.d = d;
                            lastLegendItem.zoomToLevel = zoomToLevel;
                        }
                    }
                    newItem.style.display = '';
                    items.appendChild(newItem);
                } catch(e) {
                    console.log(e);
                }
            }
        }

        function removeItemFromLegend(d, toLevel) {
            try {
                let removeMore = false;
                do {
                    removeMore = false;
                    let items = legendEl.querySelectorAll('.legend-items')[0];
                    let legendItems = items.querySelectorAll('.legend-item');
                    if (legendItems.length) {
                        let lastLegendItem = legendItems[legendItems.length - 1];
                        // after we remove this one, do we have more levels to remove?
                        // (level is zero-indexed, so we add one to it)
                        if ((legendItems.length-1) > (toLevel+1)) {
                            removeMore = true;
                        }
                        items.removeChild(lastLegendItem);
                    }
                } while(removeMore);
            } catch(e) {
                console.log(e);
            }
        }

        // When zooming in, draw the new nodes on top, and fade them in.
        function zoomin(d) {
            const group0 = group.attr("pointer-events", "none");
            const group1 = group = svg.append("g").call(render, d);

            x.domain([d.x0, d.x1]);
            y.domain([d.y0, d.y1]);

            svg.transition()
                .duration(0)
                .call(t => group0.transition(t).remove()
                .call(position, d.parent))
                .call(t => group1.transition(t)
                .attrTween("opacity", () => interpolate(0, 1))
                .call(position, d));

            level++;
            currentRoot = d;
            addItemToLegend(d);
        }

        function zoomout(d, toLevel, callback, callback_args) {
            let targetLevel = toLevel;

            const transitionPromises = [];

            while (level > targetLevel) {

                const group0 = group.attr("pointer-events", "none");

                const group1 = group = level == 1 ? svg.insert("g", "*").call(render, d.parent, callback, callback_args) : svg.insert("g", "*").call(render, d.parent);

                x.domain([d.parent.x0, d.parent.x1]);
                y.domain([d.parent.y0, d.parent.y1]);

                svg.transition()
                    .duration(0)
                    .call(t => group0.transition(t).remove()
                    .attrTween("opacity", () => interpolate(1, 0))
                    .call(position, d))
                    .call(t => group1.transition(t)
                    .call(position, d.parent))

                level--;
                currentRoot = d.parent;
                d = d.parent;
                removeItemFromLegend(d, level);
            }
        }

        return {
            controller: treemapController, // Expose the controller
        };

    });

    return graphResult;

}

const renderEncumbrancesDetailsTreemap = function(chartDomEl, data, treemapCallback2) {
    chartDomEl.innerHTML = '';

    import('d3').then(({select, treemap, hierarchy, treemapSquarify, treemapBinary, scaleLinear, interpolate}) => {
        let realData = {
            children: data,
            contentType: 'Encumbrances',
            name: 'Encrumbrances',
            childrenContentTypes: 'Encumbrances',
        };

        let width = chartDomEl.parentElement.offsetWidth;

        const container = select(chartDomEl);
        let firstRender = true;
        let level = 0;

        let height = width;

        function tile(node, x0, y0, x1, y1) {
            treemapBinary(node, 0, 0, width, height);
            for (const child of node.children) {
              child.x0 = x0 + child.x0 / width * (x1 - x0);
              child.x1 = x0 + child.x1 / width * (x1 - x0);
              child.y0 = y0 + child.y0 / height * (y1 - y0);
              child.y1 = y0 + child.y1 / height * (y1 - y0);
            }
        }

        const name = d => {
            let s = '';
            let n = d.ancestors().reverse().map(d => d.data.name);
            for (let i = 0; i < n.length; i++) {
                if (s.length) {
                    s += ' > ';
                }
                if (n[i].length > 10 && i > 0) {
                    s += n[i].substring(0, 10) + '...';
                }
                else {
                    s += n[i] + ' ';
                }
            }
            if (d.ancestors().length > 1) {
                s = '← Back | ' + s;
            }
            return s;
        };

        const contentTypes = d => {
            let s = '';
            let n = d.ancestors().reverse().map(d => d.data.childrenContentTypes);
            /*
            for (let i = 0; i < n.length; i++) {
                if (s.length) {
                    s += ' > ';
                }
                if (n[i].length > 10 && i > 0) {
                    s += n[i];
                }
                else {
                    s += n[i] + ' ';
                }
            }
            */
            if (n.length) {
                s += n[n.length-1];
            }
            if (d.ancestors().length > 1) {
                s = '← Back | ' + s;
            }
            return s;
        };

        const treemapFunc = realData => treemap()
        .tile(tile)
        (hierarchy(realData)
        .sum(d => d.encumbrances)
        .sort((a, b) => b.encumbrances - a.encumbrances))

        var testMe = treemapFunc(realData);
        if (testMe.value == 0) treemapCallback2(false);

        //const x = scaleLinear().domain([0, width]).range([0, width]);//rangeRound([0, width]);
        const x = scaleLinear().rangeRound([0, width]);
        //const y = scaleLinear().domain([0, height]).range([0, height]);//.rangeRound([0, height]);
        const y = scaleLinear().rangeRound([0, height]);

        //x.domain([0, width]);
        //y.domain([0, height]);

        let maxWidthShowText = 230;

        const svg = container.append('svg')
            .attr("viewBox", [0.5, -60.5, width, height + 70])
        //.style("font", "10px sans-serif");

        let group = svg.append("g")
            .call(render, treemapFunc(realData));

        const tooltip = container.append("div").attr("class", "treemap-tooltip").style('display', 'none');
        const tooltipHide = (event) => {
            event.target.style.opacity = 1.0;
            tooltip.style('display', 'none');
        }
        const tooltipShow = (root, event, d) => {
            let middleX = x(d.x0) + (x(d.x1) - x(d.x0))/2;
            let middleY = y(d.y0) + (y(d.y1) - y(d.y0))/2;
            if (y(d.y1) - y(d.y0) > 100) {
                middleY += 100;
            }
            else {
                middleY += 60;
            }
            event.target.style.opacity = 0.5;
            tooltip
                .html(
                    '<div class="treemap-tooltip-type">' + d.data.name + '</div>'
                    + '<div class="treemap-tooltip-title">' + getPercentage(root, d) + '</div>'
                    + '<div>$' + priceFormat(d.value) + '</div>'
                )
                .style('display', 'block')
                .style('top', middleY + 'px')
                .style('left', middleX + 'px');
        };

        function getPercentage(root, data) {
            try {
                let thisLevelTotal = 0;
                let siblings = data.parent.children;
                if (siblings) {
                    for (let i = 0; i < siblings.length; i++) {
                        if (siblings[i].value < 0) {
                            continue;
                        }
                        thisLevelTotal += siblings[i].value;
                    }
                }
                if (!thisLevelTotal) {
                    return '';
                }
                let x = Math.round((data.value / thisLevelTotal) * 100) + '%';
                if (x === '0%' && data.value) {
                    return '<1%';
                }
                if (x === '100%' && data.value !== thisLevelTotal) {
                    return '>99%';
                }
                return x;
            } catch (e) {
                return '';
            }
        }

        function render(group, root) {
            if (firstRender) {
                firstRender = false;
            }

            const node = group
                .selectAll("g")
                .data(root.children.concat(root))
                .join("g");

            node.filter(d => d === root ? d.parent : d.children)
                .attr("cursor", "pointer")
                .on("click", (event, d) => {
                    removeClone();
                    tooltipHide(event);
                    d === root ? zoomout(root, (level-1)) : zoomin(d);
                    setTimeout(() => {
                        for (const a of document.querySelectorAll("tspan")) {
                            if (a.textContent.includes("← Back")) {
                                const index = a.textContent.indexOf("|");
                                const pre = a.textContent.substr(0, index);
                                const post = a.textContent.substr(index + 1);
                                const new_html = "<tspan id='underline'>" + pre.trim() + "</tspan> | " + post;
                                a.innerHTML = new_html;
                                a.setAttribute('underline', 'true');
                            } else {
                                a.removeAttribute('underline');
                            }
                        }
                    }, 100);
                })
                //.on("mouseover", (event, d) => { tooltipShow(event, d) })
                //.on("mouseout", tooltipHide);

            //node.append("title")
            //    .text(d => `${name(d)}\n\$${priceFormat(d.value)}`);

            node.append("rect")
                .attr("id", d => (d.leafUid = Math.random()))
                .attr("fill", (d, i) => { return d === root ? "#fff" : d.children ? CHART_COLORS[(i+1) % CHART_COLORS.length] : CHART_COLORS[(i+1) % CHART_COLORS.length] })
                .attr("stroke", "#fff")
                .on("mouseover", (event, d) => {
                    tooltipShow(root, event, d);
                })
                .on("mouseout", (event, d) => { tooltipHide(event); });

            group.call(position, root);

            setTimeout(() => {
                node.append("clipPath")
                    .attr("id", d => (d.clipUid = Math.random()))
                    .append("use")
                    .attr("xlink:href", d => d.leafUid.href);

                node.append("text")
                    .attr("clip-path", d => d.clipUid)
                    .attr("font-weight", d => d === root ? "bold" : null)
                    .attr("class", "text-container")
                    .selectAll("tspan")
                    .data(d => [(d === root ? contentTypes(d) : d.data.name)].concat(d === root ? ('$' + priceFormat(d.value) + (getPercentage(root, d) ? ' (' + getPercentage(root, d) + ')' : '')) : getPercentage(root, d)))
                    .join("tspan")
                    .text(d => d)
                    .attr("data-anchor", (d => d.replace(/[^A-Z0-9]/ig, "_")))
                    .text((d, i, nodes) => {
                        let t = d;
                        let rect = nodes[0].parentElement.previousElementSibling.previousElementSibling;
                        let rectWidth = Number.parseFloat(rect.getAttribute('width'));
                        let textWidth = nodes[i].getComputedTextLength();
                        if (textWidth > rectWidth - 50 && t.length > 20 && (!t.includes('← Back') || width < 600)) {
                            t = t.substring(0, 20) + '...';
                        }
                        return t;
                    })
                    .attr("x", (d, i, nodes) => {
                        let rect = nodes[0].parentElement.previousElementSibling.previousElementSibling;
                        let rectWidth = Number.parseFloat(rect.getAttribute('width'));
                        let textWidth = nodes[i].getComputedTextLength();
                        return 3 + rectWidth/2 - textWidth/2;
                    })
                    .attr("y", (d, i, nodes) => {
                        let rect = nodes[0].parentElement.previousElementSibling.previousElementSibling;
                        let rectHeight = Number.parseFloat(rect.getAttribute('height'));
                        return `${rectHeight/2 + i * 20}px`
                    })
                    .attr("fill-opacity", (d, i, nodes) => i === nodes.length - 1 ? 0.7 : null)
                    .attr("font-weight", (d, i, nodes) => i === 0 ? "bold" : "normal");

                /*
                node.append("path")
                    .attr("d", "M201.4 278.6C207.6 284.9 215.8 288 224 288s16.38-3.125 22.62-9.375l192-192c12.5-12.5 12.5-32.75 0-45.25s-32.75-12.5-45.25 0L224 210.8L54.63 41.38c-12.5-12.5-32.75-12.5-45.25 0s-12.5 32.75 0 45.25L201.4 278.6zM393.4 233.4L224 402.8L54.63 233.4c-12.5-12.5-32.75-12.5-45.25 0s-12.5 32.75 0 45.25l192 192C207.6 476.9 215.8 480 224 480s16.38-3.125 22.62-9.375l192-192c12.5-12.5 12.5-32.75 0-45.25S405.9 220.9 393.4 233.4z")
                    .attr("class", "treemap-icon")
                    .attr("x", 10 + "px")
                    .attr("y", 10 + "px")

                node.append("path")
                    .attr("d", "M31.9965818,348.699822 C14.3189123,348.699822 -3.55271368e-14,334.38091 -3.55271368e-14,316.70324 C-3.19744231e-14,299.025571 14.3189123,284.706658 31.9965818,284.706658 L31.9965818,284.706658 L223.934,284.723 L415.871711,284.706658 C433.549381,284.706658 447.868293,299.025571 447.868293,316.70324 C447.868293,334.38091 433.549381,348.699822 415.871711,348.699822 Z M393.491646,9.375 C405.991646,-3.125 426.241646,-3.125 438.741646,9.375 C451.241646,21.875 451.241646,42.125 438.741646,54.625 L438.741646,54.625 L246.741646,246.625 C240.501646,252.875 232.321646,256 224.121646,256 C215.921646,256 207.721646,252.9 201.521646,246.6 L201.521646,246.6 L9.50164643,54.63 C-2.99835357,42.13 -2.99835357,21.88 9.50164643,9.38 C22.0016464,-3.12 42.2516464,-3.12 54.7516464,9.38 L54.7516464,9.38 L224.121646,178.8 Z")
                    .attr("class", "treemap-icon--stop")
                    .attr("x", 10 + "px")
                    .attr("y", 10 + "px");
                */
            }, 100);

        }

        function position(group, root) {
            group.selectAll("g")
                .attr("transform", d => {
                    return d === root ? `translate(0,-70)` : `translate(${x(d.x0)},${y(d.y0)})`
                })
                .select("rect")
                .attr("width", d => {
                    const rectWidth = d === root ? width : Math.min(width, x(d.x1) - x(d.x0));
                    return rectWidth;
                })
                .attr("height", d => {
                    const rectHeight = d === root ? 70 : Math.min(height, (d.value ? Math.max(y(d.y1) - y(d.y0), 10) : 0));
                    return rectHeight;
                })
                .attr('class', d => {
                    const rectWidth = d === root ? width : x(d.x1) - x(d.x0);
                    let classStr = 'show-text';
                    if (rectWidth < maxWidthShowText) {
                        classStr = 'hide-text';
                    }
                    return classStr;
                });
        }

        function zoomOutToLevel(event, d, toLevel) {
            if (toLevel < 0 || toLevel === level) {
                return;
            }
            zoomout(d, toLevel);
        }

        function zoomOutToLevelClickListener(event) {
            zoomOutToLevel(event, event.currentTarget.d, event.currentTarget.zoomToLevel);
        }

        // When zooming in, draw the new nodes on top, and fade them in.
        function zoomin(d) {
            const group0 = group.attr("pointer-events", "none");
            const group1 = group = svg.append("g").call(render, d);

            x.domain([d.x0, d.x1]);
            y.domain([d.y0, d.y1]);

            svg.transition()
                .duration(0)
                .call(t => group0.transition(t).remove()
                .call(position, d.parent))
                .call(t => group1.transition(t)
                .attrTween("opacity", () => interpolate(0, 1))
                .call(position, d));

            level++;
        }

        // When zooming out, draw the old nodes on top, and fade them out.
        function zoomout(d, toLevel) {
            const group0 = group.attr("pointer-events", "none");
            const group1 = group = svg.insert("g", "*").call(render, d.parent);

            x.domain([d.parent.x0, d.parent.x1]);
            y.domain([d.parent.y0, d.parent.y1]);

            svg.transition()
                .duration(0)
                .call(t => group0.transition(t).remove()
                .attrTween("opacity", () => interpolate(1, 0))
                .call(position, d))
                .call(t => group1.transition(t)
                .call(position, d.parent));

            level = toLevel;
        }

    });
}

const renderTurnoverAllChart = function(chartDomEl, data) {
    import('d3').then(({schemeSpectral, stack, select, scaleLinear, scaleOrdinal, scaleLog, scaleBand, max, axisBottom, axisLeft, axisTop, range}) => {
        const width = chartDomEl.parentElement.offsetWidth;

        const container = select(chartDomEl);
        const margin = ({top: 30, right: 10, bottom: 0, left: 50})

        const columns = [
            'out',
            'in',
        ];

        const series = stack()
            .keys(columns)
        (data)
            .map(d => (d.forEach(v => v.key = d.agencyLocationId), d));

        const height = data.length * 50 + margin.top + margin.bottom;
        const formatValue = x => isNaN(x) ? "N/A" : x.toLocaleString("en");
        const yAxis = g => g
            .attr("transform", `translate(${margin.left},0)`)
            .style("font-size", "14px")
            .call(axisLeft(y).tickSizeOuter(0))
            .call(g => g.selectAll(".domain").remove());

        const xAxis = g => g
            .attr("transform", `translate(0,${margin.top})`)
            .style("font-size", "14px")
            .call(axisTop(x).ticks(width / 100, "s")/*.tickFormat((d, i) => {
                if (d < 1000000000) {
                    return ('$' + priceFormat(d/1000000) + 'M')
                }
                else {
                    return ('$' + priceFormat(d/1000000000) + 'B')
                }
            })*/)
            .call(g => g.selectAll(".domain").remove());

        const colorSpectral = [
            SPEND_COLORS[4],
            SPEND_COLORS[5],
        ];

        const tooltip = container.append("div").attr("class", "bar-chart-tooltip chart-tooltip").style('display', 'none');
        const tooltipHide = () => tooltip.style('display', 'none');
        const tooltipShow = (event, d) => {
            let title = d.data.fiscalYearFrom + ' ';
            if (d.key === 'disbursementAmount') {
                title += 'Disbursement Amount';
            }
            else {
                title += 'Remaining';
            }
            title += ': $' + formatValue(d.data[d.key]);
            tooltip
                .text(title)
                .style('display', 'block')
                .style('top', (event.offsetY + 'px'))
                .style('left', (event.clientX + 20) + 'px');
        };

        const color = scaleOrdinal()
            .domain(series.map(d => d.key))
            .range(colorSpectral)
            .unknown("#ccc");

        const y = scaleBand()
            .domain(data.map(d => d.agencyName))
            .range([margin.top, height - margin.bottom])
            .padding(0.4);

        const x = scaleLinear()
            .domain([0, max(series, d => max(d, d => d[1]))])
            .range([margin.left, width - margin.right]);

        const svg = container.append("svg")
            .attr("viewBox", [0, 0, width, height]);

        svg.append("g")
            .selectAll("g")
            .data(series)
            .join("g")
            .attr("fill", d => color(d.agencyLocationId))
            .selectAll("rect")
            .data(d => d)
            .join("rect")
            .attr("x", d => x(d[0]))
            .attr("y", (d, i) => y(d.data.out))
            .attr("width", d => x(d[1]) - x(d[0]))
            .attr("height", y.bandwidth())
            .on("mouseover", (event, d) => tooltipShow(event, d))
            .on("mouseout", tooltipHide);
            /*.append("title")
            .text(d => {
                let title = d.data.fiscalYearFrom + ' ';
                if (d.key === 'disbursementAmount') {
                    title += 'Disbursement Amount';
                }
                else {
                    title += 'Remaining';
                }
                title += ': $' + formatValue(d.data[d.key]);
                return title;
            });*/
        svg.append("g")
            .call(xAxis);

        svg.append("g")
            .call(yAxis);

    });
}
export {
    renderBudgetChart,
    renderBudgetTypeChart,
    renderDisbursementCategoriesChart,
    renderProgramBudgetHistoryChart,
    renderStateBudgetChart,
    renderStateAgencyBudgetsTreemap,
    renderBudgetDetailsTreemap,
    renderBudgetCategoriesDetailsTreemap,
    renderTurnoverAllChart,
    renderEncumbrancesDetailsTreemap,
    renderProcurementChart,
    renderContractChart,
    renderDocumentsChart,
};
