import RequestHandler from '@/handler/RequestHandler';
import LocalStorageHandler from "@/handler/LocalStorageHandler";
import { ACTION_TYPES, MUTATION_TYPES } from "@/constants";

export const createUserStore = () => {
    const sessionId = LocalStorageHandler.get('sesId');
    const csrfToken = LocalStorageHandler.get('sesToken');
    const eZSessIdKey = LocalStorageHandler.get('eZSessIdKey');

    let refreshUserInterval = null;

    const state = () => ({
        bookmarkedIds: [],
        bookmarkedIdsLoaded: false,
        sessionId: sessionId,
        csrfToken: csrfToken,
        eZSessIdKey: eZSessIdKey,
        userReference: null,
        user: null,
        userLoading: false,
        userLoaded: false,
        signInError: false,
        redirectPath: '',
        newlyRegistered: false,
        savedPasswordFromData: null,
    });

    const userWakeUp = (context, skipUserRefetch) => {
        const wakeFailed = () => {
            context.commit(MUTATION_TYPES.setUser, null);
            context.commit(MUTATION_TYPES.setUserLoading, false);
            context.commit(MUTATION_TYPES.setUserLoaded, true);
        };

        return RequestHandler.sessionRefresh(context.state.sessionId, context.state.csrfToken, context.state.eZSessIdKey)
            .then((data) => {
                if (data.status && data.status !== 200) {
                    wakeFailed();
                } else {
                    parseSession(data, context, skipUserRefetch);
                }
            })
            .catch(wakeFailed);
    }

    const fetchUser = (context) => {
        const failed = () => {
            context.commit(MUTATION_TYPES.setUser, null);
            context.commit(MUTATION_TYPES.setUserLoading, false);
            context.commit(MUTATION_TYPES.setUserLoaded, true);
        };

        return RequestHandler.loadUserInfo().then(response => {
            context.commit(MUTATION_TYPES.setUser, response);
            context.commit(MUTATION_TYPES.setUserLoading, false);
            context.commit(MUTATION_TYPES.setUserLoaded, true);

            // refresh session every 100 mins, so it will not expire while you're on the website
            if (!refreshUserInterval) {
                refreshUserInterval = setInterval(() => {
                    if (context.state.sessionId) {
                        userWakeUp(context, true)
                    }
                }, 6000000);
            }
        }).catch(failed);
    }

    const parseSession = (data, context, skipUserRefetch) => {
        const session = data.Session;
        context.commit(MUTATION_TYPES.setUserReference, session.User);
        context.commit(MUTATION_TYPES.setSessionId, session.identifier);
        context.commit(MUTATION_TYPES.setCsrfToken, session.csrfToken);
        context.commit(MUTATION_TYPES.setEZSessIdKey, session.name);
        context.commit(MUTATION_TYPES.setSignInError, false);
        if (!skipUserRefetch) {
            fetchUser(context);
        }
    }

    const actions = {
        [ACTION_TYPES.loadUser]: (context) => {
            context.commit(MUTATION_TYPES.setUserLoading, true);
            context.commit(MUTATION_TYPES.setUserLoaded, false);
            // try to load from local storage
            if (!context.state.userReference && context.state.sessionId && context.state.csrfToken) {
                return userWakeUp(context, true).then(response => {
                    return context.state.userReference ? fetchUser(context) : response;
                });
            }
            // try to load from state storage
            if (context.state.userReference) {
                return fetchUser(context);
            }
            // not stored anywhere loaded null
            setTimeout(() => {
                context.commit(MUTATION_TYPES.setUserLoading, false);
                context.commit(MUTATION_TYPES.setUserLoaded, true);
            }, 10);
        },

        [ACTION_TYPES.signIn]: (context, payload) => {
            context.commit(MUTATION_TYPES.setUserLoading, true);
            context.commit(MUTATION_TYPES.setUserLoaded, false);
            context.commit(MUTATION_TYPES.setSignInError, false);
            const failed = () => {
                context.commit(MUTATION_TYPES.setUser, null);
                context.commit(MUTATION_TYPES.setSignInError, true);
                context.commit(MUTATION_TYPES.setUserLoading, false);
                context.commit(MUTATION_TYPES.setUserLoaded, true);
            };
            return RequestHandler.signIn(payload)
                .then((data) => {
                    if (!data.status && data.status > 201) {
                        failed();
                    } else {
                        parseSession(data, context);
                        context.dispatch('fetchAgencies');
                        context.dispatch('loadAllDefinitions');
                    }
                })
                .catch(failed);
        },
        [ACTION_TYPES.logout]: (context) => {
            context.commit(MUTATION_TYPES.setUserLoading, true);
            context.commit(MUTATION_TYPES.setUserLoaded, false);
            return RequestHandler.logout(context.state.sessionId, context.state.csrfToken)
                .then(() => {
                    context.commit(MUTATION_TYPES.setUser, null);
                    context.commit(MUTATION_TYPES.setUserLoading, false);
                    context.commit(MUTATION_TYPES.setUserLoaded, true);
                    context.commit(MUTATION_TYPES.setSessionId, null);
                    context.commit(MUTATION_TYPES.setCsrfToken, null);
                    context.commit(MUTATION_TYPES.setEZSessIdKey, null);
                });
        },
        [ACTION_TYPES.loadBookmarkIds]: (context) => {
            RequestHandler.loadBookmarkLocationIds()
                .then(ids => {
                    context.commit(MUTATION_TYPES.setBookmarkIds, ids);
                    context.commit(MUTATION_TYPES.setBookmarkIdsLoaded, true);
                })
                .catch((error) => {
                    console.error(error);
                    context.commit(MUTATION_TYPES.setBookmarkIdsLoaded, false);
                });
        },
        [ACTION_TYPES.addBookmarkId]: (context, id) => {
            context.commit(MUTATION_TYPES.addBookmarkId, id);
            RequestHandler.addBookmarkId(id, context.state.csrfToken)
                .catch((error) => {
                    console.error(error);
                    context.commit(MUTATION_TYPES.removeBookmarkId, id);
                });
        },
        [ACTION_TYPES.removeBookmarkId]: (context, id) => {
            context.commit(MUTATION_TYPES.removeBookmarkId, id);
            RequestHandler.removeBookmarkId(id, context.state.csrfToken)
                .catch((error) => {
                    console.error(error);
                    context.commit(MUTATION_TYPES.addBookmarkId, id);
                });
        },
    };

    const mutations = {
        [MUTATION_TYPES.setSessionId]: (state, data) => {
            state.sessionId = data;
            LocalStorageHandler.set('sesId', data);
        },
        [MUTATION_TYPES.setBookmarkIdsLoaded]: (state, data) => state.bookmarkedIdsLoaded = data,
        [MUTATION_TYPES.setBookmarkIds]: (state, data) => state.bookmarkedIds = data,
        [MUTATION_TYPES.addBookmarkId]: (state, data) => state.bookmarkedIds.push(data),
        [MUTATION_TYPES.removeBookmarkId]: (state, data) => {
            const index = state.bookmarkedIds.indexOf(data);
            if (index !== -1) {
                state.bookmarkedIds.splice(index, 1);
            }
        },
        [MUTATION_TYPES.setCsrfToken]: (state, data) => {
            state.csrfToken = data;
            LocalStorageHandler.set('sesToken', data);
        },
        [MUTATION_TYPES.setEZSessIdKey]: (state, data) => {
            state.eZSessIdKey = data;
            LocalStorageHandler.set('eZSessIdKey', data);
        },
        [MUTATION_TYPES.setUserReference]: (state, data) => state.userReference = data,
        [MUTATION_TYPES.setUser]: (state, data) => state.user = data,
        [MUTATION_TYPES.setUserLoading]: (state, data) => state.userLoading = data,
        [MUTATION_TYPES.setUserLoaded]: (state, data) => state.userLoaded = data,
        [MUTATION_TYPES.setSignInError]: (state, data) => state.signInError = data,
        [MUTATION_TYPES.setRedirectPath]: (state, data) => state.redirectPath = data,
        [MUTATION_TYPES.setVerificationCode]: (state, data) => state.verificationCode = data,
        [MUTATION_TYPES.setNewlyRegistered]: (state, data) => state.newlyRegistered = data,
        [MUTATION_TYPES.setUserVerified]: (state, x) => state.user.verified = x,
        [MUTATION_TYPES.setSavedPasswordFromData]: (state, data) => state.savedPasswordFromData = data,
    };

    const getters = {
        hasSession: (state) => !!state.sessionId,
        isUserLoading: (state) => state.userLoading,
        isNewlyRegistered: (state) => state.newlyRegistered,
        bookmarkedIds: (state) => state.bookmarkedIds,
        isBookmarkedIdsLoaded: (state) => state.bookmarkedIdsLoaded,
        isUserLoaded: (state) => state.userLoaded,
        isSignInError: (state) => state.signInError,
        user: (state) => state.user,
        csrf: (state) => state.csrfToken,
        redirectPath: (state) => state.redirectPath,
        verificationCode: (state) => state.verificationCode,
        savedPasswordFromData: (state) => state.savedPasswordFromData,
    };


    return {state, actions, mutations, getters}
};
