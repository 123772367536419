<template>
    <!-- Component: molecules/tag-input -->
    <div class="field">
        <label>{{ label || 'Tag'}}</label>
        <p class="help-text">You can start typing Contact Names, Positions, Agencies or Programs and we'll see if we can find the related tag in our system.</p>
        <div class="control">
            <input type="search" class="input" v-model="searchInput">
        </div>

        <ul>
            <li v-for="searchItem in searchResults" @click.prevent="selectTag(searchItem)">
                <span v-if="searchItem.objectNameString">{{ searchItem.objectNameString }}:</span>
                <span v-if="searchItem.class === 'ContactRef'">
                    {{ searchItem.firstName }} {{ searchItem.middleName }} {{ searchItem.lastName }}
                </span>
                <span v-else>{{ searchItem.name }}</span>
                <span v-if="searchItem.agency"> ({{ searchItem.agency.name }})</span>
            </li>
        </ul>

    </div>
    <!-- End Component: molecules/tag-input -->
</template>

<script>
    import RequestHandler from "@/handler/RequestHandler";
    import { watch, ref } from "vue";

    export default {
        name: 'TagInput',
        props: ['label'],
        emits: ['tag-selected'],
        setup(props, {emit}) {
            const searchInput = ref('');
            const searchResults = ref([]);
            let pending = null;

            const updateAutocompleteResults = () => {
                RequestHandler.loadSearch(searchInput.value).then(response => {
                    searchResults.value = response.searchResults || [];
                }).catch((error) => {
                    console.error(error);
                    searchResults.value = [];
                });
            };

            watch(searchInput, (now) => {
                if (pending) {
                    clearTimeout(pending);
                }
                if (now.length > 2) {
                    pending = setTimeout(updateAutocompleteResults, 200);
                } else {
                    searchResults.value = [];
                }
            });

            const selectTag = (tag) => {
                emit('tag-selected', tag);
                searchInput.value = '';
            }

            return {
                selectTag,
                searchInput,
                searchResults
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";

    .field > label {
        margin-bottom: 0.5em;
        display: block;
        font-size: 1rem;
        font-weight: 700;
        color: $blue--dark;
    }
</style>
