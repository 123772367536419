import { createStore } from 'vuex';
import { createPageStore } from './PageStore';
import { createUserStore } from './UserStore';
import { createDefinitionsStore } from './DefinitionsStore';

export const createConfiguredStore = () => {
    const PageStore = createPageStore();
    const UserStore = createUserStore();
    const DefinitionsStore = createDefinitionsStore();

    return createStore({
        state: {},
        mutations: {},
        actions: {},
        modules: {PageStore, UserStore, DefinitionsStore}
    });
};
