<template>
    <!-- Component: molecules/definition -->

    <details class="glossary__item" :class="setType()">
        <summary class="glossary__item__title " :class="class" v-if="class" @click="disable()">{{ name }}</summary>
        <summary class="glossary__item__title" v-else>{{ name }}</summary>

        <p>
            <div :id="'tabs-with-content-' + rand">
                <div class="tabs is-centered">
                    <ul>
                        <li class="is-active">
                            <a>Gov definition</a>
                        </li>
                        <li>
                            <a>What it means</a>
                        </li>
                    </ul>
                </div>
                <div>
                    <section class="tab-content is-active" v-if="definition">{{ definition }}</section>
                    <section class="tab-content is-active" v-else>No definition found!</section>

                    <section class="tab-content" v-if="secondDefinition">{{ secondDefinition }}</section>
                    <section class="tab-content" v-else>No definition found!</section>
                </div>
            </div>
        </p>

    </details>

    <!-- End Component: molecules/definition -->
</template>

<script>
    export default {
        name: 'Definition',
        props: {
            name: String,
            definition: String,
            secondDefinition: String,
            darkMode: Boolean,
            rand: Number,
            class: String
        },
        methods: {
            setType() {
                if (this.darkMode == true) {
                    const compiledType = ' dark-mode';
                    return compiledType;
                }
            }
        },
        data() {
            return {

            }
        },
        setup() {


        },
        computed: {

        },
        mounted() {
            const random = this.rand;

            let tabs = document.querySelectorAll('#tabs-with-content-' + random + ' .tabs li');

            let deactvateAllTabs = function () {
                tabs.forEach(function (tab) {
                tab.classList.remove('is-active');
                });
            };

            let hideTabsContent = function () {
                document.querySelectorAll('#tabs-with-content-' + random + ' .tab-content').forEach(function (tabContent) {
                tabContent.classList.remove('is-active');
                });
            };

            let activateTabsContent = function (tab) {
                document.querySelectorAll('#tabs-with-content-' + random + ' .tab-content')[getIndex(tab)].classList.add('is-active');
            };

            let getIndex = function (el) {
                return [...el.parentElement.children].indexOf(el);
            };

            tabs.forEach(function (tab) {
                tab.addEventListener('click', function () {
                    deactvateAllTabs();
                    hideTabsContent();
                    tab.classList.add('is-active');
                    activateTabsContent(tab);
                });
            });

            tabs[0].click();
        }
    }
</script>

<style lang="scss">
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";

    @include block('glossary') {
        @include element('item') {
            margin-bottom: 15px;
            padding: 15px;
            @media screen and (min-width: 768px) and (max-width: 1215px) {
                padding: 5px;
            }
            &:nth-of-type(even) {
                background-color: rgba(255,255,255,0.05);
                border: 1px solid rgba(255,255,255,0.1);
            }
            @include element('title') {
                font-weight: 600;
                font-size: 1.2em;
                &.no-results {
                    pointer-events: none; /* prevents click events */
                    user-select: none; /* prevents text selection */
                    &:after {
                        content: '';
                    }
                }
            }
            @include element('definition') {}
            &.dark-mode {
                .tabs ul {
                    border-color: $blue--med;
                }
                .tabs a, .tab-content {
                    color: $white;
                    border-bottom-color: $blue--med;
                }
                .tabs li a {
                    padding: 5px 15px;
                    @media screen and (min-width: 768px) and (max-width: 1215px) {
                        padding: 5px 10px;
                        font-size: 14px;
                    }
                }
                .tabs li.is-active a {
                    background-color: $blue--med;
                    border-color: $blue--med;
                }
                .glossary__item__title {
                    color: $white;
                    margin-bottom: 10px;
                    padding-bottom: 0px;
                }
            }
        }
    }

    // bulma-tabs: https://eiji.dev/bulma-tabs-with-content.html
    div[id^="tabs-with-content-"] .tabs:not(:last-child) {
        margin-bottom: 0;
    }

    div[id^="tabs-with-content-"] .tab-content {
        padding: 1rem;
        display: none;
        border: 1px solid $blue--med;
    }

    div[id^="tabs-with-content-"] .tab-content.is-active {
        display: block;
    }

    // https://codepen.io/frederickallen/pen/NZMEMw
    details[open] summary ~ * {
        animation: open 0.3s ease-in-out;
    }

    @keyframes open {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    details summary::-webkit-details-marker {
        display: none;
    }

    details summary {
        width: 100%;
        position: relative;
        cursor: pointer;
        font-size: 1.25rem;
        font-weight: 300;
        list-style: none;
        margin: 0px !important;
        padding: 0px !important;
    }

    details summary:after {
        content: "+";
        color: $white;
        position: absolute;
        font-size: 1.75rem;
        line-height: 0;
        margin-top: 0.75rem;
        right: 0;
        font-weight: 200;
        transform-origin: center;
        transition: 200ms linear;
    }

    details[open] summary:after {
        transform: rotate(45deg);
        font-size: 2rem;
    }

    details summary {
        outline: 0;
    }

    details p {
        font-size: 0.95rem;
        margin: 0 0 1rem;
        padding-top: 1rem;
    }

</style>
