<template>
    <!-- Component: atoms/card-tag -->
    <span class="info-card__tag">
        <Tooltip :tooltipText="`The contact's information is analyzed daily for any updates and this is the date of the last time their was a change to the contact's profile.`">
            <strong class="dotted-underline">Last Change:</strong> {{ modificationMonth }}
        </Tooltip>
    </span>
    <!-- End Component: atoms/card-tag -->
</template>

<script>
    import Tooltip from "./tooltip";
    export default {
        name: 'CardTag',
        props: ['modificationMonth'],
        data() {
            return {}
        },
        components: {
			Tooltip,
		},
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";

    .info-card__tag {
        float: right;
        background-color: $white;
        padding: 5px 30px;
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;
        border: 1px solid #D9E7F4;
        border-bottom: none;
        margin-right: 20px;
        color: $blue--dark;

        strong {
            color: $blue--dark;
        }
    }

    .dotted-underline {
        border-bottom: 1px dotted $blue--med;
    }
</style>
