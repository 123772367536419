<template>
    <!-- Component: organisms/faq-item -->
    <template v-for="(item, k, i) in items" :key="k + '-title'">

        <div :id="item.name.replace(/[^A-Z0-9]/ig, '_') + '-modal'" class="modal faq-modal" v-if="item.children.length">
            <div class="modal-background" @click="$emit('close');resetAccordions();"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">{{ item.name }}</p>
                    <button class="delete" aria-label="close" @click="$emit('close')"></button>
                </header>
                <section class="modal-card-body">
                    <div class="modal-card-content">

                        <div class="faq-item__container" v-if="item.children.length">
                            <template v-for="(itemChild, k2) in item.children" :key="k + '-child-' + k2">
                                <div class="faq-item">
                                    <div class="accordions accordions-custom">
                                        <div class="accordion">

                                            <button :class="'btn btn__' + k2" data-toggle="collapse" :data-target="'.collapse.item' + k2" data-text="Collapse" @click="triggerAccordion($event)">
                                                <img class="header-icon header-icon--closed shown" src="@/assets/svg/plus.svg" alt="open icon" />
                                                <img class="header-icon header-icon--open" src="@/assets/svg/minus.svg" alt="close icon" />
                                                <p class="header-title">{{ itemChild.name }}</p>
                                            </button>

                                            <div :class="'accordion-content block collapse item' + k2">
                                                <div class="container">
                                                    <div class="columns">
                                                        <div class="column">
                                                            <div class="faq-content" v-html="itemChild.content"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </div>

                    </div>
                </section>
            </div>
        </div>

    </template>
    <!-- End Component: organisms/faq-item -->
</template>

<script>
    import { v4 as uuidv4 } from "uuid";

    export default {
        name: 'Faqs',
        props: {
            items: Object
        },
        emits: ['close'],
        data() {
            return {

            }
        },
        setup(props, {emit}) {

            return {

            };
        },
        methods: {
            triggerAccordion(ev) {
                // array of all triggers
                let triggers = Array.from(document.querySelectorAll('[data-toggle="collapse"]'));
                // the button triggered
                const elm = ev.target;

                // find all the triggerable items
                const items = Array.from(document.getElementsByClassName('btn'));
                items.forEach(target => {
                    // if the button is is the same as the one just triggered
                    if (target === elm) {
                        // skip this one, it is the one triggered
                    } else { // otherwise
                        // close the item
                        target.nextElementSibling.classList.remove('show');
                        // reset the icons on the button
                        target.querySelector('.header-icon--closed').classList.add('shown');
                        target.querySelector('.header-icon--open').classList.remove('shown');
                    }
                });

                // switch icons in the button
                var openIcon = elm.querySelector('.header-icon--closed');
                var closeIcon = elm.querySelector('.header-icon--open');
                closeIcon.classList.toggle('shown');
                openIcon.classList.toggle('shown');

                // if the button triggered is inside of the array of all the triggers (this should always be true)
                if (triggers.includes(elm)) {
                    // get the content related to the trigger button
                    const selector = elm.getAttribute('data-target');
                    const targets = Array.from(document.querySelectorAll(selector));

                    const fnmap = {
                        'toggle': 'toggle',
                        'show': 'add',
                        'hide': 'remove'
                    };

                    // open the selected one
                    targets.forEach(target => {
                        target.classList[fnmap['toggle']]('show');
                    });
                }
            },
            resetAccordions() {
                // find all the triggerable items
                const items = Array.from(document.getElementsByClassName('btn'));
                items.forEach(target => {
                    // close the item
                    target.nextElementSibling.classList.remove('show');
                    // reset the icons on the button
                    target.querySelector('.header-icon--closed').classList.add('shown');
                    target.querySelector('.header-icon--open').classList.remove('shown');
                });
            }
        },
        mounted() {
            this.id = uuidv4();
        }

    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";
    @import "../../assets/scss/partials/_accordion-checkbox.scss";


    .modal.faq-modal {
        display: none;
        z-index: 110;
        &.is-active {
            display: flex;
        }
    }

    .header-icon {
        display: none;
        &.shown {
            display: inline-block;
        }
    }

    .header-title, .header-icon, .header-icon--open {
        pointer-events: none;
    }

    .header-title {
        display: inline-block;
        font-weight: 600;
        font-size: 28px;
        margin-left: 10px;
        margin-bottom: 0px;
        position: relative;
        top: -5px;
    }

    .faq-item__container {
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .faq-item {
        border: 3px solid $blue--med;
        width: 100%;
        transition: all 0.3s ease-in-out;
        background-color: $white;
        border-radius: 6px;
        padding-bottom: 10px;
        margin-bottom: 15px;
        .accordion-label {
            .header-icon, .header-icon--open {
                padding-right: 15px;
            }
            .header-title {
                font-size: 28px;
                margin-bottom: 0px;
            }
        }
        .accordion-content {
            padding-top: 0px;
            .column {
                padding-top: 0px;
            }
        }
    }

    .faq-content {
        padding: 20px;
    }

    .modal-card-head, .modal-card-foot {
        border: none;
        background-color: white;
    }

    .modal-card-body {
        border-top: 1px solid $grey--dark;
    }

    .modal-card-title {
        color: $blue--dark;
        font-size: 24px;
        margin-bottom: 0px;
        font-weight: 600;
        flex-shrink: initial !important;
        line-height: 1.4em !important;
        &.modal-card-title__name {
            font-weight: 600;
            color: $blue--dark;
            font-size: 28px;
            margin-bottom: 30px;
        }
    }
    @media screen and (min-width: 769px) {
        .modal-card {
            width: 740px;
        }
    }

    @media screen and (min-width: 1200px) {
        .modal-card {
            min-width: 1100px;
            width: 80%;
        }
    }

    .collapse {
        display: block;
        max-height: 0px;
        overflow: hidden;
        transition: max-height .5s cubic-bezier(0, 1, 0, 1);

        &.show {
            max-height: 99em;
            transition: max-height .5s ease-in-out;
        }
    }

    .btn {
        background-color: transparent;
        border: none;
        margin-top: 15px;
    }
</style>
